import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import Iconify from '../../../components/iconify';
//
import AccountPopover from './AccountPopover';
import '../../../App.css'
import NotificationsPopover from './NotificationsPopover';
import { onMessageListener } from '../../../firebase';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 90;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: '#F9FAFB',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 5}px)`,
    backgroundColor: '#F9FAFB'
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }: any) {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = async () =>
    setTimeout(() => {
      toast(<ToastDisplay />);
    }, 10);

  const ToastDisplay = () => {
    setNotification({ title: '', body: '' });
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify()
      // addData();
    }
    onMessageListener().then((payload: any) => {
      setNotification({ title: payload.notification.title, body: payload.notification.body });
    })
      .catch((err) => console.log('failed: ', err));
  })

  return (
    <>
      <Toaster
        position="top-right"
      />
      <StyledRoot>
        <StyledToolbar>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
            sx={{ bgcolor: '#F9FAFb' }}
          >
            <NotificationsPopover />
            <AccountPopover />
          </Stack>
        </StyledToolbar>
      </StyledRoot>
    </>
  );
}
