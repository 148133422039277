import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { FormGroup, Typography, Box, Grid, Button, Stack, FormHelperText, TextField, IconButton, InputAdornment } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import './LoginPage.css'
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, confirmPasswordReset } from '../firebase';
import { Form } from 'react-bootstrap';
import Iconify from '../components/iconify';

const initialState = {
  password: "",
  confirmPassword: "",
  id: "",
  token: ""
}

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function ResetPasswordPage() {
  const [reset, setReset] = useState<any>(initialState)
  const [isPasswordValidateError, setIsPasswordValidateError] = useState(false);
  const [passwordValidateErrorMsg, setPasswordValidateErrorMsg] = useState("");
  const [isConfirmPasswordError, setIsConfirmPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  window.onpopstate = () => {
    navigate('/');
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setReset({ ...reset, [name]: value });
  };

  const mediumRegex = new RegExp(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*\d))|((?=.*[A-Z])(?=.*\d)))(?=.{7,})/);
  const ValidatePassword = (event: any) => {
    if (event.target.value) {
      if (!(mediumRegex.test(event.target.value))) {
        setIsPasswordValidateError(true);
        setPasswordValidateErrorMsg("Password must be 8 character long including number,special character and alphabet");
      } else {
        setIsPasswordValidateError(false);
        setPasswordValidateErrorMsg("");
      }
    } else {
      setIsPasswordValidateError(true);
      setPasswordValidateErrorMsg("Please enter your new password");
    }
  }

  const confirmPasswordValidation = (event: any) => {
    if (event.target.value) {
      if ((reset.password !== event.target.value)) {
        setIsConfirmPasswordError(true);
        setConfirmPasswordError("Password and confirm password must be match")
      } else {
        setIsConfirmPasswordError(false);
        setConfirmPasswordError("");
      }
    } else {
      setIsConfirmPasswordError(true);
      setConfirmPasswordError("Please enter your confirm password");
    }
  }

  const validateForm = () => {
    let flag = true;
    if (!reset.password) {
      setIsPasswordValidateError(true);
      setPasswordValidateErrorMsg("Please enter your new password");
      flag = false;
    } else {
      if ((mediumRegex.test(reset.password))) {
        setIsPasswordValidateError(false);
        setPasswordValidateErrorMsg("");
      } else {
        setIsPasswordValidateError(true);
        setPasswordValidateErrorMsg("Password must be 8 character long including number,special character and alphabet");
        flag = false;
      }
    }
    if (!reset.confirmPassword) {
      setIsConfirmPasswordError(true);
      setConfirmPasswordError("Please enter your confirm password");
      flag = false;
    } else {
      if ((reset.confirmPassword !== reset.password)) {
        setIsConfirmPasswordError(true);
        setConfirmPasswordError("Password and confirm password must be match")
      } else {
        setIsConfirmPasswordError(false);
        setConfirmPasswordError("");
      }
    }
    return flag;
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let flag = validateForm();
    if (flag) {
      if (reset.password === reset.confirmPassword) {
        try {
          const params = new URLSearchParams(window.location.search);
          const oobCode: any = params.get('oobCode')
          const res = await confirmPasswordReset(auth, oobCode, reset.password);
          toast.success(' Password Change Successfully Please Login',
            {
              position: 'top-right',
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            });
          setTimeout(() => {
            navigate("/");
          }, 6000)
        } catch (err: any) {
          console.error(err);
        }
      }
    };
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault();
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <Helmet>
        <title> ResetPassword | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <StyledRoot>
        <Grid container spacing={0} style={{ flexDirection: 'row' }}>
          <Box className="container-info ">
            <Typography className="centered">
              <Box display="flex" alignItems="center" >
                <img src="/vyanjanLogo.png" alt="logo" width="24%" className="centered-img" />
                <Box >
                  <img src="/vyanjanTagline.png" alt="logo" width="100%" className="centered-img" />
                </Box>
              </Box>
            </Typography>
          </Box >
          <div className="container-main">
            <Box sx={{ width: '80%', margin: "auto" }} className="centered">
              <Typography style={{ fontWeight: '700', fontSize: "152%" }}>
                RESET PASSWORD
              </Typography>
              <Form onSubmit={onFormSubmit}>
                <Stack spacing={3} sx={{ mt: 3 }}>
                  <FormGroup>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      autoFocus
                      label="New password"
                      name="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={reset.password}
                      onChange={(event: any) => { ValidatePassword(event); handleInputChange(event); }}
                    />
                    <FormHelperText style={{ color: 'red', height: '22px' }}>{isPasswordValidateError && passwordValidateErrorMsg}</FormHelperText>
                    <TextField sx={{ mt: 3 }}
                      type={showConfirmPassword ? 'text' : 'password'}
                      label="Confirm password"
                      name="confirmPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                              <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={reset.confirmPassword}
                      onChange={(event: any) => { confirmPasswordValidation(event); handleInputChange(event); }} />
                    <FormHelperText style={{ color: 'red', height: '22px' }}>{isConfirmPasswordError && confirmPasswordError}</FormHelperText>
                  </FormGroup>
                </Stack>
                {/* <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ mt: 2 }}>
                  <RouterLink to="/" style={{ textDecoration: "none", color: "#1565c0", fontSize: '0.865rem', marginBottom: '2%' }}>
                    Back To Login Page
                  </RouterLink>
                </Stack> */}
                <Button fullWidth
                  sx={{ mt: 3 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={(e) => handleSubmit(e)}>
                  Submit
                </Button>
              </Form>
            </Box>
          </div>
        </Grid>
      </StyledRoot>
    </>
  );
}
