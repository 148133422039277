import { useEffect, useState } from 'react';
import {
    alpha,
    Avatar,
    Breadcrumbs,
    Button, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, OutlinedInput, Paper, Stack, styled, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography,
} from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import { Link, useNavigate } from 'react-router-dom'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import AddIcon from '@mui/icons-material/Add';
import Loader from './../spinner';
import { storage, db } from "./../../firebase"
import { getDownloadURL, ref, uploadString } from '@firebase/storage';
import { Helmet } from 'react-helmet-async';
import { addDoc, collection, deleteDoc, doc, getDocs, getCountFromServer, updateDoc, query, limit, startAfter, endBefore, limitToLast, orderBy, where, serverTimestamp } from 'firebase/firestore';
import { v4 } from 'uuid';
import Scrollbar from '../../components/scrollbar';
import Resizer from "react-image-file-resizer";
import Iconify from '../../components/iconify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './productCategory.css';
import './../FoodMakerPage.css';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 280,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

const initialState = {
    id: '',
    PCid: "",
    image: "",
    name: "",
    url: "",
    isActive: ""
}

export const ProductCategory = () => {
    const [product, setProduct] = useState<any>(initialState)
    const [row, setRow] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [prevPage, setPrevPage] = useState<number>(1);
    let [limits, setLimits] = useState<number>(10);
    let [image, setImage] = useState<any>("");
    let [url, setUrl] = useState("")
    let [searchInput, setSearchInput] = useState('');
    let [detail, setDetail] = useState<any>([]) // to show data inside page
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDel, setIsDel] = useState(false)
    const [isCheck, setIsCheck] = useState(false);
    const [flag, setFlag] = useState<boolean>(true);
    const [isNameError, setNameError] = useState(false);
    const [NameErrorMsg, setNameErrorMsg] = useState("");
    const [isImageError, setImageError] = useState(false);
    const [ImageErrorMsg, setImageErrorMsg] = useState("");
    const [lastVisible, setLastVisible] = useState<any>();
    const [firstVisible, setFirstVisible] = useState<any>();
    const [prevLastVisible, setPrevLastVisible] = useState<any>();
    const [prevFirstVisible, setPrevFirstVisible] = useState<any>();

    const navigate = useNavigate();

    //if click on view set this item in sessionStorage which used in detail page
    const handleClickDetails = (element: any) => {
        let uid = element?.id
        sessionStorage.setItem("productName", element?.name + "");
        navigate(`/admin/productcategory/view/${uid}`)
    }

    const reg = new RegExp(/^[a-zA-Z_ ]+$/); //regex only alphabet
    const validateName = (arr: any) => {
        if (arr.target.value) {
            if ((reg.test(arr.target.value))) {
                setNameError(false);
                setNameErrorMsg("");
            } else {
                setNameError(true);
                setNameErrorMsg("Only Alphabet is allowed");
            }
        } else {
            setNameError(true);
            setNameErrorMsg("Product category is required");
        }
    }

    //image validation
    const ValidateImage = (arr: any) => {
        if (arr.target.value) {
            setImage(false);
            setImageErrorMsg("")
        } else {
            setImageError(true)
            setImageErrorMsg("Image is required")
        }
    }

    // validate form
    const validateForm = (e: any) => {
        e.preventDefault();
        let flag = true;
        if (!product.name) {
            setNameError(true);
            setNameErrorMsg("Product category is required");
            flag = false;
        } else {
            if ((reg.test(product.name))) {
                setNameError(false);
                setNameErrorMsg("");
            } else {
                setNameError(true);
                setNameErrorMsg("Only Alphabet is allowed");
                flag = false;
            }
        }
        if (!(image)) {
            setImageError(true)
            setImageErrorMsg("Image is required")
            flag = false;
        }
        return flag;
    }

    //close switch dialog
    const handleCloseSwitch = () => {
        setIsCheck(false);
    }

    // change in switch
    const handleSwitch = async (id: number, isActive: boolean) => {
        let obj = {
            "id": id,
            "isActive": isActive
        }
        setProduct(obj);
        setIsCheck(true);
    };

    // save switch changes
    const handleCheckSwitch = async () => {
        try {
            if (product.isActive === true) {
                const obj = {
                    isActive: false
                }
                const activeStatus = doc(db, 'productCategory', product.id)
                updateDoc(activeStatus, obj);
                let data = JSON.parse(JSON.stringify(detail));
                let ind = data.findIndex((c: any) => c.id === product.id);
                if (ind > -1) {
                    data[ind].isActive = !data[ind].isActive;
                }
                setDetail(data);
            } else if (product.isActive === false) {
                const obj = {
                    isActive: true
                }
                const activeStatus = doc(db, 'productCategory', product.id)
                updateDoc(activeStatus, obj)
                let data = JSON.parse(JSON.stringify(detail));
                let ind = data.findIndex((c: any) => c.id === product.id);
                if (ind > -1) {
                    data[ind].isActive = !data[ind].isActive;
                }
                setDetail(data);
                // if (page === 0)
                //     getProductCategory();
                // else
                //     await handlePagination()
            }
        } catch (error) {
            console.log(error)
        }
        setIsCheck(false);
    };

    // next and previous pagination change
    const handlePagination = async () => {
        if ((page >= prevPage)) {
            let items: any[] = [];
            setDetail(items);
            const userCollectionRef = collection(db, 'productCategory')
            let q = query(userCollectionRef, orderBy('name', 'desc'), startAfter(prevLastVisible), limit(limits))
            const arr = searchInput.split(" ");
            for (let i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
            }
            const str = arr.join(" ");
            if (searchInput) {
                q = query(q, where('name', '>=', str), where('name', '<', str + 'z'))
            }
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            updateState(querySnapshot)
            setDetail(items);
        } else {
            let items: any[] = [];
            setDetail(items);
            const userCollectionRef = collection(db, 'productCategory')
            let q = query(userCollectionRef, orderBy('name', 'desc'), endBefore(prevFirstVisible), limitToLast(limits))
            const arr = searchInput.split(" ");
            for (let i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
            }
            const str = arr.join(" ");
            if (searchInput) {
                q = query(q, where('name', '>=', str), where('name', '<', str + 'z'))
            }
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            updateState(querySnapshot)
            setDetail(items);
        }
    }

    // when product change
    const handleChange = (arr: any) => {
        const { name, value } = arr.target;
        setProduct({ ...product, [name]: value })
        setOpen(true)
    }

    // close dialog
    const handleClose = () => {
        setOpen(false)
    }

    // click on add
    const handleClickisAdd = () => {
        setProduct(initialState)
        setOpen(true)
        setNameError(false);
        setNameErrorMsg("");
        setImage("")
        setImageError(false)
        setImageErrorMsg("")
    }

    // click on edit
    const handleClickisEdit = (no: string, img: string, str: string) => {
        setImage(false);
        setImageErrorMsg("")
        let obj = {
            id: no,
            url: img,
            name: str,
        }
        if (img) {
            setImage(img)
            image = img;
        } else {
            setImage("")
        }

        setProduct(obj);
        setOpen(true);
        setNameError(false);
        setNameErrorMsg("");
    };

    //close delete dialog
    const handleCancel = () => {
        setIsDel(false)
    }

    // when click on delete
    const handleClickisDelete = (no: string, image: any, count: number) => {
        let obj = {
            id: no,
            image: image,
        }

        setProduct(obj)
        {
            count === 0
                ?
                setIsDel(true)
                :
                toast.error('Cannot delete the Category which are in used!', {
                    autoClose: 6000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored"
                })

        }

    }

    // it is the first method invoked when this page is open
    useEffect(() => {
        getProductCategory();
    }, [])

    // call intially
    const getProductCategory = async () => {
        if (searchInput) {
            setIsLoading(false);
            setFlag(true);
        } else {
            setIsLoading(true);
        }
        try {
            const items: any[] = [];
            const items1: any[] = [];
            let data: any[] = [];
            let data1: any[] = [];
            let qLenn = query(collection(db, 'productCategory'))
            // if (searchInput) {
            //     // const arr = searchInput.split(" ");
            //     // for (let i = 0; i < arr.length; i++) {
            //     //     arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
            //     // }
            //     // const str = arr.join(" ");
            //     // qLenn = query(qLenn, where('name', '>=', searchInput.charAt(0).toUpperCase() + searchInput.substr(1).toLowerCase()), where('name', '<', (searchInput.charAt(0).toUpperCase() + searchInput.substr(1).toLowerCase()) + 'z'))

            //     qLenn = query(qLenn, where('name', '>=', searchInput), where('name', '<=', searchInput + '\uf8ff'))
            // }
            const querySnapshotLen = await getCountFromServer(qLenn);
            setRow(querySnapshotLen.data().count);
            const userCollectionRef = collection(db, 'productCategory')
            let q = query(userCollectionRef, orderBy('name', 'desc'), limit(limits))
            // if (searchInput) {
            //     // const arr = searchInput.split(" ");
            //     // for (let i = 0; i < arr.length; i++) {
            //     //     arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
            //     // }
            //     // const str = arr.join(" ");
            //     // q = query(q, where('name', '>=', searchInput.charAt(0).toUpperCase() + searchInput.substr(1).toLowerCase()), where('name', '<', (searchInput.charAt(0).toUpperCase() + searchInput.substr(1).toLowerCase()) + 'z'))
            //     q = query(q, where('name', '>=', searchInput), where('name', '<=', searchInput + '\uf8ff'))
            // }

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            const makerProduct = collection(db, 'makerProduct')
            const q1 = query(makerProduct);
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
                items1.push({ id: doc1.id, ...doc1.data() });
            });
            data1 = items.map((e: any) => {
                return ({
                    ...e,
                    total: items1.filter((d: any) => d.productCategoryId === e.id),
                })
            })
            data = data1.map((e: any) => {
                return ({
                    ...e,
                    count: e.total.length,
                })
            })
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstVisible(querySnapshot.docs[0]);
            setDetail(data);
            if (data.length > 0) {
                setFlag(true);
            } else {
                setFlag(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    // when cloud function is called
    async function searchData(url = '', data = {}) {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData; // return the response data
        } catch (error) {
            console.error(error);
        }
    }

    // invoked everytime when their is searchvalue in searchBox
    const searchItems = async (searchValue: any, startIndex: number, fetchRecord: number) => {
        try {
            if (searchValue) {
                const items: any[] = [];
                const items1: any[] = [];
                let data: any[] = [];
                let data1: any[] = [];
                setSearchInput(searchValue)
                searchInput = searchValue
                const requestBody = {
                    query: searchValue,
                    size: fetchRecord,
                    from: startIndex
                };
                const result = await searchData(process.env.REACT_APP_PRODUCTCATEGORY_SEARCH, requestBody);
                for (let i = 0; i < result.data.length; i++) {
                    let element = result.data[i]._source;
                    const q = query(collection(db, 'productCategory'), where('name', '==', element.name), orderBy('createdDate', 'desc'), limit(limits))
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        items.push({ id: doc.id, ...doc.data() });
                    });
                    const makerProduct = collection(db, 'makerProduct')
                    const q1 = query(makerProduct);
                    const querySnapshot1 = await getDocs(q1);
                    querySnapshot1.forEach((doc1) => {
                        items1.push({ id: doc1.id, ...doc1.data() });
                    });
                    data1 = items.map((e: any) => {
                        return ({
                            ...e,
                            total: items1.filter((d: any) => d.productCategoryId === e.id),
                        })
                    })
                    data = data1.map((e: any) => {
                        return ({
                            ...e,
                            count: e.total.length,
                        })
                    })
                    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    setFirstVisible(querySnapshot.docs[0]);
                    if (data.length > 0) {
                        setDetail(data);
                        detail = data
                        setFlag(true);
                        setRow(data.length)
                    } else {
                        setFlag(false);
                    }
                }
            } else {
                searchInput = ""
                setSearchInput("")
                await getProductCategory();
            }
        } catch (error) {
            console.error(error);
        }
    }

    //save product
    const saveProduct = async (e: any) => {
        let flag = validateForm(e)
        if (flag) {
            if (product.id) {
                try {
                    const obj = {
                        'id': product.id,
                        'productUrl': url ? url : product.url,
                        'name': product.name,
                    }
                    const dataRef = doc(db, 'productCategory', product.id)
                    updateDoc(dataRef, obj);
                    let data = JSON.parse(JSON.stringify(detail));
                    let ind = data.findIndex((c: any) => c.id === product.id);
                    if (ind > -1) {
                        data[ind].name = product.name;
                        data[ind].productUrl = url ? url : product.url;
                    }
                    setDetail(data);
                } catch (error) {
                    console.log(error)
                    setIsLoading(false)
                }
                setOpen(false)
            } else {
                try {
                    const ProductRef = collection(db, 'productCategory')
                    await addDoc(ProductRef, {
                        productUrl: url ? url : product.url,
                        name: product.name.charAt(0).toUpperCase() + product.name.slice(1).toLowerCase(),
                        PCid: v4(),
                        isActive: true,
                        createdDate: serverTimestamp()
                    });
                    await getProductCategory();
                } catch (error) {
                    console.log(error)
                }
                setOpen(false)
            }
        }
    }

    // delete product
    const handleDeleteProduct = async () => {
        const ProductRef = collection(db, 'productCategory');
        try {
            const deleteRef = doc(ProductRef, product.id)
            deleteDoc(deleteRef);
            if (page === 0) {
                await getProductCategory();
            }
            else {
                await handlePagination()
            }
        }
        catch (error) {
            console.error(error);
        }
        setIsDel(false);
    }

    //image upload
    const onUploadImage = async (images: any) => {
        const ids = v4();
        if (images == null) return;
        const storageRef = ref(storage, `/productCategory_image/productCategory${ids}`)
        uploadString(storageRef, images, 'data_url').then((snapshot: any) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl(url)
                product.url = url
                url = url
            })
        })
    }

    //file select
    const onFileChange = async (e: any) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                setImage(reader.result.toString());
            }
        };
        reader.readAsDataURL(file);
        let ext = file.type.split("/")[1];
        let res = await resizeFile(file, ext);
        let img64: string = res as string;
        setOpen(true)
        await onUploadImage(img64)
    }

    //image resize
    const resizeFile = (file: any, ext: string) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                250,
                250,
                ext,
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    //image change
    const onImageChange = (e: any) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value })
        setOpen(true)
    }

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleChangePage = async (event: any, newPage: any) => {
        if (searchInput) {
            setPage(newPage);
            searchItems(searchInput, (newPage * limits), limits)
        } else {
            setPrevPage(page);
            setPage(newPage);
            if (page < newPage) {
                let items: any[] = [];
                let items1: any[] = [];
                let data: any[] = [];
                let data1: any[] = [];
                setPrevLastVisible(lastVisible);
                const userCollectionRef = collection(db, 'productCategory')
                let q = query(userCollectionRef, orderBy('name', 'desc'), startAfter(lastVisible), limit(limits))
                const arr = searchInput.split(" ");
                for (let i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
                }
                const str = arr.join(" ");
                if (searchInput) {
                    q = query(q, where('name', '>=', str), where('name', '<', str + 'z'))
                }
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() });
                });
                updateState(querySnapshot)
                const makerProduct = collection(db, 'makerProduct')
                const q1 = query(makerProduct);
                const querySnapshot1 = await getDocs(q1);
                querySnapshot1.forEach((doc1) => {
                    items1.push({ id: doc1.id, ...doc1.data() });
                });
                data1 = items.map((e: any) => {
                    return ({
                        ...e,
                        total: items1.filter((d: any) => d.productCategoryId === e.id),
                    })
                })
                data = data1.map((e: any) => {
                    return ({
                        ...e,
                        count: e.total.length,
                    })
                })
                setDetail(data);

            } else {
                let items: any[] = [];
                let items1: any[] = [];
                let data: any[] = [];
                let data1: any[] = [];
                setPrevFirstVisible(firstVisible);
                const userCollectionRef = collection(db, 'productCategory')
                let q = query(userCollectionRef, orderBy('name', 'desc'), endBefore(firstVisible), limitToLast(limits))
                const arr = searchInput.split(" ");
                for (let i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
                }
                const str = arr.join(" ");
                if (searchInput) {
                    q = query(q, where('name', '>=', str), where('name', '<', str + 'z'))
                }
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() });
                });
                updateState(querySnapshot)
                const makerProduct = collection(db, 'makerProduct')
                const q1 = query(makerProduct);
                const querySnapshot1 = await getDocs(q1);
                querySnapshot1.forEach((doc1) => {
                    items1.push({ id: doc1.id, ...doc1.data() });
                });
                data1 = items.map((e: any) => {
                    return ({
                        ...e,
                        total: items1.filter((d: any) => d.productCategoryId === e.id),
                    })
                })
                data = data1.map((e: any) => {
                    return ({
                        ...e,
                        count: e.total.length,
                    })
                })
                setDetail(data);
            }
        }
    }

    // update first visible and last visible value according to pagination (next and previous button)
    const updateState = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisible(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisible(documents.docs[documents.docs.length - 1]);
        }
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setPage(0);
        setLimits(parseInt(event.target.value));
        limits = parseInt(event.target.value)
        getProductCategory()
    };

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={6000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <Helmet>
                <title> Product Category | Vyanjan-Pursuit for Hunger! </title>
            </Helmet>
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt="-7px">
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link
                                    to="/admin/dashboard"
                                    style={{ display: 'flex', color: "black" }}
                                >
                                    <HomeIcon />
                                </Link>
                                <Typography color="inherit"> Product Category</Typography>
                            </Breadcrumbs>
                            <div className="largeScreenContent">
                                <Button
                                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                                    variant="contained"
                                    onClick={handleClickisAdd}
                                    startIcon={<Iconify icon="eva:plus-fill" />}>
                                    New Product Category
                                </Button>
                            </div>
                            <div className="smallScreenContent">
                                <Button
                                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                                    variant="contained"
                                    onClick={handleClickisAdd}
                                >
                                    <AddIcon />
                                </Button>
                            </div>
                        </Stack>
                        <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', mt: "-7px" }}>
                            <div>
                                {isLoading ? <Loader title="Loading..." /> :
                                    <>
                                        <StyledRoot
                                            sx={{
                                                color: 'primary.main',
                                                bgcolor: 'white',

                                            }}
                                        >
                                            <StyledSearch
                                                value={searchInput}
                                                onChange={(e) => searchItems(e.target.value, (page * limits), limits)}
                                                placeholder="Search by Product Category..."
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                                    </InputAdornment>
                                                }
                                            />
                                        </StyledRoot>
                                        {(flag === true) ?
                                            <>
                                                <Scrollbar>
                                                    <TableContainer className="productCategory-list" sx={{ backgroundColor: "white" }} >
                                                        <Table stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell >#</TableCell>
                                                                    <TableCell sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Product Category</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Count</Typography></TableCell>
                                                                    <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {detail
                                                                    .map((arr: any, index: any) => {
                                                                        return (
                                                                            <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                                                                <TableCell >
                                                                                    {(page * limits) + index + 1}</TableCell>

                                                                                <TableCell align="left" sx={{ color: '#212B36' }}>
                                                                                    <Stack direction="row" justifyContent="left" spacing={2}>
                                                                                        {arr.productUrl ? <Avatar src={arr.productUrl} variant="rounded"></Avatar> : <Avatar src="/defaultFoodLogo.png" variant="rounded"></Avatar>}
                                                                                        <Typography variant='subtitle2' noWrap style={{ display: "flex", alignItems: "center" }}>
                                                                                            {arr.name.charAt(0).toUpperCase() + arr.name.slice(1).toLowerCase()}
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                </TableCell>
                                                                                <TableCell >
                                                                                    {arr.count}
                                                                                </TableCell>
                                                                                <TableCell align="right" style={{ display: "flex" }} >

                                                                                    <Tooltip title="View" arrow>
                                                                                        <IconButton
                                                                                            sx={{ color: '#1565c0' }}
                                                                                            color="inherit"
                                                                                            size="small"
                                                                                            onClick={(e) => { handleClickDetails(arr) }}
                                                                                        >
                                                                                            <VisibilityIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Delete" arrow>
                                                                                        <IconButton
                                                                                            sx={{ pt: 0.5, pb: 0.5, color: '#1565c0' }}
                                                                                            color="inherit"
                                                                                            size="small"
                                                                                            onClick={(e) => handleClickisDelete(arr.id, arr.productUrl, arr.count)}
                                                                                        >
                                                                                            <DeleteForeverIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    {arr.count === 0 ?
                                                                                        <>
                                                                                            <Tooltip title={(arr.isActive === true) ? "Active" : "Inactive"} arrow>
                                                                                                <Switch
                                                                                                    checked={arr.isActive}
                                                                                                    onClick={(e) => handleSwitch(arr.id, arr.isActive)}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </>
                                                                                        : " "}
                                                                                    <Tooltip title="Edit" arrow>
                                                                                        <IconButton
                                                                                            sx={{ pt: 0.5, pb: 0.5, color: '#1565c0' }}
                                                                                            color="inherit"
                                                                                            size="small"
                                                                                            onClick={(e) => handleClickisEdit(arr.id, arr.productUrl, arr.name)}
                                                                                        >
                                                                                            <EditTwoToneIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Scrollbar>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30, 40]}
                                                    component="div"
                                                    count={row}
                                                    rowsPerPage={limits}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </>
                                            :
                                            <TableContainer className="productCategory-list-notFound" sx={{ backgroundColor: "white" }}>
                                                <Table>
                                                    <Divider />
                                                    <TableBody>
                                                        <TableRow>
                                                            <Paper
                                                                sx={{
                                                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                }}
                                                            >
                                                                <img src="\search.png" alt="noData" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                <Typography variant="h6" paragraph>
                                                                    Data not Available
                                                                </Typography>
                                                            </Paper>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </>
                                }
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            <div>
                <Dialog open={isDel} onClose={handleClose}>
                    <DialogTitle>Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button onClick={handleDeleteProduct}>Delete</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div className='dialogsmallScreenContent'>
                <Dialog open={open} onClose={handleClose} PaperProps={{
                    sx: {
                        width: "316px",
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        borderRadius: '6px',
                        boxShadow: 24,
                        margin: '0px',
                    }
                }}
                    className='dialogsmallScreenContent'
                >
                    <DialogTitle sx={{ fontSize: "17px" }}>
                        {product.id ? "Edit Product Category" : "Add Product Category"}
                        <IconButton
                            sx={{
                                fontSize: "2px", color: "grey", position: 'absolute',
                                right: 3,
                                top: 12,
                            }}
                            onClick={handleClose}
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ paddingTop: '16px' }}>
                        <FormGroup style={{ alignItems: "center" }}>
                            <div>
                                {image ? (
                                    <img src={image}
                                        style={{
                                            // height: 100,
                                            // width: 100,
                                            // height: "166px",
                                            // width: "200px",
                                            borderRadius: '20%',
                                            // width: '338.33px',
                                            // height: '189.88px',
                                            // borderRadius: '1px solid black',
                                        }}
                                        alt="FoodImage"
                                    />
                                ) : (
                                    <img src="/defaultFood.png"
                                        style={{
                                            // height: 100,
                                            // width: 100,
                                            borderRadius: '20%',
                                            // width: '338.33px',
                                            // height: '189.88px',
                                            // borderRadius: '1px solid black',
                                        }}
                                        alt="FoodImage"
                                    />
                                )}
                                <input
                                    style={{
                                        display: 'none',
                                        position: 'fixed',
                                        bottom: '5%',
                                        paddingTop: '49px',
                                        marginLeft: '-26px',
                                    }}
                                    id="icon-button-file"
                                    accept="image/*"
                                    type="file"
                                    name="image"
                                    onChange={(e) => { onFileChange(e); onImageChange(e); ValidateImage(e) }}
                                    className="upload-button" />
                                <label htmlFor="icon-button-file">
                                    <IconButton component="span" color="primary" style={{ top: "-20px", right: "-245px" }} >
                                        <UploadTwoToneIcon />
                                    </IconButton>
                                </label>
                                {image &&
                                    <IconButton component="span" color="primary" style={{ top: "-205px", right: "-205px " }} onClick={(e: any) => { { setImage(e.target.value = ''); ValidateImage(e) } }}>
                                        {/* <RemoveIcon /> */} <CancelIcon />
                                    </IconButton>
                                }
                            </div>
                        </FormGroup>
                        <FormHelperText style={{ color: 'red', height: '22px' }}>{isImageError && ImageErrorMsg}</FormHelperText>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Product Category"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="name"
                            value={product.name}
                            onChange={(arr) => { handleChange(arr); validateName(arr) }}
                            required={true}
                        />
                        <FormHelperText style={{ color: 'red', height: '22px' }}>{isNameError && NameErrorMsg}</FormHelperText>
                    </DialogContent>
                    <DialogActions sx={{ pl: '24px', pr: '24px' }}>
                        <Button onClick={saveProduct}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div className='dialoglargeScreenContent'>
                <Dialog open={open} onClose={handleClose} PaperProps={{
                    sx: {
                        width: "390px",
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        borderRadius: '6px',
                        boxShadow: 24,
                        margin: '0px',
                    }
                }}
                    className='dialoglargeScreenContent'
                >
                    <DialogTitle sx={{ fontSize: "17px" }}>
                        {product.id ? "Edit Product Category" : "Add Product Category"}
                        <IconButton
                            sx={{
                                fontSize: "2px", color: "grey", position: 'absolute',
                                right: 3,
                                top: 12,
                            }}
                            onClick={handleClose}
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ paddingTop: '16px' }}>
                        <FormGroup style={{ alignItems: "center" }}>
                            <div>
                                {image ? (
                                    <img src={image}
                                        style={{
                                            // height: 100,
                                            // width: 100,
                                            // height: "166px",
                                            // width: "200px",
                                            borderRadius: '10%',
                                            width: '338.33px',
                                            // width: '250px',
                                            height: '189.88px',
                                            // borderRadius: '1px solid black',
                                        }}
                                        alt="FoodImage"
                                    />
                                ) : (
                                    <img src="/defaultFoodLogo.png"
                                        style={{
                                            // height: 100,
                                            // width: 100,
                                            borderRadius: '10%',
                                            width: '338.33px',
                                            // width: '250px',
                                            height: '189.88px',
                                            // borderRadius: '1px solid black',
                                        }}
                                        alt="FoodImage"
                                    />
                                )}
                                <input
                                    style={{
                                        display: 'none',
                                        position: 'fixed',
                                        bottom: '5%',
                                        paddingTop: '49px',
                                        marginLeft: '-26px',
                                    }}
                                    id="icon-button-file"
                                    accept="image/*"
                                    type="file"
                                    name="image"
                                    onChange={(e) => { onFileChange(e); onImageChange(e); ValidateImage(e) }}
                                    className="upload-button" />
                                <label htmlFor="icon-button-file">
                                    <IconButton component="span" color="primary" style={{ top: "-20px", right: "-311px" }} >
                                        <UploadTwoToneIcon />
                                    </IconButton>
                                </label>
                                {image &&
                                    <IconButton component="span" color="primary" style={{ top: "-205px", right: "-270px " }} onClick={(e: any) => { { setImage(e.target.value = ''); ValidateImage(e) } }}>
                                        {/* <RemoveIcon /> */} <CancelIcon />
                                    </IconButton>
                                }
                            </div>
                        </FormGroup>
                        <FormHelperText style={{ color: 'red', height: '22px' }}>{isImageError && ImageErrorMsg}</FormHelperText>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Product Category"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="name"
                            value={product.name}
                            onChange={(arr) => { handleChange(arr); validateName(arr) }}
                            required={true}
                        />
                        <FormHelperText style={{ color: 'red', height: '22px' }}>{isNameError && NameErrorMsg}</FormHelperText>
                    </DialogContent>
                    <DialogActions sx={{ pl: '24px', pr: '24px' }}>
                        <Button onClick={saveProduct}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog open={isCheck} onClose={handleCloseSwitch}>
                    <DialogTitle>{(product.isActive === true) ? 'Active' : 'InActive'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {(product.isActive === false) ? 'Are you sure you want to Active?' : 'Are you sure you want to InActive?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSwitch}>Cancel</Button>
                        <Button onClick={handleCheckSwitch}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div >
    )
}