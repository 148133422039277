import React from "react";
import "./dashboardTableLoader.css";

interface Props {
  title: string
}

const TableLoader: React.FC<Props> = (props: Props) => {
  return (
    <div className="table-spinner-container">
      {/* style={{ height: 'calc(100vh - 210px)' }} */}
      <div className="loading-small-spinner">
      </div>
    </div>
  )
}

export default TableLoader