import { Breadcrumbs, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormGroup, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import { Helmet } from 'react-helmet-async'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ClearIcon from "@mui/icons-material/Clear";
import Scrollbar from '../../components/scrollbar';
import Loader from '../spinner'
import { addDoc, collection, doc, getDocs, orderBy, query, serverTimestamp, setDoc, Timestamp, where } from 'firebase/firestore';
import { db } from '../../firebase';
import './../FoodMakerPage.css';
import DoneIcon from '@mui/icons-material/Done';
import ReplyIcon from '@mui/icons-material/Reply';
import { format } from 'date-fns';

const initialState = {
    id: '',
    totalPayable: 0,
    makerId: '',
    reward: 0
}
const initialStateStatus = {
    status: '',
    makerName: '',
}

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

export default function PaymentHistoryNew() {
    const [isLoading, setIsLoading] = useState(false);
    const [payment, setPayment] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const [payout, setPayout] = useState<any>(initialState)
    const [page, setPage] = useState(0);
    const [limits, setLimits] = useState<number>(10);
    const [row, setRow] = useState<number>(10);
    const [isConfirm, setIsConfirm] = useState(false);
    const [flag, setFlag] = useState<boolean>(true);
    const [status, setStatus] = useState<any>(initialStateStatus);
    const navigate = useNavigate();
    const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

    // it is the first method invoked when this page is open
    useEffect(() => {
        getMonthlyData();
    }, [])

    //call intially
    const getMonthlyData = async () => {
        setIsLoading(true);
        try {
            const items: any[] = [];
            let makersList: any[] = [];
            const usersCollectionRef = collection(db, 'users');
            const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'asc'))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            });

            for (let i = 0; i < items.length; i++) {
                let items2: any[] = [];
                const monthlyPaymentsRef = collection(db, 'makersPayoutRelease');
                const q2 = query(monthlyPaymentsRef, where('MakerId', '==', items[i].uid), orderBy('toDate', 'desc'))
                const querySnapshot2 = await getDocs(q2)
                querySnapshot2.forEach((doc2: any) => {
                    items2.push({ id: doc2.id, ...doc2.data() });
                });


                if (items2.length == 0) {
                    let items3: any[] = [];
                    const ordersRef = collection(db, 'userOrders');
                    const q3 = query(ordersRef, where('makerId', '==', items[i].uid), where('orderStatus', '==', 'Delivered'))
                    const querySnapshot3 = await getDocs(q3)
                    querySnapshot3.forEach((doc3: any) => {
                        items3.push({ id: doc3.id, ...doc3.data() });
                    });

                    if (items3 && items3.length > 0) {
                        items3.forEach(element => {
                            element.deliveryDate = new Date(element.deliveryDate);
                        });
                        items3 = items3.sort((a, b) => (b.deliveryDate > a.deliveryDate) ? -1 : 1);


                        let startDate = items3[0].deliveryDate;
                        let colData = [];
                        if (items[i].payoutReleasePeriod.payoutPeriod.toLowerCase().includes('week')) {
                            let eDt = JSON.parse(JSON.stringify(startDate));
                            eDt = new Date(eDt);
                            while (new Date(eDt).getTime() <= new Date().getTime()) {
                                if (eDt.getDay() !== 1) {
                                    eDt.setDate(eDt.getDate() + 1);
                                } else {
                                    startDate.setDate(eDt.getDate() - 7);

                                    let orders = items3.filter(c => c.deliveryDate >= startDate && c.deliveryDate < eDt);
                                    if (orders && orders.length > 0) {
                                        let totalSale = 0;
                                        let adminCommission = 0;
                                        let orderEarning = 0;
                                        for (let o = 0; o < orders.length; o++) {
                                            //Calculate Commission and Etc
                                            totalSale += orders[o].totalNetAmount;
                                            adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                            orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                        }
                                        let obj = {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: adminCommission.toFixed(2),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: orderEarning.toFixed(2),
                                            totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        }
                                        colData.push(obj);
                                        const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                        await addDoc(monthlyMakersPaymentsRef, {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: parseFloat(adminCommission.toFixed(2)),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                            totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        });
                                        eDt.setDate(eDt.getDate() + 1);
                                        startDate = eDt;
                                    }
                                }
                            }
                        } else if (items[i].payoutReleasePeriod.payoutPeriod.toLowerCase().includes('half month')) {
                            const dayOfMonth = items3[0].deliveryDate;
                            if (dayOfMonth.getDate() <= 15) {
                                if (new Date().getDate() > 15) {
                                    let startDate = new Date(items3[0].deliveryDate.getFullYear(), items3[0].deliveryDate.getMonth(), 1);
                                    let eDt = new Date(items3[0].deliveryDate.getFullYear(), items3[0].deliveryDate.getMonth(), 15);
                                    eDt = new Date(eDt);
                                    let orders = items3.filter(c => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                    if (orders && orders.length > 0) {
                                        let totalSale = 0;
                                        let adminCommission = 0;
                                        let orderEarning = 0;
                                        for (let o = 0; o < orders.length; o++) {
                                            //Calculate Commission and Etc
                                            totalSale += orders[o].totalNetAmount;
                                            adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                            orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                        }
                                        let obj = {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: adminCommission.toFixed(2),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: orderEarning.toFixed(2),
                                            totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        }
                                        colData.push(obj);
                                        const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                        await addDoc(monthlyMakersPaymentsRef, {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: parseFloat(adminCommission.toFixed(2)),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                            totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        });
                                    }
                                }
                            } else {
                                if ((new Date().getMonth() > dayOfMonth.getMonth()) || (new Date().getMonth() == 0 && (dayOfMonth.getFullYear() < new Date().getFullYear()))) {
                                    const lastDayOfMonth = new Date(items3[0].deliveryDate.getFullYear(), items3[0].deliveryDate.getMonth() + 1, 0).getDate();
                                    let startDate = new Date(items3[0].deliveryDate.getFullYear(), items3[0].deliveryDate.getMonth(), 16);
                                    let eDt = new Date(items3[0].deliveryDate.getFullYear(), items3[0].deliveryDate.getMonth(), lastDayOfMonth);
                                    eDt = new Date(eDt);
                                    let orders = items3.filter(c => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                    if (orders && orders.length > 0) {
                                        let totalSale = 0;
                                        let adminCommission = 0;
                                        let orderEarning = 0;
                                        for (let o = 0; o < orders.length; o++) {
                                            //Calculate Commission and Etc
                                            totalSale += orders[o].totalNetAmount;
                                            adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                            orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                        }
                                        let obj = {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: adminCommission.toFixed(2),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: orderEarning.toFixed(2),
                                            totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        }
                                        colData.push(obj);
                                        const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                        await addDoc(monthlyMakersPaymentsRef, {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: parseFloat(adminCommission.toFixed(2)),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                            totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        });
                                    }

                                }
                            }
                        } else if (items[i].payoutReleasePeriod.payoutPeriod.toLowerCase().includes('month')) {
                            if ((new Date().getMonth() > items3[0].deliveryDate.getMonth()) || (new Date().getMonth() == 0 && (items3[0].deliveryDate.getFullYear() < new Date().getFullYear()))) {
                                const nextDayOfMonth: any = new Date(items3[0].deliveryDate.getFullYear(), items3[0].deliveryDate.getMonth() + 1, 1);
                                let startDate = new Date(items3[0].deliveryDate.getFullYear(), items3[0].deliveryDate.getMonth(), 1);;
                                let eDt = new Date(nextDayOfMonth - 1);
                                eDt = new Date(eDt);
                                let orders = items3.filter(c => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                if (orders && orders.length > 0) {
                                    let totalSale = 0;
                                    let adminCommission = 0;
                                    let orderEarning = 0;
                                    for (let o = 0; o < orders.length; o++) {
                                        //Calculate Commission and Etc
                                        totalSale += orders[o].totalNetAmount;
                                        adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                        orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                    }
                                    let obj = {
                                        fromDate: startDate,
                                        toDate: eDt,
                                        MakerId: items[i].uid,
                                        MakerName: items[i].displayName,
                                        commission: items[i].commission.commission,
                                        adminCommission: adminCommission.toFixed(2),
                                        totalSells: totalSale,
                                        rewardsEarning: items[i].walletAmount,
                                        ordersEarning: orderEarning.toFixed(2),
                                        totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                        isPaid: 0,
                                        payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                        payoutDays: items[i].payoutReleasePeriod.days,
                                        lastPayoutReleaseDate: null,
                                        createdDate: serverTimestamp(),
                                        modifiedDate: serverTimestamp(),
                                    }
                                    colData.push(obj);
                                    const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                    await addDoc(monthlyMakersPaymentsRef, {
                                        fromDate: startDate,
                                        toDate: eDt,
                                        MakerId: items[i].uid,
                                        MakerName: items[i].displayName,
                                        commission: items[i].commission.commission,
                                        adminCommission: parseFloat(adminCommission.toFixed(2)),
                                        totalSells: totalSale,
                                        rewardsEarning: items[i].walletAmount,
                                        ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                        totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                        isPaid: 0,
                                        payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                        payoutDays: items[i].payoutReleasePeriod.days,
                                        lastPayoutReleaseDate: null,
                                        createdDate: serverTimestamp(),
                                        modifiedDate: serverTimestamp(),
                                    });
                                }
                            }
                        } else {
                            let startDate = items3[0].deliveryDate;
                            let eDt = JSON.parse(JSON.stringify(startDate));
                            eDt = new Date(eDt);
                            eDt = eDt.setDate(eDt.getDate() + items[i].payoutReleasePeriod.days);
                            eDt = new Date(eDt);
                            while (new Date(eDt).getTime() <= new Date().getTime()) {
                                let orders = items3.filter(c => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                if (orders && orders.length > 0) {
                                    let totalSale = 0;
                                    let adminCommission = 0;
                                    let orderEarning = 0;
                                    for (let o = 0; o < orders.length; o++) {
                                        //Calculate Commission and Etc
                                        totalSale += orders[o].totalNetAmount;
                                        adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                        orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                    }
                                    let obj = {
                                        fromDate: startDate,
                                        toDate: eDt,
                                        MakerId: items[i].uid,
                                        MakerName: items[i].displayName,
                                        commission: items[i].commission.commission,
                                        adminCommission: adminCommission.toFixed(2),
                                        totalSells: totalSale,
                                        rewardsEarning: items[i].walletAmount,
                                        ordersEarning: orderEarning.toFixed(2),
                                        totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                        isPaid: 0,
                                        payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                        payoutDays: items[i].payoutReleasePeriod.days,
                                        lastPayoutReleaseDate: null,
                                        createdDate: serverTimestamp(),
                                        modifiedDate: serverTimestamp(),
                                    }
                                    colData.push(obj);
                                    const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                    await addDoc(monthlyMakersPaymentsRef, {
                                        fromDate: startDate,
                                        toDate: eDt,
                                        MakerId: items[i].uid,
                                        MakerName: items[i].displayName,
                                        commission: items[i].commission.commission,
                                        adminCommission: parseFloat(adminCommission.toFixed(2)),
                                        totalSells: totalSale,
                                        rewardsEarning: items[i].walletAmount,
                                        ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                        totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                        isPaid: 0,
                                        payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                        payoutDays: items[i].payoutReleasePeriod.days,
                                        lastPayoutReleaseDate: null,
                                        createdDate: serverTimestamp(),
                                        modifiedDate: serverTimestamp(),
                                    });
                                }
                                eDt = new Date(eDt);
                                eDt = eDt.setDate(eDt.getDate() + items[i].payoutReleasePeriod.days);
                                startDate = new Date(JSON.parse(JSON.stringify(eDt)));
                            }
                        }
                    }

                }
                else {
                    let itemso: any[] = [];
                    const ordersRef = collection(db, 'userOrders');
                    const qo = query(ordersRef, where('makerId', '==', items[i].uid), where('orderStatus', '==', 'Delivered'))
                    const querySnapshoto = await getDocs(qo)
                    querySnapshoto.forEach((doco: any) => {
                        itemso.push({ id: doco.id, ...doco.data() });
                    });

                    if (itemso && itemso.length > 0) {

                        const timestampMilliseconds = items2[0].toDate.seconds * 1000 + Math.floor(items2[0].toDate.nanoseconds / 1e6);
                        const date = new Date(timestampMilliseconds);
                        const iso8601Date = date.toISOString();
                        console.log(iso8601Date);
                        console.log(itemso[0].deliveryDate);

                        let filteredData = itemso.filter((item: any) => new Date(item.deliveryDate).getTime() > new Date(iso8601Date).getTime());
                        console.log(itemso);
                        console.log(filteredData);

                        filteredData.forEach(element => {
                            element.deliveryDate = new Date(element.deliveryDate);
                        });
                        filteredData = filteredData.sort((a: any, b: any) => (b.deliveryDate > a.deliveryDate) ? -1 : 1);

                        if (filteredData && filteredData.length > 0) {

                            let startDate = filteredData[0].deliveryDate;
                            let colData = [];
                            if (items[i].payoutReleasePeriod.payoutPeriod.toLowerCase().includes('week')) {
                                let eDt = JSON.parse(JSON.stringify(startDate));
                                eDt = new Date(eDt);
                                while (new Date(eDt).getTime() <= new Date().getTime()) {
                                    if (eDt.getDay() !== 1) {
                                        eDt.setDate(eDt.getDate() + 1);
                                    } else {
                                        startDate.setDate(eDt.getDate() - 7);
                                        let orders = filteredData.filter((c: any) => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                        if (orders && orders.length > 0) {
                                            let totalSale = 0;
                                            let adminCommission = 0;
                                            let orderEarning = 0;
                                            for (let o = 0; o < orders.length; o++) {
                                                //Calculate Commission and Etc
                                                totalSale += orders[o].totalNetAmount;
                                                adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                                orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                            }
                                            let obj = {
                                                fromDate: startDate,
                                                toDate: eDt,
                                                MakerId: items[i].uid,
                                                MakerName: items[i].displayName,
                                                commission: items[i].commission.commission,
                                                adminCommission: adminCommission.toFixed(2),
                                                totalSells: totalSale,
                                                rewardsEarning: items[i].walletAmount,
                                                ordersEarning: orderEarning.toFixed(2),
                                                totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                                isPaid: 0,
                                                payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                                payoutDays: items[i].payoutReleasePeriod.days,
                                                lastPayoutReleaseDate: null,
                                                createdDate: serverTimestamp(),
                                                modifiedDate: serverTimestamp(),
                                            }
                                            colData.push(obj);
                                            const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                            await addDoc(monthlyMakersPaymentsRef, {
                                                fromDate: startDate,
                                                toDate: eDt,
                                                MakerId: items[i].uid,
                                                MakerName: items[i].displayName,
                                                commission: items[i].commission.commission,
                                                adminCommission: parseFloat(adminCommission.toFixed(2)),
                                                totalSells: totalSale,
                                                rewardsEarning: items[i].walletAmount,
                                                ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                                totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                                isPaid: 0,
                                                payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                                payoutDays: items[i].payoutReleasePeriod.days,
                                                lastPayoutReleaseDate: null,
                                                createdDate: serverTimestamp(),
                                                modifiedDate: serverTimestamp(),
                                            });
                                            eDt.setDate(eDt.getDate() + 1);
                                            startDate = eDt;
                                        }
                                    }
                                }
                            } else if (items[i].payoutReleasePeriod.payoutPeriod.toLowerCase().includes('half month')) {
                                const dayOfMonth = filteredData[0].deliveryDate;
                                if (dayOfMonth.getDate() <= 15) {
                                    if (new Date().getDate() > 15) {
                                        let startDate = new Date(filteredData[0].deliveryDate.getFullYear(), filteredData[0].deliveryDate.getMonth(), 1);
                                        let eDt = new Date(filteredData[0].deliveryDate.getFullYear(), filteredData[0].deliveryDate.getMonth(), 15);
                                        eDt = new Date(eDt);
                                        let orders = filteredData.filter((c: any) => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                        if (orders && orders.length > 0) {
                                            let totalSale = 0;
                                            let adminCommission = 0;
                                            let orderEarning = 0;
                                            for (let o = 0; o < orders.length; o++) {
                                                //Calculate Commission and Etc
                                                totalSale += orders[o].totalNetAmount;
                                                adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                                orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                            }
                                            let obj = {
                                                fromDate: startDate,
                                                toDate: eDt,
                                                MakerId: items[i].uid,
                                                MakerName: items[i].displayName,
                                                commission: items[i].commission.commission,
                                                adminCommission: adminCommission.toFixed(2),
                                                totalSells: totalSale,
                                                rewardsEarning: items[i].walletAmount,
                                                ordersEarning: orderEarning.toFixed(2),
                                                totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                                isPaid: 0,
                                                payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                                payoutDays: items[i].payoutReleasePeriod.days,
                                                lastPayoutReleaseDate: null,
                                                createdDate: serverTimestamp(),
                                                modifiedDate: serverTimestamp(),
                                            }
                                            colData.push(obj);
                                            const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                            await addDoc(monthlyMakersPaymentsRef, {
                                                fromDate: startDate,
                                                toDate: eDt,
                                                MakerId: items[i].uid,
                                                MakerName: items[i].displayName,
                                                commission: items[i].commission.commission,
                                                adminCommission: parseFloat(adminCommission.toFixed(2)),
                                                totalSells: totalSale,
                                                rewardsEarning: items[i].walletAmount,
                                                ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                                totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                                isPaid: 0,
                                                payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                                payoutDays: items[i].payoutReleasePeriod.days,
                                                lastPayoutReleaseDate: null,
                                                createdDate: serverTimestamp(),
                                                modifiedDate: serverTimestamp(),
                                            });
                                        }
                                    }
                                } else {
                                    if ((new Date().getMonth() > dayOfMonth.getMonth()) || (new Date().getMonth() == 0 && (dayOfMonth.getFullYear() < new Date().getFullYear()))) {
                                        const lastDayOfMonth = new Date(filteredData[0].deliveryDate.getFullYear(), filteredData[0].deliveryDate.getMonth() + 1, 0).getDate();
                                        let startDate = new Date(filteredData[0].deliveryDate.getFullYear(), filteredData[0].deliveryDate.getMonth(), 16);
                                        let eDt = new Date(filteredData[0].deliveryDate.getFullYear(), filteredData[0].deliveryDate.getMonth(), lastDayOfMonth);
                                        eDt = new Date(eDt);
                                        let orders = filteredData.filter((c: any) => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                        if (orders && orders.length > 0) {
                                            let totalSale = 0;
                                            let adminCommission = 0;
                                            let orderEarning = 0;
                                            for (let o = 0; o < orders.length; o++) {
                                                //Calculate Commission and Etc
                                                totalSale += orders[o].totalNetAmount;
                                                adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                                orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                            }
                                            let obj = {
                                                fromDate: startDate,
                                                toDate: eDt,
                                                MakerId: items[i].uid,
                                                MakerName: items[i].displayName,
                                                commission: items[i].commission.commission,
                                                adminCommission: adminCommission.toFixed(2),
                                                totalSells: totalSale,
                                                rewardsEarning: items[i].walletAmount,
                                                ordersEarning: orderEarning.toFixed(2),
                                                totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                                isPaid: 0,
                                                payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                                payoutDays: items[i].payoutReleasePeriod.days,
                                                lastPayoutReleaseDate: null,
                                                createdDate: serverTimestamp(),
                                                modifiedDate: serverTimestamp(),
                                            }
                                            colData.push(obj);
                                            const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                            await addDoc(monthlyMakersPaymentsRef, {
                                                fromDate: startDate,
                                                toDate: eDt,
                                                MakerId: items[i].uid,
                                                MakerName: items[i].displayName,
                                                commission: items[i].commission.commission,
                                                adminCommission: parseFloat(adminCommission.toFixed(2)),
                                                totalSells: totalSale,
                                                rewardsEarning: items[i].walletAmount,
                                                ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                                totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                                isPaid: 0,
                                                payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                                payoutDays: items[i].payoutReleasePeriod.days,
                                                lastPayoutReleaseDate: null,
                                                createdDate: serverTimestamp(),
                                                modifiedDate: serverTimestamp(),
                                            });
                                        }
                                    }
                                }
                            } else if (items[i].payoutReleasePeriod.payoutPeriod.toLowerCase().includes('month')) {
                                if ((new Date().getMonth() > filteredData[0].deliveryDate.getMonth()) || (new Date().getMonth() == 0 && (filteredData[0].deliveryDate.getFullYear() < new Date().getFullYear()))) {
                                    const nextDayOfMonth: any = new Date(filteredData[0].deliveryDate.getFullYear(), filteredData[0].deliveryDate.getMonth() + 1, 1);
                                    let startDate = new Date(filteredData[0].deliveryDate.getFullYear(), filteredData[0].deliveryDate.getMonth(), 1);;
                                    let eDt = new Date(nextDayOfMonth - 1);
                                    eDt = new Date(eDt);
                                    let orders = filteredData.filter((c: any) => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                    if (orders && orders.length > 0) {
                                        let totalSale = 0;
                                        let adminCommission = 0;
                                        let orderEarning = 0;
                                        for (let o = 0; o < orders.length; o++) {
                                            //Calculate Commission and Etc
                                            totalSale += orders[o].totalNetAmount;
                                            adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                            orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                        }
                                        let obj = {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: adminCommission.toFixed(2),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: orderEarning.toFixed(2),
                                            totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        }
                                        colData.push(obj);
                                        const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                        await addDoc(monthlyMakersPaymentsRef, {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: parseFloat(adminCommission.toFixed(2)),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                            totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        });
                                    }
                                }
                            } else {
                                let startDate = filteredData[0].deliveryDate;
                                let eDt = JSON.parse(JSON.stringify(startDate));
                                eDt = new Date(eDt);
                                eDt = eDt.setDate(eDt.getDate() + items[i].payoutReleasePeriod.days);
                                eDt = new Date(eDt);
                                while (new Date(eDt).getTime() <= new Date().getTime()) {
                                    let orders = filteredData.filter((c: any) => c.deliveryDate >= startDate && c.deliveryDate <= eDt);
                                    if (orders && orders.length > 0) {
                                        let totalSale = 0;
                                        let adminCommission = 0;
                                        let orderEarning = 0;
                                        for (let o = 0; o < orders.length; o++) {
                                            //Calculate Commission and Etc
                                            totalSale += orders[o].totalNetAmount;
                                            adminCommission += orders[o].totalNetAmount * (items[i].commission.commission / 100);
                                            orderEarning += orders[o].totalNetAmount - (orders[o].totalNetAmount * (items[i].commission.commission / 100));
                                        }
                                        let obj = {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: adminCommission.toFixed(2),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: orderEarning.toFixed(2),
                                            totalPayable: orderEarning.toFixed(2) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        }
                                        colData.push(obj);
                                        const monthlyMakersPaymentsRef = collection(db, 'makersPayoutRelease')
                                        await addDoc(monthlyMakersPaymentsRef, {
                                            fromDate: startDate,
                                            toDate: eDt,
                                            MakerId: items[i].uid,
                                            MakerName: items[i].displayName,
                                            commission: items[i].commission.commission,
                                            adminCommission: parseFloat(adminCommission.toFixed(2)),
                                            totalSells: totalSale,
                                            rewardsEarning: items[i].walletAmount,
                                            ordersEarning: parseFloat(orderEarning.toFixed(2)),
                                            totalPayable: parseFloat(orderEarning.toFixed(2)) + items[i].walletAmount,
                                            isPaid: 0,
                                            payoutPeriod: items[i].payoutReleasePeriod.payoutPeriod,
                                            payoutDays: items[i].payoutReleasePeriod.days,
                                            lastPayoutReleaseDate: null,
                                            createdDate: serverTimestamp(),
                                            modifiedDate: serverTimestamp(),
                                        });
                                        eDt = new Date(eDt);
                                        eDt = eDt.setDate(eDt.getDate() + items[i].payoutReleasePeriod.days);
                                        startDate = new Date(JSON.parse(JSON.stringify(eDt)));
                                    }
                                }
                            }
                        }

                    }
                }
            }

            const monthlyPaymentsRef = collection(db, 'makersPayoutRelease');
            let qm = query(monthlyPaymentsRef, orderBy('toDate', 'desc'))
            if (status.status === 'Paid') {
                qm = query(qm, where('isPaid', '==', 1))
            }
            if (status.status === 'Not Paid') {
                qm = query(qm, where('isPaid', '==', 0))
            }
            if (status.makerName) {
                qm = query(qm, where('MakerName', '==', status.makerName))
            }
            const querySnapshotm = await getDocs(qm)
            querySnapshotm.forEach((docm: any) => {
                makersList.push({ id: docm.id, ...docm.data() });
            });

            setPayment(makersList);
            if (makersList.length > 0) {
                setFlag(true);
            } else {
                setFlag(false);
            }
            setRow(makersList.length)

            const uniqueNamesSet = new Set();
            const uniqueMaker: any[] = [];

            makersList.filter((e: any) => {
                if (!uniqueNamesSet.has(e.MakerName)) {
                    uniqueNamesSet.add(e.MakerName);
                    uniqueMaker.push(e);
                }
            });
            setList(uniqueMaker);

        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    //change value while filtering status field
    const handleChangeStatus = (e: any) => {
        e.preventDefault();
        status.status = e.target.value
        getMonthlyData();
    }

    //when foodMaker changes in dropdown
    const handleChange = (e: any) => {
        e.preventDefault();
        status.makerName = e.target.value
        getMonthlyData();
    };

    //if we clear foodMaker which is selected then it is invoked
    const handleClearClick = () => {
        status.makerName = '';
        getMonthlyData();
    };

    //if click on view set this item in sessionStorage which used in detail page
    const handleClickVisible = (e: any) => {
        let from: any;
        let to: any;
        if (e.fromDate) {
            const timestampMilliseconds = e.fromDate.seconds * 1000 + Math.floor(e.fromDate.nanoseconds / 1e6);
            const date = new Date(timestampMilliseconds);
            const iso8601Date = date.toISOString();
            console.log(iso8601Date);
            from = iso8601Date;
        }
        if (e.toDate) {
            const timestampMilliseconds = e.toDate.seconds * 1000 + Math.floor(e.toDate.nanoseconds / 1e6);
            const date = new Date(timestampMilliseconds);
            const iso8601Date = date.toISOString();
            console.log(iso8601Date);
            to = iso8601Date;
        }

        sessionStorage.setItem("makerName", e.MakerName + "");
        sessionStorage.setItem("makerId", e.MakerId + "");
        sessionStorage.setItem("fromDate", from + "");
        sessionStorage.setItem("toDate", to + "");
        navigate(`/admin/makerspayment/view/${e.MakerId}`);
    }

    //if click markAsPaid icon
    const handleClickPaid = async (element: any) => {
        let obj = {
            "id": element.id,
            "totalPayable": element.totalPayable,
            "maker": element.MakerName,
            "makerId": element.MakerId,
            "reward": element.rewardsEarning
        }
        setPayout(obj);
        setIsConfirm(true);
    }

    //close confirm dialog
    const handleCloseConfirm = () => {
        setIsConfirm(false);
    }

    // save switch changes
    const handleCheckConfirm = async () => {
        try {
            let obj = {
                isPaid: 1,
                lastPayoutReleaseDate: serverTimestamp()
            }
            const datasRef = doc(db, 'makersPayoutRelease', payout.id);
            await setDoc(datasRef, obj, { merge: true });

            if (payout.reward > 0) {
                const items: any[] = [];
                const usersCollectionRef = collection(db, 'users');
                const q = query(usersCollectionRef, where('uid', '==', payout.makerId))
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc: any) => {
                    items.push({ id: doc.id, ...doc.data() });
                });

                if (items && items.length > 0) {

                    let obj1 = {
                        walletAmount: items[0].walletAmount - payout.reward
                    }
                    const userRef = doc(db, 'users', items[0].id);
                    await setDoc(userRef, obj1, { merge: true }); 

                }
                const usersWalletRef = collection(db, 'userWalletHistory');
                await addDoc(usersWalletRef, {
                    amount: payout.reward,
                    makerId: payout.makerId,
                    redeemStatus: false,
                    title: 'Payout Release',
                    walletHistoryType: 'debit',
                    createdDate: serverTimestamp(),
                    modifiedDate: serverTimestamp(),
                });

            }

        } catch (error) {
            console.log(error)
        }
        setIsConfirm(false);
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleChangePage = async (event: any, newPage: any) => {
        setPage(newPage);
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleChangeRowsPerPage = (event: any) => {
        setPage(0);
        setLimits(parseInt(event.target.value, 10));
    };

    return (
        <>
            <Helmet>
                <title> Makers Payment | Vyanjan-Pursuit for Hunger! </title>
            </Helmet>
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Stack alignItems="left" justifyContent="space-between" mb={5} >
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to="/admin/dashboard"
                                    style={{ display: 'flex', color: "black" }}
                                >
                                    <HomeIcon />
                                </Link>
                                <Typography color="inherit">Makers Payment</Typography>
                            </Breadcrumbs>
                        </Stack>
                        <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                            <div>
                                {isLoading ? <Loader title="Loading..." /> :
                                    <>
                                        <StyledRoot
                                            sx={{
                                                color: 'primary.main',
                                                bgcolor: 'white',
                                            }}
                                        >
                                            <Typography></Typography>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 8px' }}>
                                                <div className="dataShowLarge">
                                                    <FormGroup >
                                                        <FormControl sx={{ width: "134px", ml: 0.5, mb: 1, mt: '10px' }} size="small">
                                                            <InputLabel id="demo-simple-select-label">Food Maker</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name="foodMaker"
                                                                value={status.makerName}
                                                                label="FoodMaker"
                                                                onChange={handleChange}
                                                                endAdornment={(status.makerName) ? <IconButton sx={{ marginRight: '8px' }} onClick={handleClearClick}><ClearIcon sx={{ fontSize: '15px' }} /></IconButton> : ''}
                                                            >
                                                                {list.map((e: any) => (
                                                                    <MenuItem value={e.MakerName} key={e.MakerName}>{e.MakerName}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </FormGroup>
                                                </div>
                                                <div className="dataShowLarge">
                                                    <FormGroup>
                                                        <FormControl sx={{ width: "134px", ml: 0.5, mb: 1, mt: '10px' }} size="small">
                                                            <InputLabel id="demo-simple-select-label">Paid Status</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                name="status"
                                                                value={status.status || 'All'}
                                                                label="Status"
                                                                onChange={(e) => { handleChangeStatus(e) }}
                                                            >
                                                                <MenuItem value='Paid'>Paid</MenuItem>
                                                                <MenuItem value='Not Paid'>Not Paid</MenuItem>
                                                                <MenuItem value='All'>All</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </StyledRoot>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 8px' }}>
                                            <div className="dataShowSmall">
                                                <FormGroup >
                                                    <FormControl sx={{ width: "134px", ml: 0.5, mb: 1, mt: '10px' }} size="small">
                                                        <InputLabel id="demo-simple-select-label">Food Maker</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name="foodMaker"
                                                            value={status.makerName}
                                                            label="FoodMaker"
                                                            onChange={handleChange}
                                                            endAdornment={(status.makerName) ? <IconButton sx={{ marginRight: '8px' }} onClick={handleClearClick}><ClearIcon sx={{ fontSize: '15px' }} /></IconButton> : ''}
                                                        >
                                                            {list.map((e: any) => (
                                                                <MenuItem value={e.MakerName} key={e.MakerName}>{e.MakerName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </FormGroup>
                                            </div>
                                            <div className="dataShowSmall">
                                                <FormGroup>

                                                    <FormControl sx={{ width: "134px", ml: 0.5, mb: 1, mt: '10px' }} size="small">
                                                        <InputLabel id="demo-simple-select-label">Paid Status</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name="status"
                                                            value={status.status || 'All'}
                                                            label="Status"
                                                            onChange={(e) => { handleChangeStatus(e) }}
                                                        >
                                                            <MenuItem value='Paid'>Paid</MenuItem>
                                                            <MenuItem value='Not Paid'>Not Paid</MenuItem>
                                                            <MenuItem value='All'>All</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        {(flag === true) ?
                                            <>
                                                <Scrollbar>
                                                    <TableContainer className="makerpayment-list" sx={{ backgroundColor: "white" }}>
                                                        <Table stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell >#</TableCell>
                                                                    <TableCell sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Makers</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Total Sells</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Admin Commission</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Orders Earning</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Rewards Earning</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Makers Payable</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">From Date</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">To Date</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Paid Status</Typography></TableCell>
                                                                    <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">View Orders</Typography></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {payment.slice(page * limits, page * limits + limits).map((arr: any, index: number) => (
                                                                    <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                                                        <TableCell >{(page * limits) + index + 1}</TableCell>
                                                                        <TableCell sx={{ color: '#212B36' }}><Typography variant='subtitle2' noWrap>{arr.MakerName}</Typography></TableCell>
                                                                        <TableCell ><Typography noWrap fontSize="0.875rem">₹ {(arr.totalSells).toFixed(2)}</Typography></TableCell>
                                                                        <TableCell><Typography noWrap fontSize="0.875rem">₹ {(arr.adminCommission)}</Typography></TableCell>
                                                                        <TableCell><Typography noWrap fontSize="0.875rem">₹ {(arr.ordersEarning)}</Typography></TableCell>
                                                                        <TableCell><Typography noWrap fontSize="0.875rem">₹ {(arr.rewardsEarning)}</Typography></TableCell>
                                                                        <TableCell><Typography noWrap fontSize="0.875rem">₹ {((arr.totalPayable))}</Typography></TableCell>
                                                                        <TableCell ><Typography noWrap fontSize="0.875rem">{format(new Date(arr.fromDate.seconds * 1000), formatedDate)}</Typography></TableCell>
                                                                        <TableCell><Typography noWrap fontSize="0.875rem">{format(new Date(arr.toDate.seconds * 1000), formatedDate)}</Typography></TableCell>
                                                                        <TableCell>{arr.isPaid == 0 ? <>
                                                                            <Tooltip title="Make Payout" arrow>
                                                                                <IconButton
                                                                                    sx={{ color: '#1565c0' }}
                                                                                    color="inherit"
                                                                                    size="small"
                                                                                    onClick={(e) => { handleClickPaid(arr) }}
                                                                                >
                                                                                    <ReplyIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </> : <>
                                                                            <Tooltip title="Paid" arrow>
                                                                                <IconButton
                                                                                    sx={{ color: '#1565c0' }}
                                                                                    color="inherit"
                                                                                    size="small"
                                                                                >
                                                                                    <DoneIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </>
                                                                        }</TableCell>
                                                                        <TableCell align="right">
                                                                            <Tooltip title="View Orders" arrow>
                                                                                <IconButton
                                                                                    sx={{ color: '#1565c0' }}
                                                                                    color="inherit"
                                                                                    size="small"
                                                                                    onClick={(e) => { handleClickVisible(arr) }}
                                                                                >
                                                                                    <VisibilityIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Scrollbar>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30, 40]}
                                                    component="div"
                                                    count={row}
                                                    rowsPerPage={limits}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </>
                                            :
                                            <TableContainer sx={{ height: 'calc(100vh - 314px)', backgroundColor: "white" }}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <Paper
                                                                sx={{
                                                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                }}
                                                            >
                                                                <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                <Typography variant="h6" paragraph>
                                                                    Data not Available
                                                                </Typography>
                                                            </Paper>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                        <Divider />
                                    </>
                                }
                            </div >
                        </Card>
                    </Grid>
                </Grid>
            </Container >

            <div>
                <Dialog open={isConfirm} onClose={handleCloseConfirm}>
                    <DialogTitle>Confirm Payout Release</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to Pay ₹{((payout.totalPayable)).toFixed(2)} to {payout.maker}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirm}>Cancel</Button>
                        <Button onClick={handleCheckConfirm}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}