import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import FoodMakerPage from './pages/FoodMakerPage';
import LoginPage from './pages/LoginPage';
import FoodSeekerPage from './pages/FoodSeekerPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import PaymentTransactionPage from './pages/PaymentTransaction';
import OrdersPage from './pages/OrdersPage';
import CommisionPage from './pages/CommisionPage';
import SettingPage from './pages/SettingPage';
import FoodMakerDetails from './pages/foodMakerDetails';
import FoodSeekerDetails from './pages/FoodSeekerDetails';
import PaymentTransactionDetailPage from './pages/PaymentTransactionDetailPage';
import OrderDetailPage from './pages/OrderDetailPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SignUpPage from './pages/signUpPage';
import Protected from './protected'
// import TermConditon from './sections/auth/TermCondition/termConditon';
// import PrivacyPolicy from './sections/auth/privacyPolicy/privacyPolicy';
import TopFoodMaker from './pages/Reports/TopFoodMaker';
import TopFoodSeeker from './pages/Reports/TopFoodSeeker';
import RewardPage from './pages/RewardPage';
import BannerPage from './pages/BannerPage';
import { AdminProfit } from './pages/Reports/AdminProfit';
import { ProductCategory } from './pages/ProductCategory/productCategory';
import { Feedback } from './pages/FeedBack/Feedback';
import { PaymentHistory } from './pages/PaymentHistory/index';
import { PaymentHistoryDetail } from './pages/PaymentHistory/PaymentHistoryDetail';
import { PayoutRelease } from './pages/PayoutRelease'
import { FoodmakerPaymentHistory } from './pages/PaymentHistory/FoodmakerPaymentHistory';
import TopProductCategory from './pages/Reports/TopProductCategory';
import TopProductItem from './pages/Reports/TopProductItem';
import { ProductCategoryDetail } from './pages/ProductCategory/productCategoryDetail';
import AllNotifications from './pages/AllNotifications';
import { Country } from './pages/country';
import { PaymentHistoryNew } from './pages/PaymentHistoryNew';
import { PaymentHistoryNewDetail } from './pages/PaymentHistoryNew/PaymentHistoryNewDetail';
import VerifyEmail from './pages/VerifyEmail/verifyEmail/VerifyEmail';
import SeekerDeepLink from './pages/VerifyEmail/seekerDeepLink/SeekerDeepLink';
import MakerDeepLink from './pages/VerifyEmail/makerDeepLink/MakerDeepLink';

export default function Router() {
  const routes = useRoutes([
    {
      path: '/admin',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/admin/dashboard" />, index: true },
        { path: '/admin/dashboard', element: <Protected ><DashboardAppPage /></Protected> },
        { path: 'foodMakers', element: <Protected><FoodMakerPage /></Protected> },
        { path: 'foodMakers/view/:uid', element: <Protected ><FoodMakerDetails /></Protected> },
        { path: 'foodSeekers', element: <Protected ><FoodSeekerPage /></Protected> },
        { path: 'foodSeekers/view/:uid', element: <Protected><FoodSeekerDetails /></Protected> },
        { path: 'productcategory', element: <Protected><ProductCategory /></Protected> },
        { path: 'productcategory/view/:id', element: <Protected><ProductCategoryDetail /></Protected> },
        { path: 'ordersTransaction', element: <Protected><PaymentTransactionPage /></Protected> },
        { path: 'ordersTransaction/view/:id', element: <Protected><PaymentTransactionDetailPage /></Protected> },
        { path: 'orders', element: <Protected><OrdersPage /></Protected> },
        { path: 'orders/view/:id', element: <Protected><OrderDetailPage /></Protected> },
        { path: 'commission', element: <Protected ><CommisionPage /></Protected> },
        { path: 'reward', element: <Protected><RewardPage /></Protected> },
        { path: 'banner', element: <Protected ><BannerPage /></Protected> },
        { path: 'topfoodmakers', element: <Protected><TopFoodMaker /></Protected> },
        { path: 'topsellbyproductcategory', element: <Protected><TopProductCategory /></Protected> },
        { path: 'admincommission', element: <Protected><AdminProfit /></Protected> },
        // { path: 'topfoodmaker', element: <Protected><ReportsPage /></Protected> },
        { path: 'topfoodseekers', element: <Protected><TopFoodSeeker /></Protected> },
        { path: 'topsellingproducts', element: <Protected><TopProductItem /></Protected> },
        { path: 'setting', element: <Protected ><SettingPage /></Protected> },
        { path: 'feedback', element: <Protected ><Feedback /></Protected> },
        // { path: 'makerspayment', element: <Protected ><PaymentHistory /></Protected> },
        // { path: 'makerspayment/view', element: <Protected ><PaymentHistoryDetail /></Protected> },
        // { path: 'makerspayment/makerspaymentDetail/view/:uid', element: <Protected ><FoodmakerPaymentHistory /></Protected> },
        { path: 'makerspayment', element: <Protected ><PaymentHistoryNew /></Protected> },
        { path: 'makerspayment/view/:id', element: <Protected ><PaymentHistoryNewDetail /></Protected> },
        { path: 'payoutreleaseperiod', element: <Protected ><PayoutRelease /></Protected> },
        { path: 'notifications', element: <Protected ><AllNotifications /></Protected> },
        { path: 'region', element: <Protected ><Country /></Protected> },
      ],
    },
    {
      path: "",
      element: <LoginPage />,
      children: [
        {
          path: '/',
          element: <Navigate to="/" replace />
        },
      ]
    },
    {
      path: 'forgotPassword',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'signup',
      element: <SignUpPage />,
    },
    {
      path: 'resetPassword',
      element: <ResetPasswordPage />,
    },
    {
      path: 'verifyEmail/:type/:userId/:token',
      element: <VerifyEmail />,
    },
    // {
    //   path: 'seeker-deeplink',
    //   element: <SeekerDeepLink />,
    // },
    // {
    //   path: 'maker-deeplink',
    //   element: <MakerDeepLink />,
    // },
    // {
    //   path: 'terms&condition',
    //   element: <TermConditon />,
    // },
    // {
    //   path: 'privacypolicy',
    //   element: <PrivacyPolicy />,
    // },
    // {
    //   path: 'signUp',
    //   element: <SignUpForm />,
    // },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}