import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { format, set, sub } from 'date-fns';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  Divider,
  Paper,
  Grid,
  Breadcrumbs,
  IconButton,
  Tooltip,
  alpha,
  OutlinedInput,
  styled,
  Toolbar,
  Button,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { db } from '../firebase';
import { collection, query, where, setDoc, getDocs, orderBy, limit, startAfter, getCountFromServer, endBefore, limitToLast, doc, updateDoc, deleteDoc } from "firebase/firestore";
import Loader from './spinner';
import './FoodMakerPage.css'
import Label from '../components/label';
import Iconify from '../components/iconify';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 100,
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function AllNotifications() {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  let [limits, setLimits] = useState<number>(10);
  const [row, setRow] = useState<number>(10);
  let [detail, setDetail] = React.useState<any>([]);// to show data inside page
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisible, setFirstVisible] = useState<any>();
  const [flag, setFlag] = useState<boolean>(true);
  const navigate = useNavigate();
  const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

  // it is the first method invoked when this page is open
  useEffect(() => {
    getNotifications();
  }, [])

  //call intially
  const getNotifications = async () => {
    const items: any[] = [];
    setIsLoading(true);
    try {
      const notificationRef = collection(db, 'notifications');
      const qLenn = query(notificationRef, where('role', '==', 'admin'))
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count);
      const q = query(notificationRef, where('role', '==', 'admin'),orderBy('createdDate', 'desc'), limit(limits))
      const querySnapshot = await getDocs(q)

      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisible(querySnapshot.docs[0]);
      setDetail(items);
      if (items.length > 0) {
        setFlag(true);
      } else {
        setFlag(false);
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  // when click on delete all
  const handleDeleteAll = async () => {
    try {
      const items1: any = [];
      const notificationRef = collection(db, 'notifications');
      const q = query(notificationRef, where('role', '==', 'admin'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items1.push({ id: doc.id, ...doc.data() });
      });
      for (let i = 0; i < items1.length; i++) {
        const deleteRef = doc(notificationRef, items1[i]?.id)
        deleteDoc(deleteRef);
      }
    }
    catch (error) {
      console.error(error);
    }
    getNotifications();
  }

  //when click on delete of row
  const handleDelete = async (id: any) => {
    const NotificationsRef = collection(db, 'notifications');
    try {
      const deleteRef = doc(NotificationsRef, id)
      deleteDoc(deleteRef);
    }
    catch (error) {
      console.error(error);
    }
    getNotifications();
  }

  // when next(>) button in pagination change which is on bottom right of the page
  const nextData = async () => {
    let next = query(collection(db, "notifications"),
      where('role', '==', 'admin'),
      orderBy('createdDate', 'desc'),
      startAfter(lastVisible),
      limit(limits));
    const items: any[] = [];
    const querySnapshot = await getDocs(next);
    querySnapshot.forEach((doc: any) => {
      items.push({ id: doc.id, ...doc.data() });
    });
    setDetail(items);
    updateState(querySnapshot)
  }

  // when previous(<) button in pagination change which is on bottom right of the page
  const previousData = async () => {
    let next = query(collection(db, "notifications"),
      where('role', '==', 'admin'),
      orderBy('createdDate', 'desc'),
      endBefore(firstVisible),
      limitToLast(limits));
    const items: any[] = [];
    const querySnapshot = await getDocs(next);
    querySnapshot.forEach((doc: any) => {
      items.push({ id: doc.id, ...doc.data() });
    });
    setDetail(items)
    updateState(querySnapshot);
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handlePageChange = async (event: any, newPage: number) => {
    setPage(newPage);
    if (page < newPage) {
      nextData();
    } else {
      previousData();
    }
  };

  // update first visible and last visible value according to pagination (next and previous button)
  const updateState = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimits(parseInt(event.target.value));
    limits = parseInt(event.target.value);
    setPage(0);
    // getFoodMaker();
  };


  return (
    <>
      <Helmet>
        <title> Notifications | Vyanjan-Pursuit for Hunger!</title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt="-7px" >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit" >Notifications</Typography>
              </Breadcrumbs>
              <div className="largeScreenContent">
                <Button
                  sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                  variant="contained"
                  onClick={(e) => handleDeleteAll()}
                  startIcon={<Iconify icon="ic:baseline-delete-forever" />}>
                  Delete All
                </Button>
              </div>
              <div className="smallScreenContent">
                <Button
                  sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                  variant="contained"
                  onClick={(e) => handleDeleteAll()}>
                  <DeleteForeverIcon />
                </Button>
              </div>
            </Stack>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>

              <Card className="card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                <div>
                  {isLoading ? <Loader title="Loading..." /> :
                    <>
                      {
                        ((flag === true)) ?
                          <>
                            <Scrollbar>
                              <TableContainer className="tableContainer-list" sx={{ backgroundColor: "white" }}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="left" >#</TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Title</Typography></TableCell>
                                      {/* <TableCell align="left" >Subtitle</TableCell> */}
                                      <TableCell align="left"><Typography noWrap fontWeight="600" fontSize="0.875rem">Notification-For</Typography></TableCell>
                                      <TableCell align="left"><Typography noWrap fontWeight="600" fontSize="0.875rem">Status</Typography></TableCell>
                                      <TableCell align="left"><Typography noWrap fontWeight="600" fontSize="0.875rem">createdDate</Typography></TableCell>
                                      <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody >
                                    {
                                      detail.map((arr: any, index: any) => (
                                        <>
                                          {/* {arr.isRead === false ? */}
                                          <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                            <TableCell align="left" > {(page * limits) + index + 1}</TableCell>
                                            <TableCell align="left" sx={{ textTransform: 'capitalize' }}><Typography noWrap fontSize="0.875rem">{arr.title}</Typography></TableCell>
                                            {/* <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{arr.subtitle}</TableCell> */}
                                            <TableCell align="left" sx={{ textTransform: 'capitalize' }}><Typography noWrap fontSize="0.875rem">{arr.basedOn}</Typography></TableCell>
                                            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                                              {arr.isRead === false && <Label sx={{ color: "Red", width: '77px' }}>UnRead</Label>}
                                              {arr.isRead === true && <Label sx={{ color: "Green", width: '77px' }}>Read</Label>}
                                            </TableCell>
                                            <TableCell align="left"><Typography noWrap fontSize="0.875rem">  {format(new Date(arr.createdDate.seconds * 1000), formatedDate)}</Typography></TableCell>
                                            <TableCell align="right" >
                                              <Tooltip title="Delete" arrow>
                                                <IconButton
                                                  sx={{ pt: 0.5, pb: 0.5, color: '#1565c0' }}
                                                  color="inherit"
                                                  size="small"
                                                  onClick={(e) => handleDelete(arr.id)}
                                                >
                                                  <DeleteForeverIcon fontSize="small" />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                          {/* :
                                            <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                              <TableCell align="left" > {(page * limits) + index + 1}</TableCell>
                                              <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{arr.title}</TableCell>
                                              <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{arr.subtitle}</TableCell>
                                              <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{arr.basedOn}</TableCell>
                                              <TableCell align="left">  {format(new Date(arr.createdDate.seconds * 1000), formatedDate)}</TableCell>
                                              <TableCell align="right" >
                                                <Tooltip title="Delete" arrow>
                                                  <IconButton
                                                    sx={{ pt: 0.5, pb: 0.5, color: '#1565c0' }}
                                                    color="inherit"
                                                    size="small"
                                                    onClick={(e) => handleDelete(arr.id)}
                                                  >
                                                    <DeleteForeverIcon fontSize="small" />
                                                  </IconButton>
                                                </Tooltip>
                                              </TableCell>
                                            </TableRow>
                                          } */}

                                        </>
                                      )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Scrollbar>
                            <TablePagination
                              component="div"
                              count={row}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleLimitChange}
                              page={page}
                              rowsPerPage={limits}
                              rowsPerPageOptions={[10, 20, 30, 40]}
                              sx={{ pt: 1, pb: 1 }}
                            />
                          </>
                          :
                          <TableContainer className="foodmaker-list-notFound" sx={{ backgroundColor: "white" }}>
                            <Table>
                              <Divider />
                              <TableBody>
                                <TableRow>
                                  <Paper
                                    sx={{
                                      width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                    }}
                                  >
                                    <img src="\search.png" height="50" width="50" style={{ marginLeft: "35%" }} />
                                    <Typography variant="h6" paragraph>
                                      Data not Available
                                    </Typography>
                                  </Paper>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                      }
                    </>
                  }
                </div>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Container >
    </>
  );
}