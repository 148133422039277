import React from "react";
import "./spinner.css";

interface Props {
  title: string
}

const Loader: React.FC<Props> = (props: Props) => {
  return (
    <div className="spinner-container">
      {/* style={{ height: 'calc(100vh - 210px)' }} */}
      <div className="loading-spinner">
      </div>
    </div>
  )
}

export default Loader