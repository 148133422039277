import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Breadcrumbs, Button, Card, CardContent, Container, DialogActions, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, Switch, Tab, TableContainer, TextField, Tooltip, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import Scrollbar from '../components/scrollbar';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { db, storage } from '../firebase';
import { collection, doc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import Loader from './spinner';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';
import Resizer from "react-image-file-resizer";
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import Iconify from '../components/iconify';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image']
    ],
  },
};

const style1 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  px: 3,
  zindex: 1,
};

const initialState = {
  flagGroupName: "",
}

const SettingPage = () => {
  let [value1, setValue1] = React.useState<any>([]);
  const [value, setValue] = React.useState<any>('1');
  const [isLoading, setIsLoading] = useState(false);
  const [detailEmailCredential, setDetailEmailCredential] = React.useState<any>([]);
  const [detailDateFormat, setDetailDateFormat] = React.useState<any>([]);
  const [detailCommission, setDetailCommission] = React.useState<any>([]);
  const [detailSocialMedia, setDetailSocialMedia] = React.useState<any>([]);
  let [detailPayment, setDetailPayment] = React.useState<any>([]);
  const [detailFirebaseCredential, setDetailFirebaseCredential] = React.useState<any>([]);
  const [detailWelcomeMail, setDetailWelcomeMail] = React.useState<any>([]);
  const [emailCredential, setEmailCredential] = React.useState<any>([]);
  const [welcomeMail, setWelcomeMail] = React.useState<any>([]);
  const [dateFormat, setDateFormat] = React.useState<any>([]);
  const [commission, setCommission] = React.useState<any>([]);
  let [imageData, setImageData] = React.useState<any>([]);
  const [socialMedia, setSocialMedia] = useState<any>([]);
  const [firebaseCredential, setFirebaseCredential] = useState<any>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [imageUrls, setImageUrls] = useState<any>('');
  const [imgSrc, setImgSrc] = useState<any>('')
  const [imageUrlsDefault, setImageUrlsDefault] = useState<any>('');
  const [imgSrcDefault, setImgSrcDefault] = useState<any>('')
  const [imageUrlsUser, setImageUrlsUser] = useState<any>('');
  const [imgSrcUser, setImgSrcUser] = useState<any>('')
  let [result, setResult] = useState<any>('');
  let [resultDefault, setResultDefault] = useState<any>('');
  let [resultUser, setResultUser] = useState<any>('');
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null)
  const imgRef = React.useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const scale: number = 1;
  const rotate: number = 0;
  const [openChild, setOpenChild] = React.useState(false);
  const [openChildDefault, setOpenChildDefault] = React.useState(false);
  const [openChildUser, setOpenChildUser] = React.useState(false);
  const [image, setImage] = useState<any>('');
  const [imageDefault, setImageDefault] = useState<any>('');
  const [imageUser, setImageUser] = useState<any>('');
  const [aspectBanner, setAspectBanner] = useState<any>();
  const [aspectDefault, setAspectDefault] = useState<any>(1);
  const [aspectUser, setAspectUser] = useState<any>(1);
  const [parentFlagGroupName, setParentFlagGroupName] = useState<any>([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [dropdownItem, setDropdownItem] = useState<any>([]);
  let [dropdowndata, setDropdownData] = useState<any>(initialState);
  let [general, setGeneral] = useState<any>([]);
  let [generalData, setGeneralData] = useState<any>([]);
  let [seperateData, setSeperateData] = useState<any>([]);
  const [detailGeneralData, setDetailGeneralData] = React.useState<any>([]);
  const [bannerImageId, setBannerImageId] = React.useState<any>([]);
  const [defaultImageId, setDefaultImageId] = React.useState<any>([]);
  const [userImageId, setUserImageId] = React.useState<any>([]);
  const [name, setName] = React.useState<any>([]);

  // it is the first method invoked when this page is open
  useEffect
    (() => {
      getData();
    }, []);

  //call intially
  const getData = async () => {
    const items: any[] = [];
    const items1: any[] = [];
    const items2: any[] = [];
    const items3: any[] = [];
    const items4: any[] = [];
    setIsLoading(true);
    try {
      const systemFlagsCollectionRef = collection(db, 'systemFlags');
      const flagGroupCollectionRef = collection(db, 'flagGroup');

      const q = query(flagGroupCollectionRef, where('groupName', '==', 'General'))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        items.push(doc.data());
      })
      console.log(items);
      setGeneral(items);
      const q1 = query(systemFlagsCollectionRef, where('parentFlagGroupId', '==', items[0].flagGroupId))
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach(async (doc) => {
        items1.push(doc.data());
      })
      console.log(items1);
      setGeneralData(items1);

      const separatedData: any = {}; // Object to hold separated arrays

      // Iterate through the items and separate them by flag group name
      items1.forEach(item => {
        const flagGroupName = item.flagGroupName;
        if (!separatedData[flagGroupName]) {
          separatedData[flagGroupName] = []; // Initialize an array for the flag group name
        }
        separatedData[flagGroupName].push(item); // Push the item into the corresponding array
      });
      console.log(separatedData);
      const separatedDataArray = Object.entries(separatedData).map(([flagGroupName, items]) => ({
        flagGroupName,
        items
      })).sort((a, b) => (a.flagGroupName === "Image" ? 1 : -1));;

      // Now you can set this array in your state
      setSeperateData(separatedDataArray);
      const flagGroup = separatedDataArray.find(item => item.flagGroupName === 'Image');
      if (flagGroup) {
        imageData = flagGroup.items;
        const bannerItems = imageData.filter((item: any) => item.name === 'bannerImage');
        if (bannerItems) {
          setImgSrc(bannerItems[0]?.value);
          const arr = bannerItems[0]?.aspect.split("/");
          if (arr) {
            const arr0 = parseInt(arr[0]);
            const arr1 = parseInt(arr[1]);
            let d = arr0 / arr1;
            setAspectBanner(d);
          }
        }
        const defaultItems = imageData.filter((item: any) => item.name === 'defaultImage');
        if (defaultItems) {
          setImgSrcDefault(defaultItems[0]?.value);
          setAspectDefault(defaultItems[0]?.aspect);
        }
        const userItems = imageData.filter((item: any) => item.name === 'userImage');
        if (userItems) {
          setImgSrcUser(userItems[0]?.value);
          setAspectUser(userItems[0]?.aspect);
        }
      }

      const q4 = query(systemFlagsCollectionRef, where('flagGroupName', '==', 'Welcome Mail'), orderBy('displayOrder', 'asc'))
      const querySnapshot4 = await getDocs(q4);
      querySnapshot4.forEach(async (doc4) => {
        let itm4 = doc4.data();
        let obj = {
          flagGroupId4: doc4.data().parentFlagGroupId,
        }
        if (obj.flagGroupId4) {
          const q4 = query(flagGroupCollectionRef, where('flagGroupId', '==', obj.flagGroupId4))
          const _querySnapshot4 = await getDocs(q4)
          _querySnapshot4.forEach((_doc4) => {
            itm4.systemFlagGroupName = _doc4.data().groupName;
          })
          items4.push(itm4);
          setWelcomeMail(items4);
        }
      })
      setName([]);
      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  // change general data
  const handleChangeGeneralData = (e: any, valueTypeName: any) => {
    let num;
    if (valueTypeName === 'Number') {
      num = parseInt(e.target.value);
    }
    let val = JSON.parse(JSON.stringify(generalData));
    let index = val.findIndex((x: any) => x.id === e.target.name);
    if (index >= 0) {
      setGeneralData([]);
      const changeValue = num ? num : e.target.value;
      val[index].value = changeValue;
      setGeneralData(val);
      setName((name: any) => [...name, val[index]])

      const separatedData: any = {};
      val.forEach((item: any) => {
        const flagGroupName = item.flagGroupName;
        if (!separatedData[flagGroupName]) {
          separatedData[flagGroupName] = [];
        }
        separatedData[flagGroupName].push(item);
      });
      console.log(separatedData);
      const separatedDataArray = Object.entries(separatedData).map(([flagGroupName, items]) => ({
        flagGroupName,
        items
      })).sort((a, b) => (a.flagGroupName === "image" ? 1 : -1));;
      setSeperateData(separatedDataArray);

      // setDetailGeneralData(val[index]);
      setDetailGeneralData((detailGeneralData: any) => ({
        ...detailGeneralData,
        [val[index].id]: changeValue,
      }));
    }
  };

  //change in switch in genral data
  const handleSwitchGeneral = async (id: any, e: any) => {
    if (e === "1") {
      setDetailGeneralData((detailGeneralData: any) => ({
        ...detailGeneralData,
        [id]: '0',
      }));
    }
    else if (e === "0") {
      setDetailGeneralData((detailGeneralData: any) => ({
        ...detailGeneralData,
        [id]: '1',
      }));
    }
  };

  // update general data
  const handleClickUpdateGeneralData = async (e: any) => {
    setIsLoading(true);
    const entries = Object.entries(detailGeneralData);
    for (const [key, value] of entries) {
      let obj = {
        'value': value,
      }
      const datasRef = doc(db, 'systemFlags', key);
      await setDoc(datasRef, obj, { merge: true });
    }
    for (let i = 0; i < name.length; i++) {
      if (name[i].name === 'dateFormat') {
        localStorage.setItem('dateFormat', JSON.stringify(name[i].value))
      }
      if (name[i].name === 'commission') {
        localStorage.setItem('commission', JSON.stringify(name[i].value))
      }

    }

    const ids = v4();
    if (result) {
      let data = result.includes(",") ? result.split(",")[1] : result;
      const blob = b64toBlob(data, 'image/png');
      const imageRef = ref(storage, `systemFlag/banner/${'image_' + ids}`);
      uploadBytes(imageRef, blob).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setImageUrls(url);
          setImgSrc(url);
          let obj = {
            'value': url,
          }
          const datasRef = doc(db, 'systemFlags', bannerImageId);
          setDoc(datasRef, obj, { merge: true });
        });
      })
    }

    if (resultDefault) {
      let data = resultDefault.includes(",") ? resultDefault.split(",")[1] : resultDefault;
      const blob = b64toBlob(data, 'image/png');
      const imageRef = ref(storage, `systemFlag/default/${'image_' + ids}`);
      uploadBytes(imageRef, blob).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setImageUrlsDefault(url);
          setImgSrcDefault(url);
          let obj2 = {
            'value': url,
          }
          const datasRef1 = doc(db, 'systemFlags', defaultImageId);
          setDoc(datasRef1, obj2, { merge: true });
        });
      })
    }

    if (resultUser) {
      let data = resultUser.includes(",") ? resultUser.split(",")[1] : resultUser;
      const blob = b64toBlob(data, 'image/png');
      const imageRef = ref(storage, `systemFlag/user/${'image_' + ids}`);
      uploadBytes(imageRef, blob).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setImageUrlsUser(url);
          setImgSrcUser(url);
          let obj3 = {
            'value': url,
          }
          const datasRef2 = doc(db, 'systemFlags', userImageId);
          setDoc(datasRef2, obj3, { merge: true });
        });
      })
    }
    getData();
    setIsLoading(false);
  }

  //change  in welcome mail
  const handleChangeWelcomeMail = (e: any) => {
    let val = JSON.parse(JSON.stringify(welcomeMail));
    let index = val.findIndex((x: any) => x.id === e);
    if (index >= 0) {
      setWelcomeMail([]);
      val[index].value = e.target.value;
      setWelcomeMail(val);
      // setDetailWelcomeMail(val[index]);
      setDetailWelcomeMail((detailWelcomeMail: any) => ({
        ...detailWelcomeMail,
        [val[index].id]: e.target.value,
      }));
    }
  };

  // update welcome mail
  const handleClickUpdateWelcomeMail = async (e: any) => {
    const entries = Object.entries(detailWelcomeMail);
    for (const [key, value] of entries) {
      let obj = {
        'value': value,
      }
      const datasRef = doc(db, 'systemFlags', key);
      await setDoc(datasRef, obj, { merge: true });
    }
    getData();
    setIsLoading(false);
  }

  //change  in social media
  const handleChangeSocialMedia = (e: any) => {
    let val = JSON.parse(JSON.stringify(socialMedia));
    let index = val.findIndex((x: any) => x.id === e.target.name);
    if (index >= 0) {
      setSocialMedia([]);
      val[index].value = e.target.value;
      setSocialMedia(val);

      // setDetailSocialMedia(val[index]);
      setDetailSocialMedia((detailSocialMedia: any) => ({
        ...detailSocialMedia,
        [val[index].id]: e.target.value,
      }));


    }
  };

  // update social media
  const handleClickUpdateSocialMedia = async (e: any) => {
    setIsLoading(true);
    const entries = Object.entries(detailSocialMedia);
    for (const [key, value] of entries) {
      let obj = {
        'value': value,
      }
      const datasRef = doc(db, 'systemFlags', key);
      await setDoc(datasRef, obj, { merge: true });
    }
    getData();
    setIsLoading(false);
  }

  //change  in  payment
  const handleChangePayment = (e: any) => {
    let val = JSON.parse(JSON.stringify(paymentData));
    let index = val.findIndex((x: any) => x.id === e.target.name);
    if (index >= 0) {
      setPaymentData([]);
      val[index].value = e.target.value;
      setPaymentData(val);
      // setDetailPayment(val[index]);
      setDetailPayment((detailPayment: any) => ({
        ...detailPayment,
        [val[index].id]: e.target.value,
      }));
    }
  };

  // update  payment
  const handleClickUpdatePayment = async (e: any) => {
    setIsLoading(true);
    const entries = Object.entries(detailPayment);
    for (const [key, value] of entries) {
      let obj = {
        'value': value,
      }
      const datasRef = doc(db, 'systemFlags', key);
      await setDoc(datasRef, obj, { merge: true });
    };
    getData();
    setIsLoading(false);
  }

  //change  in firebase credential
  const handleChangeFirebaseCredential = (e: any) => {
    let val = JSON.parse(JSON.stringify(firebaseCredential));
    let index = val.findIndex((x: any) => x.id === e.target.name);
    if (index >= 0) {
      setFirebaseCredential([]);
      val[index].value = e.target.value;
      setFirebaseCredential(val);

      // setDetailFirebaseCredential(val[index]);
      setDetailFirebaseCredential((detailFirebaseCredential: any) => ({
        ...detailFirebaseCredential,
        [val[index].id]: e.target.value,
      }));

    }
  };

  // update firebase credential
  const handleClickUpdateFirebaseCredential = async (e: any) => {
    setIsLoading(true);
    const entries = Object.entries(detailFirebaseCredential);
    for (const [key, value] of entries) {
      let obj = {
        'value': value,
      }
      const datasRef = doc(db, 'systemFlags', key);
      await setDoc(datasRef, obj, { merge: true });
    }
    getData();
    setIsLoading(false);
  }

  //change  in payment
  const handleChangedropdown = async (event: any) => {
    let obj = {
      "flagGroupName": event.target.value
    }
    setDropdownData(obj);

    const collectionRef = collection(db, 'systemFlags')
    for (let i = 0; i < dropdownItem.length; i++) {
      if (dropdownItem[i].flagGroupName === event.target.value) {
        const q1 = query(collectionRef, where('flagGroupName', '==', event.target.value));
        const querySnapshot1 = await getDocs(q1);

        for (const docId of querySnapshot1.docs) {
          const activeStatus = doc(db, 'systemFlags', docId.id);
          let change = {
            isSelected: 1
          }
          await setDoc(activeStatus, change, { merge: true })
        }
      }
      else {
        const q1 = query(collectionRef, where('flagGroupName', '==', dropdownItem[i].flagGroupName));
        const querySnapshot1 = await getDocs(q1);

        for (const docId of querySnapshot1.docs) {
          const activeStatus = doc(db, 'systemFlags', docId.id);
          let change = {
            isSelected: 0
          }
          await setDoc(activeStatus, change, { merge: true })
        }
      }
    }

  };

  //tab change
  const handleChange = async (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === '2') {
      const items: any[] = [];
      setIsLoading(true);
      try {
        const systemFlagsCollectionRef = collection(db, 'systemFlags');
        const flagGroupCollectionRef = collection(db, 'flagGroup');
        const q = query(systemFlagsCollectionRef, where('flagGroupName', '==', 'Social Media'))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          let itm = doc.data();
          let obj = {
            flagGroupId: doc.data().parentFlagGroupId,
          }
          const q = query(flagGroupCollectionRef, where('flagGroupId', '==', obj.flagGroupId))
          const _querySnapshot = await getDocs(q)
          _querySnapshot.forEach((_doc) => {
            itm.systemFlagGroupName = _doc.data().groupName;

          })
          items.push(itm);

          setSocialMedia(items);
        })
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    }
    else if (newValue === '3') {
      const items: any[] = [];
      const items1: any[] = [];
      setIsLoading(true);
      try {
        const flagGroupCollectionRef = collection(db, 'flagGroup');
        const q = query(flagGroupCollectionRef, where('groupName', '==', 'Payment'))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          items.push(doc.data());
        })
        console.log(items);
        setParentFlagGroupName(items);
        const systemFlagsCollectionRef = collection(db, 'systemFlags');
        const q1 = query(systemFlagsCollectionRef, where('parentFlagGroupId', '==', items[0].flagGroupId))
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach(async (doc) => {
          items1.push(doc.data());
        })
        console.log(items1);
        setPaymentData(items1);
        const uniqueData = items1.filter((item, index, array) => {
          const firstIndex = array.findIndex((element) => element.flagGroupName === item.flagGroupName);
          return index === firstIndex;
        });
        console.log(uniqueData);
        setDropdownItem(uniqueData);
        const defaultSelectedValue = uniqueData.find(item => item.isSelected === 1)?.flagGroupName;
        dropdowndata.flagGroupName = defaultSelectedValue;
        console.log(dropdowndata)
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error)
      }
    }
    else if (newValue === '4') {
      const items: any[] = [];
      setIsLoading(true);
      try {
        const systemFlagsCollectionRef = collection(db, 'systemFlags');
        const flagGroupCollectionRef = collection(db, 'flagGroup');
        const q = query(systemFlagsCollectionRef, where('flagGroupName', '==', 'Firebase Credentials'))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          let itm = doc.data();
          let obj = {
            flagGroupId: doc.data().parentFlagGroupId,
          }
          const q = query(flagGroupCollectionRef, where('flagGroupId', '==', obj.flagGroupId))
          const _querySnapshot = await getDocs(q)
          _querySnapshot.forEach((_doc) => {
            itm.systemFlagGroupName = _doc.data().groupName;

          })
          items.push(itm);

          setFirebaseCredential(items);
        })
        setIsLoading(false);
      } catch (error) {
        console.log(error)
      }
    }
  };

  //select banner
  const onSelectFileBanner = async (e: any) => {
    const file = e.target.files[0];
    const name = e.target.name;
    setBannerImageId(name);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setImage(reader.result.toString());
      }
    };
    reader.readAsDataURL(file);
    setOpenChild(true);
  }

  //select default
  const onSelectFileDefault = async (e: any) => {
    const file = e.target.files[0];
    const name = e.target.name;
    setDefaultImageId(name);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setImageDefault(reader.result.toString());
      }
    };
    reader.readAsDataURL(file);
    setOpenChildDefault(true);
  }

  //select user
  const onSelectFileUser = async (e: any) => {
    const file = e.target.files[0];
    const name = e.target.name;
    setUserImageId(name);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setImageUser(reader.result.toString());
      }
    };
    reader.readAsDataURL(file);
    setOpenChildUser(true);
  }

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspectBanner: any,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspectBanner,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  // image load banner
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspectBanner) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspectBanner))
    }
  }

  // image load default
  function onImageLoadDefault(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspectDefault) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspectDefault))
    }
  }

  // image load user
  function onImageLoadUser(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspectUser) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspectUser))
    }
  }

  //convert image
  const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  //result of crop image
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
        const base64 = previewCanvasRef.current.toDataURL();
        if (image) {
          setResult(base64);
        } else if (imageDefault) {
          setResultDefault(base64);
        } else if (imageUser) {
          setResultUser(base64);
        }

      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  // cropped image
  const croppedImage = (e: any) => {
    setCompletedCrop(e);
  }

  // close child
  const handleCloseChild = (e: any) => {
    setImage('');
    setOpenChild(false);
  };

  //close child default
  const handleCloseChildDefault = (e: any) => {
    setImageDefault('');
    setOpenChildDefault(false);
  };

  //close childUser
  const handleCloseChildUser = (e: any) => {
    setImageUser('');
    setOpenChildUser(false);
  };

  // when clicked on saveBanner
  const saveBanner = async (e: any) => {
    setImgSrc(result)
    let imgSrc = result;
  }

  // when clicked on saveDefault
  const saveDefault = (e: any) => {
    setImgSrcDefault(resultDefault)
  }

  // when clicked on saveuser
  const saveUser = (e: any) => {
    setImgSrcUser(resultUser)
  }

  return (
    <>
      <Helmet>
        <title> Setting | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item xs={12}>
            <Stack alignItems="left" justifyContent="space-between" mb={5} >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit" >Setting</Typography>
              </Breadcrumbs>
            </Stack>
            <Card className="setting-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
              <div>
                {isLoading ? <Loader title="Loading..." /> :
                  <>
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <TabList onChange={handleChange}
                            aria-label="scrollable force tabs example"
                            variant="scrollable" scrollButtons
                            allowScrollButtonsMobile
                          >
                            <Tab label="General" value="1" style={{ paddingLeft: "10px", paddingRight: "10px", margin: "0px" }} />
                            <Tab label="Social Media" value="2" style={{ paddingLeft: "10px", paddingRight: "10px", margin: "0px" }} />
                            <Tab label="Payment" value="3" style={{ paddingLeft: "10px", paddingRight: "10px", margin: "0px" }} />
                            <Tab label="Firebase Credentials" value="4" style={{ paddingLeft: "10px", paddingRight: "10px", margin: "0px" }} />
                            <Tab label="Welcome Mail" value="5" style={{ paddingLeft: "10px", paddingRight: "10px", margin: "0px" }} />
                          </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ paddingLeft: "0px", paddingRight: '0px', overflow: 'auto' }}>
                          <div>
                            {isLoading ? <Loader title="Loading..." /> :
                              <>
                                <Scrollbar>
                                  <TableContainer className="setting-tab-list" sx={{ bgcolor: 'white' }}>
                                    <div >
                                      <div >
                                        <div>
                                          {seperateData.map((groupData: any, index: any) => (
                                            <Card
                                              variant="outlined"
                                              key={index}
                                              sx={{
                                                boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                                                mx: 3, mb: 3
                                              }}
                                            >
                                              <CardContent sx={{ py: 3, bgcolor: '#F9FAFB' }}>
                                                <Box
                                                  component="form"
                                                  sx={{
                                                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                                                  }}
                                                  noValidate
                                                  autoComplete="off"
                                                >
                                                  <label className="form-label text-capitalize">
                                                    <b>{groupData.flagGroupName}</b>
                                                  </label>
                                                  {groupData.items.sort((a: any, b: any) => a.displayOrder - b.displayOrder).map((resp: any, itemIndex: any) => (
                                                    <div className="mb-3" style={{ paddingTop: 3, paddingBottom: 8 }} key={itemIndex}>
                                                      {resp.valueTypeName === 'Text' ?
                                                        <TextField
                                                          label={resp.displayName}
                                                          name={resp.id}
                                                          className="form-control"
                                                          style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '8px', width: 'auto', paddingBottom: '8px' }}
                                                          type={resp.valueTypeName}
                                                          value={resp.value}
                                                          onChange={(e) => handleChangeGeneralData(e, resp.valueTypeName)}
                                                        />
                                                        : ''}
                                                      {resp.valueTypeName === 'Password' ?
                                                        <TextField
                                                          label={resp.displayName}
                                                          name={resp.id}
                                                          className="form-control"
                                                          style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '8px', width: 'auto', paddingBottom: '8px' }}
                                                          value={resp.value}
                                                          type={showPassword === true ? 'text' : 'password'}
                                                          InputProps={{
                                                            endAdornment: (
                                                              <InputAdornment position="end">
                                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                  <Iconify icon={showPassword === true ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                                </IconButton>
                                                              </InputAdornment>
                                                            ),
                                                          }}
                                                          onChange={(e) => handleChangeGeneralData(e, resp.valueTypeName)}
                                                        />
                                                        : ''}
                                                      {resp.valueTypeName === 'Drop Down' ?
                                                        <FormControl fullWidth>
                                                          <InputLabel id="demo-simple-select-label" >{resp.displayName}</InputLabel>
                                                          <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name={resp.id}
                                                            value={resp.value}
                                                            label={resp.displayName}
                                                            sx={{ bgcolor: '#F9FAFB' }}
                                                            onChange={(e) => handleChangeGeneralData(e, resp.valueTypeName)}
                                                          >
                                                            {resp.valueList.split(';').map((e: any) => (
                                                              <MenuItem value={e} key={e}>{e}</MenuItem>
                                                            ))}
                                                          </Select>
                                                        </FormControl>
                                                        : ''}

                                                      {resp.valueTypeName === 'img' ?
                                                        <>
                                                          {resp.name === 'bannerImage' && (
                                                            <div className="mb-3 col-lg-6" style={{ paddingTop: '14px' }} >
                                                              <label className="form-label text-capitalize" style={{ color: '#637381', fontFamily: "Public Sans,sans-serif", fontSize: '1rem', fontWeight: '400' }}>
                                                                {resp.displayName}
                                                              </label>
                                                              {
                                                                imgSrc ?
                                                                  <>
                                                                    <img src={imgSrc}
                                                                      style={{
                                                                        width: '338.33px',
                                                                        height: '189.88px',
                                                                        borderRadius: '1px solid black',
                                                                      }}
                                                                      alt="Image"
                                                                    />
                                                                  </>
                                                                  :
                                                                  <img src="/defaultFood.png"
                                                                    style={{
                                                                      width: '338.33px',
                                                                      height: '189.88px',
                                                                      borderRadius: '1px solid black',
                                                                    }}
                                                                    alt="Image"
                                                                  />
                                                              }
                                                              <input
                                                                style={{
                                                                  display: 'none',
                                                                  position: 'fixed',
                                                                  bottom: '5%',
                                                                  paddingTop: '49px',
                                                                  marginLeft: '-26px',
                                                                }}
                                                                id="icon-button-banner-file"
                                                                accept="image/*"
                                                                type="file"
                                                                name={resp.id}
                                                                onChange={onSelectFileBanner}
                                                                className="upload-button" />
                                                              <label htmlFor="icon-button-banner-file">
                                                                <IconButton component="span" color="primary" style={{ top: "-20px", right: "-300px" }}
                                                                >
                                                                  <UploadTwoToneIcon />
                                                                </IconButton>
                                                              </label>
                                                            </div>
                                                          )}

                                                          {resp.name !== 'bannerImage' && resp.name !== 'userImage' && (
                                                            <div className="mb-3 col-lg-6" style={{ paddingTop: '14px' }} >
                                                              <label className="form-label text-capitalize" style={{ color: '#637381', fontFamily: "Public Sans,sans-serif", fontSize: '1rem', fontWeight: '400' }}>
                                                                {resp.displayName}
                                                              </label>
                                                              {
                                                                imgSrcDefault ?
                                                                  <>
                                                                    <img src={imgSrcDefault}
                                                                      style={{
                                                                        width: '250px',
                                                                        borderRadius: '1px solid black',
                                                                      }}
                                                                      alt="Image"
                                                                    />
                                                                  </>
                                                                  :
                                                                  <img src="/defaultFood.png"
                                                                    style={{
                                                                      width: '250px',
                                                                      borderRadius: '1px solid black',
                                                                    }}
                                                                    alt="Image"
                                                                  />
                                                              }
                                                              <input
                                                                style={{
                                                                  display: 'none',
                                                                  position: 'fixed',
                                                                  bottom: '5%',
                                                                  paddingTop: '49px',
                                                                  marginLeft: '-26px',
                                                                }}
                                                                id="icon-button-default-file"
                                                                accept="image/*"
                                                                type="file"
                                                                name={resp.id}
                                                                onChange={onSelectFileDefault}
                                                                className="upload-button" />
                                                              <label htmlFor="icon-button-default-file">
                                                                <IconButton component="span" color="primary" style={{ top: "-20px", right: "-220px" }}
                                                                >
                                                                  <UploadTwoToneIcon />
                                                                </IconButton>
                                                              </label>
                                                            </div>
                                                          )}

                                                          {resp.name === 'userImage' && (
                                                            <div className="mb-3 col-lg-6" style={{ paddingTop: '14px' }} >
                                                              <label className="form-label text-capitalize" style={{ color: '#637381', fontFamily: "Public Sans,sans-serif", fontSize: '1rem', fontWeight: '400' }}>
                                                                {resp.displayName}
                                                              </label>
                                                              {
                                                                imgSrcUser ?
                                                                  <>
                                                                    <img src={imgSrcUser}
                                                                      style={{
                                                                        width: '250px',
                                                                        borderRadius: '1px solid black',
                                                                      }}
                                                                      alt="Image"
                                                                    />
                                                                  </>
                                                                  :
                                                                  <img src="/Image20230227180951.png"
                                                                    style={{
                                                                      width: '250px',
                                                                      borderRadius: '1px solid black',
                                                                    }}
                                                                    alt="Image"
                                                                  />
                                                              }
                                                              <input
                                                                style={{
                                                                  display: 'none',
                                                                  position: 'fixed',
                                                                  bottom: '5%',
                                                                  paddingTop: '49px',
                                                                  marginLeft: '-26px',
                                                                }}
                                                                id="icon-button-user-file"
                                                                accept="image/*"
                                                                type="file"
                                                                name={resp.id}
                                                                onChange={onSelectFileUser}
                                                                className="upload-button" />
                                                              <label htmlFor="icon-button-user-file">
                                                                <IconButton component="span" color="primary" style={{ top: "-20px", right: "-220px" }}
                                                                >
                                                                  <UploadTwoToneIcon />
                                                                </IconButton>
                                                              </label>
                                                            </div>
                                                          )}

                                                        </>
                                                        : ''}
                                                      {resp.valueTypeName === 'Number' ?
                                                        <TextField
                                                          label={resp.displayName}
                                                          name={resp.id}
                                                          className="form-control"
                                                          style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '8px', width: 'auto', paddingBottom: '8px' }}
                                                          type={resp.valueTypeName}
                                                          value={resp.value}
                                                          onChange={(e) => handleChangeGeneralData(e, resp.valueTypeName)}
                                                        />
                                                        : ''}
                                                      {resp.valueTypeName === 'Boolean' &&
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                          <Typography sx={{ paddingLeft: '12px', color: '#637381' }}>Enable SSL</Typography>
                                                          <Tooltip title='Enable SSL' arrow>
                                                            {resp.value === "1" ?
                                                              <Switch
                                                                defaultChecked
                                                                name={resp.id}
                                                                value={resp.value}
                                                                onChange={(e) => handleSwitchGeneral(resp.id, resp.value)}
                                                              />
                                                              :
                                                              <Switch
                                                                name={resp.id}
                                                                value={resp.value}
                                                                onChange={(e) => handleSwitchGeneral(resp.id, resp.value)}
                                                              />
                                                            }
                                                          </Tooltip>
                                                        </div>
                                                      }
                                                    </div>
                                                  ))}
                                                </Box>
                                              </CardContent>
                                            </Card>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </TableContainer>
                                </Scrollbar>
                              </>
                            }
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                              sx={{ mt: { xs: 2, md: 0.8 }, mr: 2 }}
                              variant="contained"
                              onClick={handleClickUpdateGeneralData}
                            >
                              Save
                            </Button>
                          </div>
                        </TabPanel>
                        <TabPanel value="2" sx={{ paddingLeft: "0px", paddingRight: '0px', overflow: 'auto' }}>
                          <div>
                            {isLoading ? <Loader title="Loading..." /> :
                              <>
                                <Scrollbar>
                                  <TableContainer className="setting-tab-list" sx={{ bgcolor: 'white' }}>
                                    <div >
                                      <div >
                                        <Card variant="outlined" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', mx: 3 }} >
                                          <CardContent sx={{ py: 3, bgcolor: '#F9FAFB' }} >
                                            <Box
                                              component="form"
                                              sx={{
                                                '& .MuiTextField-root': { m: 1, width: '25ch' }
                                              }}
                                              noValidate
                                              autoComplete="off"
                                            >
                                              <label className="form-label text-capitalize">
                                                <b>{socialMedia[0]?.flagGroupName}</b>
                                              </label>
                                              {
                                                socialMedia.map((resp: any) => (
                                                  <div className="mb-3" style={{ paddingTop: 3, paddingBottom: 8 }} key={resp.id}>
                                                    <TextField
                                                      label={resp.displayName}
                                                      name={resp.id}
                                                      className="form-control"
                                                      style={{ display: 'flex', justifyContent: 'flex-end', width: 'auto', paddingTop: '8px', paddingBottom: '8px' }}
                                                      type={resp.valueTypeName}
                                                      value={resp.value}
                                                      onChange={(e) => { handleChangeSocialMedia(e) }}
                                                    />
                                                  </div>
                                                ))}
                                            </Box>
                                          </CardContent>
                                        </Card>
                                      </div>
                                    </div>
                                  </TableContainer>
                                </Scrollbar>
                              </>
                            }
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                              sx={{ mt: { xs: 2, md: 0.8 }, mr: 2 }}
                              variant="contained"
                              onClick={handleClickUpdateSocialMedia}
                            >
                              Save
                            </Button>
                          </div>
                        </TabPanel>
                        <TabPanel value="3" sx={{ paddingLeft: "0px", paddingRight: '0px', overflow: 'auto' }}>
                          <div>
                            {isLoading ? <Loader title="Loading..." /> :
                              <>
                                <Scrollbar>
                                  <TableContainer className="setting-tab-list" sx={{ bgcolor: 'white' }}>
                                    <div>
                                      <div style={{ marginTop: "5px", padding: "0px 48px 0px 0px" }}>
                                        <FormControl fullWidth sx={{ mx: 3 }}>
                                          <InputLabel id="demo-simple-select-label" >{parentFlagGroupName[0]?.groupName}</InputLabel>
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={dropdowndata.flagGroupName}
                                            name="flagGroupName"
                                            label={parentFlagGroupName[0]?.groupName}
                                            sx={{ bgcolor: '#F9FAFB' }}
                                            onChange={handleChangedropdown}
                                          >
                                            {dropdownItem.map((arr: any) => (
                                              <MenuItem key={arr.flagGroupName} value={arr.flagGroupName}>{arr.flagGroupName}</MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </div>
                                      {dropdownItem.map((i: any) => (
                                        <div className="md-3" >
                                          <Card variant="outlined" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', m: 3 }}>
                                            <CardContent sx={{ py: 3, bgcolor: '#F9FAFB' }} >
                                              <Box
                                                component="form"
                                                sx={{
                                                  '& .MuiTextField-root': { m: 1, width: '25ch' }
                                                }}
                                                noValidate
                                                autoComplete="off"
                                              >
                                                <div key={i.flagGroupName}>
                                                  <label className="form-label text-capitalize">
                                                    <b>{i.flagGroupName}</b>
                                                  </label>
                                                  {
                                                    paymentData
                                                      .filter((j: any) => j.flagGroupName === i.flagGroupName).map((resp: any) => (
                                                        <div className="mb-3" style={{ paddingTop: 3, paddingBottom: 8 }} key={resp.id}>
                                                          <TextField
                                                            label={resp.displayName}
                                                            name={resp.id}
                                                            className="form-control"
                                                            style={{ display: 'flex', justifyContent: 'flex-end', width: 'auto', paddingTop: '8px', paddingBottom: '8px' }}
                                                            type={showPassword ? 'text' : 'password'}
                                                            InputProps={{
                                                              endAdornment: (
                                                                <InputAdornment position="end">
                                                                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                    <Iconify icon={showPassword === true ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                                  </IconButton>
                                                                </InputAdornment>
                                                              ),
                                                            }}
                                                            value={resp.value}
                                                            onChange={(e) => handleChangePayment(e)}
                                                          />
                                                        </div>
                                                      ))}
                                                </div>
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </div>
                                      ))}
                                    </div>
                                  </TableContainer>
                                </Scrollbar>
                              </>
                            }
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                              sx={{ mt: { xs: 2, md: 0.8 }, mr: 2 }}
                              variant="contained"
                              onClick={handleClickUpdatePayment}
                            >
                              Save
                            </Button>
                          </div>
                        </TabPanel>
                        <TabPanel value="4" sx={{ paddingLeft: "0px", paddingRight: '0px', overflow: 'auto' }}>
                          <div>
                            {isLoading ? <Loader title="Loading..." /> :
                              <>
                                <Scrollbar>
                                  <TableContainer className="setting-tab-list" sx={{ bgcolor: 'white' }}>
                                    <div >
                                      <div >
                                        <Card variant="outlined" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', mx: 3 }}>
                                          <CardContent sx={{ py: 3, bgcolor: '#F9FAFB' }} >
                                            <Box
                                              component="form"
                                              sx={{
                                                '& .MuiTextField-root': { m: 1, width: '25ch' }
                                              }}
                                              noValidate
                                              autoComplete="off"
                                            >
                                              <label className="form-label text-capitalize">
                                                <b>{firebaseCredential[0]?.flagGroupName}</b>
                                              </label>
                                              {
                                                firebaseCredential.map((resp: any) => (
                                                  <div className="mb-3" style={{ paddingTop: 3, paddingBottom: 8 }} key={resp.id}>
                                                    <TextField
                                                      label={resp.displayName}
                                                      name={resp.id}
                                                      className="form-control"
                                                      style={{ display: 'flex', justifyContent: 'flex-end', width: 'auto', paddingTop: '8px', paddingBottom: '8px' }}
                                                      type={showPassword ? 'text' : 'password'}
                                                      InputProps={{
                                                        endAdornment: (
                                                          <InputAdornment position="end">
                                                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                              <Iconify icon={showPassword === true ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                            </IconButton>
                                                          </InputAdornment>
                                                        ),
                                                      }}
                                                      value={resp.value}
                                                      onChange={(e) => handleChangeFirebaseCredential(e)}
                                                    />
                                                  </div>
                                                ))}
                                            </Box>
                                          </CardContent>
                                        </Card>
                                      </div>
                                    </div>
                                  </TableContainer>
                                </Scrollbar>
                              </>
                            }
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                              sx={{ mt: { xs: 2, md: 0.8 }, mr: 2 }}
                              variant="contained"
                              onClick={handleClickUpdateFirebaseCredential}
                            >
                              Save
                            </Button>
                          </div>
                        </TabPanel>
                        <TabPanel value="5" sx={{ paddingLeft: "0px", paddingRight: '0px', overflow: 'auto' }}>
                          <div>
                            {isLoading ? <Loader title="Loading..." /> :
                              <>
                                <Scrollbar>
                                  <TableContainer className="setting-tab-list" sx={{ bgcolor: 'white' }}>
                                    <div >
                                      <div >
                                        <div>
                                          <Card variant="outlined" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', mx: 3, mb: 3 }} >
                                            <CardContent sx={{ py: 3, bgcolor: '#F9FAFB' }} >
                                              <Box
                                                component="form"
                                                sx={{
                                                  '& .MuiTextField-root': { m: 1, width: '25ch' }
                                                }}
                                                noValidate
                                                autoComplete="off"
                                              >
                                                <label className="form-label text-capitalize">
                                                  <b>{welcomeMail[0]?.flagGroupName}</b>
                                                </label>
                                                {
                                                  welcomeMail.map((resp: any) => (
                                                    <div className="mb-3" style={{ paddingTop: 3, paddingBottom: 8 }} key={resp.id}>
                                                      <ReactQuill
                                                          theme="snow"
                                                          value={resp.value}
                                                          onChange={(e) => { handleChangeWelcomeMail(e) }}
                                                          modules={modules}
                                                        />
                                                      {/* <TextField
                                                        label={resp.displayName}
                                                        name={resp.id}
                                                        className="form-control"
                                                        style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '8px', width: 'auto', paddingBottom: '8px' }}
                                                        type={resp.valueTypeName}
                                                        value={resp.value}
                                                        multiline
                                                        rows={10}
                                                        variant="outlined"
                                                        onChange={(e) => handleChangeWelcomeMail(e)}
                                                      /> */}
                                                    </div>
                                                  ))}
                                              </Box>
                                            </CardContent>
                                          </Card>
                                        </div>
                                      </div>
                                    </div>
                                  </TableContainer>
                                </Scrollbar>
                              </>
                            }
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                              sx={{ mt: { xs: 2, md: 0.8 }, mr: 2 }}
                              variant="contained"
                              onClick={handleClickUpdateWelcomeMail}
                            >
                              Save
                            </Button>
                          </div>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </>
                }
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <div className='dialoglargeScreenContent'>
        <React.Fragment>
          <Modal
            // hideBackdrop
            open={openChild}
            onClose={handleCloseChild}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{ ...style1 }}
            >
              <h3 id="child-modal-title">Cropped Image
                <IconButton
                  sx={{
                    fontSize: "2px", color: "grey", position: 'absolute',
                    right: 3,
                    top: 12,
                  }}
                  onClick={handleCloseChild}
                >
                  <CancelIcon />
                </IconButton></h3>
              <p id="child-modal-description">
                {!!image && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => croppedImage(c)}
                    aspect={aspectBanner}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={image}
                      style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, height: '300px' }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                )}
                <div style={{ display: 'none' }}>
                  {Boolean(completedCrop) && (
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: completedCrop && completedCrop.width,
                          height: completedCrop && completedCrop.height,
                        }}
                      />
                    </div>
                  )}
                </div>
              </p>
              <DialogActions >
                <Button
                  onClick={(e) => { saveBanner(e); handleCloseChild(e) }}
                >
                  Save</Button>
                <Button
                  onClick={(e) => { handleCloseChild(e) }}
                >
                  Clear</Button>
              </DialogActions>
            </Box>
          </Modal>
        </React.Fragment>
      </div>

      <div className='dialoglargeScreenContent'>
        <React.Fragment>
          <Modal
            // hideBackdrop
            open={openChildDefault}
            onClose={handleCloseChildDefault}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{ ...style1 }}
            >
              <h3 id="child-modal-title">Cropped Image
                <IconButton
                  sx={{
                    fontSize: "2px", color: "grey", position: 'absolute',
                    right: 3,
                    top: 12,
                  }}
                  onClick={handleCloseChildDefault}
                >
                  <CancelIcon />
                </IconButton></h3>
              <p id="child-modal-description">
                {!!imageDefault && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => croppedImage(c)}
                    aspect={aspectDefault}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imageDefault}
                      style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, height: '300px' }}
                      onLoad={onImageLoadDefault}
                    />
                  </ReactCrop>
                )}
                <div style={{ display: 'none' }}>
                  {Boolean(completedCrop) && (
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: completedCrop && completedCrop.width,
                          height: completedCrop && completedCrop.height,
                        }}
                      />
                    </div>
                  )}
                </div>
              </p>
              <DialogActions >
                <Button
                  onClick={(e) => { saveDefault(e); handleCloseChildDefault(e) }}
                >
                  Save</Button>
                <Button
                  onClick={(e) => { handleCloseChildDefault(e) }}
                >
                  Clear</Button>
              </DialogActions>
            </Box>
          </Modal>
        </React.Fragment>
      </div>


      <div className='dialoglargeScreenContent'>
        <React.Fragment>
          <Modal
            // hideBackdrop
            open={openChildUser}
            onClose={handleCloseChildUser}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{ ...style1 }}
            >
              <h3 id="child-modal-title">Cropped Image
                <IconButton
                  sx={{
                    fontSize: "2px", color: "grey", position: 'absolute',
                    right: 3,
                    top: 12,
                  }}
                  onClick={handleCloseChildUser}
                >
                  <CancelIcon />
                </IconButton></h3>
              <p id="child-modal-description">
                {!!imageUser && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => croppedImage(c)}
                    aspect={aspectUser}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imageUser}
                      style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, height: '300px' }}
                      onLoad={onImageLoadUser}
                    />
                  </ReactCrop>
                )}
                <div style={{ display: 'none' }}>
                  {Boolean(completedCrop) && (
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: completedCrop && completedCrop.width,
                          height: completedCrop && completedCrop.height,
                        }}
                      />
                    </div>
                  )}
                </div>
              </p>
              <DialogActions >
                <Button
                  onClick={(e) => { saveUser(e); handleCloseChildUser(e) }}
                >
                  Save</Button>
                <Button
                  onClick={(e) => { handleCloseChildUser(e) }}
                >
                  Clear</Button>
              </DialogActions>
            </Box>
          </Modal>
        </React.Fragment>
      </div>
    </>
  );
}

export default SettingPage
