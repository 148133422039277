import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import * as serviceWorker from './serviceWorker';
import { AuthContextProvider } from './context/AuthContext';

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AuthContextProvider>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  )
}


const container = document.getElementById('root');
const root = createRoot(container!) //if you use TypeScript
root.render(
  // <AuthProvider>
  <App />
  // </AuthProvider>

);




// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// function createRoot(arg0: HTMLElement) {
//   throw new Error('Function not implemented.');
// }

