import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getToken } from 'firebase/messaging';
import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { v4 } from 'uuid';
import { db, messaging, onMessageListener } from './firebase';

const NotificationForeground = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = async () =>
    setTimeout(() => {
      toast(<ToastDisplay />);
    }, 10);
  const ToastDisplay = () => {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // Generate Token
      const token = await getToken(messaging, { vapidKey: "BNhugvGJ3oC5Zuar5KTbc0VHu-aoCwIZIckK_aun3Zq-e4mBwSBPuLZQcCSec1GIsgnMBmZe66eDqEavCB3A9o4" })
      localStorage.setItem('tokenGenerated', JSON.stringify(token))
    } else if (permission === 'denied') {
      alert('You denied for the notification');
    }
  }

  useEffect(() => {
    requestPermission();

  }, [notification])

  return (
    <Toaster
      position="top-right"
    />

  )
}

export default NotificationForeground