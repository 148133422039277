import { Grid, Breadcrumbs, Typography, Stack, Button, Container, Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Switch, Tooltip, TablePagination, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Checkbox, Chip, IconButton, TextField, FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material'
import { useEffect, useRef, useState } from 'react';
import Papa from 'papaparse';
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import Iconify from '../components/iconify'
import HomeIcon from '@mui/icons-material/Home';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { CSVLink } from 'react-csv'
import { db } from './../firebase'
import './FoodMakerPage.css';
import './country.css'
import { addDoc, collection, doc, getCountFromServer, getDocs, limit, limitToLast, orderBy, query, serverTimestamp, updateDoc, where } from '@firebase/firestore';
import { endBefore, setDoc, startAfter } from 'firebase/firestore';
import Loader from './spinner';
import { ToastContainer, toast } from 'react-toastify';

const initialState = {
  countryId: "",
  country: "",
  isocode2: "",
  isocode3: "",
  stateId: "",
  state: "",
  scode2: "",
  scode3: "",
  regionId: "",
  region: "",
  areaId: "",
  area: "",
  pincode: ""
}

export const Country = () => {
  let [Countrydata, setCountryData] = useState<any>(initialState)
  const [isCheckCountry, setIsCheckCountry] = useState(false)
  const [isCheckState, setIsCheckState] = useState(false)
  const [isCheckRegion, setIsCheckRegion] = useState(false)
  const [isCheckArea, setIsCheckArea] = useState(false)
  const [isOpenCountry, setIsOpenCountry] = useState(false)
  const [isOpenState, setIsOpenState] = useState(false)
  const [isOpenRegion, setIsOpenRegion] = useState(false)
  const [isOpenArea, setIsOpenArea] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [page, setPage] = useState(0);
  let [limits, setLimits] = useState(10);
  const [row, setRow] = useState<number>(10);
  const [pageState, setPageState] = useState(0);
  let [limitState, setLimitState] = useState(10);
  const [rowState, setRowState] = useState<number>(10);
  const [pageRegion, setPageRegion] = useState(0);
  let [limitRegion, setLimitRegion] = useState(10);
  const [rowRegion, setRowRegion] = useState<number>(10);
  const [pageArea, setPageArea] = useState(0);
  let [limitArea, setLimitArea] = useState(10);
  const [rowArea, setRowArea] = useState<number>(10);
  let [array, setArray] = useState<any>([]);
  let [country, setCountry] = useState<any>([])
  let [state, setState] = useState<any>([])
  let [region, setRegion] = useState<any>([])
  let [area, setArea] = useState<any>([])
  let [countryAll, setCountryAll] = useState<any>([])
  let [stateAll, setStateAll] = useState<any>([])
  let [regionAll, setRegionAll] = useState<any>([])
  let [areaAll, setAreaAll] = useState<any>([])
  let [selectCountry, setSelectCountry] = useState<any>([])
  let [selectState, setSelectState] = useState<any>([])
  let [selectRegion, setSelectRegion] = useState<any>([])
  let [selectArea, setSelectArea] = useState<any>([])
  let [chipCountry, setChipCountry] = useState<any>([])
  let [chipState, setChipState] = useState<any>([])
  let [chipRegion, setChipRegion] = useState<any>([])
  let [stateDropdown, setStateDropDown] = useState<any>([])
  let [regionDropdown, setRegionDropDown] = useState<any>([])
  const [firstVisible, setFirstVisible] = useState<any>();
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisibleState, setFirstVisibleState] = useState<any>();
  const [lastVisibleState, setLastVisibleState] = useState<any>();
  const [firstVisibleRegion, setFirstVisibleRegion] = useState<any>();
  const [lastVisibleRegion, setLastVisibleRegion] = useState<any>();
  const [firstVisibleArea, setFirstVisibleArea] = useState<any>();
  const [lastVisibleArea, setLastVisibleArea] = useState<any>();
  const [isAreaNameError, setAreaNameError] = useState(false);
  const [AreaNameErrorMsg, setAreaNameErrorMsg] = useState("");
  const [isRegionNameError, setRegionNameError] = useState(false);
  const [RegionNameErrorMsg, setRegionNameErrorMsg] = useState("");
  const [isCountryNameError, setCountryNameError] = useState(false);
  const [CountryNameErrorMsg, setCountryNameErrorMsg] = useState("");
  const [isStateNameError, setStateNameError] = useState(false);
  const [StateNameErrorMsg, setStateNameErrorMsg] = useState("");
  const [pincodeError, setPincodeError] = useState("")
  const [pincodemsg, setPincodemsg] = useState(false)
  const [isCode2Error, setCode2Error] = useState(false);
  const [Code2ErrorMsg, setCode2ErrorMsg] = useState("");
  const [isCode3Error, setCode3Error] = useState(false);
  const [Code3ErrorMsg, setCode3ErrorMsg] = useState("");

  const inputFile: any = useRef(null);

  const reg = new RegExp(/^[a-zA-Z_ ]+$/); //regex which allow only alphabet
  const validateCountryName = (arr: any) => {
    if (arr.target.value) {
      if ((reg.test(arr.target.value))) {
        setCountryNameError(false);
        setCountryNameErrorMsg("");
      } else {
        setCountryNameError(true);
        setCountryNameErrorMsg("Only Alphabet is allowed");
      }
    } else {
      setCountryNameError(true);
      setCountryNameErrorMsg("Country is required");
    }
  }

  const reg1 = /^[a-zA-Z]{2}$/  // regex only 2 letter alphabet allow
  const validateIsocode2 = (arr: any) => {
    if (arr.target.value) {
      if ((reg1.test(arr.target.value))) {
        setCode2Error(false);
        setCode2ErrorMsg("");
      } else {
        setCode2Error(true);
        setCode2ErrorMsg("Only 2 letter alphabet is allowed");
      }
    } else {
      setCode2Error(true);
      setCode2ErrorMsg("Iso code2 is required");
    }
  }

  const reg2 = /^[a-zA-Z]{3}$/ // regex allow only 3 letter alphabet
  const validateIsocode3 = (arr: any) => {
    if (arr.target.value) {
      if ((reg2.test(arr.target.value))) {
        setCode3Error(false);
        setCode3ErrorMsg("");
      } else {
        setCode3Error(true);
        setCode3ErrorMsg("Only 3 letter alphabet is allowed");
      }
    } else {
      setCode3Error(true);
      setCode3ErrorMsg("Iso code3 is required");
    }
  }

  // check validation for state
  const validateStateName = (arr: any) => {
    if (arr.target.value) {
      if ((reg.test(arr.target.value))) {
        setStateNameError(false);
        setStateNameErrorMsg("");
      } else {
        setStateNameError(true);
        setStateNameErrorMsg("Only Alphabet is allowed");
      }
    } else {
      setStateNameError(true);
      setStateNameErrorMsg("State is required");
    }
  }

  //check validation for region
  const validateRegionName = (arr: any) => {
    if (arr.target.value) {
      if ((reg.test(arr.target.value))) {
        setRegionNameError(false);
        setRegionNameErrorMsg("");
      } else {
        setRegionNameError(true);
        setRegionNameErrorMsg("Only Alphabet is allowed");
      }
    } else {
      setRegionNameError(true);
      setRegionNameErrorMsg("Region is required");
    }
  }

  //check validation for area
  const validateAreaName = (arr: any) => {
    if (arr.target.value) {
      if ((reg.test(arr.target.value))) {
        setAreaNameError(false);
        setAreaNameErrorMsg("");
      } else {
        setAreaNameError(true);
        setAreaNameErrorMsg("Only Alphabet is allowed");
      }
    } else {
      setAreaNameError(true);
      setAreaNameErrorMsg("Area is required");
    }
  }

  //check validation for pincode
  const validatePincode = (arr: any) => {
    if (arr.target.value) {
      setPincodeError("")
      setPincodemsg(false)
    } else {
      setPincodeError("Pincode is required")
      setPincodemsg(true)
    }
  }

  // country validation dropdown
  const validateCountrydropdown = (arr: any) => {
    if (arr.target.value) {
      setCountryNameError(false);
      setCountryNameErrorMsg("");
    } else {
      setCountryNameError(true);
      setCountryNameErrorMsg("Country is Required");
    }
  }

  //state validation dropdown
  const validateStatedropdown = (arr: any) => {
    if (arr.target.value) {
      setStateNameError(false);
      setStateNameErrorMsg("");
    } else {
      setStateNameError(true);
      setStateNameErrorMsg("State is required");
    }
  }

  //region validation dropdown
  const validateRegiondropdown = (arr: any) => {
    if (arr.target.value) {
      setCountryNameError(false);
      setCountryNameErrorMsg("");
    } else {
      setCountryNameError(true);
      setCountryNameErrorMsg("Country is Required");
    }
  }

  // validate full form of country
  const validateFormCountry = (e: any) => {
    e.preventDefault();
    let flag = true;
    if (!Countrydata.country) {
      setCountryNameError(true);
      setCountryNameErrorMsg("Country is required");
      flag = false;
    } else {
      if ((reg.test(Countrydata.country))) {
        setCountryNameError(false);
        setCountryNameErrorMsg("");
      } else {
        setCountryNameError(true);
        setCountryNameErrorMsg("Only Alphabet is allowed");
        flag = false;
      }
    }
    return flag;
  }

  // validate full form of state
  const validateFormState = (e: any) => {
    e.preventDefault();
    let flag = true;
    if (!Countrydata.state) {
      setStateNameError(true);
      setStateNameErrorMsg("State is required");
      flag = false;
    } else {
      if ((reg.test(Countrydata.state))) {
        setStateNameError(false);
        setStateNameErrorMsg("");
      } else {
        setStateNameError(true);
        setStateNameErrorMsg("Only Alphabet is allowed");
        flag = false;
      }
    }
    if (!Countrydata.country) {
      setCountryNameError(true);
      setCountryNameErrorMsg("Country is Required");
      flag = false;
    } else {
      setCountryNameError(false);
      setCountryNameErrorMsg("");
    }
    return flag;
  }

  // validate full form of region
  const validateFormRegion = (e: any) => {
    let flag = true;
    if (!Countrydata.region) {
      setRegionNameError(true);
      setRegionNameErrorMsg("Region is required");
      flag = false;
    } else {
      if ((reg.test(Countrydata.region))) {
        setRegionNameError(false);
        setRegionNameErrorMsg("");
      } else {
        setRegionNameError(true);
        setRegionNameErrorMsg("Only Alphabet is allowed");
        flag = false;
      }
    }
    if (!Countrydata.country) {
      setCountryNameError(true);
      setCountryNameErrorMsg("Country is Required");
      flag = false;
    } else {
      setCountryNameError(false);
      setCountryNameErrorMsg("");
    }
    if (!Countrydata.state) {
      setStateNameError(true);
      setStateNameErrorMsg("State is Required");
      flag = false;
    } else {
      setStateNameError(false);
      setStateNameErrorMsg("");
    }
    return flag;
  }

  // validate full form of area
  const validateFormArea = (e: any) => {
    e.preventDefault();
    let flag = true;
    if (!Countrydata.area) {
      setAreaNameError(true);
      setAreaNameErrorMsg("Area is required");
      flag = false;
    } else {
      if ((reg.test(Countrydata.area))) {
        setAreaNameError(false);
        setAreaNameErrorMsg("");
      } else {
        setAreaNameError(true);
        setAreaNameErrorMsg("Only Alphabet is allowed");
        flag = false;
      }
    }
    if (!Countrydata.pincode) {
      setPincodeError("Pincode is required")
      setPincodemsg(true)
      flag = false;
    } else {
      setPincodeError("")
      setPincodemsg(false)
    }
    if (!Countrydata.country) {
      setCountryNameError(true);
      setCountryNameErrorMsg("Country is Required");
      flag = false;
    } else {
      setCountryNameError(false);
      setCountryNameErrorMsg("");
    }
    if (!Countrydata.state) {
      setStateNameError(true);
      setStateNameErrorMsg("State is Required");
      flag = false;
    } else {
      setStateNameError(false);
      setStateNameErrorMsg("");
    }
    if (!Countrydata.region) {
      setRegionNameError(true);
      setRegionNameErrorMsg("Region is Required");
      flag = false;
    } else {
      setRegionNameError(false);
      setRegionNameErrorMsg("");
    }
    return flag;
  }

  //clear each validation
  const validationClear = () => {
    setAreaNameError(false);
    setAreaNameErrorMsg("");
    setRegionNameError(false);
    setRegionNameErrorMsg("");
    setStateNameError(false);
    setStateNameErrorMsg("");
    setCountryNameError(false);
    setCountryNameErrorMsg("");
    setPincodeError("")
    setPincodemsg(false)
    setCode3Error(false);
    setCode3ErrorMsg("");
    setCode2Error(false);
    setCode2ErrorMsg("");
  }

  // when thier is change in countrydata
  const handleChange = (arr: any) => {
    const { name, value } = arr.target;
    setCountryData({ ...Countrydata, [name]: value });
  }

  // when thier is change in countrydata of country
  const handleChange1 = (e: any) => {
    Countrydata.country = e.target.value
    setCountryData(Countrydata)
  }

  // when thier is change in countrydata of state
  const handleChange2 = (e: any) => {
    Countrydata.state = e.target.value
    setCountryData(Countrydata)
  }

  // when click on upload
  const handleClick = () => {
    inputFile.current.click()
  }

  // import csv file if selected
  const handleFile = (e: any) => {
    let file = e.target.files[0]
    Papa.parse(file, {
      header: true,
      complete: function (result) {
        setArray(result.data)
        array = result.data
        csvFile()
      }
    })
  }

  // call when thier is result in upload
  const csvFile = async () => {
    // country
    let cVal: any = []
    for (let i = 0; i < array.length; i++) {
      let element = array[i].Country.split(" ");
      for (let i = 0; i < element.length; i++) {
        element[i] = element[i].charAt(0).toUpperCase() + element[i].slice(1).toLowerCase();
      }
      element = element.join(" ");

      let iso2 = array[i].ISOCode2.toString() ? array[i].ISOCode2.toString().toUpperCase() : ""
      let iso3 = array[i].ISOCode3.toString() ? array[i].ISOCode3.toString().toUpperCase() : ""

      let obj = {
        country: element,
        iso2: iso2,
        iso3: iso3
      }
      cVal.push(obj)
    }

    Countrydata.country = cVal.filter((item: any, index: any) => index === cVal.findIndex((elem: any) => elem.country === item.country && elem.iso2 === item.iso2 && elem.iso3 === item.iso3))

    for (let i = 0; i < Countrydata.country.length; i++) {
      let data = JSON.parse(JSON.stringify(countryAll));
      let ind = data.findIndex((c: any) => c.name === Countrydata.country[i].country);
      if (ind >= 0) {
        //data[ind].uId;
        let iso2 = Countrydata.country[i].iso2.toString() ? Countrydata.country[i].iso2.toString().toUpperCase() : ""
        let iso3 = Countrydata.country[i].iso3.toString() ? Countrydata.country[i].iso3.toString().toUpperCase() : ""
        let obj = {
          name: Countrydata.country[i].country,
          isoCode2: iso2,
          isoCode3: iso3
        }
        const datasRef = doc(db, 'countries', data[ind].uId);
        await setDoc(datasRef, obj, { merge: true })

        let Cdata = JSON.parse(JSON.stringify(countryAll));
        let ind1 = Cdata.findIndex((c: any) => c.uId === data[ind].uId);
        if (ind1 >= 0) {
          //Cdata[ind1].name = cname.toUpperCase().charAt(0).toUpperCase() + cname.toUpperCase().slice(1).toLowerCase();
          Cdata[ind1].iso2 = iso2
          Cdata[ind1].iso3 = iso3
        }
        setCountry(Cdata);
      } else {
        let cname = Countrydata.country[i].country.toString()
        let iso2 = Countrydata.country[i].iso2.toString() ? Countrydata.country[i].iso2.toString().toUpperCase() : ""
        let iso3 = Countrydata.country[i].iso3.toString() ? Countrydata.country[i].iso3.toString().toUpperCase() : ""
        const CountryRef = collection(db, 'countries')
        await addDoc(CountryRef, {
          name: cname.toUpperCase().charAt(0).toUpperCase() + cname.toUpperCase().slice(1).toLowerCase(),
          isoCode2: iso2,
          isoCode3: iso3,
          isActive: 1,
          isDelete: 0,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp(),
          createdBy: "admin",
          modifiedBy: "admin",
          uId: CountryRef.id
        }).then(docRef => {
          console.log(docRef.id)
        });
        await getCountry();
        await getCountryAll();
      }
    }
    // state
    let sVal: any[] = [];
    for (let i = 0; i < array.length; i++) {
      let element = array[i].Country.split(" ");
      for (let i = 0; i < element.length; i++) {
        element[i] = element[i].charAt(0).toUpperCase() + element[i].slice(1).toLowerCase();
      }
      element = element.join(" ");

      let element1 = array[i].State.split(" ");
      for (let i = 0; i < element1.length; i++) {
        element1[i] = element1[i].charAt(0).toUpperCase() + element1[i].slice(1).toLowerCase();
      }
      element1 = element1.join(" ");

      let iso2 = array[i].StateISOCode2.toString() ? array[i].StateISOCode2.toString().toUpperCase() : ""
      let iso3 = array[i].StateISOCode3.toString() ? array[i].StateISOCode3.toString().toUpperCase() : ""

      let obj = {
        state: element1,
        country: element,
        iso2: iso2,
        iso3: iso3
      }

      sVal.push(obj)
    }

    Countrydata.state = sVal.filter((item: any, index: any) => index === sVal.findIndex(elem => elem.state === item.state && elem.country === item.country && elem.iso2 === item.iso2 && elem.iso3 === item.iso3))

    for (let i = 0; i < Countrydata.state.length; i++) {
      let data = JSON.parse(JSON.stringify(stateAll));
      let countryAllData = JSON.parse(JSON.stringify(countryAll))
      let iso2 = Countrydata.state[i].iso2.toString() ? Countrydata.state[i].iso2.toString().toUpperCase() : ""
      let iso3 = Countrydata.state[i].iso3.toString() ? Countrydata.state[i].iso3.toString().toUpperCase() : ""
      let val = countryAllData.find((c: any) => c.name === Countrydata.state[i].country).uId
      let ind = data.findIndex((c: any) => c.name === Countrydata.state[i].state);
      if (ind >= 0) {
        let obj = {
          name: Countrydata.state[i].state,
          isoCode2: iso2,
          isoCode3: iso3,
          countryId: val
        }
        const datasRef = doc(db, 'states', data[ind].uId);
        await setDoc(datasRef, obj, { merge: true })
        let Sdata = JSON.parse(JSON.stringify(stateAll));
        let ind1 = Sdata.findIndex((c: any) => c.uId === data[ind].uId);
        if (ind1 >= 0) {
          //Sdata[ind1].name = sname.toUpperCase().charAt(0).toUpperCase() + sname.toUpperCase().slice(1).toLowerCase();
          Sdata[ind1].isoCode2 = iso2
          Sdata[ind1].isoCode3 = iso3
          Sdata[ind1].countryId = val
        }
        setState(Sdata);
      } else {
        const StateRef = collection(db, 'states')
        await addDoc(StateRef, {
          name: Countrydata.state[i].state,
          isoCode2: iso2,
          isoCode3: iso3,
          isActive: 1,
          isDelete: 0,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp(),
          createdBy: "admin",
          modifiedBy: "admin",
          uId: StateRef.id,
          countryId: val
        }).then(docRef => {
          console.log(docRef.id)
        });
        await getState();
        await getStateAll();
      }
    }
    // region
    let rVal: any[] = [];
    for (let i = 0; i < array.length; i++) {

      let element2 = array[i].Country.split(" ");
      for (let i = 0; i < element2.length; i++) {
        element2[i] = element2[i].charAt(0).toUpperCase() + element2[i].slice(1).toLowerCase();
      }
      element2 = element2.join(" ");

      let element1 = array[i].State.split(" ");
      for (let i = 0; i < element1.length; i++) {
        element1[i] = element1[i].charAt(0).toUpperCase() + element1[i].slice(1).toLowerCase();
      }
      element1 = element1.join(" ");

      let element = array[i].Region.split(" ");
      for (let i = 0; i < element.length; i++) {
        element[i] = element[i].charAt(0).toUpperCase() + element[i].slice(1).toLowerCase();
      }
      element = element.join(" ");

      let obj = {
        region: element,
        state: element1,
        country: element2
      }

      rVal.push(obj)
    }

    Countrydata.region = rVal.filter((item: any, index: any) => index === rVal.findIndex(elem => elem.region === item.region && elem.state === item.state && elem.country === item.country))

    for (let i = 0; i < Countrydata.region.length; i++) {
      let data = JSON.parse(JSON.stringify(regionAll));
      let countryAllData = JSON.parse(JSON.stringify(countryAll))
      let stateAllData = JSON.parse(JSON.stringify(stateAll));
      let val1 = countryAllData.find((c: any) => c.name === Countrydata.region[i].country).uId
      let val = stateAllData.find((c: any) => c.name === Countrydata.region[i].state).uId
      let ind = data.findIndex((c: any) => c.name === Countrydata.region[i].region.toString());
      if (ind >= 0) {
        let obj = {
          name: Countrydata.region[i].region,
          stateId: val,
          countryId: val1,
        }
        const datasRef = doc(db, 'regions', data[ind].uId);
        await setDoc(datasRef, obj, { merge: true })
        let Rdata = JSON.parse(JSON.stringify(regionAll));
        let ind1 = Rdata.findIndex((c: any) => c.uId === data[ind].uId);
        if (ind1 >= 0) {
          Rdata[ind1].name = Countrydata.region[i].region;
          Rdata[ind1].stateId = val
          Rdata[ind1].countryId = val1
        }
        setRegion(Rdata);
      } else {
        const RegionRef = collection(db, 'regions')
        await addDoc(RegionRef, {
          name: Countrydata.region[i].region,
          stateId: val,
          countryId: val1,
          isActive: 1,
          isDelete: 0,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp(),
          createdBy: "admin",
          modifiedBy: "admin",
          uId: RegionRef.id
        }).then(docRef => {
          console.log(docRef.id)
        });
        await getRegion();
        await getRegionAll();
      }
    }
    // area
    let aVal: any[] = [];
    for (let i = 0; i < array.length; i++) {
      let element3 = array[i].Country.split(" ");
      for (let i = 0; i < element3.length; i++) {
        element3[i] = element3[i].charAt(0).toUpperCase() + element3[i].slice(1).toLowerCase();
      }
      element3 = element3.join(" ");

      let element2 = array[i].State.split(" ");
      for (let i = 0; i < element2.length; i++) {
        element2[i] = element2[i].charAt(0).toUpperCase() + element2[i].slice(1).toLowerCase();
      }
      element2 = element2.join(" ");

      let element1 = array[i].Region.split(" ");
      for (let i = 0; i < element1.length; i++) {
        element1[i] = element1[i].charAt(0).toUpperCase() + element1[i].slice(1).toLowerCase();
      }
      element1 = element1.join(" ");

      let element = array[i].Area.split(" ");
      for (let i = 0; i < element.length; i++) {
        element[i] = element[i].charAt(0).toUpperCase() + element[i].slice(1).toLowerCase();
      }
      element = element.join(" ");

      let obj = {
        area: element,
        region: element1,
        state: element2,
        country: element3
      }

      aVal.push(obj)
    }

    Countrydata.area = aVal.filter((item: any, index: any) => index === aVal.findIndex(elem => elem.area === item.area && elem.region === item.region && elem.state === item.state && elem.country === item.country))

    let aPincode: any[] = [];
    for (let i = 0; i < array.length; i++) {
      let element = array[i].Pincode
        .replace(/\s/g, "");
      aPincode.push(element)
      Countrydata.pincode = aPincode
    }

    for (let i = 0; i < Countrydata.area.length; i++) {
      let countryAllData = JSON.parse(JSON.stringify(countryAll))
      let stateAllData = JSON.parse(JSON.stringify(stateAll));
      let regionAllData = JSON.parse(JSON.stringify(regionAll));
      let val2 = countryAllData.find((c: any) => c.name === Countrydata.area[i].country)?.uId
      let val1 = stateAllData.find((c: any) => c.name === Countrydata.area[i].state)?.uId
      let val = regionAllData.find((c: any) => c.name === Countrydata.area[i].region)?.uId
      let data = JSON.parse(JSON.stringify(areaAll));
      let ind = data.findIndex((c: any) => c.name === Countrydata.area[i].area);
      if (ind >= 0) {
        let obj = {
          name: Countrydata.area[i].area,
          pinCode: Countrydata.pincode[i].toString(),
          stateId: val1,
          countryId: val2,
          regionId: val
        }
        const datasRef = doc(db, 'areas', data[ind].uId);
        await setDoc(datasRef, obj, { merge: true })
        let Adata = JSON.parse(JSON.stringify(areaAll));
        let ind1 = Adata.findIndex((c: any) => c.uId === data[ind].uId);
        if (ind1 >= 0) {
          Adata[ind1].name = Countrydata.area[i].area;
          Adata[ind1].pinCode = Countrydata.pincode[i].toString();
          Adata[ind1].stateId = val1;
          Adata[ind1].countryId = val2;
          Adata[ind1].regionId = val;
        }
        setArea(Adata);
      } else {
        const areaRef = collection(db, 'areas')
        await addDoc(areaRef, {
          name: Countrydata.area[i].area,
          pinCode: Countrydata.pincode[i],
          isActive: 1,
          isDelete: 0,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp(),
          createdBy: "admin",
          modifiedBy: "admin",
          uId: areaRef.id,
          regionId: val,
          stateId: val1,
          countryId: val2
        }).then(docRef => {
          console.log(docRef.id)
        });
        await getArea();
        await getAreaAll();
      }
    }
  }

  // it is the first method invoked when this page is open
  useEffect(() => {
    getBoth()
  }, [])

  //call intially
  const getBoth = async () => {
    try {
      await Promise.all([getData(), getAllData()]);
      console.log('Data fetched successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // call when getBoth is called
  const getData = async () => {
    try {
      await Promise.all([getCountry(), getState(), getRegion(), getArea()]);
      console.log('Data from getData() fetched successfully');
    } catch (error) {
      console.error('Error fetching data from getData():', error);
    }
  };

  // call when getBoth is called
  const getAllData = async () => {
    try {
      await Promise.all([getCountryAll(), getStateAll(), getRegionAll(), getAreaAll()]);
      console.log('Data from getAllData() fetched successfully');
    } catch (error) {
      console.error('Error fetching data from getAllData():', error);
    }
  };

  // call when getData is called
  const getCountry = async () => {
    try {
      setIsLoading(true)
      const items: any[] = [];
      let qLenn = query(collection(db, 'countries'))
      const q = query(collection(db, 'countries'), orderBy("createdDate", 'desc'), limit(limits))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisible(querySnapshot.docs[0]);

      setCountry(items)
      country = items

      for (let i = 0; i < items.length; i++) {
        const obj = {
          uId: items[i].uid
        }
        const dataRef = doc(db, 'countries', country[i].uid)
        await setDoc(dataRef, obj, { merge: true });
        country = items
        setCountry(items)
        setIsLoading(false)
      }
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count);
    } catch (error) {
      console.log(error)
    }
  }

  // call when getAllData is called
  const getCountryAll = async () => {
    try {
      const items: any[] = [];
      const q = query(collection(db, 'countries'), orderBy("createdDate", 'desc'))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })

      setCountryAll(items)
      countryAll = items

      for (let i = 0; i < items.length; i++) {
        const obj = {
          uId: items[i].uid
        }
        const dataRef = doc(db, 'countries', countryAll[i].uid)
        await setDoc(dataRef, obj, { merge: true });
        countryAll = items
        setCountryAll(items)
      }
    } catch (error) {
      console.log(error)
    }
  }

  //call when country checkbox is selected
  const handleCountry = async (e: any, string: any) => {
    try {
      const selected = selectCountry.indexOf(string)
      let newSelected: readonly string[] = [];
      let cVal: any[] = []
      let items: any[] = []
      let items1: any[] = []
      let items2: any[] = []
      if (!selectCountry.length) {
        if (selected === -1) {
          newSelected = newSelected.concat(selectCountry, string);
        }
        let obj = {
          "name": string
        }
        cVal.push(obj)
        setChipCountry(cVal)
        chipCountry = cVal
        setSelectCountry(newSelected);

        let cntRegion = 0;
        let cntArea = 0
        if (chipState.length) {
          let stateData = JSON.parse(JSON.stringify(stateAll));
          let ind = stateData.find((c: any) => c.name === chipState[0].name).countryId
          let countryData = JSON.parse(JSON.stringify(countryAll))
          let cInd = countryData.find((c: any) => c.name === chipCountry[0].name).uId
          if (ind !== cInd) {
            setSelectState([])
            setChipState([])
          } else {
            const qLenn = query(collection(db, 'states'), where('countryId', '==', cInd))
            const querySnapshotLen = await getCountFromServer(qLenn);
            setRowState(querySnapshotLen.data().count);
            const q = query(collection(db, 'states'), where('countryId', '==', cInd), orderBy("createdDate", 'desc'), limit(limitState))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items.push({ uid: doc.id, ...doc.data() })
            })
            setLastVisibleState(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstVisibleState(querySnapshot.docs[0]);
            let i = 1;
            items.forEach(async (obj) => {
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items.length) {
                  setState(items)
                } else {
                  i++;
                }
              }
            })

          }
        }
        if (chipRegion.length) {
          let regionData = JSON.parse(JSON.stringify(regionAll));
          let ind = regionData.find((c: any) => c.name === chipRegion[0].name).countryId
          let countryData = JSON.parse(JSON.stringify(countryAll))
          let cInd = countryData.find((c: any) => c.name === chipCountry[0].name).uId
          if (ind !== cInd) {
            setSelectRegion([])
            setChipRegion([])
          } else if (chipState.length) {
            let regionData = JSON.parse(JSON.stringify(regionAll));
            let cind = regionData.find((c: any) => c.name === chipRegion[0].name).countryId
            let sind = regionData.find((c: any) => c.name === chipRegion[0].name).stateId
            const q1 = query(collection(db, 'regions'), where('countryId', '==', cind), where('stateId', '==', sind), orderBy("createdDate", 'desc'), limit(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items1.push(doc1.data())
            })
            setLastVisibleRegion(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
            setFirstVisibleRegion(querySnapshot1.docs[0]);
            let i = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items1.length) {
                  setRegion(items1)
                } else {
                  i++;
                }
              }
            })
            // setRegion(items1)
          } else {
            const qLenn = query(collection(db, 'regions'), where('countryId', '==', cInd))
            const querySnapshotLen = await getCountFromServer(qLenn);
            cntRegion += querySnapshotLen.data().count
            setRowRegion(cntRegion);
            const q1 = query(collection(db, 'regions'), where('countryId', '==', cInd), orderBy("createdDate", 'desc'), limit(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items1.push(doc1.data())
            })
            setLastVisibleRegion(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
            setFirstVisibleRegion(querySnapshot1.docs[0]);
            let i = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items1.length) {
                  setRegion(items1)
                } else {
                  i++;
                }
              }
            })
            // setRegion(items1)
          }
        } else {
          for (let j = 0; j < chipCountry.length; j++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[j].name).uId
            const qLenn = query(collection(db, 'states'), where('countryId', '==', ind))
            const querySnapshotLen = await getCountFromServer(qLenn);
            setRowState(querySnapshotLen.data().count);
            const q = query(collection(db, 'states'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), limit(limitState))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items.push({ uid: doc.id, ...doc.data() })
            })
            setLastVisibleState(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstVisibleState(querySnapshot.docs[0]);
            let i = 0
            items.forEach(async (obj) => {
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items.length) {
                  setState(items)
                  state = items
                } else {
                  i++;
                }
              }
            })
          }
          for (let i = 0; i < chipCountry.length; i++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[i].name).uId
            const qLenn = query(collection(db, 'regions'), where('countryId', '==', ind))
            const querySnapshotLen = await getCountFromServer(qLenn);
            cntRegion += querySnapshotLen.data().count
            setRowRegion(cntRegion);
            const q1 = query(collection(db, 'regions'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), limit(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items1.push(doc1.data())
            })
            setLastVisibleRegion(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
            setFirstVisibleRegion(querySnapshot1.docs[0]);
            let j = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (j === items.length) {
                  setRegion(items1)
                } else {
                  j++;
                }
              }
            })
          }

          for (let k = 0; k < chipCountry.length; k++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[k].name).uId
            const qLenn = query(collection(db, 'areas'), where('countryId', '==', ind))
            const querySnapshotLen = await getCountFromServer(qLenn);
            cntArea += querySnapshotLen.data().count
            setRowArea(cntArea);
            const q1 = query(collection(db, 'areas'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), limit(limitArea))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items2.push(doc1.data())
            })
            setLastVisibleArea(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
            setFirstVisibleArea(querySnapshot1.docs[0]);
            let i = 1;
            items2.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items2.length) {
                  setRegion(items2)
                } else {
                  i++;
                }
              }
            })
          }

          setState(items)
          setRegion(items1)
          setArea(items2)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isSelected = (name: string) => selectCountry.indexOf(name) !== -1;

  //call when country chip is removed
  const handleDelete = async (name: any) => {
    debugger
    const data = selectCountry.filter((d: any) => d !== name)
    setSelectCountry(data)
    if (chipCountry) {
      const chipData = chipCountry.filter((d: any) => d.name !== name)
      setChipCountry(chipData)
      await getState()
      await getRegion()
      await getArea()
    } else {
      await getState()
      await getRegion()
      await getArea()
    }
  }

  // add new country
  const handleClickisAddCountry = () => {
    setCountryData(initialState)
    setIsOpenCountry(true)
    validationClear()
  }

  // edit country
  const handleClickisEditCountry = (element: any) => {
    let obj = {
      "countryId": element?.uId,
      "country": element?.name,
      "isocode2": element?.isoCode2,
      "isocode3": element?.isoCode3
    }
    setCountryData(obj);
    setIsOpenCountry(true);
    validationClear()
  };

  // when save on country dialog
  const handleCountrySave = async (e: any) => {
    try {
      let flag = validateFormCountry(e);
      if (flag) {
        let cName = Countrydata.country.split(" ")
        for (let i = 0; i < cName.length; i++) {
          cName[i] = cName[i][0].charAt(0).toUpperCase() + cName[i].slice(1).toLowerCase()
        }
        const countryName = cName.join(" ");
        if (Countrydata.countryId) {
          let data1 = JSON.parse(JSON.stringify(countryAll));
          let ind1 = data1.findIndex((c: any) => c.name === Countrydata.country);
          if (ind1 >= 0) {
            toast.error(`Data already exists`, {
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          } else {
            let obj = {
              name: countryName,
              isoCode2: Countrydata.isoCode2 ? Countrydata.isoCode2.toUpperCase() : "",
              isoCode3: Countrydata.isoCode3 ? Countrydata.isoCode3.toUpperCase() : "",
            }
            const datasRef = doc(db, 'countries', Countrydata.countryId);
            await setDoc(datasRef, obj, { merge: true });
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.findIndex((c: any) => c.uId === Countrydata.countryId);
            if (ind >= 0) {
              data[ind].name = countryName;
              data[ind].isoCode2 = Countrydata.isoCode2 ? Countrydata.isoCode2.toUpperCase() : "";
              data[ind].isoCode3 = Countrydata.isoCode3 ? Countrydata.isoCode3.toUpperCase() : "";
            }
            setCountry(data);
            setIsOpenCountry(false);
          }
        } else {
          let val = Countrydata.country.charAt(0).toUpperCase() + Countrydata.country.slice(1).toLowerCase()
          let data = JSON.parse(JSON.stringify(countryAll));
          let ind = data.findIndex((c: any) => c.name === val);
          if (ind > -1) {
            toast.error(`Data already exists`, {
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          } else {
            const CountryRef = collection(db, 'countries')
            await addDoc(CountryRef, {
              name: countryName,
              isoCode2: Countrydata.isoCode2 ? Countrydata.isoCode2.toUpperCase() : "",
              isoCode3: Countrydata.isoCode3 ? Countrydata.isoCode3.toUpperCase() : "",
              isActive: 1,
              isDelete: 0,
              createdDate: serverTimestamp(),
              modifiedDate: serverTimestamp(),
              createdBy: "admin",
              modifiedBy: "admin",
              uId: CountryRef.id
            }).then(docRef => {
              console.log(docRef.id)
            });
            await getCountry();
          }
          setIsOpenCountry(false);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // STATE
  // call when getData is called
  const getState = async () => {
    try {
      setIsLoading1(true)
      const items: any[] = [];
      const q = query(collection(db, 'states'), orderBy("createdDate", 'desc'), limit(limitState))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })
      setLastVisibleState(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisibleState(querySnapshot.docs[0]);
      let i = 1;
      items.forEach(async (obj) => {
        if (obj.uid) {
          const obj1 = {
            uId: obj.uid
          }
          const dataRef = doc(db, 'states', obj.uid)
          await setDoc(dataRef, obj1, { merge: true });
        }
        if (obj.countryId) {
          const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.cName = doc.data().name
          })
          if (i === items.length) {
            state = items
            setState(items)
            setIsLoading1(false)

          } else {
            i++;
          }
        }
      })
      let qLenn = query(collection(db, 'states'), orderBy("createdDate", 'desc'))
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRowState(querySnapshotLen.data().count);
    } catch (error) {
      console.log(error)
    }
    // setIsLoading1(false)
  }

  // call when getAllData is called
  const getStateAll = async () => {
    try {
      const items: any[] = [];
      const q = query(collection(db, 'states'), orderBy("createdDate", 'desc'))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })
      let i = 1;
      items.forEach(async (obj) => {
        if (obj.uid) {
          const obj1 = {
            uId: obj.uid
          }
          const dataRef = doc(db, 'states', obj.uid)
          await setDoc(dataRef, obj1, { merge: true });
        }
        if (obj.countryId) {
          const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.cName = doc.data().name
          })
          if (i === items.length) {
            setStateAll(items)
          } else {
            i++;
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  //call when state checkbox is selected
  const handleState = async (e: any, string: any) => {
    try {
      const selected = selectState.indexOf(string)
      let newSelected: readonly string[] = [];
      let sVal: any[] = []
      let items: any[] = []
      let items1: any[] = []
      if (!selectState.length) {
        if (selected === -1) {
          newSelected = newSelected.concat(selectState, string);
        }
        let obj = {
          "name": string
        }
        sVal.push(obj)
        setChipState(sVal)
        chipState = sVal
        setSelectState(newSelected);
        let cntArea = 0
        if (chipRegion.length) {
          let regionData = JSON.parse(JSON.stringify(regionAll));
          let ind = regionData.find((c: any) => c.name === chipRegion[0].name).stateId
          let stateData = JSON.parse(JSON.stringify(stateAll))
          let sInd = stateData.find((c: any) => c.name === chipState[0].name).uId
          if (ind !== sInd) {
            setSelectRegion([])
            setChipRegion([])
          } else {
            const qLenn = query(collection(db, 'regions'), where('stateId', '==', ind))
            const querySnapshotLen = await getCountFromServer(qLenn);
            setRowRegion(querySnapshotLen.data().count);
            const q = query(collection(db, 'regions'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), limit(limitRegion))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items.push({ uid: doc.id, ...doc.data() })
            })
            setLastVisibleRegion(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstVisibleRegion(querySnapshot.docs[0]);
            let i = 1;
            items.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items.length) {
                  setRegion(items)
                } else {
                  i++;
                }
              }
            })
          }
        }
        for (let j = 0; j < chipState.length; j++) {
          let data = JSON.parse(JSON.stringify(stateAll));
          let ind = data.find((c: any) => c.name === chipState[j].name).uId
          const qLenn = query(collection(db, 'regions'), where('stateId', '==', ind))
          const querySnapshotLen = await getCountFromServer(qLenn);
          setRowRegion(querySnapshotLen.data().count);
          const q = query(collection(db, 'regions'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), limit(limitRegion))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          setLastVisibleRegion(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisibleRegion(querySnapshot.docs[0]);
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.uid) {
              const obj1 = {
                uId: obj.uid
              }
              const dataRef = doc(db, 'regions', obj.uid)
              await setDoc(dataRef, obj1, { merge: true });
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setRegion(items)
              } else {
                i++;
              }
            }
          })
        }

        for (let i = 0; i < chipState.length; i++) {
          let data = JSON.parse(JSON.stringify(stateAll));
          let ind = data.find((c: any) => c.name === chipState[i].name).uId
          const qLenn = query(collection(db, 'areas'), where('stateId', '==', ind))
          const querySnapshotLen = await getCountFromServer(qLenn);
          cntArea += querySnapshotLen.data().count
          setRowArea(cntArea);
          const q1 = query(collection(db, 'areas'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), limit(limitArea))
          const querySnapshot1 = await getDocs(q1);
          querySnapshot1.forEach((doc1) => {
            items1.push(doc1.data())
          })
          setLastVisibleArea(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
          setFirstVisibleArea(querySnapshot1.docs[0]);
          let j = 1;
          items1.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.regionId) {
              const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.rName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (j === items1.length) {
                setArea(items1)
                area = items1
              } else {
                j++;
              }
            }
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isSelectedState = (name: string) => selectState.indexOf(name) !== -1;

  // removed chip of state selected
  const handleDeleteState = async (name: any) => {
    debugger
    const data = selectState.filter((d: any) => d !== name)
    setSelectState(data)
    if (chipState.length) {
      const chipData = chipState.filter((d: any) => d.name !== name)
      setChipState(chipData)
      chipState = chipData
      let items: any[] = []
      let items1: any[] = []
      let items2: any[] = []

      let cntRegion = 0;
      let cntArea = 0
      if (chipState.length) {
        let stateData = JSON.parse(JSON.stringify(stateAll));
        let ind = stateData.find((c: any) => c.name === chipState[0].name).countryId
        let countryData = JSON.parse(JSON.stringify(countryAll))
        let cInd = countryData.find((c: any) => c.name === chipCountry[0].name).uId
        if (ind !== cInd) {
          setSelectState([])
          setChipState([])
        } else {
          const qLenn = query(collection(db, 'states'), where('countryId', '==', cInd))
          const querySnapshotLen = await getCountFromServer(qLenn);
          setRowState(querySnapshotLen.data().count);
          const q = query(collection(db, 'states'), where('countryId', '==', cInd), orderBy("createdDate", 'desc'), limit(limitState))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          setLastVisibleState(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisibleState(querySnapshot.docs[0]);
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.uid) {
              const obj1 = {
                uId: obj.uid
              }
              const dataRef = doc(db, 'states', obj.uid)
              await setDoc(dataRef, obj1, { merge: true });
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setState(items)
              } else {
                i++;
              }
            }
          })
        }
      }
      if (chipRegion.length) {
        let regionData = JSON.parse(JSON.stringify(regionAll));
        let ind = regionData.find((c: any) => c.name === chipRegion[0].name).countryId
        let countryData = JSON.parse(JSON.stringify(countryAll))
        if (chipCountry.length) {
          let cInd = countryData.find((c: any) => c.name === chipCountry[0].name).uId
          if (ind !== cInd) {
            setSelectRegion([])
            setChipRegion([])
          }
          else if (chipState.length) {
            let regionData = JSON.parse(JSON.stringify(regionAll));
            let cind = regionData.find((c: any) => c.name === chipRegion[0].name).countryId
            let sind = regionData.find((c: any) => c.name === chipRegion[0].name).stateId
            const q1 = query(collection(db, 'regions'), where('countryId', '==', cind), where('stateId', '==', sind), orderBy("createdDate", 'desc'), limit(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items1.push(doc1.data())
            })
            setLastVisibleRegion(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
            setFirstVisibleRegion(querySnapshot1.docs[0]);
            let i = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items1.length) {
                  setRegion(items1)
                } else {
                  i++;
                }
              }
            })

          } else {
            const qLenn = query(collection(db, 'regions'), where('countryId', '==', cInd))
            const querySnapshotLen = await getCountFromServer(qLenn);
            cntRegion += querySnapshotLen.data().count
            setRowRegion(cntRegion);
            const q1 = query(collection(db, 'regions'), where('countryId', '==', cInd), orderBy("createdDate", 'desc'), limit(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items1.push(doc1.data())
            })
            setLastVisibleRegion(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
            setFirstVisibleRegion(querySnapshot1.docs[0]);
            let i = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items1.length) {
                  setRegion(items1)
                } else {
                  i++;
                }
              }
            })
          }
        } else {
          getRegion()
        }
      } else if (chipCountry.length) {
        for (let j = 0; j < chipCountry.length; j++) {
          let data = JSON.parse(JSON.stringify(countryAll));
          let ind = data.find((c: any) => c.name === chipCountry[j].name).uId
          const qLenn = query(collection(db, 'states'), where('countryId', '==', ind))
          const querySnapshotLen = await getCountFromServer(qLenn);
          setRowState(querySnapshotLen.data().count);
          const q = query(collection(db, 'states'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), limit(limitState))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          setLastVisibleState(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisibleState(querySnapshot.docs[0]);
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setState(items)
              } else {
                i++;
              }
            }
          })
        }
        for (let i = 0; i < chipCountry.length; i++) {
          let data = JSON.parse(JSON.stringify(countryAll));
          let ind = data.find((c: any) => c.name === chipCountry[i].name).uId
          const qLenn = query(collection(db, 'regions'), where('countryId', '==', ind))
          const querySnapshotLen = await getCountFromServer(qLenn);
          cntRegion += querySnapshotLen.data().count
          setRowRegion(cntRegion);
          const q1 = query(collection(db, 'regions'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), limit(limitRegion))
          const querySnapshot1 = await getDocs(q1);
          querySnapshot1.forEach((doc1) => {
            items1.push(doc1.data())
          })
          setLastVisibleRegion(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
          setFirstVisibleRegion(querySnapshot1.docs[0]);
          let j = 1;
          items1.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (j === items1.length) {
                setRegion(items1)
              } else {
                j++;
              }
            }
          })

        }

        for (let k = 0; k < chipCountry.length; k++) {
          let data = JSON.parse(JSON.stringify(countryAll));
          let ind = data.find((c: any) => c.name === chipCountry[k].name).uId
          const qLenn = query(collection(db, 'areas'), where('countryId', '==', ind))
          const querySnapshotLen = await getCountFromServer(qLenn);
          cntArea += querySnapshotLen.data().count
          setRowArea(cntArea);
          const q1 = query(collection(db, 'areas'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), limit(limitArea))
          const querySnapshot1 = await getDocs(q1);
          querySnapshot1.forEach((doc1) => {
            items2.push(doc1.data())
          })
          setLastVisibleArea(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
          setFirstVisibleArea(querySnapshot1.docs[0]);
          let i = 1;
          items2.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.regionId) {
              const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.rName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items2.length) {
                setArea(items2)
                area = items2
              } else {
                i++;
              }
            }
          })
        }

        // setState(items)
        // setRegion(items1)
        // setArea(items2)
      } else {
        await getRegion()
        await getArea()
      }
    } else {
      await getRegion()
      await getArea()
    }
  }

  // add new state
  const handleClickisAddState = () => {
    setCountryData(initialState)
    setIsOpenState(true)
    validationClear()
  }

  // edit state
  const handleClickisEditState = async (element: any) => {
    let obj = {
      "stateId": element?.uId,
      "country": element?.countryId,
      "state": element?.name,
      "scode2": element?.isocode2,
      "scode3": element?.isocode3,
    }
    await getCountryAll();
    setCountryData(obj);
    setIsOpenState(true);
    validationClear()
  };

  // save changes in state dialog
  const handleSaveState = async (e: any) => {
    try {
      let flag = validateFormState(e);
      if (flag) {
        let sName = Countrydata.state.split(" ")
        for (let i = 0; i < sName.length; i++) {
          sName[i] = sName[i][0].charAt(0).toUpperCase() + sName[i].slice(1).toLowerCase()
        }
        const stateName = sName.join(" ");
        if (Countrydata.stateId) {
          let data1 = JSON.parse(JSON.stringify(stateAll));
          let ind1 = data1.findIndex((c: any) => c.name === Countrydata.state);
          if (ind1 >= 0) {
            toast.error(`Data already exists`, {
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          } else {
            let obj = {
              name: stateName,
              isoCode2: Countrydata.scode2 ? Countrydata.scode2.toUpperCase() : "",
              isoCode3: Countrydata.scode3 ? Countrydata.scode3.toUpperCase() : "",
              countryId: Countrydata.country ? Countrydata.country : ""
            }
            const datasRef = doc(db, 'states', Countrydata.stateId);
            await setDoc(datasRef, obj, { merge: true });
            let data = JSON.parse(JSON.stringify(stateAll));
            let ind = data.findIndex((c: any) => c.uId === Countrydata.stateId);
            if (ind >= 0) {
              data[ind].name = stateName;
              data[ind].isoCode2 = Countrydata.scode2 ? Countrydata.scode2.toUpperCase() : "";
              data[ind].isoCode3 = Countrydata.scode3 ? Countrydata.scode3.toUpperCase() : "";
              data[ind].countryId = Countrydata.country ? Countrydata.country : ""
            }
            setState(data);
            setIsOpenState(false);
          }
        } else {
          let val = Countrydata.state.charAt(0).toUpperCase() + Countrydata.state.slice(1).toLowerCase()
          let data = JSON.parse(JSON.stringify(stateAll));
          let ind = data.findIndex((c: any) => c.name === val);
          if (ind > -1) {
            toast.error(`Data already exists`, {
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          } else {
            const stateRef = collection(db, 'states')
            await addDoc(stateRef, {
              name: stateName,
              isoCode2: Countrydata.scode2 ? Countrydata.scode2.toUpperCase() : "",
              isoCode3: Countrydata.scode3 ? Countrydata.scode3.toUpperCase() : "",
              isActive: 1,
              isDelete: 0,
              createdDate: serverTimestamp(),
              modifiedDate: serverTimestamp(),
              createdBy: "admin",
              modifiedBy: "admin",
              uId: stateRef.id,
              countryId: Countrydata.country
            }).then(docRef => {
              console.log(docRef.id)
            });
            await getState();
          }
          setIsOpenState(false);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // REgion
  // call when getData is called
  const getRegion = async () => {
    try {
      if (chipRegion.length || chipState.length) {
        setIsLoading(false)
        setIsLoading1(false)
      } else {
        setIsLoading2(true)
      }
      const items: any[] = [];
      let qLenn = query(collection(db, 'regions'), orderBy("createdDate", 'desc'))
      const q = query(collection(db, 'regions'), orderBy("createdDate", 'desc'), limit(limitRegion))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })
      setLastVisibleRegion(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisibleRegion(querySnapshot.docs[0]);
      let i = 1;
      items.forEach(async (obj) => {
        if (obj.stateId) {
          const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.sName = doc.data().name
          })
        }
        if (obj.uid) {
          const obj1 = {
            uId: obj.uid
          }
          const dataRef = doc(db, 'regions', obj.uid)
          await setDoc(dataRef, obj1, { merge: true });
        }
        if (obj.countryId) {
          const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.cName = doc.data().name
          })
          if (i === items.length) {
            region = items
            setRegion(items)
            setIsLoading2(false)
          } else {
            i++;
          }
        }
      })
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRowRegion(querySnapshotLen.data().count);
    } catch (error) {
      console.log(error)
    }
    // setIsLoading2(false)
  }

  // call when getAllData is called
  const getRegionAll = async () => {
    try {
      const items: any[] = [];
      const q = query(collection(db, 'regions'), orderBy("createdDate", 'desc'))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })
      let i = 1;
      items.forEach(async (obj) => {
        if (obj.stateId) {
          const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.sName = doc.data().name
          })
        }
        if (obj.uid) {
          const obj1 = {
            uId: obj.uid
          }
          const dataRef = doc(db, 'regions', obj.uid)
          await setDoc(dataRef, obj1, { merge: true });
        }
        if (obj.countryId) {
          const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.cName = doc.data().name
          })
          if (i === items.length) {
            setRegionAll(items)
          } else {
            i++;
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  //call when region checkbox is selected
  const handleRegion = async (e: any, string: any) => {
    try {
      const selected = selectRegion.indexOf(string)
      let newSelected: readonly string[] = [];
      let rVal: any[] = []
      let items: any[] = []
      if (!selectRegion.length) {
        if (selected === -1) {
          newSelected = newSelected.concat(selectRegion, string);
        }
        let obj = {
          "name": string
        }
        rVal.push(obj)
        setChipRegion(rVal)
        chipRegion = rVal
        setSelectRegion(newSelected);
        let cntArea = 0
        for (let j = 0; j < chipRegion.length; j++) {
          let data = JSON.parse(JSON.stringify(regionAll));
          let ind = data.find((c: any) => c.name === chipRegion[j].name).uId
          const qLenn = query(collection(db, 'areas'), where('regionId', '==', ind))
          const querySnapshotLen = await getCountFromServer(qLenn);
          cntArea += querySnapshotLen.data().count
          setRowArea(cntArea);
          const q = query(collection(db, 'areas'), where('regionId', '==', ind), orderBy("createdDate", 'desc'), limit(limitArea))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          setLastVisibleArea(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisibleArea(querySnapshot.docs[0]);
          let i = 1;
          if (items.length > 0) {
            items.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.regionId) {
                const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.rName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items.length) {
                  setArea(items)
                  area = items
                } else {
                  i++;
                }
              }
            })
          } else {
            setArea(items)
            area = items
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isSelectedRegion = (name: string) => selectRegion.indexOf(name) !== -1;

  // region chip is removed
  const handleDeleteRegion = async (name: any) => {
    const data = selectRegion.filter((d: any) => d !== name)
    setSelectRegion(data)
    if (chipRegion) {
      const items: any[] = []
      const items1: any[] = []
      const items2: any[] = []
      const chipData = chipRegion.filter((d: any) => d.name !== name)
      setChipRegion(chipData)
      if (chipState.length) {
        let cntArea = 0;

        if (chipRegion.length) {
          let regionData = JSON.parse(JSON.stringify(regionAll));
          let ind = regionData.find((c: any) => c.name === chipRegion[0].name).stateId
          let stateData = JSON.parse(JSON.stringify(stateAll))
          let sInd = stateData.find((c: any) => c.name === chipState[0].name).uId
          if (ind !== sInd) {
            setSelectRegion([])
            setChipRegion([])
          } else {
            const qLenn = query(collection(db, 'regions'), where('stateId', '==', ind))
            const querySnapshotLen = await getCountFromServer(qLenn);
            setRowRegion(querySnapshotLen.data().count);
            const q = query(collection(db, 'regions'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), limit(limitRegion))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items2.push({ uid: doc.id, ...doc.data() })
            })
            setLastVisibleRegion(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstVisibleRegion(querySnapshot.docs[0]);
            let i = 1;
            items2.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items2.length) {
                  setRegion(items2)
                } else {
                  i++;
                }
              }
            })
          }
        }
        for (let j = 0; j < chipState.length; j++) {
          let data = JSON.parse(JSON.stringify(stateAll));
          let ind = data.find((c: any) => c.name === chipState[j].name).uId
          const qLenn = query(collection(db, 'regions'), where('stateId', '==', ind))
          const querySnapshotLen = await getCountFromServer(qLenn);
          setRowRegion(querySnapshotLen.data().count);
          const q = query(collection(db, 'regions'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), limit(limitRegion))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          setLastVisibleRegion(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisibleRegion(querySnapshot.docs[0]);
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setRegion(items)
              } else {
                i++;
              }
            }
          })
        }

        for (let i = 0; i < chipState.length; i++) {
          let data = JSON.parse(JSON.stringify(stateAll));
          let ind = data.find((c: any) => c.name === chipState[i].name).uId
          const qLenn = query(collection(db, 'areas'), where('stateId', '==', ind))
          const querySnapshotLen = await getCountFromServer(qLenn);
          cntArea += querySnapshotLen.data().count
          setRowArea(cntArea);
          const q1 = query(collection(db, 'areas'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), limit(limitArea))
          const querySnapshot1 = await getDocs(q1);
          querySnapshot1.forEach((doc1) => {
            items1.push(doc1.data())
          })
          setLastVisibleArea(querySnapshot1.docs[querySnapshot1.docs.length - 1]);
          setFirstVisibleArea(querySnapshot1.docs[0]);
          let j = 1;
          items1.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.regionId) {
              const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.rName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (j === items1.length) {
                setArea(items1)
                area = items1
              } else {
                j++;
              }
            }
          })
        }
      } else {
        await getArea()
      }
    }
  }

  // add region
  const handleClickisAddRegion = () => {
    setCountryData(initialState)
    setIsOpenRegion(true)
    validationClear()
  }

  // edit region
  const handleClickisEditRegion = async (element: any) => {
    let obj = {
      "regionId": element?.uId,
      "region": element?.name,
      "country": element?.countryId,
      "state": element?.stateId
    }
    await getCountryAll();
    await getStateDropDownData(null);
    setCountryData(obj);
    Countrydata = obj
    await getCountryAll();
    await getStateDropDownData(null);
    // getRegionDropDownData(null);
    setTimeout(() => {
      setIsOpenRegion(true);
    }, 1000);
    validationClear()

  };

  // save region changes
  const handleSaveRegion = async (e: any) => {
    try {
      let flag = validateFormRegion(e);
      if (flag) {
        let rName = Countrydata.region.split(" ")
        for (let i = 0; i < rName.length; i++) {
          rName[i] = rName[i][0].charAt(0).toUpperCase() + rName[i].slice(1).toLowerCase()
        }
        const regionName = rName.join(" ");
        if (Countrydata.regionId) {
          let data1 = JSON.parse(JSON.stringify(regionAll));
          let ind1 = data1.findIndex((c: any) => c.name === Countrydata.region);
          if (ind1 >= 0) {
            toast.error(`Data already exists`, {
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          } else {
            let obj = {
              name: regionName,
              stateId: Countrydata.state ? Countrydata.state : "",
              countryId: Countrydata.country ? Countrydata.country : "",
            }
            const datasRef = doc(db, 'regions', Countrydata.regionId);
            await setDoc(datasRef, obj, { merge: true });
            let data = JSON.parse(JSON.stringify(regionAll));
            let ind = data.findIndex((c: any) => c.uId === Countrydata.regionId);
            if (ind >= 0) {
              data[ind].name = regionName;
              data[ind].state = Countrydata.state ? Countrydata.state : "";
              data[ind].country = Countrydata.country ? Countrydata.country : "";
            }
            setRegion(data);
            setIsOpenRegion(false);
          }
        } else {
          let val = Countrydata.region.charAt(0).toUpperCase() + Countrydata.region.slice(1).toLowerCase()
          let data = JSON.parse(JSON.stringify(regionAll));
          let ind = data.findIndex((c: any) => c.name === val);
          if (ind > -1) {
            toast.error(`Data already exists`, {
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          } else {
            const RegionRef = collection(db, 'regions')
            await addDoc(RegionRef, {
              name: regionName,
              stateId: Countrydata.state ? Countrydata.state : "",
              countryId: Countrydata.country ? Countrydata.country : "",
              isActive: 1,
              isDelete: 0,
              createdDate: serverTimestamp(),
              modifiedDate: serverTimestamp(),
              createdBy: "admin",
              modifiedBy: "admin",
              uId: RegionRef.id,
            }).then(docRef => {
              console.log(docRef.id)
            });
            await getRegion();
          }
          setIsOpenRegion(false);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  //get state dropdown
  const getStateDropDownData = async (e: any) => {
    let items: any[] = []
    if (Countrydata.country) {
      const q = query(collection(db, 'states'), where('countryId', '==', Countrydata.country))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })
      setStateDropDown(items)
    }
  }

  // get region dropdown
  const getRegionDropDownData = async (e: any) => {
    let items: any[] = []
    if (Countrydata.state) {
      const q = query(collection(db, 'regions'), where('stateId', '==', Countrydata.state))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })
      setRegionDropDown(items)
    }
  }

  // area
  // call when getData is called
  const getArea = async () => {
    try {
      setIsLoading3(true)
      const items: any[] = [];
      let qLenn = query(collection(db, 'areas'))
      const q = query(collection(db, 'areas'), orderBy("createdDate", 'desc'), limit(limitArea))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })
      setLastVisibleArea(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisibleArea(querySnapshot.docs[0]);
      let i = 1;
      items.forEach(async (obj) => {
        if (obj.stateId) {
          const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.sName = doc.data().name
          })
        }
        if (obj.uid) {
          const obj1 = {
            uId: obj.uid
          }
          const dataRef = doc(db, 'regions', obj.uid)
          await setDoc(dataRef, obj1, { merge: true });
        }
        if (obj.regionId) {
          const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.rName = doc.data().name
          })
        }
        if (obj.countryId) {
          const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.cName = doc.data().name
          })
          if (i === items.length) {
            setArea(items)
            area = items
            setIsLoading3(false)
          } else {
            i++;
          }
        }
      })
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRowArea(querySnapshotLen.data().count);
    } catch (error) {
      console.log(error)
    }
    // setIsLoading3(false)
  }

  // call when getAllData is called
  const getAreaAll = async () => {
    try {
      const items: any[] = [];
      const q = query(collection(db, 'areas'), orderBy("createdDate", 'desc'))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push({ uid: doc.id, ...doc.data() })
      })

      let i = 1;
      items.forEach(async (obj) => {
        if (obj.stateId) {
          const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.sName = doc.data().name
          })
        }
        if (obj.uid) {
          const obj1 = {
            uId: obj.uid
          }
          const dataRef = doc(db, 'regions', obj.uid)
          await setDoc(dataRef, obj1, { merge: true });
        }
        if (obj.regionId) {
          const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.rName = doc.data().name
          })
        }
        if (obj.countryId) {
          const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            obj.cName = doc.data().name
          })
          if (i === items.length) {
            setAreaAll(items)
            areaAll = items
          } else {
            i++;
          }
        }
      })

    } catch (error) {
      console.log(error)
    }
  }

  //call when area checkbox is selected
  const handleArea = async (e: any, string: any) => {
    try {
      const selected = selectArea.indexOf(string)
      let newSelected: readonly string[] = [];
      if (selected === -1) {
        newSelected = newSelected.concat(selectArea, string);
      }
      setSelectArea(newSelected);
    } catch (error) {
      console.log(error)
    }
  }

  const isSelectedArea = (name: string) => selectArea.indexOf(name) !== -1;

  //add area
  const handleClickisAddArea = () => {
    setCountryData(initialState)
    setIsOpenArea(true)
    validationClear()
  }

  //edit area
  const handleClickisEditArea = async (element: any) => {
    let obj = {
      "areaId": element?.uId,
      "area": element?.name,
      "pincode": element?.pinCode,
      "country": element?.countryId,
      "state": element?.stateId,
      "region": element?.regionId,
    }
    setCountryData(obj);
    Countrydata = obj
    await getCountryAll();
    await getStateDropDownData(null);
    await getRegionDropDownData(null);
    setTimeout(() => {
      setIsOpenArea(true);
    }, 1000);
    validationClear()
  };

  //save area changes
  const handleSaveArea = async (e: any) => {
    try {
      let flag = validateFormArea(e);
      if (flag) {
        let aName = Countrydata.area.split(" ")
        for (let i = 0; i < aName.length; i++) {
          aName[i] = aName[i][0].charAt(0).toUpperCase() + aName[i].slice(1).toLowerCase()
        }
        const areaName = aName.join(" ");
        if (Countrydata.areaId) {
          let data1 = JSON.parse(JSON.stringify(areaAll));
          let ind1 = data1.findIndex((c: any) => c.name === Countrydata.area);
          if (ind1 >= 0) {
            toast.error(`Data already exists`, {
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          } else {
            let obj = {
              name: areaName,
              pinCode: Countrydata.pincode ? Countrydata.pincode : "",
              regionId: Countrydata.region ? Countrydata.region : "",
              stateId: Countrydata.state ? Countrydata.state : "",
              countryId: Countrydata.country ? Countrydata.country : "",
            }
            const datasRef = doc(db, 'areas', Countrydata.areaId);
            await setDoc(datasRef, obj, { merge: true });
            let data = JSON.parse(JSON.stringify(areaAll));
            let ind = data.findIndex((c: any) => c.uId === Countrydata.areaId);
            if (ind >= 0) {
              data[ind].name = areaName;
              data[ind].pinCode = Countrydata.pincode ? Countrydata.pincode : "";
              data[ind].regionId = Countrydata.region ? Countrydata.region : "";
              data[ind].stateId = Countrydata.state ? Countrydata.state : "";
              data[ind].countryId = Countrydata.country ? Countrydata.country : "";
            }
            setRegion(data);
            setIsOpenArea(false);
          }
        } else {
          let val = Countrydata.area.charAt(0).toUpperCase() + Countrydata.area.slice(1).toLowerCase()
          let data = JSON.parse(JSON.stringify(areaAll));
          let ind = data.findIndex((c: any) => c.name === val);
          if (ind > -1) {
            toast.error(`Data already exists`, {
              autoClose: 6000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
          } else {
            const areaRef = collection(db, 'areas')
            await addDoc(areaRef, {
              name: areaName,
              pinCode: Countrydata.pincode ? Countrydata.pincode : "",
              regionId: Countrydata.region ? Countrydata.region : "",
              stateId: Countrydata.state ? Countrydata.state : "",
              countryId: Countrydata.country ? Countrydata.country : "",
              isActive: 1,
              isDelete: 0,
              createdDate: serverTimestamp(),
              modifiedDate: serverTimestamp(),
              createdBy: "admin",
              modifiedBy: "admin",
              uId: areaRef.id,
            }).then(docRef => {
              console.log(docRef.id)
            });
            await getArea();
          }
          setIsOpenArea(false);
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const headers = [
    {
      label: "Index", key: "id",
    },
    {
      label: "Country", key: "country",
    },
    {
      label: "ISOCode2", key: "ISOcode2",
    },
    {
      label: "ISOCode3", key: "ISOcode3",
    },
    {
      label: "State", key: "state",
    },
    {
      label: "StateISOCode2", key: "stateISOcode2",
    },
    {
      label: "StateISOCode3", key: "stateISOcode3",
    },
    {
      label: "Region", key: "region",
    },
    {
      label: "Area", key: "area",
    },
    {
      label: "Pincode", key: "pincode",
    },
  ]

  let data: any[] = [];
  let variable = JSON.parse(JSON.stringify(areaAll))
  for (let i = 0; i < variable.length; i++) {
    //let area: any[]=[];
    let selectedRegion = regionAll.find((c: any) => c.uId === variable[i].regionId);
    let selectedState: any;
    let selectedCountry: any
    if (selectedRegion) {
      selectedState = stateAll.find((c: any) => c.uId === selectedRegion.stateId);
      if (selectedState) {
        selectedCountry = countryAll.find((c: any) => c.uId === selectedState?.countryId);
      }
    }
    let obj = {
      "id": i + 1,
      "area": variable[i]?.name ? variable[i]?.name : "",
      "pincode": variable[i]?.pinCode ? variable[i]?.pinCode : "",
      "region": selectedRegion?.name ? selectedRegion?.name : " ",
      "state": selectedState?.name ? selectedState?.name : "",
      "stateISOcode2": selectedState?.isoCode2 ? selectedState?.isoCode2 : "",
      "stateISOcode3": selectedState?.isoCode3 ? selectedState?.isoCode3 : "",
      "country": selectedCountry?.name ? selectedCountry?.name : "",
      "ISOcode2": selectedCountry?.isoCode2 ? selectedCountry?.isoCode2 : "",
      "ISOcode3": selectedCountry?.isoCode3 ? selectedCountry?.isoCode3 : "",
    }
    data.push(obj)
    //variable =
  }
  //data.sort((a, b) => a.country < b.country ? -1 : 1).sort((a, b) => a.state < b.state ? -1 : 1);
  data.sort(function (a, b) {
    return a.country.localeCompare(b.country) || a.state.localeCompare(b.state) || a.region.localeCompare(b.region) || a.area.localeCompare(b.area);
  });
  for (let i = 0; i < data.length; i++) {
    data[i].id = i + 1;
  }

  // csv download
  const csvlink = {
    filename: "region.csv",
    headers: headers,
    data: data,
  }

  // close switch
  const handleCloseSwitch = () => {
    setIsCheckCountry(false)
    setIsCheckState(false)
    setIsCheckRegion(false)
    setIsCheckArea(false)
    setIsOpenCountry(false)
    setIsOpenState(false)
    setIsOpenRegion(false)
    setIsOpenArea(false)
  }

  // when country switch is change
  const handleSwitchCountry = (id: any, isActive: any) => {
    let obj = {
      "id": id,
      "isActive": isActive
    }
    setCountryData(obj);
    setIsCheckCountry(true);
  }

  // save switch for country
  const handleCheckSwitchCountry = async () => {
    const items: any[] = [];
    const item1: any[] = [];
    const item2: any[] = []
    try {
      if (Countrydata.isActive === 1) {
        const obj = {
          isActive: 0
        }
        const activeStatus = doc(db, 'countries', Countrydata.id)
        await setDoc(activeStatus, obj, { merge: true });
        let data = JSON.parse(JSON.stringify(countryAll));
        let ind = data.findIndex((c: any) => c.uId === Countrydata.id);
        if (ind > -1) {
          data[ind].isActive = 0;
        }
        const q = query(collection(db, 'states'), where('countryId', '==', data[ind].uId))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          items.push({ uid: doc.id, ...doc.data() })
        })
        let dataState = JSON.parse(JSON.stringify(stateAll));
        for (let i = 0; i < items.length; i++) {
          const activeState = doc(db, 'states', items[i].uId)
          await setDoc(activeState, {
            isActive: 0
          }, { merge: true })

          let indState = dataState.findIndex((c: any) => c.uId === items[i].uId);
          if (indState > -1) {
            dataState[indState].isActive = 0;
          }
        }
        state = dataState
        const q1 = query(collection(db, 'regions'), where('countryId', '==', data[ind].uId))
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach((doc) => {
          item1.push({ uid: doc.id, ...doc.data() })
        })
        let dataRegion = JSON.parse(JSON.stringify(regionAll));
        for (let i = 0; i < item1.length; i++) {
          const activeRegion = doc(db, 'regions', item1[i].uId)
          await setDoc(activeRegion, {
            isActive: 0
          }, { merge: true })

          let indRegion = dataRegion.findIndex((c: any) => c.uId === item1[i].uId);
          if (indRegion > -1) {
            dataRegion[indRegion].isActive = 0;
          }
        }
        region = dataRegion
        const q2 = query(collection(db, 'areas'), where('countryId', '==', data[ind].uId))
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
          item2.push({ uid: doc.id, ...doc.data() })
        })
        let dataArea = JSON.parse(JSON.stringify(areaAll));
        for (let i = 0; i < item2.length; i++) {
          const activeArea = doc(db, 'areas', item2[i].uId)
          await setDoc(activeArea, {
            isActive: 0
          }, { merge: true })

          let indArea = dataArea.findIndex((c: any) => c.uId === item2[i].uId);
          if (indArea > -1) {
            dataArea[indArea].isActive = 0;
          }
        }
        area = dataArea
        setCountry(data);
        setState(state)
        setRegion(region)
        setArea(area)
        setCountryAll(data);
        setStateAll(state)
        setRegionAll(region)
        setAreaAll(area)
      } else if (Countrydata.isActive === 0) {
        const obj = {
          isActive: 1
        }
        const activeStatus = doc(db, 'countries', Countrydata.id)
        await setDoc(activeStatus, obj, { merge: true })
        let data = JSON.parse(JSON.stringify(countryAll));
        let ind = data.findIndex((c: any) => c.uId === Countrydata.id);
        if (ind > -1) {
          data[ind].isActive = 1;
        }
        const q = query(collection(db, 'states'), where('countryId', '==', data[ind].uId))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          items.push({ uid: doc.id, ...doc.data() })
        })
        let dataState = JSON.parse(JSON.stringify(stateAll));
        for (let i = 0; i < items.length; i++) {
          const activeState = doc(db, 'states', items[i].uId)
          await setDoc(activeState, {
            isActive: 1
          }, { merge: true })

          let indState = dataState.findIndex((c: any) => c.uId === items[i].uId);
          if (indState > -1) {
            dataState[indState].isActive = 1;
          }
        }
        state = dataState
        const q1 = query(collection(db, 'regions'), where('countryId', '==', data[ind].uId))
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach((doc) => {
          item1.push({ uid: doc.id, ...doc.data() })
        })
        let dataRegion = JSON.parse(JSON.stringify(regionAll));
        for (let i = 0; i < item1.length; i++) {
          const activeRegion = doc(db, 'regions', item1[i].uId)
          await setDoc(activeRegion, {
            isActive: 1
          }, { merge: true })

          let indRegion = dataRegion.findIndex((c: any) => c.uId === item1[i].uId);
          if (indRegion > -1) {
            dataRegion[indRegion].isActive = 1;
          }
        }
        region = dataRegion
        const q2 = query(collection(db, 'areas'), where('countryId', '==', data[ind].uId))
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
          item2.push({ uid: doc.id, ...doc.data() })
        })
        let dataArea = JSON.parse(JSON.stringify(areaAll));
        for (let i = 0; i < item2.length; i++) {
          const activeArea = doc(db, 'areas', item2[i].uId)
          await setDoc(activeArea, {
            isActive: 1
          }, { merge: true })

          let indArea = dataArea.findIndex((c: any) => c.uId === item2[i].uId);
          if (indArea > -1) {
            dataArea[indArea].isActive = 1;
          }
        }
        area = dataArea
        setCountry(data);
        setState(state)
        setRegion(region)
        setArea(area)
        setCountryAll(data);
        setStateAll(state)
        setRegionAll(region)
        setAreaAll(area)
      }
    } catch (error) {
      console.log(error)
    }
    setIsCheckCountry(false);
  }

  // when state switch is change
  const handleSwitchState = (id: any, isActive: any) => {
    let obj = {
      "id": id,
      "isActive": isActive
    }
    setCountryData(obj);
    setIsCheckState(true);
  }

  // save switch for state
  const handleCheckSwitchState = async () => {
    const items: any[] = [];
    const item1: any[] = [];
    try {
      if (Countrydata.isActive === 1) {
        const obj = {
          isActive: 0
        }
        const activeStatus = doc(db, 'states', Countrydata.id)
        await setDoc(activeStatus, obj, { merge: true });
        let data = JSON.parse(JSON.stringify(stateAll));
        let ind = data.findIndex((c: any) => c.uId === Countrydata.id);
        if (ind > -1) {
          data[ind].isActive = 0;
        }
        const q = query(collection(db, 'regions'), where('stateId', '==', data[ind].uId))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
          items.push({ uid: doc.id, ...doc.data() })
        })
        let dataRegion = JSON.parse(JSON.stringify(regionAll));
        for (let i = 0; i < items.length; i++) {
          const activeRegion = doc(db, 'regions', items[i].uId)
          await setDoc(activeRegion, {
            isActive: 0
          }, { merge: true })

          let indRegion = dataRegion.findIndex((c: any) => c.uId === items[i].uId);
          if (indRegion > -1) {
            dataRegion[indRegion].isActive = 0;
          }
        }
        region = dataRegion
        const q1 = query(collection(db, 'areas'), where('stateId', '==', data[ind].uId))
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach((doc) => {
          item1.push({ uid: doc.id, ...doc.data() })
        })
        let dataArea = JSON.parse(JSON.stringify(areaAll));
        for (let i = 0; i < item1.length; i++) {
          const activeArea = doc(db, 'areas', item1[i].uId)
          await setDoc(activeArea, {
            isActive: 0
          }, { merge: true })

          let indArea = dataArea.findIndex((c: any) => c.uId === item1[i].uId);
          if (indArea > -1) {
            dataArea[indArea].isActive = 0;
          }
        }
        area = dataArea
        setState(data);
        setStateAll(data);
        setRegion(region)
        setRegionAll(region)
        setArea(area)
        setAreaAll(area)
      } else if (Countrydata.isActive === 0) {
        const obj = {
          isActive: 1
        }
        const activeStatus = doc(db, 'states', Countrydata.id)
        await setDoc(activeStatus, obj, { merge: true })
        let data = JSON.parse(JSON.stringify(stateAll));
        let ind = data.findIndex((c: any) => c.uId === Countrydata.id);
        if (ind > -1) {
          data[ind].isActive = 1
        }
        const q = query(collection(db, 'regions'), where('stateId', '==', data[ind].uId))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc) => {
          items.push({ uid: doc.id, ...doc.data() })
        })
        let dataRegion = JSON.parse(JSON.stringify(regionAll));
        for (let i = 0; i < items.length; i++) {
          const activeRegion = doc(db, 'regions', items[i].uId)
          await setDoc(activeRegion, {
            isActive: 1
          }, { merge: true })

          let indRegion = dataRegion.findIndex((c: any) => c.uId === items[i].uId);
          if (indRegion > -1) {
            dataRegion[indRegion].isActive = 1;
          }
        }
        region = dataRegion

        const q1 = query(collection(db, 'areas'), where('stateId', '==', data[ind].uId))
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach((doc) => {
          item1.push({ uid: doc.id, ...doc.data() })
        })

        let dataArea = JSON.parse(JSON.stringify(areaAll));
        for (let i = 0; i < item1.length; i++) {
          const activeArea = doc(db, 'areas', item1[i].uId)
          await setDoc(activeArea, {
            isActive: 1
          }, { merge: true })

          let indArea = dataArea.findIndex((c: any) => c.uId === item1[i].uId);
          if (indArea > -1) {
            dataArea[indArea].isActive = 1;
          }
        }
        area = dataArea
        setState(data);
        setStateAll(data);
        setRegion(region)
        setRegionAll(region)
        setArea(area)
        setAreaAll(area)
      }
    } catch (error) {
      console.log(error)
    }
    setIsCheckState(false);
  }

  // when region switch is change
  const handleSwitchRegion = (id: any, isActive: any) => {
    let obj = {
      "id": id,
      "isActive": isActive
    }
    setCountryData(obj);
    setIsCheckRegion(true);
  }

  // save switch for region
  const handleCheckSwitchRegion = async () => {
    const items: any[] = [];
    try {
      if (Countrydata.isActive === 1) {
        const obj = {
          isActive: 0
        }
        const activeStatus = doc(db, 'regions', Countrydata.id)
        await setDoc(activeStatus, obj, { merge: true });
        let data = JSON.parse(JSON.stringify(regionAll));
        let ind = data.findIndex((c: any) => c.uId === Countrydata.id);
        if (ind > -1) {
          data[ind].isActive = 0;
        }
        const q = query(collection(db, 'areas'), where('regionId', '==', data[ind].uId))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          items.push({ uid: doc.id, ...doc.data() })
        })
        for (let i = 0; i < items.length; i++) {
          const activeArea = doc(db, 'areas', items[i].uId)
          await setDoc(activeArea, {
            isActive: 0
          }, { merge: true })
          let dataArea = JSON.parse(JSON.stringify(areaAll));
          let indArea = dataArea.findIndex((c: any) => c.uId === items[i].uId);
          if (indArea > -1) {
            dataArea[indArea].isActive = 0;
          }
          area = dataArea
        }
        setRegion(data);
        setArea(area)
        setRegionAll(data);
        setAreaAll(area)
      } else if (Countrydata.isActive === 0) {
        const obj = {
          isActive: 1
        }
        const activeStatus = doc(db, 'regions', Countrydata.id)
        await setDoc(activeStatus, obj, { merge: true })
        let data = JSON.parse(JSON.stringify(regionAll));
        let ind = data.findIndex((c: any) => c.uId === Countrydata.id);
        if (ind > -1) {
          data[ind].isActive = 1
        }
        const q = query(collection(db, 'areas'), where('regionId', '==', data[ind].uId))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          items.push({ uid: doc.id, ...doc.data() })
        })
        for (let i = 0; i < items.length; i++) {
          const activeArea = doc(db, 'areas', items[i].uId)
          await setDoc(activeArea, {
            isActive: 1
          }, { merge: true })
          let dataArea = JSON.parse(JSON.stringify(areaAll));
          let indArea = dataArea.findIndex((c: any) => c.uId === items[i].uId);
          if (indArea > -1) {
            dataArea[indArea].isActive = 1;
          }
          area = dataArea
        }
        setRegion(data);
        setArea(area)
        setRegionAll(data);
        setAreaAll(area)
      }
    } catch (error) {
      console.log(error)
    }
    setIsCheckRegion(false);
  }

  // when area switch is change
  const handleSwitchArea = (id: any, isActive: any) => {
    let obj = {
      "id": id,
      "isActive": isActive
    }
    setCountryData(obj);
    setIsCheckArea(true);
  }

  // save switch for area
  const handleCheckSwitchArea = async () => {
    try {
      if (Countrydata.isActive === 1) {
        const obj = {
          isActive: 0
        }
        const activeStatus = doc(db, 'areas', Countrydata.id)
        await setDoc(activeStatus, obj, { merge: true });
        let data = JSON.parse(JSON.stringify(areaAll));
        let ind = data.findIndex((c: any) => c.uId === Countrydata.id);
        if (ind > -1) {
          data[ind].isActive = 0;
        }
        setArea(data);
        setAreaAll(data);
      } else if (Countrydata.isActive === 0) {
        const obj = {
          isActive: 1
        }
        const activeStatus = doc(db, 'areas', Countrydata.id)
        await setDoc(activeStatus, obj)
        let data = JSON.parse(JSON.stringify(areaAll));
        let ind = data.findIndex((c: any) => c.uId === Countrydata.id);
        if (ind > -1) {
          data[ind].isActive = 1
        }
        setArea(data);
        setAreaAll(data)
      }
    } catch (error) {
      console.log(error)
    }
    setIsCheckArea(false);
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page in country
  const handleCountryChangePage = async (event: any, newPage: any) => {
    setPage(newPage)
    if (page < newPage) {
      try {
        const items: any[] = [];
        let qLenn = query(collection(db, 'countries'))
        const querySnapshotLen = await getCountFromServer(qLenn);
        setRow(querySnapshotLen.data().count);
        const q = query(collection(db, 'countries'), orderBy("createdDate", 'desc'), startAfter(lastVisible), limit(limits))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          items.push({ uid: doc.id, ...doc.data() })
          Countrydata.cId = doc.id
        })
        setCountry(items)
        updateCountry(querySnapshot)
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const items: any[] = [];
        let qLenn = query(collection(db, 'countries'))
        const querySnapshotLen = await getCountFromServer(qLenn);
        setRow(querySnapshotLen.data().count);
        const q = query(collection(db, 'countries'), orderBy("createdDate", 'desc'), endBefore(firstVisible), limitToLast(limits))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          items.push({ uid: doc.id, ...doc.data() })
          Countrydata.cId = doc.id
        })
        setCountry(items)
        updateCountry(querySnapshot)
      } catch (error) {
        console.log(error)
      }
    }
  }

  // update pagination change for country
  const updateCountry = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in country page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleCountryLimitChange = (event: { target: { value: string; }; }) => {
    setPage(0);
    setLimits(parseInt(event.target.value));
    limits = parseInt(event.target.value)
    getCountry()
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page in state
  const handleStateChangePage = async (event: any, newPage: any) => {
    setPageState(newPage)
    let items: any[] = [];
    let items1: any[] = [];
    if (pageState < newPage) {
      try {
        if (chipCountry.length) {
          for (let j = 0; j < chipCountry.length; j++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[j].name).uId
            const q = query(collection(db, 'states'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleState), limit(limitState))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items1.push({ uid: doc.id, ...doc.data() })
            })
            let i = 1;
            items1.forEach(async (obj) => {
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items1.length) {
                  setState(items1)
                } else {
                  i++;
                }
              }
            })
            updateState(querySnapshot)
          }
        } else {
          const q = query(collection(db, 'states'), orderBy("createdDate", 'desc'), startAfter(lastVisibleState), limit(limitState))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          updateState(querySnapshot)
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setState(items)
              } else {
                i++;
              }
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        if (chipCountry.length) {
          for (let j = 0; j < chipCountry.length; j++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[j].name).uId
            const q = query(collection(db, 'states'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleState), limitToLast(limitState))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items1.push({ uid: doc.id, ...doc.data() })
            })
            let i = 1;
            items1.forEach(async (obj) => {
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items1.length) {
                  setState(items1)
                } else {
                  i++;
                }
              }
            })
            updateState(querySnapshot)
          }
        } else {
          const q = query(collection(db, 'states'), orderBy("createdDate", 'desc'), endBefore(firstVisibleState), limitToLast(limitState))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          updateState(querySnapshot)
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setState(items)
              } else {
                i++;
              }
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  // update first visible and last visible value according to pagination (next and previous button)
  const updateState = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisibleState(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisibleState(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in state page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleStateLimitChange = (event: { target: { value: string; }; }) => {
    setPageState(0);
    setLimitState(parseInt(event.target.value));
    limitState = parseInt(event.target.value)
    getState()
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page in region
  const handleRegionChangePage = async (event: any, newPage: any) => {
    setPageRegion(newPage)
    let items: any[] = []
    let items1: any[] = [];
    let items2: any[] = [];
    if (pageRegion < newPage) {
      try {
        if (chipCountry.length) {
          for (let i = 0; i < chipCountry.length; i++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[i].name).uId
            const q1 = query(collection(db, 'regions'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleRegion), limit(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items1.push(doc1.data())
            })
            let j = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (j === items1.length) {
                  setRegion(items1)
                } else {
                  j++;
                }
              }
            })
            updateRegion(querySnapshot1)
          }
          if (chipState.length) {
            for (let i = 0; i < chipState.length; i++) {
              let data = JSON.parse(JSON.stringify(stateAll));
              let ind = data.find((c: any) => c.name === chipState[i].name).uId
              const q1 = query(collection(db, 'regions'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleRegion), limit(limitRegion))
              const querySnapshot1 = await getDocs(q1);
              querySnapshot1.forEach((doc1) => {
                items2.push(doc1.data())
              })
              let j = 1;
              items2.forEach(async (obj) => {
                if (obj.stateId) {
                  const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.sName = doc.data().name
                  })
                }
                if (obj.countryId) {
                  const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.cName = doc.data().name
                  })
                  if (j === items2.length) {
                    setRegion(items2)
                  } else {
                    j++;
                  }
                }
              })
              updateRegion(querySnapshot1)
            }
          }
        } else if (chipState.length) {
          for (let i = 0; i < chipState.length; i++) {
            let data = JSON.parse(JSON.stringify(stateAll));
            let ind = data.find((c: any) => c.name === chipState[i].name).uId
            const q1 = query(collection(db, 'regions'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleRegion), limit(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items2.push(doc1.data())
            })
            let j = 1;
            items2.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (j === items2.length) {
                  setRegion(items2)
                } else {
                  j++;
                }
              }
            })
            updateRegion(querySnapshot1)
          }
        } else {
          const q = query(collection(db, 'regions'), orderBy("createdDate", 'desc'), startAfter(lastVisibleRegion), limit(limitRegion))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          updateRegion(querySnapshot)
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setRegion(items)
                region = items
              } else {
                i++;
              }
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        if (chipCountry.length) {
          for (let i = 0; i < chipCountry.length; i++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[i].name).uId
            const q1 = query(collection(db, 'regions'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleRegion), limitToLast(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items1.push(doc1.data())
            })
            let j = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (j === items1.length) {
                  setRegion(items1)
                } else {
                  j++;
                }
              }
            })
            updateRegion(querySnapshot1)
          }
          if (chipState.length) {
            for (let i = 0; i < chipState.length; i++) {
              let data = JSON.parse(JSON.stringify(stateAll));
              let ind = data.find((c: any) => c.name === chipState[i].name).uId
              const q1 = query(collection(db, 'regions'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleRegion), limitToLast(limitRegion))
              const querySnapshot1 = await getDocs(q1);
              querySnapshot1.forEach((doc1) => {
                items2.push(doc1.data())
              })
              let j = 1;
              items2.forEach(async (obj) => {
                if (obj.stateId) {
                  const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.sName = doc.data().name
                  })
                }
                if (obj.countryId) {
                  const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.cName = doc.data().name
                  })
                  if (j === items2.length) {
                    setRegion(items2)
                  } else {
                    j++;
                  }
                }
              })
              updateRegion(querySnapshot1)
            }
          }
        } else if (chipState.length) {
          for (let i = 0; i < chipState.length; i++) {
            let data = JSON.parse(JSON.stringify(stateAll));
            let ind = data.find((c: any) => c.name === chipState[i].name).uId
            const q1 = query(collection(db, 'regions'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleRegion), limitToLast(limitRegion))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items2.push(doc1.data())
            })
            let j = 1;
            items2.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (j === items2.length) {
                  setRegion(items2)
                } else {
                  j++;
                }
              }
            })
            updateRegion(querySnapshot1)
          }
        } else {
          const q = query(collection(db, 'regions'), orderBy("createdDate", 'desc'), endBefore(firstVisibleRegion), limitToLast(limitRegion))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })

          let i = 1;
          items.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setRegion(items)
                region = items
              } else {
                i++;
              }
            }
          })
          updateRegion(querySnapshot)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  // update pagination change for region
  const updateRegion = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisibleRegion(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisibleRegion(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in region page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleRegionLimitChange = (event: { target: { value: string; }; }) => {
    setPageRegion(0);
    setLimitRegion(parseInt(event.target.value));
    limitRegion = parseInt(event.target.value)
    getRegion()
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page in area
  const handleAreaChangePage = async (event: any, newPage: any) => {
    setPageArea(newPage)
    const items: any[] = [];
    let items1: any[] = [];
    let items2: any[] = [];
    if (pageArea < newPage) {
      try {
        if (chipCountry.length) {
          for (let k = 0; k < chipCountry.length; k++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[k].name).uId
            const q = query(collection(db, 'areas'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleArea), limit(limitArea))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items2.push(doc.data())
            })
            let i = 1;
            items2.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.regionId) {
                const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.rName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items2.length) {
                  setArea(items2)
                  area = items2
                } else {
                  i++;
                }
              }
            })
            updateArea(querySnapshot)
          }
          if (chipState.length) {
            for (let i = 0; i < chipState.length; i++) {
              let data = JSON.parse(JSON.stringify(stateAll));
              let ind = data.find((c: any) => c.name === chipState[i].name).uId
              const q1 = query(collection(db, 'areas'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleArea), limit(limitArea))
              const querySnapshot1 = await getDocs(q1);
              querySnapshot1.forEach((doc1) => {
                items1.push(doc1.data())
              })
              let j = 1;
              items1.forEach(async (obj) => {
                if (obj.stateId) {
                  const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.sName = doc.data().name
                  })
                }
                if (obj.regionId) {
                  const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.rName = doc.data().name
                  })
                }
                if (obj.countryId) {
                  const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.cName = doc.data().name
                  })
                  if (j === items1.length) {
                    setArea(items1)
                    area = items1
                  } else {
                    j++;
                  }
                }
              })
              updateArea(querySnapshot1)
            }
            if (chipRegion.length) {
              for (let j = 0; j < chipRegion.length; j++) {
                let data = JSON.parse(JSON.stringify(regionAll));
                let ind = data.find((c: any) => c.name === chipRegion[j].name).uId
                const q = query(collection(db, 'areas'), where('regionId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleArea), limit(limitArea))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  items.push({ uid: doc.id, ...doc.data() })
                })
                let i = 1;
                items.forEach(async (obj) => {
                  if (obj.stateId) {
                    const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                      obj.sName = doc.data().name
                    })
                  }
                  if (obj.regionId) {
                    const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                      obj.rName = doc.data().name
                    })
                  }
                  if (obj.countryId) {
                    const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                      obj.cName = doc.data().name
                    })
                    if (i === items.length) {
                      setArea(items)
                      area = items
                    } else {
                      i++;
                    }
                  }
                })
                updateArea(querySnapshot)
              }
            }
          }
        } else if (chipState.length) {
          for (let i = 0; i < chipState.length; i++) {
            let data = JSON.parse(JSON.stringify(stateAll));
            let ind = data.find((c: any) => c.name === chipState[i].name).uId
            const q1 = query(collection(db, 'areas'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleArea), limit(limitArea))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc1) => {
              items1.push(doc1.data())
            })
            let j = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.regionId) {
                const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.rName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (j === items1.length) {
                  setArea(items1)
                  area = items1
                } else {
                  j++;
                }
              }
            })
            updateArea(querySnapshot1)
          }
          if (chipRegion.length) {
            for (let j = 0; j < chipRegion.length; j++) {
              let data = JSON.parse(JSON.stringify(regionAll));
              let ind = data.find((c: any) => c.name === chipRegion[j].name).uId
              const q = query(collection(db, 'areas'), where('regionId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleArea), limit(limitArea))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                items.push({ uid: doc.id, ...doc.data() })
              })
              let i = 1;
              items.forEach(async (obj) => {
                if (obj.stateId) {
                  const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.sName = doc.data().name
                  })
                }
                if (obj.regionId) {
                  const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.rName = doc.data().name
                  })
                }
                if (obj.countryId) {
                  const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.cName = doc.data().name
                  })
                  if (i === items.length) {
                    setArea(items)
                    area = items

                  } else {
                    i++;
                  }
                }
              })
              updateArea(querySnapshot)
            }
          }
        } else if (chipRegion.length) {
          for (let j = 0; j < chipRegion.length; j++) {
            let data = JSON.parse(JSON.stringify(regionAll));
            let ind = data.find((c: any) => c.name === chipRegion[j].name).uId
            const q = query(collection(db, 'areas'), where('regionId', '==', ind), orderBy("createdDate", 'desc'), startAfter(lastVisibleArea), limit(limitArea))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items.push({ uid: doc.id, ...doc.data() })
            })
            let i = 1;
            items.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.regionId) {
                const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.rName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items.length) {
                  setArea(items)
                  area = items

                } else {
                  i++;
                }
              }
            })
            updateArea(querySnapshot)
          }
        }
        else {
          const q = query(collection(db, 'areas'), orderBy("createdDate", 'desc'), startAfter(lastVisibleArea), limit(limitArea))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })

          let i = 1;
          items.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.uid) {
              const obj1 = {
                uId: obj.uid
              }
              const dataRef = doc(db, 'regions', obj.uid)
              await setDoc(dataRef, obj1, { merge: true });
            }
            if (obj.regionId) {
              const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.rName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setArea(items)
                area = items

              } else {
                i++;
              }
            }
          })
          updateArea(querySnapshot)
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        if (chipCountry.length) {
          for (let k = 0; k < chipCountry.length; k++) {
            let data = JSON.parse(JSON.stringify(countryAll));
            let ind = data.find((c: any) => c.name === chipCountry[k].name).uId
            const q = query(collection(db, 'areas'), where('countryId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleArea), limitToLast(limitArea))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items2.push(doc.data())
            })
            let i = 1;
            items2.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.regionId) {
                const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.rName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items2.length) {
                  setArea(items2)
                  area = items2
                } else {
                  i++;
                }
              }
            })
            updateArea(querySnapshot)
          }
          if (chipState.length) {
            for (let k = 0; k < chipState.length; k++) {
              let data = JSON.parse(JSON.stringify(stateAll));
              let ind = data.find((c: any) => c.name === chipState[k].name).uId
              const q = query(collection(db, 'areas'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleArea), limitToLast(limitArea))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                items1.push(doc.data())
              })
              let i = 1;
              items1.forEach(async (obj) => {
                if (obj.stateId) {
                  const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.sName = doc.data().name
                  })
                }
                if (obj.regionId) {
                  const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.rName = doc.data().name
                  })
                }
                if (obj.countryId) {
                  const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.cName = doc.data().name
                  })
                  if (i === items1.length) {
                    setArea(items1)
                    area = items1
                  } else {
                    i++;
                  }
                }
              })
              updateArea(querySnapshot)
            }
            if (chipRegion.length) {
              for (let j = 0; j < chipRegion.length; j++) {
                let data = JSON.parse(JSON.stringify(regionAll));
                let ind = data.find((c: any) => c.name === chipRegion[j].name).uId
                const q = query(collection(db, 'areas'), where('regionId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleArea), limitToLast(limitArea))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  items.push({ uid: doc.id, ...doc.data() })
                })
                let i = 1;
                items.forEach(async (obj) => {
                  if (obj.stateId) {
                    const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                      obj.sName = doc.data().name
                    })
                  }
                  if (obj.regionId) {
                    const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                      obj.rName = doc.data().name
                    })
                  }
                  if (obj.countryId) {
                    const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                      obj.cName = doc.data().name
                    })
                    if (i === items.length) {
                      setArea(items)
                      area = items

                    } else {
                      i++;
                    }
                  }
                })
                updateArea(querySnapshot)
              }
            }
          }
        } else if (chipState.length) {
          for (let k = 0; k < chipState.length; k++) {
            let data = JSON.parse(JSON.stringify(stateAll));
            let ind = data.find((c: any) => c.name === chipState[k].name).uId
            const q = query(collection(db, 'areas'), where('stateId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleArea), limitToLast(limitArea))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items1.push(doc.data())
            })
            let i = 1;
            items1.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.regionId) {
                const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.rName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items1.length) {
                  setArea(items1)
                  area = items1
                } else {
                  i++;
                }
              }
            })
            updateArea(querySnapshot)
          }
          if (chipRegion.length) {
            for (let j = 0; j < chipRegion.length; j++) {
              let data = JSON.parse(JSON.stringify(regionAll));
              let ind = data.find((c: any) => c.name === chipRegion[j].name).uId
              const q = query(collection(db, 'areas'), where('regionId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleArea), limitToLast(limitArea))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                items.push({ uid: doc.id, ...doc.data() })
              })
              let i = 1;
              items.forEach(async (obj) => {
                if (obj.stateId) {
                  const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.sName = doc.data().name
                  })
                }
                if (obj.regionId) {
                  const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.rName = doc.data().name
                  })
                }
                if (obj.countryId) {
                  const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                  const querySnapshot = await getDocs(q);
                  querySnapshot.forEach((doc) => {
                    obj.cName = doc.data().name
                  })
                  if (i === items.length) {
                    setArea(items)
                    area = items

                  } else {
                    i++;
                  }
                }
              })
              updateArea(querySnapshot)
            }
          }
        } else if (chipRegion.length) {
          for (let j = 0; j < chipRegion.length; j++) {
            let data = JSON.parse(JSON.stringify(regionAll));
            let ind = data.find((c: any) => c.name === chipRegion[j].name).uId
            const q = query(collection(db, 'areas'), where('regionId', '==', ind), orderBy("createdDate", 'desc'), endBefore(firstVisibleArea), limitToLast(limitArea))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
              items.push({ uid: doc.id, ...doc.data() })
            })
            let i = 1;
            items.forEach(async (obj) => {
              if (obj.stateId) {
                const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.sName = doc.data().name
                })
              }
              if (obj.regionId) {
                const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.rName = doc.data().name
                })
              }
              if (obj.countryId) {
                const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                  obj.cName = doc.data().name
                })
                if (i === items.length) {
                  setArea(items)
                  area = items

                } else {
                  i++;
                }
              }
            })
            updateArea(querySnapshot)
          }
        }
        else {
          const q = query(collection(db, 'areas'), orderBy("createdDate", 'desc'), endBefore(firstVisibleArea), limitToLast(limitArea))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            items.push({ uid: doc.id, ...doc.data() })
          })
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.stateId) {
              const q = query(collection(db, 'states'), where('uId', '==', obj.stateId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.sName = doc.data().name
              })
            }
            if (obj.uid) {
              const obj1 = {
                uId: obj.uid
              }
              const dataRef = doc(db, 'regions', obj.uid)
              await setDoc(dataRef, obj1, { merge: true });
            }
            if (obj.regionId) {
              const q = query(collection(db, 'regions'), where('uId', '==', obj.regionId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.rName = doc.data().name
              })
            }
            if (obj.countryId) {
              const q = query(collection(db, 'countries'), where('uId', '==', obj.countryId))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                obj.cName = doc.data().name
              })
              if (i === items.length) {
                setArea(items)
                area = items

              } else {
                i++;
              }
            }
          })
          updateArea(querySnapshot)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  // update pagination change for area
  const updateArea = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisibleArea(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisibleArea(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in area page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleAreaLimitChange = (event: { target: { value: string; }; }) => {
    setPageArea(0);
    setLimitArea(parseInt(event.target.value));
    limitArea = parseInt(event.target.value)
    getArea()
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <Helmet>
        <title> Region | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt="-7px" >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit">Region</Typography>
              </Breadcrumbs>
              <Stack direction="row" spacing={1}>
                <div className="largeScreenContent">
                  <Button
                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                    variant="contained"

                    startIcon={<Iconify icon="ic:baseline-cloud-download" />}>
                    <CSVLink {...csvlink} style={{ color: "white", textDecoration: "none" }}>Download</CSVLink>
                  </Button>
                </div>
                <div className="smallScreenContent">
                  <Tooltip title="Download" >
                    <Button
                      sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                      variant="contained"
                    //   onClick={handleClickisAdd}
                    >
                      <CSVLink {...csvlink} style={{ color: "white", textDecoration: "none", lineHeight: "0.04" }}><CloudDownloadIcon /></CSVLink>
                    </Button>
                  </Tooltip>
                </div>
                <div className="largeScreenContent">
                  <input
                    style={{
                      display: 'none',
                    }}
                    ref={inputFile}
                    accept=".csv"
                    type="file"
                    onChange={handleFile} />
                  <Button
                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                    variant="contained"
                    startIcon={<Iconify icon="ic:baseline-cloud-upload" />}
                    onClick={handleClick}
                  >
                    Upload
                  </Button>
                </div>
                <div className="smallScreenContent">
                  <input
                    style={{
                      display: 'none',
                    }}
                    ref={inputFile}
                    accept=".csv"
                    type="file"
                    onChange={handleFile} />
                  <Button
                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                    variant="contained"
                    onClick={handleClick}
                  >
                    <CloudUploadIcon />
                  </Button>
                </div>
              </Stack>
            </Stack>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', mt: "-7px" }}>

              <Grid container>
                <Grid item lg={3} md={3} sm={6} xs={12} sx={{ borderRight: { xs: "0px black solid", sm: "1px black solid", md: "1px black solid", lg: "1px black solid" }, overflow: "hidden" }}>
                  <div>
                    {isLoading ? <Loader title="Loading..." /> :
                      <>
                        <TableContainer className="country-tableContainer-list" >
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ color: '#212B36' }}>Country</TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    // sx={{ color: '#1565c0' }}
                                    color="inherit"
                                    size="small"
                                    onClick={handleClickisAddCountry}
                                  >
                                    <AddIcon fontSize="small" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {country.map((arr: any, index: number) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const isItemSelected = isSelected(arr.name);
                                return (
                                  <TableRow key={index}
                                    hover

                                    role="checkbox"
                                    aria-checked
                                    tabIndex={-1}
                                    sx={{ cursor: "pointer" }}
                                    selected={isItemSelected}
                                  >
                                    <TableCell sx={{ color: '#212B36' }}
                                      onClick={(event) => handleCountry(event, arr.name)}>
                                      <Typography variant="subtitle2" noWrap>
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                        />
                                        {arr.name}
                                      </Typography></TableCell>
                                    <TableCell align="right" style={{ display: "flex" }}>
                                      <Tooltip title={(arr.isActive === 1) ? "Active" : "Inactive"} arrow
                                      >
                                        <Switch
                                          checked={arr.isActive === 0 ? false : true}
                                          onClick={(e) => { handleSwitchCountry(arr.uId, arr.isActive) }}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          sx={{ color: '#1565c0' }}
                                        />
                                      </Tooltip>
                                      <IconButton
                                        sx={{ color: '#1565c0' }}
                                        color="inherit"
                                        size="small"
                                        onClick={(e) => handleClickisEditCountry(arr)}
                                      >
                                        <EditTwoToneIcon fontSize="small" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination className="MuiTablePagination-selectLabel"
                          component="div"
                          count={row}
                          onPageChange={handleCountryChangePage}
                          onRowsPerPageChange={handleCountryLimitChange}
                          page={page}
                          rowsPerPage={limits}
                          rowsPerPageOptions={[10, 20, 30, 40]}
                        />
                      </>
                    }
                  </div>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} sx={{ borderRight: { xs: "0px black solid", sm: "0px black solid", md: "1px black solid", lg: "1px black solid" } }}>
                  <div>
                    {isLoading1 ? <Loader title="Loading..." /> :
                      <>
                        <TableContainer className="country-tableContainer-list">
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ color: '#212B36' }}>State</TableCell>
                                <TableCell align="right">
                                  <Button
                                    // sx={{ color: '#1565c0' }}
                                    color="inherit"
                                    size="small"
                                    variant="contained"
                                    onClick={handleClickisAddState}
                                  >
                                    <AddIcon fontSize="small" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                              {selectCountry.length ?
                                <TableCell align="center" colSpan={2}>
                                  {selectCountry.map((a: any) => (
                                    <Chip color="primary" size="small" label={a + ""} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDelete(a)}
                                    />
                                  ))}
                                </TableCell>
                                : ""}
                            </TableHead>
                            <TableBody>
                              {state.map((arr: any, index: number) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const isItemSelected = isSelectedState(arr.name);
                                return (
                                  <TableRow hover

                                    role="checkbox"
                                    aria-checked
                                    tabIndex={-1}
                                    sx={{ cursor: "pointer" }}
                                    selected={isItemSelected}
                                    key={index}>
                                    <TableCell sx={{ color: '#212B36' }} onClick={(event) => handleState(event, arr.name)}>
                                      <Typography variant="subtitle2" noWrap>
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                        />
                                        {arr.name}</Typography><Typography sx={{ color: "grey", fontSize: "12px", marginLeft: "39px", marginTop: "-15px" }} variant="body2"> ({arr.cName})</Typography>

                                    </TableCell>
                                    <TableCell align="right" style={{ display: "flex", padding: "25px" }}>
                                      <Tooltip title={(arr.isActive === 1) ? "Active" : "Inactive"} arrow>
                                        <Switch
                                          checked={arr.isActive === 0 ? false : true}
                                          onClick={(e) => { handleSwitchState(arr.uId, arr.isActive) }}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          sx={{ color: '#1565c0' }}
                                        />
                                      </Tooltip>
                                      <IconButton
                                        sx={{ color: '#1565c0' }}
                                        color="inherit"
                                        size="small"
                                        onClick={(e) => handleClickisEditState(arr)}
                                      >
                                        <EditTwoToneIcon fontSize="small" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination className="MuiTablePagination-selectLabel"
                          component="div"
                          count={rowState}
                          onPageChange={handleStateChangePage}
                          onRowsPerPageChange={handleStateLimitChange}
                          page={pageState}
                          rowsPerPage={limitState}
                          rowsPerPageOptions={[10, 20, 30, 40]}
                        />
                      </>
                    }
                  </div>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} sx={{ borderRight: { xs: "0px black solid", sm: "1px black solid", md: "1px black solid", lg: "1px black solid" } }}>
                  <div>
                    {isLoading2 ? <Loader title="Loading..." /> :
                      <>
                        <TableContainer className="country-tableContainer-list">
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ color: '#212B36' }} align="left">Region/City</TableCell>
                                <TableCell align="right">
                                  <Button
                                    // sx={{ color: '#1565c0' }}
                                    color="inherit"
                                    size="small"
                                    variant="contained"
                                    onClick={handleClickisAddRegion}
                                  >
                                    <AddIcon fontSize="small" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                              {selectState.length > 0 || selectCountry.length > 0 ?
                                <TableCell style={{ backgroundColor: "#F4F6F8", padding: "17px" }} align="center" colSpan={2}>
                                  {selectCountry.map((a: any) => (
                                    <Chip color="primary" size="small" label={a + ""} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDelete(a)}
                                    />
                                  ))}
                                  {selectState.map((a: any) => (
                                    <Chip color="primary" size="small" label={a + ""} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDeleteState(a)}
                                    />
                                  ))}
                                </TableCell>
                                : ""}
                            </TableHead>
                            <TableBody>
                              {region.map((arr: any, index: number) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const isItemSelected = isSelectedRegion(arr.name);
                                return (
                                  <TableRow key={index} hover

                                    role="checkbox"
                                    aria-checked
                                    tabIndex={-1}
                                    sx={{ cursor: "pointer" }}
                                    selected={isItemSelected}>
                                    <TableCell sx={{ color: '#212B36' }} onClick={(event) => handleRegion(event, arr.name)}>
                                      <Typography variant="subtitle2" noWrap>
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                        />{arr.name} </Typography>
                                      <Typography sx={{ color: "grey", fontSize: "12px", marginLeft: "39px", marginTop: "-15px" }} variant="body2">  ({arr.cName} {arr.sName})
                                      </Typography></TableCell>
                                    <TableCell align="right" style={{ display: "flex", padding: "30px" }}>
                                      <Tooltip title={(arr.isActive === 1) ? "Active" : "Inactive"} arrow>
                                        <Switch
                                          checked={arr.isActive === 0 ? false : true}
                                          onClick={(e) => { handleSwitchRegion(arr.uId, arr.isActive) }}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          sx={{ color: '#1565c0' }}
                                        />
                                      </Tooltip>
                                      <IconButton
                                        sx={{ color: '#1565c0' }}
                                        color="inherit"
                                        size="small"
                                        onClick={(e) => handleClickisEditRegion(arr)}
                                      >
                                        <EditTwoToneIcon fontSize="small" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination className="MuiTablePagination-selectLabel"
                          component="div"
                          count={rowRegion}
                          onPageChange={handleRegionChangePage}
                          onRowsPerPageChange={handleRegionLimitChange}
                          page={pageRegion}
                          rowsPerPage={limitRegion}
                          rowsPerPageOptions={[10, 20, 30, 40]}
                        />
                      </>
                    }
                  </div>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <div>
                    {isLoading3 ? <Loader title="Loading..." /> :
                      <>
                        <TableContainer className="country-tableContainer-list">
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ color: '#212B36' }}>Area/Town/Village</TableCell>

                                <TableCell align="right">
                                  <Button
                                    // sx={{ color: '#1565c0' }}
                                    color="inherit"
                                    size="small"
                                    variant="contained"
                                    onClick={handleClickisAddArea}
                                  >
                                    <AddIcon fontSize="small" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                              {selectCountry.length > 0 || selectState.length > 0 || selectRegion.length > 0 ?
                                <TableCell style={{ backgroundColor: "#F4F6F8", padding: "17px" }} align="center" colSpan={2}>
                                  {selectCountry.map((a: any) => (
                                    <Chip color="primary" size="small" label={a + ""} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDelete(a)}
                                    />
                                  ))}
                                  {selectState.map((a: any) => (
                                    <Chip color="primary" size="small" label={a + ""} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDeleteState(a)}
                                    />
                                  ))}
                                  {selectRegion.map((a: any) => (
                                    <Chip color="primary" size="small" label={a + ""} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDeleteRegion(a)}
                                    />
                                  ))}
                                </TableCell>
                                : ""}
                            </TableHead>
                            <TableBody>
                              {area.map((arr: any, index: number) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const isItemSelected = isSelectedArea(arr.name);
                                return (
                                  <TableRow key={index} hover

                                    role="checkbox"
                                    aria-checked
                                    tabIndex={-1}
                                    sx={{ cursor: "pointer" }}
                                    selected={isItemSelected}>
                                    <TableCell sx={{ color: '#212B36' }} onClick={(event) => handleArea(event, arr.name)}>
                                      <Typography variant="subtitle2" noWrap>
                                        <Checkbox
                                          color="primary"
                                          checked={isItemSelected}
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                        />
                                        {arr.name}</Typography> <Typography sx={{ color: "grey", fontSize: "12px", marginLeft: "39px", marginTop: "-15px" }} variant="body2"> ({arr.cName} {arr.sName} {arr.rName})
                                      </Typography></TableCell>
                                    <TableCell align="right" style={{ display: "flex", padding: "30px" }}>
                                      <Tooltip title={(arr.isActive === 1) ? "Active" : "Inactive"} arrow>
                                        <Switch
                                          checked={arr.isActive === 0 ? false : true}
                                          onClick={(e) => { handleSwitchArea(arr.uId, arr.isActive) }}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          sx={{ color: '#1565c0' }}
                                        />
                                      </Tooltip>
                                      <IconButton
                                        sx={{ color: '#1565c0' }}
                                        color="inherit"
                                        size="small"
                                        onClick={(e) => handleClickisEditArea(arr)}
                                      >
                                        <EditTwoToneIcon fontSize="small" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination className="MuiTablePagination-selectLabel"
                          component="div"
                          count={rowArea}
                          onPageChange={handleAreaChangePage}
                          onRowsPerPageChange={handleAreaLimitChange}
                          page={pageArea}
                          rowsPerPage={limitArea}
                          rowsPerPageOptions={[10, 20, 30, 40]}
                        />
                      </>
                    }
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <div>
        <Dialog open={isCheckCountry} onClose={handleCloseSwitch}>
          <DialogTitle>{(Countrydata.isActive === 1) ? 'Active' : 'InActive'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {(Countrydata.isActive === 0) ? 'Are you sure you want to Active this Country?' : 'Are you sure you want to InActive this Country?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSwitch}>Cancel</Button>
            <Button onClick={handleCheckSwitchCountry}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog open={isCheckState} onClose={handleCloseSwitch}>
          <DialogTitle>{(Countrydata.isActive === 1) ? 'Active' : 'InActive'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {(Countrydata.isActive === 0) ? 'Are you sure you want to Active this State?' : 'Are you sure you want to InActive this State?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSwitch}>Cancel</Button>
            <Button onClick={handleCheckSwitchState}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog open={isCheckRegion} onClose={handleCloseSwitch}>
          <DialogTitle>{(Countrydata.isActive === 1) ? 'Active' : 'InActive'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {(Countrydata.isActive === 0) ? 'Are you sure you want to Active this Region?' : 'Are you sure you want to InActive this Region?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSwitch}>Cancel</Button>
            <Button onClick={handleCheckSwitchRegion}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog open={isCheckArea} onClose={handleCloseSwitch}>
          <DialogTitle>{(Countrydata.isActive === 1) ? 'Active' : 'InActive'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {(Countrydata.isActive === 0) ? 'Are you sure you want to Active this Area?' : 'Are you sure you want to InActive this Area?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSwitch}>Cancel</Button>
            <Button onClick={handleCheckSwitchArea}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>

      <>
        <Dialog open={isOpenCountry} onClose={handleCloseSwitch} fullWidth PaperProps={{ sx: { height: "40%" } }}>
          <DialogTitle>
            {Countrydata.countryId ? "Edit Country" : "Add Country"}
            <IconButton
              sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 8, top: 8 }}
              onClick={handleCloseSwitch}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Country"
              type="text"
              fullWidth
              variant="outlined"
              name="country"
              value={Countrydata.country}
              onChange={(arr) => { handleChange(arr); validateCountryName(arr) }}
              required={true}
            />
            <FormHelperText style={{ color: 'red', height: '22px' }}>{isCountryNameError && CountryNameErrorMsg}</FormHelperText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  // autoFocus
                  margin="dense"
                  id="name"
                  label="Iso Code2"
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="isocode2"
                  value={Countrydata.isocode2}
                  onChange={(arr) => { handleChange(arr); validateIsocode2(arr) }}
                // maxLength={2}
                // required={true}
                />
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isCode2Error && Code2ErrorMsg}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  // autoFocus
                  margin="dense"
                  id="name"
                  label="Iso Code3"
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="isocode3"
                  value={Countrydata.isocode3}
                  onChange={(arr) => { handleChange(arr); validateIsocode3(arr) }}
                // maxLength={2}
                // required={true}
                />
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isCode3Error && Code3ErrorMsg}</FormHelperText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCountrySave}>Save</Button>
          </DialogActions>
        </Dialog>
      </>

      <>
        <Dialog open={isOpenState} onClose={handleCloseSwitch} fullWidth>
          <DialogTitle>
            {Countrydata.stateId ? "Edit State" : "Add State"}
            <IconButton
              sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 8, top: 8 }}
              onClick={handleCloseSwitch}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="country"
                value={Countrydata.country}
                label="Country"
                onChange={(e) => { { handleChange(e); validateCountrydropdown(e) } }}
              >
                {countryAll.map((arr: any) => (
                  <MenuItem key={arr.uId} value={arr.uId}>{arr.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText style={{ color: 'red', height: '22px' }}>{isCountryNameError && CountryNameErrorMsg}</FormHelperText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="State"
              type="text"
              fullWidth
              variant="outlined"
              name="state"
              value={Countrydata.state}
              onChange={(arr) => { handleChange(arr); validateStateName(arr) }}
              required={true}
            />
            <FormHelperText style={{ color: 'red', height: '22px' }}>{isStateNameError && StateNameErrorMsg}</FormHelperText>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  // autoFocus
                  margin="dense"
                  id="name"
                  label="Iso Code2"
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="scode2"
                  value={Countrydata.scode2}
                  onChange={(arr) => { handleChange(arr); validateIsocode2(arr) }}
                // required={true}
                />
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isCode2Error && Code2ErrorMsg}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  // autoFocus
                  margin="dense"
                  id="name"
                  label="Iso Code3"
                  type="text"
                  fullWidth
                  variant="outlined"
                  name="scode3"
                  value={Countrydata.scode3}
                  onChange={(arr) => { handleChange(arr); validateIsocode3(arr) }}
                // required={true}
                />
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isCode3Error && Code3ErrorMsg}</FormHelperText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveState}>Save</Button>
          </DialogActions>
        </Dialog>
      </>

      <>
        <Dialog open={isOpenRegion} onClose={handleCloseSwitch} fullWidth PaperProps={{ sx: { height: "40%" } }}>
          <DialogTitle>
            {Countrydata.regionId ? "Edit Region" : "Add Region"}
            <IconButton
              sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 8, top: 8 }}
              onClick={handleCloseSwitch}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    value={Countrydata.country}
                    label="Country"
                    onChange={(e) => { { handleChange1(e); getStateDropDownData(e); validateCountrydropdown(e) } }}
                  >
                    {countryAll.map((arr: any) => (
                      <MenuItem key={arr.name} value={arr.uId}>{arr.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isCountryNameError && CountryNameErrorMsg}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="state"
                    value={Countrydata.state}
                    label="State"
                    onChange={(e) => { { handleChange(e); validateStatedropdown(e) } }}
                  >
                    {stateDropdown.map((arr: any) => (
                      <MenuItem key={arr.name} value={arr.uId}>{arr.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isStateNameError && StateNameErrorMsg}</FormHelperText>
              </Grid>
            </Grid>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Region"
              type="text"
              fullWidth
              variant="outlined"
              name="region"
              value={Countrydata.region}
              onChange={(arr) => { handleChange(arr); validateRegionName(arr) }}
              required={true}
            />
            <FormHelperText style={{ color: 'red', height: '22px' }}>{isRegionNameError && RegionNameErrorMsg}</FormHelperText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveRegion}>Save</Button>
          </DialogActions>
        </Dialog>
      </>

      <>
        <Dialog open={isOpenArea} onClose={handleCloseSwitch} fullWidth>
          <DialogTitle>
            {Countrydata.areaId ? "Edit Area" : "Add Area"}
            <IconButton
              sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 8, top: 8 }}
              onClick={handleCloseSwitch}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={4} sm={4} xs={12}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="country"
                    value={Countrydata.country}
                    label="Country"
                    onChange={(e) => { { handleChange1(e); getStateDropDownData(e); validateCountrydropdown(e) } }}
                  >
                    {countryAll.map((arr: any) => (
                      <MenuItem key={arr.name} value={arr.uId}>{arr.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isCountryNameError && CountryNameErrorMsg}</FormHelperText>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="state"
                    value={Countrydata.state}
                    label="State"
                    onChange={(e) => { { handleChange2(e); getRegionDropDownData(e); validateStatedropdown(e) } }}
                  >
                    {stateDropdown.map((arr: any) => (
                      <MenuItem key={arr.name} value={arr.uId}>{arr.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isStateNameError && StateNameErrorMsg}</FormHelperText>
              </Grid>
              <Grid item md={4} sm={4} xs={12} sx={{ mt: 1 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Region</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="region"
                    value={Countrydata.region}
                    label="Region"
                    onChange={(e) => { { handleChange(e); validateRegiondropdown(e) } }}
                  >
                    {regionDropdown.map((arr: any) => (
                      <MenuItem key={arr.name} value={arr.uId}>{arr.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red', height: '22px' }}>{isRegionNameError && RegionNameErrorMsg}</FormHelperText>
              </Grid>
            </Grid>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Area"
              type="text"
              fullWidth
              variant="outlined"
              name="area"
              value={Countrydata.area}
              onChange={(arr) => { handleChange(arr); validateAreaName(arr) }}
              required={true}
            />
            <FormHelperText style={{ color: 'red', height: '22px' }}>{isAreaNameError && AreaNameErrorMsg}</FormHelperText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Pincode"
              type="number"
              fullWidth
              variant="outlined"
              name="pincode"
              value={Countrydata.pincode}
              onChange={(arr) => { handleChange(arr); validatePincode(arr) }}
              required={true}
            />
            <FormHelperText style={{ color: 'red', height: '22px' }}>{pincodemsg && pincodeError}</FormHelperText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveArea}>Save</Button>
          </DialogActions>
        </Dialog>
      </>

    </div >
  )
}
