import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  Box,
  Grid,
  TableBody, Breadcrumbs, Paper
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { useParams, Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { ToWords } from 'to-words';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './../firebase';
import Loader from './spinner';
import Label from '../components/label';
import { format } from 'date-fns';

export default function OrdersDetailPage() {
  const [detail, setDetail] = useState<any>([]) //show data inside page
  const [orderDetail, setOrderDetail] = useState<any>([]) //show data inside page
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState<any>([])
  const [flag, setFlag] = useState<boolean>(true);
  const id = useParams();
  const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
    }
  });

  // it is the first method invoked when this page is open
  useEffect(() => {
    getData()
    getOrderDetail()
    let order = sessionStorage.getItem('orderNo');
    if (order) {
      setOrderData(order)
    }
  }, [])

  //call intially
  const getData = async () => {
    let items: any[] = []
    setIsLoading(true)
    try {
      const q = query(collection(db, 'userOrders'), where('id', '==', id.id))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        let itm = doc.data();
        let obj = {
          makerId: doc.data().makerId,
          seekerId: doc.data().seekerId
        }
        if (obj.makerId) {
          const q = query(collection(db, 'users'), where('uid', '==', obj.makerId))
          const _querySnapshot1 = await getDocs(q)
          _querySnapshot1.forEach((_doc) => {
            itm.makerDisplayName = _doc.data().displayName
          })
        }
        if (obj.seekerId) {
          const q = query(collection(db, 'users'), where('uid', '==', obj.seekerId))
          const _querySnapshot1 = await getDocs(q)
          _querySnapshot1.forEach((_doc) => {
            itm.seekerDisplayName = _doc.data().displayName
          })
        }
        items.push(itm)
        setDetail(items)
        if (items.length > 0) {
          setFlag(true);
        } else {
          setFlag(false);
        }
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  //call intially
  const getOrderDetail = async () => {
    let items: any[] = [];
    const q = query(collection(db, 'userOrders'), where('id', '==', id.id))
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: any) => {
      if (doc.data() && doc.data().orderDetail && doc.data().orderDetail.length) {
        let res = doc.data().orderDetail.length;
        for (let i = 0; i < res; i++) {
          items.push(doc.data().orderDetail[i])
        }
      }
    })
    setOrderDetail(items)
  }

  return (
    <>
      <Helmet>
        <title> OrderDetails | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack alignItems="left" justifyContent="space-between" mb={5} >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Link
                  to="/admin/orders"
                  style={{ display: 'flex', color: "black", textDecoration: "none" }}
                >
                  Orders
                </Link>
                <Typography color="inherit">{orderData}</Typography>
              </Breadcrumbs>
            </Stack>
            <Card className="card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', backgroundColor: "white", overflowY: 'auto' }}>
              <div>
                {isLoading ? <Loader title="Loading..." /> :
                  <>{
                    flag === true
                      ?
                      <>
                        <Scrollbar>
                          {detail.map((data: any) => (
                            <>
                              <Grid container style={{ backgroundColor: "white" }}>
                                <Grid item xs={12} sm={6} md={6} >
                                  <Card sx={{ mt: '24px', ml: '24px', bgcolor: '#F9FAFB', boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', overflow: 'auto', height: '300px' }}>
                                    <Typography gutterBottom >
                                      <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Order Number<Box sx={{ pl: 5.8, color: 'grey' }}>{data.orderNo}</Box></Box> </h5>
                                    </Typography>
                                    <Typography gutterBottom >
                                      <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Order Date<Box sx={{ pl: 8.5, color: 'grey' }}>{format(new Date(data.createdDate.seconds * 1000), formatedDate)}</Box></Box></h5>
                                    </Typography>
                                    <Typography gutterBottom >
                                      <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Order Type <Box sx={{ pl: 8.5, color: 'grey' }}>{data.isBulkOrder === 0 ? 'Single Order' : 'Bulk Order'}</Box></Box></h5>
                                    </Typography>
                                    <Typography gutterBottom >
                                      <h5><Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}> Food Seeker <Box sx={{ pl: 7.2, color: 'grey' }}>{data.seekerDisplayName ? data.seekerDisplayName : data.seekerName}</Box></Box></h5>
                                    </Typography>
                                    <Typography gutterBottom >
                                      <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Food Maker <Box sx={{ pl: 8, color: 'grey' }}>{data.makerDisplayName ? data.makerDisplayName : data.makerName}</Box></Box></h5>
                                    </Typography>
                                    <Typography gutterBottom >
                                      <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Status <Box sx={{ pl: 12, color: 'grey' }}>
                                        {data.orderStatus === 'Confirmed' && <Label sx={{ color: "white", backgroundColor: "#919eab", width: '77px' }}>{data.orderStatus}</Label>}
                                        {data.orderStatus === 'Delivered' && <Label sx={{ color: "white", backgroundColor: "#53bc5b", width: '77px' }}>{data.orderStatus}</Label>}
                                        {data.orderStatus === 'Shipped' && <Label sx={{ color: "white", backgroundColor: "#1565c0", width: '77px' }}>{data.orderStatus}</Label>}
                                        {data.orderStatus === 'Processed' && <Label sx={{ color: "white", backgroundColor: "#fcde2a", width: '77px' }}>{data.orderStatus}</Label>}
                                        {data.orderStatus === 'Cancelled' && <Label sx={{ color: "white", backgroundColor: "#e20700", width: '77px' }}>{data.orderStatus}</Label>}
                                        {data.orderStatus === 'Pending' && <Label sx={{ color: "white", backgroundColor: "#fc7b2a", width: '77px' }}>{data.orderStatus}</Label>}
                                      </Box></Box></h5>
                                    </Typography>
                                  </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} >
                                  <Card sx={{ ml: '24px', mt: '24px', mr: '24px', bgcolor: '#F9FAFB', boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', overflow: 'auto', height: '300px' }}>
                                    <Typography gutterBottom >
                                      <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>User Preference <Box sx={{ pl: 5.8, color: 'grey' }}>{data.userPreference ? data.userPreference : "--"}</Box></Box></h5>
                                    </Typography>
                                    <Typography gutterBottom >
                                      <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Phone Number <Box sx={{ pl: 6.8, color: 'grey' }}>{data.seekerContactNo}</Box></Box></h5>
                                    </Typography>
                                    <Typography gutterBottom >
                                      <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Special Instructions <Box sx={{ pl: 2.8, color: 'grey' }}>{data.specialinstructions ? data.specialinstructions : '--'}</Box></Box></h5>
                                    </Typography>
                                    {data.deliveryAddress.addressLine1 && data.deliveryAddress.addressLine2 ?
                                      <Typography gutterBottom >
                                        <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Delivery Address <Box sx={{ pl: 5.4, color: 'grey' }}>{data.deliveryAddress.addressLine1 ? data.deliveryAddress.addressLine1 : '--'},{data.deliveryAddress.addressLine2 ? data.deliveryAddress.addressLine2 : "--"}</Box></Box></h5>
                                      </Typography>
                                      :
                                      <Typography gutterBottom >
                                        <h5> <Box display="flex" alignItems="flex-start" sx={{ pl: 2, pr: 2 }}>Delivery Address <Box sx={{ pl: 5.4, color: 'grey' }}>--</Box></Box></h5>
                                      </Typography>
                                    }
                                  </Card>
                                </Grid>
                              </Grid>
                              <div style={{ backgroundColor: 'white', padding: '24px' }}>
                                <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                  <TableContainer style={{ backgroundColor: 'white' }}>
                                    <Table>
                                      <TableHead> <TableRow >
                                        <TableCell align="left">#</TableCell>
                                        <TableCell align="left">Product</TableCell>
                                        <TableCell align="left">Rate</TableCell>
                                        <TableCell align="left" >Quantity</TableCell>
                                        <TableCell align="right" >Amount</TableCell>
                                      </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {orderDetail.map((arr: any, index: number) => (
                                          <TableRow hover key={arr.id}>
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell align="left"> {arr.productName}</TableCell>
                                            <TableCell align="left">₹ {arr.grossAmout} </TableCell>
                                            <TableCell align="left">{arr.quantity}</TableCell>
                                            <TableCell align="right">₹ {arr.totalAmount} </TableCell>
                                          </TableRow>
                                        ))}
                                         <TableRow>
                                          <TableCell rowSpan={3} />
                                          <TableCell rowSpan={3} />
                                          <TableCell colSpan={2}>Tax</TableCell>
                                          <TableCell align="right" sx={{ fontWeight: "bold", color: "green" }}>₹ {data.taxAmount}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={2}>Total Amount</TableCell>
                                          <TableCell align="right" sx={{ fontWeight: "bold", color: "green" }}>₹ {data.totalNetAmount}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={2} >Amount in words</TableCell>
                                          <TableCell align="right" sx={{ fontWeight: "bold", color: "green" }}>{toWords.convert(data.totalNetAmount)}</TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Card>
                              </div>
                            </>
                          ))}
                        </Scrollbar>
                      </>
                      :
                      <Paper
                        sx={{
                          width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                        }}
                      >
                        <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                        <Typography variant="h6" paragraph>
                          Data not Available
                        </Typography>
                      </Paper>
                  }
                  </>
                }
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container >
    </>
  );
}
