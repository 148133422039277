import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { collection, doc, endBefore, getCountFromServer, getDocs, limit, limitToLast, orderBy, query, setDoc, startAfter, Timestamp, updateDoc, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import { ToastContainer } from 'react-toastify';
import { Button, Card, Container, Grid } from '@mui/material';
import Loader from '../../spinner';
import './VerifyEmail.css'

const VerifyEmail = () => {
    const parameter = useParams();
    const navigate = useNavigate();
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

    useEffect(() => {
        debugger;
        if (parameter && parameter.type && parameter.token && parameter.userId) {
            let type: string = parameter.type;
            let userId: string = parameter.userId;
            let token: string = parameter.token;
            //Compare Token To user Collection With isEmailVerified Field = false
            verifyToken(token, type, userId)
        }
    }, []);


    const verifyToken = async (token: string, type: string, userId: string) => {
        debugger;
        setIsLoading(true);
        try {
            const usersCollectionRef = collection(db, 'users');
            let currentDate = Timestamp.fromDate(new Date());
            const q = query(usersCollectionRef, where('emailToken', '==', token), where('isEmailVerified', '==', false), where('uid', '==', userId), where('tokenExpiryDate', '>=', currentDate))
            // , where('tokenExpiryDate', '<=', currentDate)
            const querySnapshot = await getDocs(q);
            let items: any[] = [];
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            if (items && items.length > 0) {
                for (let i = 0; i < items.length; i++) {

                    // const obj = {
                    //     isEmailVerified: true,
                    //     tokenExpiryDate: Timestamp.fromDate(new Date(new Date().setDate(-1))),
                    // }
                    const obj={
                        userId:items[i].uid
                    }
                    // const dataRef = doc(db, 'users', items[i].uid)
                    // await setDoc(dataRef, obj, { merge: true });
                    try {

                        var url = process.env.REACT_APP_VERIFY_USER_EMAIL;
                        if (url) {
                            const response = await fetch(url, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(obj)
                            });
                            const responseData = await response.json();
                            console.log(responseData)
                            //return responseData; // return the response data
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
                setIsTokenValid(true);
            } else {
                setIsTokenValid(false);
            }
        } catch (error) {
            setIsTokenValid(false);
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    const redirectToApp = () => {
        //navigate(-1);
        //window.close();
        //window.location.href = 'about:blank';
        if (parameter && parameter.type && parameter.token) {
            //     let type: string = parameter.type;
            //     window.open('https://', '_blank');
            if (parameter.type == "seeker") {
                window.open('https://vyanjan.page.link/8A8r', '_blank');
                // window.open('https://vyanjan-b3af8.web.app/seeker-deeplink', '_blank');
            } else if (parameter.type == "maker") {
                window.open('https://vyanjanmaker.page.link/Nh8k', '_blank');
                // window.open('https://vyanjan-b3af8.web.app/maker-deeplink', '_blank');
            }
        }
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={6000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Card style={{ height: '100vh' }} sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle', height: '100vh' }}>
                                <div style={{ margin: 'auto', textAlign: 'center' }}>
                                    {isLoading ? <Loader title="Loading..." /> :
                                        <>
                                            {isTokenValid ? <>
                                                Your email was successfully verified

                                                click on below button to redirect to App
                                                <br />
                                                <Button
                                                    className='btn-verify'
                                                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px', marginTop: '16px', backgroundColor: '#f68600' }}
                                                    variant="contained"
                                                    onClick={redirectToApp}>
                                                    Back To App
                                                </Button>
                                            </> : <>
                                                <div style={{ margin: 'auto' }}>
                                                    Your token was expired or invalid
                                                </div>
                                                <br />
                                                <Button
                                                    className='btn-verify'
                                                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px', marginTop: '16px', backgroundColor: '#f68600' }}
                                                    variant="contained"
                                                    onClick={redirectToApp}>
                                                    Back To App
                                                </Button>
                                            </>}
                                        </>
                                    }
                                </div>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default VerifyEmail