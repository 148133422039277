import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
// @mui
import { CardHeader, Box, Grid } from '@mui/material';
// components
import { useChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.any,
  subheader: PropTypes.any,
  chartData: PropTypes.array.isRequired,
};

export default function AppWebsiteVisits({ title, subheader, chartData, ...other }: any) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '50%' } },
    fill: { type: chartData.map((i: any) => i.fill) },
    xaxis: { categories: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'] },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        if (w.globals.initialSeries.length > 1) {
          var Amount = w.globals.initialSeries[0].data[dataPointIndex];
          var Count = w.globals.initialSeries[1].data[dataPointIndex];
          //return Amount+":"+Count
          return `
        <div style="padding:10px">
          <div>
            <span style="background-color: rgb(32, 101, 209);opacity:1;width:6px;height:6px; position:relative;top:0;margin-right:10px; border-radius:50%">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span style="font-size: 12px;">Sales</span>
            <span style="font-size: 12px;"><b>₹`+ Amount + `</b></span>
          </div>
          <div style="margin-top:10px">
            <span style="background-color:  rgb(255, 193, 7);opacity:1;width:6px;height:6px; position:relative;top:0;margin-right:10px; border-radius:50%">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span style="font-size: 12px;">Orders</span>
              <span style="font-size: 12px;"><b>`+ Count + `</b></span>
          </div>
        </div>`
        } else if (w.globals.initialSeries.length == 1) {
          var Amount = w.globals.initialSeries[0].data[dataPointIndex];
          //return Amount+":"+Count
          return `
          <div style="padding:10px">
            <div>
              <span style="background-color: rgb(32, 101, 209);opacity:1;width:6px;height:6px; position:relative;top:0;margin-right:10px; border-radius:50%">&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span style="font-size: 12px;">Admin Commission</span>
              <span style="font-size: 12px;"><b>₹`+ Amount + `</b></span>
            </div>
          </div>`
        }

      }
    }
    //   shared: true,
    //   intersect: false,
    //   y: {
    //     formatter: (y: number) => {
    //       if (typeof y !== 'undefined') {
    //         return `${'₹' + y.toFixed(0)}`;
    //       }
    //       return y;
    //     },
    //   },
    // },
  });

  return (
    <>
      <Grid container spacing={0} style={{ display: "flex", justifyContent: 'space-between' }}>
        <Box display="flex" alignItems="flex-start"><CardHeader sx={{ mt: 1.5 }} title={title} /></Box><Box sx={{ color: 'grey' }}><CardHeader subheader={subheader} /></Box>
      </Grid>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr" >
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </>
  );
}
