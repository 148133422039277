import {
    Avatar,
    Breadcrumbs,
    Button,
    Card,
    CardHeader,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Rating,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
    Grid,
    Tab,
    CardContent,
    Paper
} from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState, useEffect } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import HomeIcon from '@mui/icons-material/Home';
import { Link, useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { collection, doc, endBefore, getCountFromServer, getDocs, limit, limitToLast, orderBy, query, startAfter, Timestamp, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import Scrollbar from '../components/scrollbar';
import Loader from './spinner'
import Label from '../components/label';
import './FoodMakerPage.css';
import { format } from 'date-fns';
import VisibleIcon from '@mui/icons-material/Visibility';
import Iconify from '../components/iconify';

const initialState = {
    bankId: "",
    isVerified: ""
}

const FoodMakerDetails = () => {
    const [value, setValue] = React.useState('1');
    const [page, setPage] = useState<number>(0);
    let [limits, setLimits] = useState<number>(10);
    const [row, setRow] = useState<number>(10);
    const [pageReview, setPageReview] = useState<number>(0);
    let [limitReview, setLimitReview] = useState<number>(10);
    const [rowReview, setRowReview] = useState<number>(10);
    const [pageFoodReview, setPageFoodReview] = useState<number>(0);
    let [limitFoodReview, setLimitFoodReview] = useState<number>(10);
    const [rowFoodReview, setRowFoodReview] = useState<number>(10);
    const [pageReference, setPageReference] = useState<number>(0);
    let [limitReference, setLimitReference] = useState<number>(10);
    const [rowReference, setRowReference] = useState<number>(10);
    const [pageBulkOrder, setPageBulkOrder] = useState<number>(0);
    let [limitBulkOrder, setLimitBulkOrder] = useState<number>(10);
    const [rowBulkOrder, setRowBulkOrder] = useState<number>(10);
    const [pageWallet, setPageWallet] = useState<number>(0);
    let [limitWallet, setLimitWallet] = useState<number>(10);
    const [rowWallet, setRowWallet] = useState<number>(10);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [profile, setProfile] = React.useState<any>([]);
    const [orderReceived, setOrderReceived] = React.useState<any>([]);
    const [product, setProduct] = React.useState<any>([]);
    const [hoursOfOperation, setHoursOfOperation] = useState<any>([]);
    const [bankDetail, setBankDetail] = useState<any>([])
    const [reference, setReference] = useState<any>([])
    const [bulkOrder, setBulkOrder] = useState<any>([])
    const [wallet, setWallet] = useState<any>([])
    const [verify, setVerify] = useState<any>(initialState)
    const [reviews, setReviews] = useState<any>([])
    const [foodReviews, setFoodReviews] = useState<any>([])
    const [pay, setPay] = useState<any>([])
    const [userFirstName, setUserFirstName] = useState<any>([]);
    const [userLastName, setUserLastName] = useState<any>([]);
    const [totalAmount, setTotalAmount] = useState<any>([])
    const [lastVisible, setLastVisible] = useState<any>();
    const [firstVisible, setFirstVisible] = useState<any>();
    const [lastVisibleReview, setLastVisibleReview] = useState<any>();
    const [firstVisibleReview, setFirstVisibleReview] = useState<any>();
    const [lastVisibleFoodReview, setLastVisibleFoodReview] = useState<any>();
    const [firstVisibleFoodReview, setFirstVisibleFoodReview] = useState<any>();
    const [lastVisibleReference, setLastVisibleReference] = useState<any>();
    const [firstVisibleReference, setFirstVisibleReference] = useState<any>();
    const [lastVisibleBulkOrder, setLastVisibleBulkOrder] = useState<any>();
    const [firstVisibleBulkOrder, setFirstVisibleBulkOrder] = useState<any>();
    const [lastVisibleWallet, setLastVisibleWallet] = useState<any>();
    const [firstVisibleWallet, setFirstVisibleWallet] = useState<any>();
    const [flagProfile, setFlagProfile] = useState<boolean>(true);
    const [flagOrderReceived, setFlagOrderReceived] = useState<boolean>(true);
    const [flagBulkOrders, setFlagBulkOrders] = useState<boolean>(true);
    const [flagProducts, setFlagProducts] = useState<boolean>(true);
    const [flagBankDetail, setFlagBankDetail] = useState<boolean>(true);
    const [flagHoursOfOperation, setFlagHoursOfOperation] = useState<boolean>(true);
    const [flagWallet, setflagWallet] = useState<boolean>(true);
    const [flagReference, setflagReference] = useState<boolean>(true);
    const [flagReview, setflagReview] = useState<boolean>(true);
    const [flagProductReview, setflagProductReview] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const navigate = useNavigate()
    const id = useParams();
    const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

    //if click on view set this item in sessionStorage which used in detail page
    const handleClickVisible = (element: any) => {
        let id = element?.id;
        sessionStorage.setItem("orderNo", element?.orderNo + "");
        navigate(`/admin/orders/view/${id}`);
    }

    //send notification while switch inactive
    const sendFCMTokenInActive = async () => {
        let fcm = JSON.parse(JSON.stringify(profile));
        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            body: JSON.stringify({
                "registration_ids": [fcm[0].fcmToken],
                "notification": {
                    "title": "InActive",
                    "body": "Your BankAccount is Deactivated",
                    "sound": "default",
                    "color": "#ff3296fa",
                    "vibrate": "300",
                    "priority": "high",
                    "screenNo": 12,
                },
            }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'key=AAAAvT050EY:APA91bHOLzULyLPZo2AVnwLcgzMr5oah-gf6NH1eQLupY3RGR6nAkBjBdMX81LaRYeF3Dub26OCA-TCDN7-NMZktmcRt8DtcThJB9EjjEGbLIuHTwMmcbiGWyEa2fsOVRVkuVp6wQypZ'
            }
        }).then((response) => {
            return response.json
        }).catch((error) => {
            console.log(error)
        })
    }

    //send notification while switch active
    const sendFCMTokenActive = async () => {
        let fcm = JSON.parse(JSON.stringify(profile));
        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            body: JSON.stringify({
                "registration_ids": [fcm[0].fcmToken],
                "notification": {
                    "title": "Active",
                    "body": "Your bankAccount is Activated",
                    "sound": "default",
                    "color": "#ff3296fa",
                    "vibrate": "300",
                    "priority": "high",
                    "screenNo": 12,
                },
            }),
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': 'key=AAAAvT050EY:APA91bHOLzULyLPZo2AVnwLcgzMr5oah-gf6NH1eQLupY3RGR6nAkBjBdMX81LaRYeF3Dub26OCA-TCDN7-NMZktmcRt8DtcThJB9EjjEGbLIuHTwMmcbiGWyEa2fsOVRVkuVp6wQypZ'
            }
        }).then((response) => {
            return response.json
        }).catch((error) => {
            console.log(error)
        })
    }

    // close switch dailog
    const handleCloseSwitch = () => {
        setIsCheck(false);
    }

    // call when switch change ans save
    const handleCheckSwitch = async () => {
        setIsLoading(true)
        try {
            if (verify.isVerified === true) {
                sendFCMTokenInActive();
                const obj = {
                    isVerified: false
                }
                const verifyStatus = doc(db, 'bankDetail', verify.bankId)
                updateDoc(verifyStatus, obj)
                getBankDetail();
            } else if (verify.isVerified === false) {
                sendFCMTokenActive();
                const obj = {
                    isVerified: true
                }
                const verifyStatus = doc(db, 'bankDetail', verify.bankId)
                updateDoc(verifyStatus, obj)
                getBankDetail();
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
        setIsLoading(false);
        setIsCheck(false);
    };

    // call when bank detail tab is clicked
    const getBankDetail = async () => {
        const items: any[] = [];
        setIsLoading(true);
        try {
            const bankCollectionRef = collection(db, 'bankDetail');
            const q = query(bankCollectionRef, where('makerId', '==', id.uid))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            setBankDetail(items);
            if (items.length > 0) {
                setFlagBankDetail(true);
            } else {
                setFlagBankDetail(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    // it is the first method invoked when this page is open
    useEffect(() => {
        getData();
        paymentViaPayoutPeriod();
        let firstName = sessionStorage.getItem('FirstName');
        let lastName = sessionStorage.getItem('LastName');
        if (firstName && lastName) {
            setUserFirstName(firstName)
            setUserLastName(lastName)
        }
    }, [])

    //call initially
    const getData = async () => {
        const items: any[] = [];
        setIsLoading(true);
        try {
            const usersCollectionRef = collection(db, 'users');
            const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', id.uid))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            setProfile(items);

            if (items.length > 0) {
                setFlagProfile(true);
            } else {
                setFlagProfile(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    //call initially
    const paymentViaPayoutPeriod = async () => {
        let items: any[] = [];
        let items1: any[] = [];
        let data: any[] = [];
        setIsLoading(true);
        try {
            const usersCollectionRef = collection(db, 'users');
            const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', id.uid))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            });

            if (items[0].payoutReleasePeriod.payoutPeriod === 'Monthly') {
                const date = new Date();
                let monthFirstDate = new Date(date.getFullYear(), date.getMonth(), 1)
                let monthLastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                let startDate = Timestamp.fromDate(monthFirstDate);
                let endDate = Timestamp.fromDate(monthLastDate)
                const userOrderCollectionRef = collection(db, 'userOrders');
                const q1 = query(userOrderCollectionRef, where('createdDate', '>', startDate), where('createdDate', '<', endDate))
                const querySnapshot1 = await getDocs(q1)
                querySnapshot1.forEach(async (doc1: any) => {
                    items1.push({ id: doc1.id, ...doc1.data() });
                });
                data = items.map((e: any) => {
                    return ({
                        ...e,
                        finalAmount: items1.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
                    })
                })
                let finalData = data.map((e: any) => {
                    return ({
                        ...e,
                        totalCommission: (e.finalAmount) * (e.commission.commission / 100),
                    })
                })
                setPay(finalData);
            }
            else if (items[0].payoutReleasePeriod.payoutPeriod === 'Weekly') {
                const date = new Date();
                let weekFirstDate = new Date(date.setDate(date.getDate() - date.getDay()));
                let weekLastDate = new Date(date.setDate(date.getDate() - date.getDay() + 6));
                let startDate = Timestamp.fromDate(weekFirstDate);
                let endDate = Timestamp.fromDate(weekLastDate)
                const userOrderCollectionRef = collection(db, 'userOrders');
                const q1 = query(userOrderCollectionRef, where('createdDate', '>', startDate), where('createdDate', '<', endDate))
                const querySnapshot1 = await getDocs(q1)
                querySnapshot1.forEach(async (doc1: any) => {
                    items1.push({ id: doc1.id, ...doc1.data() });
                });
                data = items.map((e: any) => {
                    return ({
                        ...e,
                        finalAmount: items1.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
                    })
                })
                let finalData = data.map((e: any) => {
                    return ({
                        ...e,
                        totalCommission: (e.finalAmount) * (e.commission.commission / 100),
                    })
                })
                setPay(finalData);
            }
            else if (items[0].payoutReleasePeriod.payoutPeriod === 'Half Monthly') {
                const date = new Date();
                let halfMonthFirstDate = new Date(date.setDate(date.getDate() - date.getDay()));
                let halfMonthLastDate = new Date(date.setDate(date.getDate() - date.getDay() + 14));
                let startDate = Timestamp.fromDate(halfMonthFirstDate);
                let endDate = Timestamp.fromDate(halfMonthLastDate)
                const userOrderCollectionRef = collection(db, 'userOrders');
                const q1 = query(userOrderCollectionRef, where('createdDate', '>', startDate), where('createdDate', '<', endDate))
                const querySnapshot1 = await getDocs(q1)
                querySnapshot1.forEach(async (doc1: any) => {
                    items1.push({ id: doc1.id, ...doc1.data() });
                });
                data = items.map((e: any) => {
                    return ({
                        ...e,
                        finalAmount: items1.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
                    })
                })
                let finalData = data.map((e: any) => {
                    return ({
                        ...e,
                        totalCommission: (e.finalAmount) * (e.commission.commission / 100),
                    })
                })
                setPay(finalData);
            }
            else if (items[0].payoutReleasePeriod.payoutPeriod === 'Yearly') {
                const date = new Date();
                let yearFirstDate = new Date(date.getFullYear(), 0, 1);
                let yearLastDate = new Date(date.getFullYear(), 11, 31);
                let startDate = Timestamp.fromDate(yearFirstDate);
                let endDate = Timestamp.fromDate(yearLastDate)
                const userOrderCollectionRef = collection(db, 'userOrders');
                const q1 = query(userOrderCollectionRef, where('createdDate', '>', startDate), where('createdDate', '<', endDate))
                const querySnapshot1 = await getDocs(q1)
                querySnapshot1.forEach(async (doc1: any) => {
                    items1.push({ id: doc1.id, ...doc1.data() });
                });
                data = items.map((e: any) => {
                    return ({
                        ...e,
                        finalAmount: items1.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
                    })
                })
                let finalData = data.map((e: any) => {
                    return ({
                        ...e,
                        totalCommission: (e.finalAmount) * (e.commission.commission / 100),
                    })
                })
                setPay(finalData);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    //call when tab changes
    const handleChange = async (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === '2') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const orderCollectionRef = collection(db, 'userOrders');
                const qLenn = query(orderCollectionRef, where('makerId', '==', id.uid)
                    , where('isBulkOrder', '==', 0), orderBy('createdDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRow(querySnapshotLen.data().count)
                const q = query(orderCollectionRef, where('makerId', '==', id.uid), where('isBulkOrder', '==', 0),
                    orderBy('createdDate', 'desc'), limit(limits))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc: any) => {
                    items.push(doc.data());
                })
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisible(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.seekerId) {
                        const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.seekerId))
                        const _querySnapshot = await getDocs(q);
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().firstName;
                            obj.lastName = _doc.data().lastName;
                        })
                        if (i === items.length) {
                            setOrderReceived(items);
                        } else {
                            i++;
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setFlagOrderReceived(true);
            } else {
                setFlagOrderReceived(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '3') {
            const items: any[] = [];
            setIsLoading(true);
            try {
                const orderCollectionRef = collection(db, 'userOrders');
                const qLenn = query(orderCollectionRef, where('makerId', '==', id.uid), where('isBulkOrder', '==', 1), orderBy('createdDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRowBulkOrder(querySnapshotLen.data().count)
                const q = query(orderCollectionRef, where('makerId', '==', id.uid), where('isBulkOrder', '==', 1), orderBy('createdDate', 'desc'), limit(limitBulkOrder))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc: any) => {
                    items.push(doc.data());
                })
                setLastVisibleBulkOrder(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleBulkOrder(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.seekerId) {
                        const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.seekerId))
                        const _querySnapshot = await getDocs(q);
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().firstName;
                            obj.lastName = _doc.data().lastName;
                        })
                        if (i === items.length) {
                            setBulkOrder(items);
                        } else {
                            i++;
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            } if (items.length > 0) {
                setFlagBulkOrders(true);
            } else {
                setFlagBulkOrders(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '4') {
            const items: any[] = [];
            setIsLoading(true);
            try {
                const productRef = collection(db, 'makerProduct');
                const q = query(productRef, where('makerId', '==', id.uid))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })
                setProduct(items)
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setFlagProducts(true);
            } else {
                setFlagProducts(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '5') {
            getBankDetail();
        }
        else if (newValue === '6') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const usersCollectionRef = collection(db, 'users');
                const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', id.uid))
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc: any) => {
                    if (doc.data() && doc.data().hoursOfOperation && doc.data().hoursOfOperation.length > 0) {
                        let res = doc.data().hoursOfOperation.length;
                        for (let i = 0; i < res; i++) {
                            items.push(doc.data().hoursOfOperation[i])
                        }
                    }
                });
                setRow(items.length);
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setHoursOfOperation(items);
                setFlagHoursOfOperation(true);
            } else {
                setFlagHoursOfOperation(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '7') {
            setIsLoading(true);
            const items: any[] = [];
            let data: any;
            try {
                const Walletref = collection(db, 'userWalletHistory')
                const qlenn = query(Walletref, where('makerId', '==', id.uid), orderBy('modifiedDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qlenn);
                setRowWallet(querySnapshotLen.data().count)
                const q = query(Walletref, where('makerId', '==', id.uid), orderBy('modifiedDate', 'desc'), limit(limitWallet))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items.push(doc.data());
                })
                setLastVisibleWallet(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleWallet(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.makerId) {
                        let walletAmount: number = 0;
                        const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', obj.makerId))
                        const _querySnapshot = await getDocs(q);
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().displayName;
                            walletAmount= _doc.data().walletAmount;
                        })
                        if (i === items.length) {
                            setWallet(items);
                            // data = items.reduce((a: any, v: any) => a = a + v.amount, 0)
                            setTotalAmount(walletAmount.toFixed(2))
                        } else {
                            i++;
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            } if (items.length > 0) {
                setflagWallet(true);
            } else {
                setflagWallet(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '8') {
            const items: any[] = [];
            setIsLoading(true);
            try {
                const usersCollectionRef = collection(db, 'users');
                const qLenn = query(usersCollectionRef, where('referedUserId', '==', id.uid),where('isDelete', '==', 0), orderBy('createdDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRowReference(querySnapshotLen.data().count)
                const q = query(usersCollectionRef, where('referedUserId', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'), limit(limitReference))
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc: any) => {
                    items.push({ id: doc.id, ...doc.data() });
                })
                setReference(items);
                setLastVisibleReference(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleReference(querySnapshot.docs[0]);
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setflagReference(true);
            } else {
                setflagReference(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '9') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const reviewCollectionRef = collection(db, 'userReviews');
                const userCollectionRef = collection(db, 'users');
                const qLenn = query(reviewCollectionRef, where('makerId', '==', id.uid), where('productId', '==', null), orderBy('reviewDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRowReview(querySnapshotLen.data().count)
                const q = query(reviewCollectionRef, where('makerId', '==', id.uid), where('productId', '==', null), orderBy('reviewDate', 'desc'), limit(limitReview))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc) => {
                    items.push(doc.data())
                })
                setLastVisibleReview(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleReview(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.seekerId) {
                        const q = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'), where('isDelete', '==', 0),where('uid', '==', obj.seekerId))
                        const _querySnapshot = await getDocs(q)
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().displayName;
                            obj.profile = _doc.data().profilePicUrl;
                        })
                        if (i === items.length) {
                            setReviews(items);
                        } else {
                            i++;
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setflagReview(true);
            } else {
                setflagReview(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '10') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const reviewCollectionRef = collection(db, 'userReviews');
                const userCollectionRef = collection(db, 'users');
                const productCollectionRef = collection(db, 'makerProduct');
                const qLenn = query(reviewCollectionRef, where('productId', '!=', null), where('makerId', 'in', [id.uid]))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRowFoodReview(querySnapshotLen.data().count)
                const q = query(reviewCollectionRef, where('productId', '!=', null), where('makerId', 'in', [id.uid]), limit(limitFoodReview))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc) => {
                    items.push(doc.data())
                })
                setLastVisibleFoodReview(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleFoodReview(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.seekerId) {
                        const q = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.seekerId))
                        const _querySnapshot = await getDocs(q)
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().displayName;
                            obj.profile = _doc.data().profilePicUrl;
                        })
                    }
                    if (obj.makerId) {
                        const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', id.uid))
                        const _querySnapshot = await getDocs(q)
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName1 = _doc.data().displayName;
                            obj.profile1 = _doc.data().profilePicUrl;
                        })
                    }
                    if (obj.productId) {
                        const q = query(productCollectionRef, where('pId', '==', obj.productId))
                        const _querySnapshot = await getDocs(q)
                        _querySnapshot.forEach((_doc) => {
                            obj.name = _doc.data().name;
                            obj.productImage = _doc.data().productImage;
                        })
                        if (i === items.length) {
                            setFoodReviews(items);
                        } else {
                            i++;
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setflagProductReview(true);
            } else {
                setflagProductReview(false);
            }
            setIsLoading(false);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handlePageChange = async (event: any, newPage: number) => {
        setPage(newPage);
        let items: any[] = [];
        if (page < newPage) {
            const q = query(collection(db, 'userOrders'), where('makerId', '==', id.uid),
                where('isBulkOrder', '==', 0),
                orderBy('createdDate', 'desc'), startAfter(lastVisible),
                limit(limits))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.seekerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                    })
                    if (i === items.length) {
                        setOrderReceived(items);
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot)
        } else {
            const q = query(collection(db, 'userOrders'),
                where('makerId', '==', id.uid),
                where('isBulkOrder', '==', 0),
                orderBy('createdDate', 'desc'),
                endBefore(firstVisible),
                limitToLast(limits))

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.seekerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                    })
                    if (i === items.length) {
                        setOrderReceived(items);
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot)
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleReviewPageChange = async (event: any, newPage: number) => {
        setPageReview(newPage);
        if (pageReview < newPage) {
            const reviewCollectionRef = collection(db, 'userReviews');
            const userCollectionRef = collection(db, 'users');
            const q = query(reviewCollectionRef, where('makerId', '==', id.uid), orderBy('reviewDate', 'desc'), startAfter(lastVisibleReview), limit(limitReview))
            const items: any[] = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                items.push(doc.data())
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.seekerId) {
                    const q = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'), where('isDelete', '==', 0),where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().displayName;
                        obj.profile = _doc.data().profilePicUrl;
                    })
                    if (i === items.length) {
                        setReviews(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateReview(querySnapshot);
        } else {
            const reviewCollectionRef = collection(db, 'userReviews');
            const userCollectionRef = collection(db, 'users');
            const q = query(reviewCollectionRef, where('makerId', '==', id.uid), orderBy('reviewDate', 'desc'),
                endBefore(firstVisibleReview), limitToLast(limitReview))
            const items: any[] = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                items.push(doc.data())
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.seekerId) {
                    const q = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().displayName;
                        obj.profile = _doc.data().profilePicUrl;
                    })
                    if (i === items.length) {
                        setReviews(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateReview(querySnapshot);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleFoodReviewPageChange = async (event: any, newPage: number) => {
        setPageFoodReview(newPage);
        if (pageFoodReview < newPage) {
            const reviewCollectionRef = collection(db, 'userReviews');
            const productCollectionRef = collection(db, 'makerProduct');
            const q = query(reviewCollectionRef, where('makerId', '==', id.uid), orderBy('reviewDate', 'desc'), startAfter(lastVisibleFoodReview), limit(limitFoodReview))
            const items: any[] = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                items.push(doc.data())
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.productId) {
                    const q = query(productCollectionRef, where('pId', '==', obj.productId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        obj.name = _doc.data().name;
                        obj.productImage = _doc.data().productImage;
                    })
                    if (i === items.length) {
                        setFoodReviews(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateFoodReview(querySnapshot);
        } else {
            const reviewCollectionRef = collection(db, 'userReviews');
            const productCollectionRef = collection(db, 'makerProduct');
            const q = query(reviewCollectionRef, where('makerId', '==', id.uid), orderBy('reviewDate', 'desc'),
                endBefore(firstVisibleFoodReview), limitToLast(limitFoodReview))
            const items: any[] = [];
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                items.push(doc.data())
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.productId) {
                    const q = query(productCollectionRef, where('pId', '==', obj.productId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        obj.name = _doc.data().name;
                        obj.productImage = _doc.data().productImage;
                    })
                    if (i === items.length) {
                        setFoodReviews(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateFoodReview(querySnapshot);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleReferencePageChange = async (event: any, newPage: number) => {
        setPageReference(newPage);
        const items: any[] = [];
        if (pageReference < newPage) {
            const q = query(collection(db, 'users'), where('referedUserId', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'), startAfter(lastVisibleReference), limit(limitReference))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            })
            setReference(items);
            updateStateReference(querySnapshot);
        } else {
            const q = query(collection(db, 'users'), where('referedUserId', '==', id.uid),where('isDelete', '==', 0), orderBy('createdDate', 'desc'), endBefore(firstVisibleReference), limitToLast(limitReference))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            })
            setReference(items);
            updateStateReference(querySnapshot);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleBulkOrderPageChange = async (event: any, newPage: number) => {
        setPageBulkOrder(newPage);
        let items: any[] = [];
        if (pageBulkOrder < newPage) {
            const q = query(collection(db, 'userOrders'), where('makerId', '==', id.uid),
                where('isBulkOrder', '==', 1), orderBy('createdDate', 'desc'), startAfter(lastVisibleBulkOrder),
                limit(limitBulkOrder))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.seekerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'), where('isDelete', '==', 0),where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                    })
                    if (i === items.length) {
                        setOrderReceived(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateBulkOrder(querySnapshot)
        } else {
            const q = query(collection(db, 'userOrders'),
                where('makerId', '==', id.uid), where('isBulkOrder', '==', 1),
                orderBy('createdDate', 'desc'),
                endBefore(firstVisibleBulkOrder),
                limitToLast(limitBulkOrder))

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.seekerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'), where('isDelete', '==', 0),where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                    })
                    if (i === items.length) {
                        setOrderReceived(items);
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot)
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleWalletPageChange = async (event: any, newPage: number) => {
        setPageWallet(newPage)
        let items: any[] = [];
        if (pageWallet < newPage) {
            const q = query(collection(db, 'userWalletHistory'), where('makerId', '==', id.uid), orderBy('modifiedDate', 'desc')
                , startAfter(lastVisibleWallet), limit(limitWallet))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            updateStateWallet(querySnapshot);
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', obj.makerId)
                        )
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                    })
                    if (i === items.length) {
                        setWallet(items);
                    } else {
                        i++;
                    }
                }
            })
        } else {
            const q = query(collection(db, 'userWalletHistory'), where('makerId', '==', id.uid), orderBy('modifiedDate', 'desc')
            , endBefore(firstVisibleWallet), limitToLast(limitWallet))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            updateStateWallet(querySnapshot)
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', obj.makerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                    })
                    if (i === items.length) {
                        setWallet(items);
                    } else {
                        i++;
                    }
                }
            })
        }
    }

    // update first visible and last visible value according to pagination (next and previous button)
    const updateState = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisible(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisible(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateReview = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleReview(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleReview(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateFoodReview = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleFoodReview(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleFoodReview(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateReference = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleReference(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleReference(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateBulkOrder = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleBulkOrder(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleBulkOrder(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateWallet = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleWallet(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleWallet(documents.docs[documents.docs.length - 1]);
        }
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimits(parseInt(event.target.value));
        setPage(0);
        limits = parseInt(event.target.value);
        handleChange(event, '2');
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleReviewLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimitReview(parseInt(event.target.value));
        setPageReview(0);
        limitReview = parseInt(event.target.value);
        handleChange(event, '9');
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleFoodReviewLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimitFoodReview(parseInt(event.target.value));
        setPageFoodReview(0);
        limitFoodReview = parseInt(event.target.value);
        handleChange(event, '10');
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleReferenceLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimitReference(parseInt(event.target.value));
        setPageReference(0);
        limitReference = parseInt(event.target.value);
        handleChange(event, '8');
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleBulkOrderLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimitBulkOrder(parseInt(event.target.value));
        setPageBulkOrder(0);
        limitBulkOrder = parseInt(event.target.value);
        handleChange(event, '3');
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleWalletLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimitWallet(parseInt(event.target.value));
        setPageWallet(0);
        limitWallet = parseInt(event.target.value);
        handleChange(event, '7');
    };

    return (
        <>
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Stack alignItems="left" justifyContent="space-between" mb={5} >
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to="/admin/dashboard"
                                    style={{ display: 'flex', color: "black" }}
                                >
                                    <HomeIcon />
                                </Link>
                                <Link
                                    to="/admin/foodMakers"
                                    style={{ display: 'flex', color: "black", textDecoration: "none" }}
                                >
                                    Food Makers
                                </Link>
                                <Typography color="inherit">{userFirstName} {userLastName}</Typography>
                            </Breadcrumbs>
                        </Stack>
                        <Grid item xs={12}>
                            <Card className="makerdetail-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', margin: "auto", display: "flex", justifyContent: "space-evenly" }} >
                                <Box sx={{ width: "100%", typography: "body1" }}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange}
                                                aria-label="scrollable force tabs example"
                                                variant="scrollable" scrollButtons
                                                allowScrollButtonsMobile
                                            >
                                                <Tab label="Profile" value="1" />
                                                <Tab label="Orders (Received)" value="2" />
                                                <Tab label="Bulk Orders" value="3" />
                                                <Tab label="Products" value="4" />
                                                <Tab label="Bank Detail" value="5" />
                                                <Tab label="Hours Of Operation" value="6" />
                                                <Tab label="Wallet History" value="7" />
                                                <Tab label="Referred User" value="8" />
                                                <Tab label="Reviews" value="9" />
                                                <Tab label="Product Reviews" value="10" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1" sx={{ height: 'calc(100vh - 260px)', overflow: 'auto' }}>
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagProfile === true) ?
                                                                <Scrollbar>
                                                                    <TableContainer sx={{ backgroundColor: 'white' }}>
                                                                        {profile.map((data: any) => (
                                                                            <Table key={data.id}>
                                                                                <Grid container spacing={3} >
                                                                                    <Grid item xs={12} sm={6} md={6} >
                                                                                        {data.profilePicUrl ? <Avatar sx={{ height: "100px", width: "100px", my: 2, ml: 2 }} src={data.profilePicUrl}></Avatar> : <Avatar sx={{ height: "100px", width: "100px", my: 2, ml: 2 }}>{data.firstName ? data.firstName[0] : null}</Avatar>}
                                                                                        <Typography gutterBottom ml={2}>
                                                                                            <Box display="flex" alignItems="flex-start"><Typography><b>Name</b></Typography><Box sx={{ pl: 18, color: 'grey' }}>
                                                                                                {data.firstName} {data.lastName}
                                                                                            </Box></Box>
                                                                                        </Typography>
                                                                                        <Typography gutterBottom ml={2}>
                                                                                            <Box display="flex" alignItems="flex-start"><Typography><b>Email</b></Typography><Box sx={{ pl: 18.5, color: 'grey' }}>
                                                                                                {data.email}
                                                                                            </Box></Box>
                                                                                        </Typography>
                                                                                        <Typography gutterBottom ml={2}>
                                                                                            <Box display="flex" alignItems="flex-start"><Typography><b>Gender</b></Typography><Box sx={{ pl: 17, color: 'grey' }}>{data.gender}</Box></Box>
                                                                                        </Typography>
                                                                                        <Typography gutterBottom ml={2}>
                                                                                            <Box display="flex" alignItems="flex-start"><Typography><b>Contact No</b></Typography><Box sx={{ pl: 13, color: 'grey' }}>{data.contactNo}</Box></Box>
                                                                                        </Typography>
                                                                                        <Typography gutterBottom ml={2}>
                                                                                            <Box display="flex" alignItems="flex-start"><Typography><b>Availabilty status</b></Typography><Box sx={{ pl: 7.6, color: 'grey' }}>
                                                                                                {(data.isMakerAvailability === false) ? "Not Available" : "Available"}
                                                                                            </Box></Box>
                                                                                        </Typography>
                                                                                        <Typography gutterBottom ml={2}>
                                                                                            <Box display="flex" alignItems="flex-start"><Typography><b>Payout Period</b></Typography><Box sx={{ pl: 10.7, color: 'grey' }}>
                                                                                                {data.payoutReleasePeriod.payoutPeriod ? data.payoutReleasePeriod.payoutPeriod : "--"}
                                                                                            </Box></Box>
                                                                                        </Typography>
                                                                                        <Typography gutterBottom ml={2}>
                                                                                            <Box display="flex" alignItems="flex-start"><Typography><b>Delivery Preference</b></Typography><Box sx={{ pl: 5, color: 'grey' }}>
                                                                                                {data.deliveryPreference ? data.deliveryPreference : "--"}
                                                                                            </Box></Box>
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={12} sm={6} md={6} >
                                                                                        <Box >
                                                                                            <Typography sx={{ my: 2, mx: 2 }}><b>Address</b></Typography>
                                                                                            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', bgcolor: '#F9FAFB', overflowY: 'auto', minHeight: '100px' }}>
                                                                                                <CardContent sx={{ overFlow: 'auto' }}>
                                                                                                    <Typography gutterBottom>
                                                                                                        <Box display="flex" alignItems="left"><Box sx={{ color: 'grey' }}>
                                                                                                            {data.addresses[0].addressLine1} ,{data.addresses[0].pincode}
                                                                                                            ,{data.addresses[0].city}, {data.addresses[0].state} {data.addresses[0].country}
                                                                                                        </Box></Box>
                                                                                                    </Typography>
                                                                                                </CardContent>
                                                                                            </Card>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Table>
                                                                        ))}
                                                                    </TableContainer>
                                                                </Scrollbar>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagOrderReceived === true) ?
                                                                <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                    <ScrollBar>
                                                                        <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell align="center" >#</TableCell>
                                                                                        <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Seekers</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Total Products</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Status</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Date</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {orderReceived.map((data: any, index: any) => (
                                                                                        <TableRow hover key={data.id}>
                                                                                            <TableCell align="center" >
                                                                                                {(page * limits) + index + 1}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                <Typography variant="subtitle2" noWrap>{data.orderNo}</Typography>
                                                                                            </TableCell>
                                                                                            <TableCell align="center" >
                                                                                                {data.firstName} {data.lastName}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" >
                                                                                                {data.orderDetail.length}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" >
                                                                                                {data.orderStatus === 'Confirmed' && <Label sx={{ color: "white", backgroundColor: "#919eab", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Delivered' && <Label sx={{ color: "white", backgroundColor: "#53bc5b", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Shipped' && <Label sx={{ color: "white", backgroundColor: "#1565c0", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Processed' && <Label sx={{ color: "white", backgroundColor: "#fcde2a", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Cancelled' && <Label sx={{ color: "white", backgroundColor: "#e20700", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Pending' && <Label sx={{ color: "white", backgroundColor: "#fc7b2a", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" >
                                                                                                {format(new Date(data.createdDate.seconds * 1000), formatedDate)}
                                                                                            </TableCell>
                                                                                            <TableCell align='center'>
                                                                                                <Tooltip title="View" arrow>
                                                                                                    <IconButton
                                                                                                        sx={{ color: '#1565c0' }}
                                                                                                        color="inherit"
                                                                                                        size="small"
                                                                                                        onClick={(e) => { handleClickVisible(data) }}
                                                                                                    >
                                                                                                        <VisibilityIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </ScrollBar>
                                                                    <TablePagination
                                                                        component="div"
                                                                        count={row}
                                                                        onPageChange={handlePageChange}
                                                                        onRowsPerPageChange={handleLimitChange}
                                                                        page={page}
                                                                        rowsPerPage={limits}
                                                                        rowsPerPageOptions={[10, 20, 30, 40]}
                                                                    />
                                                                </Card>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <div>
                                                {isLoading ? <Loader title="Loading..." /> :
                                                    <>
                                                        {(flagBulkOrders === true) ?
                                                            <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                <ScrollBar>
                                                                    <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                        <Table stickyHeader>
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="center" >#</TableCell>
                                                                                    <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                                                                    <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Seekers</Typography></TableCell>
                                                                                    <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Total Products</Typography></TableCell>
                                                                                    <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Status</Typography></TableCell>
                                                                                    <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Date</Typography></TableCell>
                                                                                    <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {bulkOrder.map((data: any, index: any) => (
                                                                                    <TableRow hover key={data.id}>
                                                                                        <TableCell align="center" >
                                                                                            {(pageBulkOrder * limitBulkOrder) + index + 1}
                                                                                        </TableCell>
                                                                                        <TableCell sx={{ color: '#212B36' }} align="center">
                                                                                            <Typography variant="subtitle2" noWrap>{data.orderNo}</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell align="center" >
                                                                                            {data.firstName} {data.lastName}
                                                                                        </TableCell>
                                                                                        <TableCell align="center" >
                                                                                            {data.orderDetail.length}
                                                                                        </TableCell>
                                                                                        <TableCell align="center" >
                                                                                            {data.orderStatus === 'Confirmed' && <Label sx={{ color: "white", backgroundColor: "#919eab", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                            {data.orderStatus === 'Delivered' && <Label sx={{ color: "white", backgroundColor: "#53bc5b", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                            {data.orderStatus === 'Shipped' && <Label sx={{ color: "white", backgroundColor: "#1565c0", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                            {data.orderStatus === 'Processed' && <Label sx={{ color: "white", backgroundColor: "#fcde2a", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                            {data.orderStatus === 'Cancelled' && <Label sx={{ color: "white", backgroundColor: "#e20700", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                            {data.orderStatus === 'Pending' && <Label sx={{ color: "white", backgroundColor: "#fc7b2a", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                        </TableCell>
                                                                                        <TableCell align="center" >
                                                                                            {format(new Date(data.createdDate.seconds * 1000), formatedDate)}
                                                                                        </TableCell>
                                                                                        <TableCell align='center' sx={{ pt: 0.5, pb: 0.5 }}>
                                                                                            <Tooltip title="View" arrow>
                                                                                                <IconButton
                                                                                                    sx={{ color: '#1565c0' }}
                                                                                                    color="inherit"
                                                                                                    size="small"
                                                                                                    onClick={(e) => { handleClickVisible(data) }}
                                                                                                >
                                                                                                    <VisibilityIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </ScrollBar>
                                                                <TablePagination
                                                                    component="div"
                                                                    count={rowBulkOrder}
                                                                    onPageChange={handleBulkOrderPageChange}
                                                                    onRowsPerPageChange={handleBulkOrderLimitChange}
                                                                    page={pageBulkOrder}
                                                                    rowsPerPage={limitBulkOrder}
                                                                    rowsPerPageOptions={[10, 20, 30, 40]}
                                                                />
                                                            </Card>
                                                            :
                                                            <Paper
                                                                sx={{
                                                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                }}
                                                            >
                                                                <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                <Typography variant="h6" paragraph>
                                                                    Data not Available
                                                                </Typography>
                                                            </Paper>}
                                                    </>
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="4" style={{ height: 'calc(100vh - 260px)', overflowY: 'auto' }}>
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagProducts === true) ?
                                                                <Grid container >
                                                                    {
                                                                        product.map((item: any) => (
                                                                            <Grid item xs={12} sm={6} md={3} key={item.id} >
                                                                                <Card sx={{ mx: 3, my: 3, boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', bgcolor: '#F9FAFB', height: "400px" }}>
                                                                                    {item.productImage ? <img src={item.productImage} srcSet={item.productImage}
                                                                                        alt={item.name} height='312px' width="312px" />
                                                                                        :
                                                                                        <img src='/defaultFoodLogo.png'
                                                                                            alt={item.name} height='312px' width="312px" />
                                                                                    }
                                                                                    <Stack spacing={2} sx={{ p: 3 }}>
                                                                                        <Typography variant="subtitle2" noWrap>
                                                                                            {item.name}
                                                                                        </Typography>
                                                                                        <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                                                                            <Typography variant="subtitle1">
                                                                                                &nbsp;
                                                                                                ₹ {(item.price).toFixed(2)}
                                                                                            </Typography>
                                                                                        </Stack>
                                                                                    </Stack>
                                                                                </Card>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </Grid>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="5">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagBankDetail === true) ?
                                                                <>
                                                                    {
                                                                        bankDetail.map((data: any) => (
                                                                            <>
                                                                                <Grid container key={data.id} spacing={3.5}>
                                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                                        <Card sx={{ bgcolor: '#F9FAFB', boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', height: '246.55px' }}>
                                                                                            {data.paymentMethod === "UPI" ?
                                                                                                <>
                                                                                                    <Typography gutterBottom ml={1}>
                                                                                                        <h5> <Box display="flex" alignItems="flex-start">Payment Method<Box sx={{ pl: 6, pr: 1, color: 'grey' }}>
                                                                                                            {data.paymentMethod ? data.paymentMethod : "--"}
                                                                                                        </Box></Box></h5>
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom ml={1} mr={1}>
                                                                                                        <h5> <Box display="flex" alignItems="flex-start">UPI ID<Box sx={{ pl: 14.8, pr: 1, color: 'grey' }}>
                                                                                                            {data.upiId ? data.upiId : "--"}
                                                                                                        </Box></Box></h5>
                                                                                                    </Typography>
                                                                                                </> : <>
                                                                                                    <Typography gutterBottom ml={1} mr={1}>
                                                                                                        <h5> <Box display="flex" alignItems="flex-start">Payment Method<Box sx={{ pl: 6, pr: 1, color: 'grey' }}>
                                                                                                            {data.paymentMethod ? data.paymentMethod : "--"}
                                                                                                        </Box></Box></h5>
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom ml={1} mr={1}>
                                                                                                        <h5> <Box display="flex" alignItems="flex-start">Bank Name<Box sx={{ pl: 10.3, pr: 1, color: 'grey' }}>
                                                                                                            {data.bankName ? data.bankName : "--"}
                                                                                                        </Box></Box></h5>
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom ml={1} mr={1}>
                                                                                                        <h5>  <Box display="flex" alignItems="flex-start">Branch Name<Box sx={{ pl: 8.5, pr: 1, color: 'grey' }}>
                                                                                                            {data.branchName ? data.branchName : "--"}
                                                                                                        </Box></Box></h5>
                                                                                                    </Typography>
                                                                                                    <Typography gutterBottom ml={1} mr={1}>
                                                                                                        <h5>  <Box display="flex" alignItems="flex-start">IFSC Code<Box sx={{ pl: 11, pr: 1, color: 'grey' }}>
                                                                                                            {data.ifscCode ? data.ifscCode : "--"}
                                                                                                        </Box></Box></h5>
                                                                                                    </Typography>
                                                                                                </>
                                                                                            }
                                                                                        </Card>
                                                                                    </Grid>
                                                                                    {data.paymentMethod === "UPI" ? "" :
                                                                                        <Grid item xs={12} sm={6} md={6}>
                                                                                            <Card sx={{ bgcolor: '#F9FAFB', boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', height: '246.55px' }}>
                                                                                                <Typography gutterBottom ml={1}>
                                                                                                    <h5><Box display="flex" alignItems="flex-start">Account Holder Name<Box sx={{ pl: 6, pr: 1, color: 'grey' }}>
                                                                                                        {(data.accHolderName) ? data.accHolderName : "--"}
                                                                                                    </Box></Box></h5>
                                                                                                </Typography>
                                                                                                <Typography gutterBottom ml={1}>
                                                                                                    <h5><Box display="flex" alignItems="flex-start">Account Number<Box sx={{ pl: 10, pr: 1, color: 'grey' }}>
                                                                                                        {/* {(data.isVerified === true) ? data.accNumber.replace(/.(?=.{4,}$)/g, '*') : data.accNumber} */}
                                                                                                        {((showPassword && data.isVerified === true) ? data.accNumber : data.accNumber.replace(/.(?=.{4,}$)/g, '*'))}
                                                                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                                                                         <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                                                                       </IconButton> </Box></Box></h5>
                                                                                                </Typography>
                                                                                            </Card>
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="6">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagHoursOfOperation === true) ?
                                                                <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                    <ScrollBar>
                                                                        <TableContainer className="makerdetailtab6-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell align="center" >#</TableCell>
                                                                                        <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Weekday</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Status</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">From Time</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">To Time</Typography></TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {hoursOfOperation.map((data: any, index: any) => (
                                                                                        <TableRow hover key={data.id} sx={{ bgcolor: 'white' }}>
                                                                                            <TableCell align="center" >
                                                                                                {(page * limits) + index + 1}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }} >
                                                                                                <Typography variant='subtitle2' noWrap>{(data.Weekday) ? data.Weekday : "--"}
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                            <TableCell align="center" >
                                                                                                {((data.isActive === true) ? <Typography style={{ color: "green" }}>Active</Typography> : <Typography style={{ color: "red" }}>InActive</Typography>)}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" >
                                                                                                {(data.isActive === false) ? '--' : (data.fromTime)}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {(data.isActive === false) ? '--' : (data.toTime)}
                                                                                            </Typography></TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </ScrollBar>
                                                                </Card>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="7" >
                                            <div>
                                                {isLoading ? <Loader title="Loading..." /> :
                                                    <>
                                                        {(flagWallet === true) ?
                                                            <>
                                                                <>
                                                                    <Typography sx={{ ml: 3.1, mb: 2 }}>
                                                                        <Box display="flex" alignItems="flex-start"><Typography sx={{ fontFamily: '"Public Sans",sans serif' }}><b>Total Amount</b></Typography><Box sx={{ pl: 5, color: 'grey' }}>
                                                                            ₹ {(totalAmount)}
                                                                        </Box></Box>
                                                                    </Typography>
                                                                    <Card className="makerdetailtab7-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                        <ScrollBar>
                                                                            <TableContainer className="makerdetailtab7-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                                <Table stickyHeader>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell align="center">#</TableCell>
                                                                                            <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Title</Typography></TableCell>
                                                                                            <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Amount</Typography></TableCell>
                                                                                            <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Transaction Date</Typography></TableCell>
                                                                                            <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Type</Typography></TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {wallet.map((data: any, index: any) => (
                                                                                            <TableRow hover key={data.id}>
                                                                                                <TableCell align="center">{(pageWallet * limitWallet) + index + 1}</TableCell>
                                                                                                <TableCell align="center"><Typography noWrap fontSize="0.875rem">{data.title}</Typography></TableCell>
                                                                                                <TableCell align="center"><Typography noWrap fontSize="0.875rem">₹ {(data.amount).toFixed(2)}</Typography></TableCell>
                                                                                                <TableCell align="center"><Typography noWrap fontSize="0.875rem">{format(new Date(data.modifiedDate.seconds * 1000), formatedDate)}</Typography></TableCell>
                                                                                                <TableCell align="center"><Typography noWrap fontSize="0.875rem">{(data.walletHistoryType === 'credit') ?
                                                                                                    <Typography style={{ color: "green" }}>{data.walletHistoryType}</Typography> : <Typography style={{ color: "red" }}>{data.walletHistoryType}</Typography>}</Typography></TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </ScrollBar>
                                                                        <TablePagination
                                                                            component="div"
                                                                            count={rowWallet}
                                                                            onPageChange={handleWalletPageChange}
                                                                            onRowsPerPageChange={handleWalletLimitChange}
                                                                            page={pageWallet}
                                                                            rowsPerPage={limitWallet}
                                                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                                                        />
                                                                    </Card>
                                                                </>
                                                            </>
                                                            :
                                                            <>
                                                                <Typography sx={{ ml: 3.1, mb: 2 }}>
                                                                    <Box display="flex" alignItems="flex-start"><Typography sx={{ fontFamily: '"Public Sans",sans serif' }}><b>Total Amount</b></Typography><Box sx={{ pl: 5, color: 'grey' }}>
                                                                        ₹ 0.00
                                                                    </Box></Box>
                                                                </Typography>
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper></>}
                                                    </>
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="8">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagReference === true) ?
                                                                <>
                                                                    <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                        <ScrollBar>
                                                                            <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ backgroundColor: 'white' }}>
                                                                                <Table>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell align="center" >#</TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Referred User</Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Role</Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Date</Typography></TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {reference.map((data: any, index: number) => (
                                                                                            <TableRow hover key={data.id}>
                                                                                                <TableCell align="center" >
                                                                                                    {(pageReference * limitReference) + index + 1}
                                                                                                </TableCell>
                                                                                                <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                    <Typography variant="subtitle2" noWrap>{data.displayName}</Typography>
                                                                                                </TableCell>
                                                                                                <TableCell align="center" sx={{textTransform: 'capitalize'}}>
                                                                                                    {data.role.roleName}
                                                                                                </TableCell>
                                                                                                <TableCell align="center" >
                                                                                                    {format(new Date(data.createdDate.seconds * 1000), formatedDate)}
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </ScrollBar>
                                                                        <TablePagination
                                                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                                                            component="div"
                                                                            count={rowReference}
                                                                            rowsPerPage={limitReference}
                                                                            page={pageReference}
                                                                            onPageChange={handleReferencePageChange}
                                                                            onRowsPerPageChange={handleReferenceLimitChange}
                                                                        />
                                                                    </Card>
                                                                </>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="9">
                                            {isLoading ? <Loader title="Loading..." /> :
                                                <>
                                                    {(flagReview === true) ?
                                                        <Grid sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                            <TableContainer className="makerdetailtab9-tableContainer-list">
                                                                <Table stickyHeader>
                                                                    <Box display="flex" alignItems="flex-start">
                                                                        <Grid style={{ display: "flex" }}>
                                                                            <Grid container>
                                                                                {
                                                                                    reviews.map((arr: any, index: number) => (
                                                                                        <Grid key={arr.id}>
                                                                                            <Card sx={{
                                                                                                boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', textAlign: 'left', mx: 3, my: 3, bgcolor: '#F9FAFB', height: "426.02px", width: '250px', overflow: 'auto'
                                                                                            }}>
                                                                                                <CardHeader
                                                                                                    avatar={arr.profile ? <Avatar src={arr.profile} /> : <Avatar>{arr.firstName ? arr.firstName[0] : null}</Avatar>}
                                                                                                    title={arr.firstName}

                                                                                                />
                                                                                                <Stack direction="row" my={1} ml={3}>
                                                                                                    <Typography mr={1}>
                                                                                                        {arr.rattingType}
                                                                                                    </Typography>
                                                                                                    <Rating name="rating" value={arr.ratting} readOnly />
                                                                                                </Stack>
                                                                                                <CardContent >
                                                                                                    <Typography sx={{ height: '192px' }}>
                                                                                                        {arr.description ? arr.description : 'No Description'}
                                                                                                    </Typography>
                                                                                                    <Typography textAlign="right">
                                                                                                        <h6>{format(new Date(arr.reviewDate.seconds * 1000), formatedDate)}</h6>
                                                                                                    </Typography>
                                                                                                </CardContent>
                                                                                            </Card>
                                                                                        </Grid>
                                                                                    ))}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Table>
                                                            </TableContainer>
                                                            <TablePagination
                                                                component="div"
                                                                count={rowReview}
                                                                onPageChange={handleReviewPageChange}
                                                                onRowsPerPageChange={handleReviewLimitChange}
                                                                page={pageReview}
                                                                rowsPerPage={limitReview}
                                                                rowsPerPageOptions={[10, 20, 30, 40]}
                                                            />
                                                        </Grid>
                                                        :
                                                        <Paper
                                                            sx={{
                                                                width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                            }}
                                                        >
                                                            <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                            <Typography variant="h6" paragraph>
                                                                Data not Available
                                                            </Typography>
                                                        </Paper>}
                                                </>
                                            }
                                        </TabPanel>
                                        <TabPanel value="10">
                                            {isLoading ? <Loader title="Loading..." /> :
                                                <>
                                                    {(flagProductReview === true) ?
                                                        <Grid sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                            <TableContainer className="makerdetailtab9-tableContainer-list">
                                                                <Table stickyHeader>
                                                                    <Box display="flex" alignItems="flex-start">
                                                                        <Grid style={{ display: "flex" }}>
                                                                            <Grid container>
                                                                                {
                                                                                    foodReviews.map((arr: any, index: number) => (
                                                                                        <Grid key={arr.id}>
                                                                                            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', textAlign: 'left', mx: 3, my: 3, bgcolor: '#F9FAFB', height: "426.02px", width: '230px', overflow: 'auto' }}>
                                                                                                <CardHeader
                                                                                                    avatar={arr.productImage ? <Avatar src={arr.productImage} variant="rounded"></Avatar> : <Avatar src="/defaultFood.png" variant="rounded"></Avatar>}
                                                                                                    title={arr.name}
                                                                                                />
                                                                                                <Stack direction="row" my={1} ml={3} mx={3}>
                                                                                                    <Typography mr={1}>
                                                                                                        {arr.rattingType}
                                                                                                    </Typography>
                                                                                                    <Rating name="rating" value={arr.ratting} readOnly />
                                                                                                </Stack>
                                                                                                <CardContent>
                                                                                                    <Typography sx={{ height: '192px' }}>
                                                                                                        {arr.description ? arr.description : 'No Description'}
                                                                                                    </Typography>
                                                                                                    <Typography textAlign="right">
                                                                                                        <h6>{format(new Date(arr.reviewDate.seconds * 1000), formatedDate)}</h6>
                                                                                                    </Typography>
                                                                                                </CardContent>
                                                                                            </Card>
                                                                                        </Grid>
                                                                                    ))}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>
                                                                </Table>
                                                            </TableContainer>
                                                            <TablePagination
                                                                component="div"
                                                                count={rowFoodReview}
                                                                onPageChange={handleFoodReviewPageChange}
                                                                onRowsPerPageChange={handleFoodReviewLimitChange}
                                                                page={pageFoodReview}
                                                                rowsPerPage={limitFoodReview}
                                                                rowsPerPageOptions={[10, 20, 30, 40]}
                                                            />
                                                        </Grid>
                                                        :
                                                        <Paper
                                                            sx={{
                                                                width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                            }}
                                                        >
                                                            <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                            <Typography variant="h6" paragraph>
                                                                Data not Available
                                                            </Typography>
                                                        </Paper>}
                                                </>
                                            }
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container >
            <div>
                <Dialog open={isCheck} onClose={handleCloseSwitch}>
                    <DialogTitle>{(verify.isVerified === true) ? "Verified" : "Not Verified"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {(verify.isVerified === false) ? 'Are you sure you want to Verify?' : 'Are you sure you want to Not Verify?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSwitch}>Cancel</Button>
                        <Button onClick={handleCheckSwitch}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

export default FoodMakerDetails