import { alpha, Breadcrumbs, Box, Button, Card, Container, Divider, Drawer, FormControl, FormGroup, Grid, Chip, IconButton, InputAdornment, OutlinedInput, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Scrollbar from '../../components/scrollbar';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import { CSVLink } from 'react-csv'
import { Timestamp, collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CancelIcon from '@mui/icons-material/Cancel';
import Iconify from '../../components/iconify';
import Loader from '../spinner';
import './../FoodMakerPage.css';
import { format } from 'date-fns';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from 'react-date-range';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 100,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 300,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

const initialState = {
    fromDate: null,
    toDate: null,
}

export const AdminProfit = () => {
    let [adminProfit, setAdminProfit] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [row, setRow] = useState<number>(10);
    const [value, setValue] = useState<any>([]);
    const [flag, setFlag] = useState<boolean>(true);
    const [userValue, setUserValue] = React.useState<any>(initialState);
    let [searchInput, setSearchInput] = useState('');
    const [chipStartDate, setChipStartDate] = useState<boolean>(false);
    const [chipEndDate, setChipEndDate] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [totalCommission, setTotalCommission] = useState<any>([])
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        }
    ]);
    const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

    // it is the first method invoked when this page is open
    useEffect(() => {
        getData();
    }, [])

    //call intially
    const getData = async () => {
        const items: any[] = [];
        const items1: any[] = [];
        let data: any[] = [];
        let data1: any[] = [];
        let sort: any[] = [];
        let amount: any[] = [];
        if (searchInput) {
            setIsLoading(false);
            setFlag(true);
        } else {
            setIsLoading(true);
        }
        try {
            const userOrderCollectionRef = collection(db, 'userOrders');
            let q1 = query(userOrderCollectionRef);
            if (userValue.fromDate && userValue.toDate) {
                let firstDate = Timestamp.fromDate(userValue.fromDate);
                let secondDate = Timestamp.fromDate(new Date(new Date(userValue.toDate).setDate(new Date(userValue.toDate).getDate() + 1)));
                q1 = query(q1, where('createdDate', '>=', firstDate), where('createdDate', '<', secondDate))
                // let q1 = query(userOrderCollectionRef, where('createdDate', '>=', firstDate), where('createdDate', '<', secondDate));
            }
            const querySnapshot1 = await getDocs(q1)
            querySnapshot1.forEach((doc1: any) => {
                items1.push({ id: doc1.id, ...doc1.data() });
            });

            const usersCollectionRef = collection(db, 'users');
            let q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true),
                orderBy('firstName', 'asc'))
            // if (searchInput) {
            //     q = query(q
            //         , where('firstName', '>=', (searchInput.charAt(0).toUpperCase() + searchInput.substring(1).toLowerCase()))
            //         , where('firstName', '<', (searchInput.charAt(0).toUpperCase() + searchInput.substring(1).toLowerCase()) + 'z')
            //     )
            // }
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            const filteredItems = items1.filter(item => item.orderStatus !== 'Cancelled');

            data1 = items.map((e: any) => {
                return ({
                    ...e,
                    finalAmount1: filteredItems.filter((d: any) => e.uid === d.makerId),
                })
            })
            data = items.map((e: any) => {
                return ({
                    ...e,
                    finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
                })
            })
            let finalData = data.map((e: any) => {
                return ({
                    ...e,
                    adminCommission: (e.finalAmount) * (e.commission.commission / 100),
                })
            })
            sort = finalData.sort((a, b) => (a.finalAmount > b.finalAmount) ? -1 : 1)
            amount = sort.filter((e: any) => e.finalAmount > 0)
            let comm = 0;
            for (let i = 0; i < amount.length; i++) {
                comm += (amount[i].adminCommission)
                setTotalCommission(comm.toFixed(2))
            }

            if (amount.length > 0) {
                setAdminProfit(amount);
                setFlag(true);
            } else {
                setFlag(false);
            }
            setRow(amount.length);
        }
        catch (error) {
            console.log(error)
        }
        setIsLoading(false);
    }

    //when click on clear button
    const handleClear = (e: any) => {
        userValue.fromDate = null;
        userValue.toDate = null;
        value.pop();
        setValue(value);
        setChipStartDate(false);
        setChipEndDate(false);
        setIsOpen(false);
        setState([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection"
            }
        ]);
        if (searchInput) {
            searchItems(searchInput);
        }
        else {
            getData();
        }

    }

    // when chip is removed
    const handleDelete = (e: any) => {
        if (e === userValue.fromDate) {
            userValue.fromDate = null
            userValue.toDate = null
            setChipStartDate(false);
            setChipEndDate(false);
        }
        if (e === userValue.toDate) {
            userValue.toDate = null
            setChipEndDate(false);
        }
        // handleSearch();
        setState([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection"
            }
        ]);
        if (searchInput) {
            searchItems(searchInput);
        }
        else {
            getData();
        }

    }

    // when date is selected
    const handleSelect = (ranges: any) => {
        const { selection } = ranges;
        setState([selection]);
        userValue.fromDate = ranges.selection.startDate;
        userValue.toDate = ranges.selection.endDate;
    }

    //open date dialog
    const handleOpenRangePicker = () => {
        setIsOpen(true);
    }

    // close date dialog
    const handleCloseDailog = () => {
        setIsOpen(false);
    };

    // save dialog
    const save = () => {
        setFlag(false);
        setIsOpen(false);
        if (userValue.fromDate) {
            setChipStartDate(true);
        }
        if (userValue.toDate) {
            setChipEndDate(true);
        }
        if (searchInput) {
            searchItems(searchInput);
        }
        else {
            getData();
        }

    }

    const headers = [
        {
            label: "Food Makers", key: "firstName",
        },
        {
            label: "Admin Commission", key: "adminCommission",
        },
    ]

    let csvValue = JSON.parse(JSON.stringify(adminProfit))
    for (let i = 0; i < csvValue.length; i++) {
        csvValue[i].adminCommission = "₹" + csvValue[i].adminCommission;

    }

    // click on csv icon
    const csvlink = {
        filename: "AdminCommission.csv",
        headers: headers,
        data: csvValue
    }

    // click on print icon
    const handlePrint = async () => {
        searchInput = ''
        setSearchInput(searchInput);
        await getData();

        let _html = `<html>
        <style>

        .img-container {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 50;
            right: 50;
            top: 0;
          }

          span{
            font-size: 30px;
          }

          .date-container {
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          table {
             border-collapse: collapse;
            border: 1px solid black;
            width: 100%;
            margin-top: 50px;
          }

          .th1 {
            border-top: 0px;
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            text-align: center;
            padding: 8px;
          }

          .th2 {
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            text-align: center;
            padding: 8px;
          }

          .th3 {
            border-bottom: 1px solid black;
            text-align: center;
            padding: 8px;
          }

          .td1 {
            border-right: 1px solid black;
            text-align: center;
            padding: 8px;
          }

          .td2 {
            border-right: 1px solid black;
            text-align: left;
            padding: 8px;
          }

          .td3 {
            text-align: right;
            padding: 8px;
          }

          @media print {
            tr.page-break {
              page-break-after: always;
              border-top: 1px solid black;
              border-bottom: 1px solid black;
            }
          }

          @media print {
            @page {
              size: A4;
            }
          }
          tr:nth-child(even) {background-color: #f2f2f2;}
          </style>
                        <body  onload="window.print(); window.close();">`;
        let totalRecord = adminProfit.length;
        let perPageRecor = adminProfit.length > 25 ? 25 : adminProfit.length;
        let totalPage = Math.ceil(totalRecord / perPageRecor);

        let finalTotal = 0;
        for (let i = 0; i < totalPage; i++) {
            let pageTotal = 0;
            _html += `      <div class="img-container">
                                <img src="/vyanjanLogo.png" alt="logo" height="50px"/>
                                <span>Admin Commission</span>
                            </div>
                            <div class="date-container">
                                <div>
                                    `+ ((userValue.fromDate && userValue.toDate) ? `<p>` + (format(new Date(userValue.fromDate), formatedDate)) + `-` + (format(new Date(userValue.toDate), formatedDate)) + `</p>` : ``) + `
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <th class="th1">#</th>
                                    <th class="th2">Food Makers</th>
                                    <th class="th3">Admin Commission</th>
                                </thead>
                                <tbody>`;

            for (let j = (perPageRecor * i); j < (perPageRecor * (i + 1)); j++) {
                _html += `          <tr>
                                        <td class="td1">`+ (j + 1) + `</td>
                                        <td class="td2">`+ adminProfit[j].displayName + `</td>
                                        <td class="td3">₹`+ (adminProfit[j].adminCommission ).toFixed(2) + `</td>
                                    </tr>`;
                pageTotal += parseFloat((adminProfit[j].adminCommission ).toFixed(2));
                finalTotal += parseFloat((adminProfit[j].adminCommission ).toFixed(2));
            }

            _html += `          </tbody>
                                <tfoot>`
            _html += `              <tr class="page-break">
                                        <td class="td1"></td>
                                        <td class="td2">Total</td>
                                        <td class="td3">₹`+ pageTotal + `</td>
                                    </tr>

                                </tfoot>
                            </table>`
            pageTotal = 0;
        }
        _html += `          <table>
                                <tfoot>
                                    <tr class="page-break">
                                        <td class="td1"></td>
                                        <td class="td2">Grand Total</td>
                                        <td class="td3">₹`+ finalTotal + `</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </body>
                    </html>`

        let frame1: any = document.createElement('iframe');
        frame1.name = "frame1";
        frame1.style.position = "absolute";
        frame1.style.top = "-1000000px";
        document.body.appendChild(frame1);
        frame1.contentWindow.document.open();
        frame1.contentWindow.document.write(_html);
        frame1.contentWindow.document.close();
    }

    // page change
    const handlePageChange = async (event: any, newPage: number) => {
        setPage(newPage);
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    // when cloud function is called
    async function searchData(url = '', data = {}) {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData; // return the response data
        } catch (error) {
            console.error(error);
        }
    }

    // invoked everytime when their is searchvalue in searchBox
    const searchItems = async (searchValue: any) => {
        if (searchValue) {
            setFlag(true);
            setPage(0);
            setSearchInput(searchValue);
            searchInput = searchValue
            try {
                const items: any[] = [];
                const itemsc: any[] = [];
                const items1: any[] = [];
                let data: any[] = [];
                let data1: any[] = [];
                let sort: any[] = [];
                let amount: any[] = [];

                let qc = query(collection(db, 'users'));
                const querySnapshotc = await getDocs(qc)
                querySnapshotc.forEach((docc: any) => {
                    itemsc.push({ id: docc.id, ...docc.data() });
                });

                const requestBody = {
                    query: searchValue,
                    role: "foodMaker",
                    size: itemsc.length
                };
                const result = await searchData(process.env.REACT_APP_USER_SEARCH, requestBody);
                const userOrderCollectionRef = collection(db, 'userOrders');
                let q1 = query(userOrderCollectionRef);
                if (userValue.fromDate && userValue.toDate) {
                    let firstDate = Timestamp.fromDate(userValue.fromDate);
                    let secondDate = Timestamp.fromDate(new Date(new Date(userValue.toDate).setDate(new Date(userValue.toDate).getDate() + 1)));
                    q1 = query(q1, where('createdDate', '>=', firstDate), where('createdDate', '<', secondDate))
                    // let q1 = query(userOrderCollectionRef, where('createdDate', '>=', firstDate), where('createdDate', '<', secondDate));
                }
                const querySnapshot1 = await getDocs(q1)
                querySnapshot1.forEach((doc1: any) => {
                    items1.push({ id: doc1.id, ...doc1.data() });
                });
                for (let i = 0; i < result.data.length; i++) {
                    let element = result.data[i]._source;
                    const usersCollectionRef = collection(db, 'users');
                    let q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true),
                        orderBy('firstName', 'asc'))

                    q = query(q, where('displayName', '==', element.displayName))
                    const querySnapshot = await getDocs(q)
                    querySnapshot.forEach((doc: any) => {
                        items.push({ id: doc.id, ...doc.data() });
                    });
                }
                const filteredItems = items1.filter(item => item.orderStatus !== 'Cancelled');

                data1 = items.map((e: any) => {
                    return ({
                        ...e,
                        finalAmount1: filteredItems.filter((d: any) => e.uid === d.makerId),
                    })
                })
                data = items.map((e: any) => {
                    return ({
                        ...e,
                        finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
                    })
                })
                let finalData = data.map((e: any) => {
                    return ({
                        ...e,
                        adminCommission: (e.finalAmount) * (e.commission.commission / 100),
                    })
                })
                sort = finalData.sort((a, b) => (a.finalAmount > b.finalAmount) ? -1 : 1)
                amount = sort.filter((e: any) => e.finalAmount > 0)
                let comm = 0;
                for (let i = 0; i < amount.length; i++) {
                    comm += (amount[i].adminCommission)
                    setTotalCommission(comm.toFixed(2))
                }

                if (amount.length > 0) {
                    setAdminProfit(amount);
                    setFlag(true);
                } else {
                    setFlag(false);
                }
                setRow(amount.length);

            }
            catch (error) {
                console.log(error)
                setIsLoading(false);
            }
        } else {
            searchInput = ""
            setSearchInput("")
            await getData();
        }
        setIsLoading(false);
    }

    return (
        <>
            <Helmet>
                <title> Admin Profit Report | Vyanjan-Pursuit for Hunger! </title>
            </Helmet>
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Stack alignItems="left" justifyContent="space-between" mb={5}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to="/admin/dashboard"
                                    style={{ display: 'flex', color: "black" }}
                                >
                                    <HomeIcon />
                                </Link>
                                <Typography color="inherit">Report-Admin Commission</Typography>
                            </Breadcrumbs>
                        </Stack>
                        <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                            <div>
                                {isLoading ? <Loader title="Loading..." /> :
                                    <div >
                                        <div >
                                            <StyledRoot
                                                sx={{
                                                    color: 'primary.main',
                                                    bgcolor: 'white',
                                                }}
                                            >
                                                <StyledSearch
                                                    value={searchInput}
                                                    onChange={(e) => searchItems(e.target.value)}
                                                    placeholder="Search by FoodMakers..."
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                                        </InputAdornment>
                                                    }
                                                />
                                                <div className='largeScreenContent'>
                                                    {chipStartDate === true && chipEndDate === true ?
                                                        <>{
                                                            userValue.fromDate && userValue.toDate ?
                                                                <Chip color="primary" size="small" label={format(new Date(userValue.fromDate), formatedDate) + "-" + format(new Date(userValue.toDate), formatedDate)} sx={{ mx: 1 }}
                                                                    onDelete={(e: any) => handleDelete(userValue.fromDate)}
                                                                /> : ""
                                                        }
                                                        </>
                                                        :
                                                        ''}
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    {(flag === true) ?
                                                        <>
                                                            <Tooltip title="Print" arrow>
                                                                <IconButton
                                                                    sx={{ color: '#1565c0' }}
                                                                    color="inherit"
                                                                    size="small"
                                                                    onClick={handlePrint}
                                                                >
                                                                    <LocalPrintshopRoundedIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Export File" arrow>
                                                                <IconButton
                                                                    sx={{ color: '#1565c0', marginTop: "4px" }}
                                                                    color="inherit"
                                                                    size="small"
                                                                >
                                                                    <CSVLink {...csvlink} style={{ color: "#1565c0" }}><UploadFileRoundedIcon /></CSVLink>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Select Date" arrow>
                                                                <IconButton
                                                                    sx={{ color: '#1565c0' }}
                                                                    color="inherit"
                                                                    size="small"
                                                                    onClick={handleOpenRangePicker}
                                                                >
                                                                    <DateRangeIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </> :
                                                        <>
                                                            <Tooltip title="Select Date" arrow>
                                                                <IconButton
                                                                    sx={{ color: '#1565c0' }}
                                                                    color="inherit"
                                                                    size="small"
                                                                    onClick={handleOpenRangePicker}
                                                                >
                                                                    <DateRangeIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>}
                                                </div>
                                            </StyledRoot>
                                            <div className='smallScreenContent'>
                                                {chipStartDate === true && chipEndDate === true ?
                                                    <>{
                                                        userValue.fromDate && userValue.toDate ?
                                                            <Chip color="primary" size="small" label={format(new Date(userValue.fromDate), formatedDate) + "-" + format(new Date(userValue.toDate), formatedDate)} sx={{ mx: 1 }}
                                                                onDelete={(e: any) => handleDelete(userValue.fromDate)}
                                                            /> : ""
                                                    }
                                                    </>
                                                    :
                                                    ''}
                                            </div>
                                        </div>
                                        {(flag === true) ?
                                            <>
                                                <Scrollbar>
                                                    <TableContainer className="reportAdmin-list" sx={{ backgroundColor: "white" }}>
                                                        <Table stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center" >#</TableCell>
                                                                    <TableCell align="center" sx={{ color: '#212B36' }} ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Makers</Typography></TableCell>
                                                                    <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Admin Commission</Typography></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {adminProfit
                                                                    .slice(page * limit, page * limit + limit)
                                                                    .map((item: any, index: number) => (
                                                                        <TableRow hover key={item.id} sx={{ bgcolor: 'white' }}>
                                                                            <TableCell align="center" >  {(page * limit) + index + 1}</TableCell>
                                                                            <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                <Typography variant="subtitle2" noWrap>{item.displayName}</Typography></TableCell>
                                                                            <TableCell align="center" >₹ {(item.adminCommission).toFixed(2)}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                <TableRow>
                                                                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                                                                    <TableCell align="center"><Typography noWrap variant="subtitle2" fontSize="0.875rem">Total</Typography></TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: "bold" }}><Typography noWrap fontWeight="400" fontSize="0.875rem">₹ {
                                                                        adminProfit
                                                                            .slice(page * limit, page * limit + limit).reduce((a: any, v: any) => a + (v.adminCommission), 0).toFixed(2)
                                                                    }</Typography></TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                                                                    <TableCell align="center"><Typography noWrap variant="subtitle2" fontSize="0.875rem">Grand Total Amount</Typography></TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: "bold" }}><Typography noWrap fontWeight="400" fontSize="0.875rem">₹ {totalCommission}</Typography></TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Scrollbar>
                                                <TablePagination
                                                    component="div"
                                                    count={row}
                                                    onPageChange={handlePageChange}
                                                    onRowsPerPageChange={handleLimitChange}
                                                    page={page}
                                                    rowsPerPage={limit}
                                                    rowsPerPageOptions={[10, 20, 30, 40]}
                                                />
                                            </>
                                            :
                                            <TableContainer className="reportAdmin-list-notFound" sx={{ backgroundColor: "white" }}>
                                                <Table>
                                                    <Divider />
                                                    <TableBody>
                                                        <TableRow>
                                                            <Paper
                                                                sx={{
                                                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                }}
                                                            >
                                                                <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                <Typography variant="h6" paragraph>
                                                                    Data not Available
                                                                </Typography>
                                                            </Paper>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }

                                    </div >
                                }
                            </div >
                        </Card>
                    </Grid>
                </Grid>
            </Container >

            <div className='dialogSmall'>
                <Dialog open={isOpen} onClose={handleCloseDailog}
                    PaperProps={{
                        sx: {
                            maxWidth: 'none',
                            width: "306.33px",
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            borderRadius: '6px',
                            boxShadow: 24,
                            margin: '0px',
                        }
                    }}
                    className='dialogSmall'
                >
                    <DialogTitle sx={{ marginBottom: '20px' }}>
                        <IconButton
                            sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 3, top: 12 }}
                            onClick={handleCloseDailog}
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DateRangePicker
                            className='rangepicker'
                            // ranges={[selectionRange]}
                            ranges={state}
                            onChange={handleSelect}
                        />
                    </DialogContent>
                    <DialogActions sx={{ pl: '24px', pr: '24px' }} >
                        <Button
                            onClick={save}
                        >Save</Button>
                        <Button
                            onClick={handleClear}
                        >Clear</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div className='dialogLarge'>
                <Dialog open={isOpen} onClose={handleCloseDailog}
                    PaperProps={{
                        sx: {
                            maxWidth: 'none',
                            width: "606.33px",
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            borderRadius: '6px',
                            boxShadow: 24,
                            margin: '0px',
                        }
                    }}
                    className='dialogLarge'
                >
                    <DialogTitle sx={{ marginBottom: '20px' }}>
                        <IconButton
                            sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 3, top: 12 }}
                            onClick={handleCloseDailog}
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DateRangePicker
                            className='rangepicker'
                            // ranges={[selectionRange]}
                            ranges={state}
                            onChange={handleSelect}
                        />
                    </DialogContent>
                    <DialogActions sx={{ pl: '24px', pr: '24px' }} >
                        <Button
                            onClick={save}
                        >Save</Button>
                        <Button
                            onClick={handleClear}
                        >Clear</Button>
                    </DialogActions>
                </Dialog>
            </div>

        </>
    )
}
