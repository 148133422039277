import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, ListItemButton, ListItemText, Collapse, List } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import navConfig from './config';
import '../../../App.css'
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AppsIcon from '@mui/icons-material/Apps';

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }: any) {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg', 'end');
  const navigate = useNavigate();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const handleClick = () => {
    setOpen(!open);
    setIsOpen(false);
  };

  const handleClick1 = () => {
    setIsOpen(!isOpen);
    setOpen(false);
  }

  const handleTopFoodMaker = () => {
    navigate("/admin/topfoodmakers")
  }

  const handleTopProductItem = () => {
    navigate("/admin/topsellingproducts")
  }

  const handleAdminProfit = () => {
    navigate("/admin/admincommission")
  }

  const handleTopFoodSeeker = () => {
    navigate('/admin/topfoodseekers')
  }

  const handleTopProductCategory = () => {
    navigate('/admin/topsellbyproductcategory')
  }

  const handleProductCategory = () => {
    navigate('/admin/productcategory')
  }

  const handleSetting = () => {
    navigate('/admin/setting')
  }

  const handleReward = () => {
    navigate('/admin/reward')
  }

  const handlePayoutRelease = () => {
    navigate('/admin/payoutreleaseperiod')
  }

  const imageClick = () => {
    navigate('/admin/dashboard')
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ pt: '12px', pb: '12px', pl: '24px', pr: '24px', display: 'inline-flex' }} >
        <img src="/vyanjanTagline.png" alt="logo" onClick={imageClick} />
      </Box>

      <NavSection data={navConfig} />
      <Box
        sx={{ px: 1.5, mt: -1 }}
      >
        <ListItemButton onClick={handleClick} >
          {<AssessmentIcon sx={{ marginLeft: "-4px", marginRight: "11px", color: "text.secondary" }} />}
          <ListItemText
            disableTypography
            sx={{ color: "text.secondary", fontSize: 15.5, ml: 0.5 }}
            title="Report"
          >Report</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" >
          <List component="div" disablePadding >
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handleTopFoodMaker(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Top Food Makers"
              >Top Food Makers</ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handleTopProductItem(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Top Selling Product"
              >Top Selling Products </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handleAdminProfit(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Admin Profit"
              >Admin Commission</ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handleTopFoodSeeker(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Top Food Seekers"
              >Top Food Seekers</ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handleTopProductCategory(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Top Product Category"
              >Top Sell By Product Category</ListItemText>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClick1}>
          {<AppsIcon sx={{ marginLeft: "-4px", marginRight: "11px", color: "text.secondary" }} />}
          <ListItemText
            disableTypography
            sx={{ color: "text.secondary", fontSize: 15.5, ml: 0.5 }}
            title="Admin Options"
          >Admin Options</ListItemText>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isOpen} timeout="auto">
          <List component="div" disablePadding >
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handleSetting(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Setting"
              >Setting</ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handleReward(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Reward"
              >Reward</ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handleProductCategory(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Product Category"
              >Product Category</ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={() => { handlePayoutRelease(); onCloseNav(); }}>
              <ListItemText
                disableTypography
                sx={{ color: "text.secondary", fontSize: 15.5, mx: 1.5 }}
                title="Payout Release Period"
              >Payout Release Period</ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
      </Box>
    </Scrollbar >
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }
          }
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}