import { Helmet } from 'react-helmet-async';
import {
  Card,
  Stack,
  Container,
  Typography,
  Box,
  Grid,
  Button,
  Breadcrumbs,
  Paper,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { useEffect, useState } from 'react';
import { db } from './../firebase'
import { collection, getDocs, query, where } from 'firebase/firestore';
import Loader from './spinner';

export default function PaymentTransactionDetailPage() {
  const [detail, setDetail] = useState<any>([]) //show data inside page
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState<any>([])
  const [flag, setFlag] = useState<boolean>(true);
  const id = useParams();
  const navigate = useNavigate();
  const navigateOrderDetail = (id: any) => {
    navigate(`/admin/orders/view/${id}`);
  }

  // it is the first method invoked when this page is open
  useEffect(() => {
    getdata()
    let order = sessionStorage.getItem('orderNo');
    if (order) {
      setOrderData(order)
    }
  }, [])

  //call intially
  const getdata = async () => {
    let items: any[] = [];
    setIsLoading(true);
    try {
      const q = query(collection(db, 'userOrderPayments'), where('id', '==', id.id))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        let itm = doc.data();
        let obj = {
          id: doc.data().userOrderId,
        }
        if (obj.id) {
          const q = query(collection(db, 'userOrders'), where('id', '==', obj.id))
          const querySnapshot1 = await getDocs(q);
          querySnapshot1.forEach(async (doc1) => {
            itm.orderDataId = doc1.data().id
            itm.orderNo = doc1.data().orderNo
            itm.quantity = (doc1.data().orderDetail[0].quantity)
            let obj1 = {
              makerId: doc1.data().makerId,
            }
            if (obj1.makerId) {
              const q = query(collection(db, 'users'), where('uid', '==', obj1.makerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                let adminProfit = (doc.data().amount) * (_doc.data().commission.commission / 100)
                itm.commission = adminProfit
              })
              items.push(itm)
              setDetail(items)
              if (items.length > 0) {
                setFlag(true);
              } else {
                setFlag(false);
              }
            }
          })
        }
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Helmet>
        <title> Orders Transaction Details | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack alignItems="left" justifyContent="space-between" mb={5}>
              <Typography variant="h5" gutterBottom mb={0}>
              </Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Link
                  to="/admin/ordersTransaction"
                  style={{ display: 'flex', color: "black", textDecoration: "none" }}
                >
                  Orders Transaction
                </Link>
                <Typography color="inherit" >{orderData}</Typography>
              </Breadcrumbs>
            </Stack>
            <Card className="card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', overflow: 'auto', textAlign: "justify" }}>
              <div>
                {isLoading ? <Loader title="Loading..." /> :
                  <>
                    {(flag === true) ?
                      <>
                        {detail.map((data: any) => (
                          <Grid container spacing={0} sx={{ display: "flex" }} key={data.id}>
                            <Grid item xs={12} sm={6} md={6} >
                              {/* <Card sx={{ mx: 2, mt: 2, bgcolor: '#F9FAFB', boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', height: "316.03px", overflow: "auto" }}> */}
                              <Typography gutterBottom >
                                <h5> <Box display="flex" alignItems="flex-start" ><Typography variant="subtitle2" sx={{ pl: 2 }}>Order Number</Typography><Box sx={{ pl: 15, color: 'grey' }}>
                                  <Button onClick={(e) => { navigateOrderDetail(data.orderDataId) }} sx={{ marginTop: "-7px" }}>{data.orderNo}</Button>
                                </Box></Box></h5>
                              </Typography>
                              {data.payment && data.payment.length > 1 ?
                                <>
                                  <Typography gutterBottom >
                                    <h5><Box display="flex" alignItems="flex-start"><Typography variant="subtitle2" sx={{ pl: 2 }}>Transaction Id</Typography><Box sx={{ pl: 16.4, color: 'grey' }}>{data.payment[0].transactionId ? data.payment[0].transactionId : data.payment[1].transactionId}</Box></Box> </h5>
                                  </Typography>
                                  <Typography gutterBottom >
                                    <h5><Box display="flex" alignItems="flex-start"><Typography variant="subtitle2" sx={{ pl: 2 }}>Payment Mode</Typography><Box sx={{ pl: 15.9, color: 'grey' }}>{(data.payment[0].paymentMode).charAt(0).toUpperCase() + (data.payment[0].paymentMode).substr(1).toLowerCase()} + {(data.payment[1].paymentMode).charAt(0).toUpperCase() + (data.payment[1].paymentMode).substr(1).toLowerCase()}</Box></Box> </h5>
                                  </Typography>
                                  <Typography gutterBottom >
                                    <h5><Box display="flex" alignItems="flex-start"><Typography variant="subtitle2" sx={{ pl: 2 }}>Wallet Amount</Typography><Box sx={{ pl: 15.9, color: 'grey' }}>₹ {(data.payment[0].amount) ? ((data.payment[0].amount)).toFixed(2) : "--"}</Box></Box> </h5>
                                  </Typography>
                                  <Typography gutterBottom >
                                    <h5><Box display="flex" alignItems="flex-start"><Typography variant="subtitle2" sx={{ pl: 2 }}>Razorpay Amount</Typography><Box sx={{ pl: 13.5, color: 'grey' }}>₹ {(data.payment[1].amount) ? ((data.payment[1].amount)).toFixed(2) : "--"}</Box></Box> </h5>
                                  </Typography>
                                </>
                                :
                                <>
                                  <Typography gutterBottom >
                                    <h5><Box display="flex" alignItems="flex-start"><Typography variant="subtitle2" sx={{ pl: 2 }}>Transaction Id</Typography><Box sx={{ pl: 16.4, color: 'grey' }}>{(data.payment[0].transactionId) ? data.payment[0].transactionId : "--"}</Box></Box> </h5>
                                  </Typography>
                                  <Typography gutterBottom >
                                    <h5><Box display="flex" alignItems="flex-start"><Typography variant="subtitle2" sx={{ pl: 2 }}>Payment Mode</Typography><Box sx={{ pl: 15.9, color: 'grey' }}>{data.payment[0].paymentMode ? ((data.payment[0].paymentMode).charAt(0).toUpperCase() + (data.payment[0].paymentMode).substr(1).toLowerCase()) : "--"}</Box></Box> </h5>
                                  </Typography>
                                </>
                              }
                              <Typography gutterBottom >
                                <h5><Box display="flex" alignItems="flex-start"><Typography variant="subtitle2" sx={{ pl: 2 }}>User Preference</Typography> <Box sx={{ pl: 14.8, color: 'grey' }}>{data.userPreference}</Box></Box></h5>
                              </Typography>
                              <Typography gutterBottom >
                                <h5><Box display="flex" alignItems="flex-start"><Typography variant="subtitle2" sx={{ pl: 2 }}>Total Products</Typography> <Box sx={{ pl: 16.4, color: 'grey' }}>{data.quantity}</Box></Box></h5>
                              </Typography>
                              <Typography gutterBottom >
                                <h5> <Box display="flex" alignItems="flex-end" ><Typography variant="subtitle2" sx={{ pl: 2 }}>Total Amount</Typography> <Box sx={{ pl: 16.8, color: 'grey' }}>₹ {(data.amount ).toFixed(2)}</Box></Box></h5>
                              </Typography>
                              <Typography gutterBottom >
                                <h5> <Box display="flex" alignItems="flex-end"><Typography variant="subtitle2" sx={{ pl: 2 }}>Admin Commission</Typography> <Box sx={{ pl: 12.2, color: 'grey' }}>₹ {(data.commission).toFixed(2)}</Box></Box></h5>
                              </Typography>
                              <Typography gutterBottom >
                                <h5> <Box display="flex" alignItems="flex-end"><Typography variant="subtitle2" sx={{ pl: 2 }}>Makers Payable</Typography> <Box sx={{ pl: 14.9, color: 'grey' }}>₹ {(((data.amount - data.commission)).toFixed(2))}</Box></Box></h5>
                              </Typography>
                              {/* </Card> */}
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={6} >
                              <Card sx={{ mx: 2, mt: 2, boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', bgcolor: '#F9FAFB', height: "250.03px", overflow: "auto" }}>

                              </Card>
                            </Grid> */}
                          </Grid>
                        ))}
                      </>
                      :
                      <>
                        <Paper
                          sx={{
                            width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                          }}
                        >
                          <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                          <Typography variant="h6" paragraph>
                            Data not Available
                          </Typography>
                        </Paper>
                      </>
                    }
                  </>
                }
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container >
    </>
  );
}