import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TableHead,
    Tooltip,
    TextField,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    DialogTitle,
    Grid,
    alpha,
    OutlinedInput,
    styled,
    Toolbar,
    InputAdornment,
    Breadcrumbs,
    Switch,
    FormHelperText,
    Paper,
    Divider,
    FormGroup
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import Loader from './spinner';
import CancelIcon from '@mui/icons-material/Cancel';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import Iconify from '../components/iconify';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './PayoutRelease.css';
import './FoodMakerPage.css';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 280,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

const initialState = {
    id: "",
    payoutPeriod: "",
    days: "",
    isActive: ""
}

export const PayoutRelease = () => {
    const [payoutRelease, setPayoutRelease] = useState<any>(initialState)
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [isDel, setIsDel] = useState(false);
    const [detail, setDetail] = useState<any>([]);//to show data inside page
    const [isValueError, setValueError] = useState(false);
    const [ValueErrorMsg, setValueErrorMsg] = useState("");
    const [isDayValueError, setDayValueError] = useState(false);
    const [DayValueErrorMsg, setDayValueErrorMsg] = useState("");
    let [searchInput, setSearchInput] = useState('');
    const [flag, setFlag] = useState<boolean>(true);

    const reg = new RegExp(/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/); // regex with no special character
    const validation = (e: any) => {
        if (e.target.value) {
            if ((reg.test(e.target.value))) {
                setValueError(false);
                setValueErrorMsg("");
            } else {
                setValueError(true);
                setValueErrorMsg("Special Character is not allowed");
            }
        } else {
            setValueError(true);
            setValueErrorMsg("Payout period is required");
        }
    }

    const reg1 = new RegExp(/^\d*$/); //regex number allowes
    const validation1 = (e: any) => {
        if (e.target.value) {
            if ((reg1.test(e.target.value))) {
                setDayValueError(false);
                setDayValueErrorMsg("");
            } else {
                setDayValueError(true);
                setDayValueErrorMsg("Only Numbers is allowed");
            }
        } else {
            setDayValueError(true);
            setDayValueErrorMsg("Days is required");
        }
    }

    // validate form
    const validateForm = (e: any) => {
        e.preventDefault();
        let flag = true;
        if (!payoutRelease.payoutPeriod) {
            setValueError(true);
            setValueErrorMsg("Payout period is required");
            flag = false;
        } else {
            if ((reg.test(payoutRelease.payoutPeriod))) {
                setValueError(false);
                setValueErrorMsg("");
            } else {
                setValueError(true);
                setValueErrorMsg("Special Character is not allowed");
            }
        }
        if (!payoutRelease.days) {
            setDayValueError(true);
            setDayValueErrorMsg("Days is required");
            flag = false;
        } else {
            if ((reg1.test(payoutRelease.days))) {
                setDayValueError(false);
                setDayValueErrorMsg("");
            } else {
                setDayValueError(true);
                setDayValueErrorMsg("Only Numbers is allowed");
                flag = false;
            }
        }
        return flag;
    }

    //close dialog
    const handleClose = () => {
        setOpen(false)
    }

    // change in payoutrealese
    const handleChange = (arr: any) => {
        const { name, value } = arr.target;
        setPayoutRelease({ ...payoutRelease, [name]: value })
        setOpen(true)
    }

    //close switch dialog
    const handleCloseSwitch = () => {
        setIsCheck(false);
    }

    //click on switch
    const handleSwitch = async (id: number, isActive: boolean) => {
        let obj = {
            "id": id,
            "isActive": isActive
        }
        setPayoutRelease(obj);
        setIsCheck(true);
    };

    //save switch changes
    const handleCheckSwitch = async () => {
        try {
            if (payoutRelease.isActive === true) {
                const obj = {
                    isActive: false
                }
                const activeStatus = doc(db, 'payoutRelease', payoutRelease.id)
                updateDoc(activeStatus, obj)
                let data = JSON.parse(JSON.stringify(detail));
                let ind = data.findIndex((c: any) => c.id === payoutRelease.id);
                if (ind > -1) {
                    data[ind].isActive = !data[ind].isActive;
                }
                setDetail(data);
            } else if (payoutRelease.isActive === false) {
                const obj = {
                    isActive: true
                }
                const activeStatus = doc(db, 'payoutRelease', payoutRelease.id)
                updateDoc(activeStatus, obj)
                let data = JSON.parse(JSON.stringify(detail));
                let ind = data.findIndex((c: any) => c.id === payoutRelease.id);
                if (ind > -1) {
                    data[ind].isActive = !data[ind].isActive;
                }
                setDetail(data);
            }
        } catch (error) {
            console.log(error)
        }
        setIsCheck(false);
    };

    //click on add icon
    const handleClickisAdd = () => {
        setPayoutRelease(initialState)
        setOpen(true)
        setValueError(false);
        setValueErrorMsg("");
        setDayValueError(false);
        setDayValueErrorMsg("");
    }

    //click on edit icon
    const handleClickisEdit = (no: string, str: string, str1: number) => {
        setValueError(false);
        setValueErrorMsg("");
        setDayValueError(false);
        setDayValueErrorMsg("");
        let obj = {
            id: no,
            payoutPeriod: str,
            days: str1,
        }

        setPayoutRelease(obj);
        setOpen(true);
    };

    //click on delete icon
    const handleClickisDelete = (no: string) => {
        let obj = {
            id: no,
        }

        setPayoutRelease(obj);
        setIsDel(true);
    };

    // close delete dialog
    const handleCancel = () => {
        setIsDel(false);
    }

    // when clicked on delete
    const handleDelete = async () => {
        const payoutRef = collection(db, 'payoutRelease');
        try {
            const deleteRef = doc(payoutRef, payoutRelease.id);
            deleteDoc(deleteRef);
        }
        catch (error) {
            console.error(error);
        }
        setIsDel(false);
        getPayoutRelease();
    }

    //when clicked on save
    const savePayoutRelease = async (e: any) => {
        if (payoutRelease.id) {
            let flag = validateForm(e);
            if (detail && detail.length > 0) {
                let ind = detail.findIndex((c: any) => c.days === payoutRelease.days && c.id !== payoutRelease.id || (c.payoutPeriod.toLowerCase() === payoutRelease.payoutPeriod.toLowerCase() && c.id !== payoutRelease.id));
                flag = ind >= 0 ? false : true
                if (ind >= 0) {
                    toast.error('Data Already Exists!', {
                        autoClose: 6000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored"
                    });
                }
            }
            if (flag) {
                try {
                    const obj = {
                        'id': payoutRelease.id,
                        'payoutPeriod': payoutRelease.payoutPeriod,
                        'days': parseInt(payoutRelease.days)
                    }
                    const dataRef = doc(db, 'payoutRelease', payoutRelease.id)
                    updateDoc(dataRef, obj)
                    getPayoutRelease()
                } catch (error) {
                    console.log(error)
                    setIsLoading(false)
                }
                setOpen(false)
            }
        }
        else {
            let flag = validateForm(e)
            let nextOrder = 1;
            if (flag) {
                if (detail && detail.length > 0) {
                    let detailData = detail;
                    let ind = detailData.findIndex((c: any) => c.days === payoutRelease.days || c.payoutPeriod.toLowerCase() === payoutRelease.payoutPeriod.toLowerCase());
                    // let flag1 = ind >= 0 ? true : false;
                    detailData.sort((a: any, b: any) => (a.displayOrder > b.displayOrder) ? -1 : 1);
                    nextOrder = detailData && detailData.length > 0 && (detailData[0].displayOrder + 1);
                    // if (flag1) {
                    if (ind >= 0) {
                        toast.error('Data Already Exists!', {
                            autoClose: 6000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored"
                        });
                    }
                    else {
                        const arr = payoutRelease.payoutPeriod.split(" ");
                        for (let i = 0; i < arr.length; i++) {
                            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
                        }
                        const str = arr.join(" ");
                        const payoutRef = collection(db, 'payoutRelease')
                        await addDoc(payoutRef, {
                            payoutPeriod: str,
                            days: parseInt(payoutRelease.days),
                            isActive: true,
                            displayOrder: nextOrder,
                        })
                        getPayoutRelease()
                        setOpen(false);
                    }
                }
            }
        }
    }

    // it is the first method invoked when this page is open
    useEffect(() => {
        getPayoutRelease();
    }, [])

    //call intially
    const getPayoutRelease = async () => {
        if (searchInput) {
            setIsLoading(false);
            setFlag(true);
        } else {
            setIsLoading(true);
        }
        try {
            let items: any[] = [];
            let items1: any[] = [];
            let data: any[] = [];
            let data1: any[] = [];
            const PayoutRef = collection(db, 'payoutRelease')
            let q = query(PayoutRef);
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push({ id: doc.id, ...doc.data() });
            });

            const usersCollectionRef = collection(db, 'users');
            const q1 = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker') );
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach((doc: any) => {
                items1.push({ id: doc.id, ...doc.data() });
            });

            data1 = items.map((e: any) => {
                return ({
                    ...e,
                    total: items1.filter((d: any) => d.payoutReleasePeriod.payoutPeriod === e.payoutPeriod)
                })
            })
            data = data1.map((e: any) => {
                return ({
                    ...e,
                    count: e.total.length,
                })
            })
            setDetail(data);
            if (data.length > 0) {
                setFlag(true);
            } else {
                setFlag(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    // when cloud function is called
    async function searchData(url = '', data = {}) {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData; // return the response data
        } catch (error) {
            console.error(error);
        }
    }

    // invoked everytime when their is searchvalue in searchBox
    const searchItems = async (searchValue: any) => {
        let items: any[] = [];
        let items1: any[] = [];
        let data: any[] = [];
        let data1: any[] = [];
        setSearchInput(searchValue);
        searchInput = searchValue
        if (searchValue) {
            const requestBody = {
                query: searchValue
            };
            const result = await searchData(process.env.REACT_APP_PAYOUTRELEASE_SEARCH, requestBody);
            for (let i = 0; i < result.data.length; i++) {
                const element = result.data[i]._source;
                const q = query(collection(db, 'payoutRelease'), where('payoutPeriod', '==', element.payoutPeriod))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() });
                });

                const usersCollectionRef = collection(db, 'users');
                const q1 = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'));
                const querySnapshot1 = await getDocs(q1);
                querySnapshot1.forEach((doc: any) => {
                    items1.push({ id: doc.id, ...doc.data() });
                });

                data1 = items.map((e: any) => {
                    return ({
                        ...e,
                        total: items1.filter((d: any) => d.payoutReleasePeriod.payoutPeriod === e.payoutPeriod)
                    })
                })
                data = data1.map((e: any) => {
                    return ({
                        ...e,
                        count: e.total.length,
                    })
                })
                setDetail(data);
                if (data.length > 0) {
                    setFlag(true);
                } else {
                    setFlag(false);
                }
            }
            if (data.length > 0) {
                setFlag(true);
            } else {
                setFlag(false);
            }
        } else {
            searchInput = ""
            setSearchInput("")
            await getPayoutRelease();
        }
    }

    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={6000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <Helmet>
                <title>Payout Release Period | Vyanjan-Pursuit for Hunger! </title>
            </Helmet>
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt="-7px">
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to="/admin/dashboard"
                                    style={{ display: 'flex', color: "black" }}
                                >
                                    <HomeIcon />
                                </Link>
                                <Typography color="inherit">Payout Release Period</Typography>
                            </Breadcrumbs>
                            <div className="largeScreenContent">
                                <Button
                                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                                    variant="contained"
                                    onClick={handleClickisAdd}
                                    startIcon={<Iconify icon="eva:plus-fill" />}>
                                    New Payout Period
                                </Button>
                            </div>
                            <div className="smallScreenContent">
                                <Button
                                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                                    variant="contained"
                                    onClick={handleClickisAdd}
                                >
                                    <AddIcon />
                                </Button>
                            </div>
                        </Stack>
                        <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', mt: "-7px" }}>
                            <div>
                                {isLoading ? <Loader title="Loading..." /> :
                                    <>
                                        <StyledRoot
                                            sx={{
                                                color: 'primary.main',
                                                bgcolor: 'white',

                                            }}
                                        >
                                            <StyledSearch
                                                value={searchInput}
                                                onChange={(e) => searchItems(e.target.value)}
                                                placeholder="Search by Payout Period..."
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                                    </InputAdornment>
                                                }
                                            />
                                        </StyledRoot>
                                        {(flag === true) ?
                                            <>
                                                <Scrollbar>
                                                    <TableContainer className="payoutrelease-list" sx={{ bgcolor: 'white' }}>
                                                        <Table stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="left" >#</TableCell>
                                                                    <TableCell align="left" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Payout Period</Typography></TableCell>
                                                                    <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Days</Typography></TableCell>
                                                                    <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {
                                                                    detail.map((arr: any, index: number) => {
                                                                        return (
                                                                            <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                                                                <TableCell align="left" >
                                                                                    {index + 1}
                                                                                </TableCell>
                                                                                <TableCell align="left" sx={{ color: '#212B36' }}>
                                                                                    <Typography variant="subtitle2" noWrap>
                                                                                        {arr.payoutPeriod}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell align="left" >
                                                                                    {arr.days}
                                                                                </TableCell>
                                                                                <TableCell align="right" style={{ display: "flex" }} >
                                                                                    {arr.count === 0 ?
                                                                                        <>
                                                                                            <Tooltip title="Delete" arrow>
                                                                                                <IconButton
                                                                                                    sx={{ pt: 0.5, pb: 0.5, color: '#1565c0' }}
                                                                                                    color="inherit"
                                                                                                    size="small"
                                                                                                    onClick={(e) => handleClickisDelete(arr.id)}
                                                                                                >
                                                                                                    <DeleteForeverIcon fontSize="small" />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            {/* //     : " "}
                                                                                    // {arr.count === 0 ? */}
                                                                                            <Tooltip title={(arr.isActive === true) ? "Active" : "Inactive"} arrow>
                                                                                                <Switch
                                                                                                    checked={arr.isActive}
                                                                                                    onClick={(e) => handleSwitch(arr.id, arr.isActive)}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </>
                                                                                        : " "}

                                                                                    <Tooltip title="Edit" arrow>
                                                                                        <IconButton
                                                                                            sx={{ pt: 0.5, pb: 0.5, color: '#1565c0' }}
                                                                                            color="inherit"
                                                                                            size="small"
                                                                                            onClick={(e) => handleClickisEdit(arr.id, arr.payoutPeriod, arr.days)}
                                                                                        >
                                                                                            <EditTwoToneIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Scrollbar>
                                            </>
                                            :
                                            <TableContainer className="payoutrelease-list-notFound" sx={{ backgroundColor: "white" }}>
                                                <Table>
                                                    <Divider />
                                                    <TableBody>
                                                        <TableRow>
                                                            <Paper
                                                                sx={{
                                                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                }}
                                                            >
                                                                <img src="\search.png" alt="no data" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                <Typography variant="h6" paragraph>
                                                                    Data not Available
                                                                </Typography>
                                                            </Paper>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </>
                                }
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            <div className='dialogsmallScreenContent'>
                <Dialog open={open} onClose={handleClose}
                    PaperProps={{
                        sx: {
                            width: "300.33px",
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            borderRadius: '6px',
                            boxShadow: 24,
                            margin: '0px',
                        }
                    }}
                    className='dialogsmallScreenContent'
                >
                    <DialogTitle sx={{ fontSize: "17px" }}>
                        {payoutRelease.id ? "Edit Payout Period" : "Add Payout Period"}
                        <IconButton
                            sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 3, top: 12 }}
                            onClick={handleClose}
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    {payoutRelease.id ?
                        <DialogContent>
                            <FormGroup>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    label="Payout Period"
                                    type="text"
                                    variant="outlined"
                                    name="payoutPeriod"
                                    disabled
                                    value={payoutRelease.payoutPeriod}
                                    required={true}
                                />
                                <FormHelperText style={{ color: 'red', height: '22px' }}>{isValueError && ValueErrorMsg}</FormHelperText>
                            </FormGroup>
                            <FormGroup>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Days "
                                    type="number"
                                    variant="outlined"
                                    name="days"
                                    value={payoutRelease.days}
                                    onChange={(e) => { handleChange(e); validation1(e) }}
                                    required={true}
                                />
                                <FormHelperText style={{ color: 'red', height: '22px' }}>{isDayValueError && DayValueErrorMsg}</FormHelperText>
                            </FormGroup>
                        </DialogContent>
                        :
                        <DialogContent>
                            <FormGroup>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Payout Period"
                                    type="text"
                                    variant="outlined"
                                    name="payoutPeriod"
                                    value={payoutRelease.payoutPeriod}
                                    onChange={(e) => { handleChange(e); validation(e) }}
                                    required={true}
                                />
                                <FormHelperText style={{ color: 'red', height: '22px' }}>{isValueError && ValueErrorMsg}</FormHelperText>
                            </FormGroup>
                            <FormGroup>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    label="Days "
                                    type="number"
                                    variant="outlined"
                                    name="days"
                                    value={payoutRelease.days}
                                    onChange={(e) => { handleChange(e); validation1(e) }}
                                    required={true}
                                />
                                <FormHelperText style={{ color: 'red', height: '22px' }}>{isDayValueError && DayValueErrorMsg}</FormHelperText>
                            </FormGroup>
                        </DialogContent>
                    }
                    <DialogActions sx={{ pl: '24px', pr: "24px" }}>
                        <Button onClick={savePayoutRelease}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div className='dialoglargeScreenContent'>
                <Dialog open={open} onClose={handleClose}
                    PaperProps={{
                        sx: {
                            width: "386.33px",
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            borderRadius: '6px',
                            boxShadow: 24,
                            margin: '0px',
                        }
                    }}
                    className='dialoglargeScreenContent'
                >
                    <DialogTitle sx={{ fontSize: "17px" }}>
                        {payoutRelease.id ? "Edit Payout Period" : "Add Payout Period"}
                        <IconButton
                            sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 3, top: 12 }}
                            onClick={handleClose}
                        >
                            <CancelIcon />
                        </IconButton>
                    </DialogTitle>
                    {/* {payoutRelease.id ?
                        <DialogContent>
                            <FormGroup>
                                <TextField
                                    margin="dense"
                                    id="name"
                                    label="Payout Period"
                                    type="text"
                                    variant="outlined"
                                    name="payoutPeriod"
                                    disabled
                                    value={payoutRelease.payoutPeriod}
                                    required={true}
                                />
                                <FormHelperText style={{ color: 'red', height: '22px' }}>{isValueError && ValueErrorMsg}</FormHelperText>
                            </FormGroup>
                            <FormGroup>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Days "
                                    type="number"
                                    variant="outlined"
                                    name="days"
                                    value={payoutRelease.days}
                                    onChange={(e) => { handleChange(e); validation1(e) }}
                                    required={true}
                                />
                                <FormHelperText style={{ color: 'red', height: '22px' }}>{isDayValueError && ValueErrorMsg1}</FormHelperText>
                            </FormGroup>
                        </DialogContent>
                        : */}
                    <DialogContent>
                        <FormGroup>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Payout Period"
                                type="text"
                                variant="outlined"
                                name="payoutPeriod"
                                value={payoutRelease.payoutPeriod}
                                onChange={(e) => { handleChange(e); validation(e) }}
                                required={true}
                            />
                            <FormHelperText style={{ color: 'red', height: '22px' }}>{isValueError && ValueErrorMsg}</FormHelperText>
                        </FormGroup>
                        <FormGroup>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Days "
                                type="number"
                                variant="outlined"
                                name="days"
                                value={payoutRelease.days}
                                onChange={(e) => { handleChange(e); validation1(e) }}
                                required={true}
                            />
                            <FormHelperText style={{ color: 'red', height: '22px' }}>{isDayValueError && DayValueErrorMsg}</FormHelperText>
                        </FormGroup>
                    </DialogContent>
                    {/* } */}
                    <DialogActions sx={{ pl: '24px', pr: "24px" }}>
                        <Button onClick={savePayoutRelease}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog open={isCheck} onClose={handleCloseSwitch}>
                    <DialogTitle>{(payoutRelease.isActive === true) ? 'Active' : 'InActive'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {(payoutRelease.isActive === false) ? 'Are you sure you want to Active?' : 'Are you sure you want to InActive?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSwitch}>Cancel</Button>
                        <Button onClick={handleCheckSwitch}>Yes</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog open={isDel} onClose={handleCancel}>
                    <DialogTitle>Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button onClick={handleDelete}>Delete</Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    )
}
