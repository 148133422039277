import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Container, Card, Stack, Typography, CardContent, Box, CardActions, Grid, Breadcrumbs, Tab, TablePagination, TableContainer, Table, Paper, TableBody, TableRow } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Loader from '../spinner';
import { collection, query, where, getDocs, endBefore, getCountFromServer, limit, limitToLast, startAfter, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { useEffect } from 'react';
import './../FoodMakerPage.css';
import { format } from 'date-fns';

export const Feedback = () => {
    const [value, setValue] = React.useState('1');
    const [page, setPage] = React.useState<number>(0);
    let [limits, setLimits] = React.useState<number>(10);
    const [row, setRow] = React.useState<number>(10);
    const [pageFoodSeeker, setPageFoodSeeker] = React.useState<number>(0);
    let [limitFoodSeeker, setLimitFoodSeeker] = React.useState<number>(10);
    const [rowFoodSeeker, setRowFoodSeeker] = React.useState<number>(10);
    const [dataMaker, setDataMaker] = React.useState<any>([]);
    const [dataSeeker, setDataSeeker] = React.useState<any>([]);
    const [isLoading, setIsLoading] = React.useState(false)
    const [flagMaker, setflagMaker] = React.useState<boolean>(true);
    const [flagSeeker, setflagSeeker] = React.useState<boolean>(true);
    const [lastVisible, setLastVisible] = React.useState<any>();
    const [firstVisible, setFirstVisible] = React.useState<any>();
    const [lastVisibleFoodSeeker, setLastVisibleFoodSeeker] = React.useState<any>();
    const [firstVisibleFoodSeeker, setFirstVisibleFoodSeeker] = React.useState<any>();
    const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

    // it is the first method invoked when this page is open
    useEffect(() => {
        getDataFoodMaker();
    }, [])

    //call intially
    const getDataFoodMaker = async () => {
        setIsLoading(true)
        let items: any[] = [];
        try {
            const feedbackCollectionRef = collection(db, 'feedback')
            const qLenn = query(feedbackCollectionRef, where('roleName', '==', 'foodMaker'), orderBy('createdDate', 'desc'))
            const querySnapshotLen = await getCountFromServer(qLenn);
            setRow(querySnapshotLen.data().count)
            if (querySnapshotLen.data().count > 0) {
                setflagMaker(true);
                const q = query(feedbackCollectionRef, where('roleName', '==', 'foodMaker'), orderBy('createdDate', 'desc'), limit(limits))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items.push((doc.data()))
                })
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisible(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj) => {
                    if (obj.userId) {
                        const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.userId))
                        const querySnapshot = await getDocs(q)
                        querySnapshot.forEach((doc) => {
                            obj.name = doc.data().displayName
                        })
                        if (i === items.length) {
                            setDataMaker(items)
                            if (items.length > 0) {
                                setflagMaker(true);
                            } else {
                                setflagMaker(false);
                            }
                        } else {
                            i++;
                        }
                    }

                })
            } else {
                setflagMaker(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    // call when click on food seeker tab
    const getDataFoodSeeker = async () => {
        const items: any[] = [];
        setIsLoading(true);
        try {
            const feedbackCollectionRef = collection(db, 'feedback')
            const qLenn = query(feedbackCollectionRef, where('roleName', '==', 'foodSeeker'), orderBy('createdDate', 'desc'))
            const querySnapshotLen = await getCountFromServer(qLenn);
            setRowFoodSeeker(querySnapshotLen.data().count)
            if (querySnapshotLen.data().count > 0) {
                setflagSeeker(true);
                const q = query(feedbackCollectionRef, where('roleName', '==', 'foodSeeker'), orderBy('createdDate', 'desc'), limit(limitFoodSeeker))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items.push((doc.data()))
                })
                let i = 1;
                items.forEach(async (obj) => {
                    if (obj.userId) {
                        const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.userId))
                        const querySnapshot = await getDocs(q)
                        querySnapshot.forEach((doc) => {
                            obj.name = doc.data().displayName
                        })
                        if (i === items.length) {
                            setDataSeeker(items)
                            if (items.length > 0) {
                                setflagSeeker(true);
                            } else {
                                setflagSeeker(false);
                            }
                        } else {
                            i++;
                        }
                    }
                })
                setLastVisibleFoodSeeker(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleFoodSeeker(querySnapshot.docs[0]);
            } else {
                setflagSeeker(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    // tab change
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === '1') {
            getDataFoodMaker();
        }
        else if (newValue === '2') {
            getDataFoodSeeker();
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page foodmaker tab
    const handlePageChangeFoodMaker = async (event: any, newPage: any) => {
        setPage(newPage);
        const items: any[] = [];
        if (page < newPage) {
            const feedbackCollectionRef = collection(db, 'feedback')
            const q = query(feedbackCollectionRef, where('roleName', '==', 'foodMaker'), orderBy('createdDate', 'desc'), startAfter(lastVisible), limit(limits))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push((doc.data()))
            })
            let i = 1;
            items.forEach(async (obj) => {
                if (obj.userId) {
                    const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.userId))
                    const querySnapshot = await getDocs(q)
                    querySnapshot.forEach((doc) => {
                        obj.name = doc.data().displayName
                    })
                    if (i === items.length) {
                        setDataMaker(items)
                        if (items.length > 0) {
                            setflagMaker(true);
                        } else {
                            setflagMaker(false);
                        }
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot);
        } else {
            const q = query(collection(db, 'feedback'), where('roleName', '==', 'foodMaker'), orderBy('createdDate', 'desc'), endBefore(firstVisible), limitToLast(limits))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push((doc.data()))
            })
            let i = 1;
            items.forEach(async (obj) => {
                if (obj.userId) {
                    const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.userId))
                    const querySnapshot = await getDocs(q)
                    querySnapshot.forEach((doc) => {
                        obj.name = doc.data().displayName
                    })
                    if (i === items.length) {
                        setDataMaker(items)
                        if (items.length > 0) {
                            setflagMaker(true);
                        } else {
                            setflagMaker(false);
                        }
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page foodseeker tab
    const handlePageChangeFoodSeeker = async (event: any, newPage: any) => {
        setPageFoodSeeker(newPage);
        const items: any[] = [];
        if (pageFoodSeeker < newPage) {
            const feedbackCollectionRef = collection(db, 'feedback')
            const q = query(feedbackCollectionRef, where('roleName', '==', 'foodSeeker'), orderBy('createdDate', 'desc'), startAfter(lastVisibleFoodSeeker), limit(limitFoodSeeker))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push((doc.data()))
            })
            let i = 1;
            items.forEach(async (obj) => {
                if (obj.userId) {
                    const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.userId))
                    const querySnapshot = await getDocs(q)
                    querySnapshot.forEach((doc) => {
                        obj.name = doc.data().displayName
                    })
                    if (i === items.length) {
                        setDataSeeker(items)
                        if (items.length > 0) {
                            setflagSeeker(true);
                        } else {
                            setflagSeeker(false);
                        }
                    } else {
                        i++;
                    }
                }
            })
            updateStateFoodSeeker(querySnapshot);
        } else {
            const feedbackCollectionRef = collection(db, 'feedback')
            const q = query(feedbackCollectionRef, where('roleName', '==', 'foodSeeker'), orderBy('createdDate', 'desc'), endBefore(firstVisibleFoodSeeker), limitToLast(limitFoodSeeker))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push((doc.data()))
            })
            let i = 1;
            items.forEach(async (obj) => {
                if (obj.userId) {
                    const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.userId))
                    const querySnapshot = await getDocs(q)
                    querySnapshot.forEach((doc) => {
                        obj.name = doc.data().displayName
                    })
                    if (i === items.length) {
                        setDataSeeker(items)
                        if (items.length > 0) {
                            setflagSeeker(true);
                        } else {
                            setflagSeeker(false);
                        }
                    } else {
                        i++;
                    }
                }
            })
            updateStateFoodSeeker(querySnapshot);
        }
    };

    // update foodmaker first visible and last visible value according to pagination (next and previous button)
    const updateState = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisible(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisible(documents.docs[documents.docs.length - 1]);
        }
    };

    // update foodseeker first visible and last visible value according to pagination (next and previous button)
    const updateStateFoodSeeker = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleFoodSeeker(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleFoodSeeker(documents.docs[documents.docs.length - 1]);
        }
    };

    //no of rows in foodmaker tab page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleLimitChangeFoodMaker = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setLimits(parseInt(event.target.value, 10));
        getDataFoodMaker();
    };

    //no of rows in page foodseeker tab limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleLimitChangeFoodSeeker = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageFoodSeeker(0);
        setLimitFoodSeeker(parseInt(event.target.value, 10));
        getDataFoodSeeker();
    };

    return (
        <div>
            <Helmet>
                <title> Feedback | Vyanjan-Pursuit for Hunger! </title>
            </Helmet>
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="left" justifyContent="space-between" mb={5}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to="/admin/dashboard"
                                    style={{ display: 'flex', color: "black" }}
                                >
                                    <HomeIcon />
                                </Link>
                                <Typography color="inherit">Feedback</Typography>
                            </Breadcrumbs>
                        </Stack>
                        <Card className="feedback-outside-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }} >
                            <div>
                                {isLoading ? <Loader title="Loading..." /> :
                                    <>
                                        <TabContext value={value}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <TabList
                                                    onChange={handleChange}
                                                    aria-label="scrollable force tabs example"
                                                    variant="scrollable" scrollButtons
                                                    allowScrollButtonsMobile
                                                >
                                                    <Tab label="Food Makers" value="1" />
                                                    <Tab label="Food Seekers" value="2" />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="1"  >
                                                <Card className="feedback-inside-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                    {(flagMaker) ?
                                                        <>
                                                            <TableContainer className="feedback-tablecontainer-list" sx={{ bgcolor: 'white' }}>
                                                                <Table>
                                                                    <Grid >
                                                                        {dataMaker.map((arr: any) => (
                                                                            <Card sx={{ bgcolor: '#F9FAFB', boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', m: 2 }} key={arr.id}>
                                                                                <CardContent><b>{((arr.title).charAt(0).toUpperCase() + (arr.title).substr(1).toLowerCase())}</b></CardContent>
                                                                                <CardContent>  <Typography sx={{ textAlign: "left" }}>{arr.description}</Typography></CardContent>
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                                                                                    <Typography sx={{ textAlign: "left", color: '#637381', pl: '9px' }}>{format(new Date(arr.createdDate.seconds * 1000), formatedDate)}</Typography>
                                                                                    <Typography sx={{ textAlign: "right" }} noWrap><b>~{arr.name}</b></Typography>
                                                                                </Box>
                                                                            </Card>
                                                                        ))}
                                                                    </Grid>
                                                                </Table>
                                                            </TableContainer>
                                                            <TablePagination
                                                                component="div"
                                                                count={row}
                                                                onPageChange={handlePageChangeFoodMaker}
                                                                onRowsPerPageChange={handleLimitChangeFoodMaker}
                                                                page={page}
                                                                rowsPerPage={limits}
                                                                rowsPerPageOptions={[10, 20, 30, 40]}
                                                                sx={{ pt: 1, pb: 1 }}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <TableContainer className="feedback-tablecontainer-list" sx={{ backgroundColor: "white" }}>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <Paper
                                                                                sx={{
                                                                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                                }}
                                                                            >
                                                                                <img src="\search.png" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                                <Typography variant="h6" paragraph>
                                                                                    Data not Available
                                                                                </Typography>
                                                                            </Paper>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </>
                                                    }
                                                </Card>

                                            </TabPanel>
                                            <TabPanel value="2" >
                                                <Card className="feedback-inside-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                    {(flagSeeker) ?
                                                        <>
                                                            <TableContainer className="feedback-tablecontainer-list" sx={{ bgcolor: 'white' }}>
                                                                <Table>
                                                                    <Grid sx={{ justifyContent: "flex-start" }}>
                                                                        {dataSeeker.map((arr: any) => (
                                                                            <Card sx={{ bgcolor: '#F9FAFB', boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', m: 2 }} key={arr.id}>
                                                                                <CardContent><b>{((arr.title).charAt(0).toUpperCase() + (arr.title).substr(1).toLowerCase())}</b></CardContent>
                                                                                <CardContent>  <Typography sx={{ textAlign: "left" }}>{arr.description}</Typography></CardContent>
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                                                                                    <Typography sx={{ textAlign: "left", color: '#637381', pl: '9px' }}>{format(new Date(arr.createdDate.seconds * 1000), formatedDate)}</Typography>
                                                                                    <Typography sx={{ textAlign: "right" }}><b>~{arr.name}</b></Typography>
                                                                                </Box>
                                                                            </Card>
                                                                        ))}
                                                                    </Grid>
                                                                </Table>
                                                            </TableContainer>
                                                            <TablePagination
                                                                component="div"
                                                                count={rowFoodSeeker}
                                                                onPageChange={handlePageChangeFoodSeeker}
                                                                onRowsPerPageChange={handleLimitChangeFoodSeeker}
                                                                page={pageFoodSeeker}
                                                                rowsPerPage={limitFoodSeeker}
                                                                rowsPerPageOptions={[10, 20, 30, 40]}
                                                                sx={{ pt: 1, pb: 1 }}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <TableContainer className="feedback-tablecontainer-list" sx={{ backgroundColor: "white" }}>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <Paper
                                                                                sx={{
                                                                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                                }}
                                                                            >
                                                                                <img src="\search.png" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                                <Typography variant="h6" paragraph>
                                                                                    Data not Available
                                                                                </Typography>
                                                                            </Paper>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </>
                                                    }
                                                </Card>
                                            </TabPanel>
                                        </TabContext>
                                    </>
                                }
                            </div>
                        </Card>
                    </Grid>
                </Grid >
            </Container >
        </div >
    );
}