import { Helmet } from 'react-helmet-async';
// import { styled } from '@mui/material/styles';
import { Typography, Box, Grid } from '@mui/material';
// import useResponsive from '../hooks/useResponsive';
// import { SignUpForm } from '../sections/auth/signup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, TextField, Button, FormHelperText, } from '@mui/material';
import validator from 'validator';
import './../App.css'
import { collection, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../components/iconify';
import { auth, db } from '../firebase';
import LoginLoader from './loginLoader';

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  contactNo: "",
}

// const StyledRoot = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
// }));

// const StyledSection = styled('div')(({ theme }) => ({
//   width: '100%',
//   maxWidth: 500,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   // boxShadow: theme.customShadows.card,
//   backgroundColor: theme.palette.background.default,
// }));

// const StyledContent = styled('div')(({ theme }) => ({
//   maxWidth: 480,
//   margin: 'auto',
//   minHeight: '100vh',
//   display: 'flex',
//   justifyContent: 'center',
//   flexDirection: 'column',
//   padding: theme.spacing(12, 0),
// }));

export default function SignUpPage() {
  // const mdUp = useResponsive('up', 'md', "end");
  const navigate = useNavigate();
  const [signup, setSignUp] = useState<any>(initialState)
  const [isfirstNameValidateError, setIsFirstNameValidateError] = useState(false);
  const [firstNameValidateErrorMsg, setFirstNameValidateErrorMsg] = useState("");
  const [islastNameValidateError, setIsLastNameValidateError] = useState(false);
  const [lastNameValidateErrorMsg, setLastNameValidateErrorMsg] = useState("");
  const [iscontactNumberValidateError, setIsContactNumberValidateError] = useState(false);
  const [contactNumberValidateErrorMsg, setContactNumberValidateErrorMsg] = useState("");
  const [isEmailValidateError, setIsEmailValidateError] = useState(false);
  const [emailValidateErrorMsg, setEmailValidateErrorMsg] = useState("");
  const [isPasswordValidateError, setIsPasswordValidateError] = useState(false);
  const [passwordValidateErrorMsg, setPasswordValidateErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  window.onpopstate = () => {
    navigate(1);
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setSignUp({ ...signup, [name]: value });
  };

  const firstNameRegex = new RegExp(/^[A-Za-z]+$/);
  const validateFirstName = (e: any) => {
    if (e.target.value) {
      if ((firstNameRegex.test(e.target.value))) {
        setIsFirstNameValidateError(false);
        setFirstNameValidateErrorMsg("");
      } else {
        setIsFirstNameValidateError(true);
        setFirstNameValidateErrorMsg("Alphabet is allowed");
      }
    } else {
      setIsFirstNameValidateError(true);
      setFirstNameValidateErrorMsg("Required firstName");
    }
  }
  const LastNameRegex = new RegExp(/^[A-Za-z]+$/);
  const validateLastName = (e: any) => {
    if (e.target.value) {
      if ((LastNameRegex.test(e.target.value))) {
        setIsLastNameValidateError(false);
        setLastNameValidateErrorMsg("");
      } else {
        setIsLastNameValidateError(true);
        setLastNameValidateErrorMsg("Alphabet is allowed");
      }
    } else {
      setIsLastNameValidateError(true);
      setLastNameValidateErrorMsg("Required lastName");
    }
  }
  const ContactNoRegex = new RegExp(/^[\+]?[(]?\d{3}[)]?[-\s\.]?\d{3}[-\s\.]?\d{4,6}$/);
  const validateContactNumber = (e: any) => {
    if (e.target.value) {
      if ((ContactNoRegex.test(e.target.value))) {
        setIsContactNumberValidateError(false);
        setContactNumberValidateErrorMsg("");
      } else {
        setIsContactNumberValidateError(true);
        setContactNumberValidateErrorMsg("number is allowed");
      }
    } else {
      setIsContactNumberValidateError(true);
      setContactNumberValidateErrorMsg("Required contactNo");
    }
  }

  const validateEmail = (e: any) => {
    if (e.target.value) {
      if ((validator.isEmail(e.target.value))) {
        setIsEmailValidateError(false);
        setEmailValidateErrorMsg("");
      } else {
        setIsEmailValidateError(true);
        setEmailValidateErrorMsg("Invalid Email");
      }
    } else {
      setIsEmailValidateError(true);
      setEmailValidateErrorMsg("Required Email");
    }
  }
  const mediumRegex = new RegExp(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*\d))|((?=.*[A-Z])(?=.*\d)))(?=.{8,})/);
  const ValidatePassword = (e: any) => {
    if (e.target.value) {
      if ((mediumRegex.test(e.target.value))) {
        setIsPasswordValidateError(false);
        setPasswordValidateErrorMsg("");
      } else {
        setIsPasswordValidateError(true);
        setPasswordValidateErrorMsg("Password must be 8 character and number");;
      }
    } else {
      setIsPasswordValidateError(true);
      setPasswordValidateErrorMsg("Required Password");
    }
  }

  const validateForm = (event: any) => {
    event.preventDefault();
    let flag = true;
    if (!signup.email) {
      setIsEmailValidateError(true);
      setEmailValidateErrorMsg("Required  Email");
    }
    else {
      if ((validator.isEmail(signup.email))) {
        setIsEmailValidateError(false);
        setEmailValidateErrorMsg("");
      } else {
        setIsEmailValidateError(true);
        setEmailValidateErrorMsg("Invalid Email");
      }
    }
    if (!signup.password) {
      setIsPasswordValidateError(true);
      setPasswordValidateErrorMsg("Required Password");
      flag = false;
    }
    else {
      if ((mediumRegex.test(signup.password))) {
        setIsPasswordValidateError(false);
        setPasswordValidateErrorMsg("");
      } else {
        setIsPasswordValidateError(true);
        setPasswordValidateErrorMsg("Password must be 8 character and number");
        flag = false;
      }
    }
    return flag;
  }

  const handleClick = async (event: any) => {
    setIsLoading(true);
    event.preventDefault();
    let flag = validateForm(event);
    if (flag) {
      try {
        const res = await createUserWithEmailAndPassword(auth, signup.email, signup.password);
        const user = res.user;
        await addDoc(collection(db, "users"), {

          // uid: user.uid,
          authProvider: "local",
          email: user.email,
          firstName: signup.firstName,
          lastName: signup.lastName,
          contactNo: signup.contactNo,
          role: {
            roleName: "admin"
          }
        });
        if (res) {
          localStorage.setItem("RefreshToken", res.user.refreshToken);
          setIsLoading(false);
          navigate('/');
        }
      } catch (err: any) {
        console.error(err);
        toast.error(err.message);
      }

    } setIsLoading(false);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <Helmet>
        <title> SignUp | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>

      <Grid container spacing={0} style={{ flexDirection: 'row', gap: '2rem' }}>
        <Box className="container-info ">
          <Typography className="centered">
            <Box display="flex" alignItems="center">
              <img src="/vyanjanLogo.png" alt="logo" width="24%" className="centered-img" />
              <Box >
                <img src="/vyanjanTagline.png" alt="logo" width="100%" className="centered-img" />
              </Box>
            </Box>
          </Typography>
        </Box >
        <div className="container-main">
          <Box sx={{ width: "80%", margin: "auto" }} className="centered">
            <Typography style={{ fontWeight: '700', fontSize: "152%" }}>
              SignUp
            </Typography>
            <Stack spacing={2} sx={{ mt: 1, mb: 3 }}>

              <TextField
                name="firstName"
                label="Enter First Name"
                autoFocus
                value={signup.firstName}
                onChange={(e: any) => { validateFirstName(e); handleInputChange(e); }}
              />
              <FormHelperText style={{ color: 'red', height: '2px' }}>{isfirstNameValidateError && firstNameValidateErrorMsg}</FormHelperText>
              <TextField
                name="lastName"
                label="Enter Last Name"
                autoFocus
                value={signup.lastName}
                onChange={(e: any) => { validateLastName(e); handleInputChange(e); }}
              />
              <FormHelperText style={{ color: 'red', height: '2px' }}>{islastNameValidateError && lastNameValidateErrorMsg}</FormHelperText>

              <TextField
                required
                name="email"
                label="Email address"
                autoFocus
                value={signup.email}
                onChange={(e: any) => { validateEmail(e); handleInputChange(e); }}
              />
              <FormHelperText style={{ color: 'red', height: '2px' }}>{isEmailValidateError && emailValidateErrorMsg}</FormHelperText>

              <TextField
                required
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={signup.password}
                onChange={(e: any) => { ValidatePassword(e); handleInputChange(e) }}
              />
              <FormHelperText style={{ color: 'red', height: '2px' }}>{isPasswordValidateError && passwordValidateErrorMsg}</FormHelperText>

              <TextField
                name="contactNo"
                label="Enter Contact Number"
                autoFocus
                value={signup.contactNo}
                onChange={(e: any) => { validateContactNumber(e); handleInputChange(e); }}
              />
              <FormHelperText style={{ color: 'red', height: '2px' }}>{iscontactNumberValidateError && contactNumberValidateErrorMsg}</FormHelperText>

            </Stack>

            <Button fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
              <div >
                {isLoading ? <LoginLoader title="Loading..." /> : 'SignUp'}
              </div>
            </Button>
          </Box>
        </div>
      </Grid>

    </>
  );
}
