import React from "react";
import "./loginLoader.css";

interface Props {
  title: string
}

const LoginLoader: React.FC<Props> = (props: Props) => {
  return (
    <div className="login-spinner-container">
      {/* style={{ height: 'calc(100vh - 210px)' }} */}
      <div className="loading-login-spinner">
      </div>
    </div>
  )
}

export default LoginLoader