import { alpha, Breadcrumbs, Box, Button, Card, Container, Divider, Drawer, FormControl, Chip, FormGroup, Grid, IconButton, InputAdornment, OutlinedInput, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Toolbar, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Scrollbar from '../../components/scrollbar';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { CSVLink } from 'react-csv'
import { collection, getDocs, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { db } from '../../firebase';
import Loader from '../spinner';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CancelIcon from '@mui/icons-material/Cancel';
import Iconify from '../../components/iconify';
import './../FoodMakerPage.css';
import { format } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const initialState = {
  fromDate: null,
  toDate: null,
}

export default function TopProductItem() {
  let [productItem, setProductItem] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [row, setRow] = useState<number>(10);
  let [searchInput, setSearchInput] = useState('');
  const [flag, setFlag] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [chipStartDate, setChipStartDate] = useState<boolean>(false);
  const [chipEndDate, setChipEndDate] = useState<boolean>(false);
  const [userValue, setUserValue] = React.useState<any>(initialState);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);
  const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

  // it is the first method invoked when this page is open
  useEffect(() => {
    getProductItem();
  }, [])

  //call intially
  const getProductItem = async (startDate?: Date, endDate?: Date) => {
    const items: any[] = [];
    const items0: any[] = [];
    const items1: any[] = [];
    const items2: any[] = [];
    let data: any[] = [];
    let data0: any[] = [];
    let data2: any[] = [];
    let sort: any[] = [];
    let amount: any[] = [];
    let finalData: any[] = [];
    if (searchInput) {
      setIsLoading(false);
      setFlag(true);
    } else {
      setIsLoading(true);
    }
    try {
      let q = query(collection(db, 'makerProduct'));
      if (searchInput) {
        const requestBody = {
          query: searchInput
        };
        const result = await searchData(process.env.REACT_APP_PRODUCTCATEGORY_SEARCH, requestBody);

      }
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });

      const userOrderCollectionRef = collection(db, 'userOrders');
      let q0 = query(userOrderCollectionRef, where('orderStatus', '!=', 'Cancelled'));
      const querySnapshot0 = await getDocs(q0)
      querySnapshot0.forEach((doc0: any) => {
        if (doc0.data() && doc0.data().orderDetail && doc0.data().orderDetail.length > 0) {
          let res = doc0.data().orderDetail.length
          for (let i = 0; i < res; i++) {
            items0.push({ id: doc0.id, ...doc0.data().orderDetail[i] });
          }
        }
      });

      let q1 = query(userOrderCollectionRef);
      if (userValue.fromDate && userValue.toDate) {
        let firstDate = Timestamp.fromDate(userValue.fromDate);
        let secondDate = Timestamp.fromDate(new Date(new Date(userValue.toDate).setDate(new Date(userValue.toDate).getDate() + 1)));
        q1 = query(q1, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      }
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        if (doc1.data() && doc1.data().orderDetail && doc1.data().orderDetail.length > 0) {
          let res = doc1.data().orderDetail.length
          for (let i = 0; i < res; i++) {
            items1.push({ id: doc1.id, ...doc1.data().orderDetail[i] });
          }
        }
      });

      data0 = items0.map((e: any) => {
        return ({
          ...e,
          match: items1.filter((d: any, i: number) => e.id === d.id),
        })
      })

      data2 = items.map((e: any) => {
        return ({
          ...e,
          quantity: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.quantity, 0),
        })
      })

      data = data2.map((e: any) => {
        return ({
          ...e,
          totalAmount: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.totalAmount, 0),
        })
      })
      sort = data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)
      const usersCollectionRef = collection(db, 'users');
      const q2 = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'asc'))
      const querySnapshot2 = await getDocs(q2)
      querySnapshot2.forEach((doc2: any) => {
        items2.push({ id: doc2.id, ...doc2.data() });
      });
      finalData = sort.map((e: any) => {
        return ({
          ...e,
          makerDisplayName: items2.filter((d: any) => e.makerId === d.uid).map((e: any) => e.displayName),
        })
      })
      amount = finalData.filter((e: any) => e.totalAmount > 0)

      if (amount.length > 0) {
        setFlag(true);
        setProductItem(amount);
      } else {
        setFlag(false);
      }
      productItem = amount;
      setRow(amount.length);
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  };

  //when click on clear button
  const handleClear = (e: any) => {
    userValue.fromDate = null;
    userValue.toDate = null;
    setChipStartDate(false);
    setChipEndDate(false);
    setIsOpen(false);
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      }
    ]);
    if (searchInput) {
      searchItems(searchInput);
    }
    else {
      getProductItem();
    }

  }

  // when chip is removed
  const handleDelete = (e: any) => {
    if (e === userValue.fromDate) {
      userValue.fromDate = null
      setChipStartDate(false);
      userValue.toDate = null
      setChipEndDate(false);
    }
    if (e === userValue.toDate) {
      userValue.toDate = null
      setChipEndDate(false);
    }
    // handleSearch();
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      }
    ]);
    if (searchInput) {
      searchItems(searchInput);
    }
    else {
      getProductItem();
    }

  }

  // when date is selected
  const handleSelect = (ranges: any) => {
    const { selection } = ranges;
    setState([selection]);
    userValue.fromDate = ranges.selection.startDate;
    userValue.toDate = ranges.selection.endDate;
  }

  //open date dialog
  const handleOpenRangePicker = () => {
    setIsOpen(true);
  }

  // close date dialog
  const handleCloseDailog = () => {
    setIsOpen(false);
  };

  // save dialog
  const save = () => {
    setFlag(false);
    setIsOpen(false);
    if (userValue.fromDate) {
      setChipStartDate(true);
    }
    if (userValue.toDate) {
      setChipEndDate(true);
    }
    if (searchInput) {
      searchItems(searchInput);
    }
    else {
      getProductItem();
    }

  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const headers = [
    {
      label: "Products", key: "name",
    },
    {
      label: "Food Makers", key: "displayName",
    },
    {
      label: "Quantity", key: "quantity",
    },
    {
      label: "Amount", key: "totalAmount",
    },
  ]


  let csvValue = JSON.parse(JSON.stringify(productItem))
  for (let i = 0; i < csvValue.length; i++) {
    csvValue[i].totalAmount = "₹" + csvValue[i].totalAmount;
  }

  // click on csv icon
  const csvlink = {
    filename: "TopSellingProducts.csv",
    headers: headers,
    data: csvValue
  }

  // click on print icon
  const handlePrint = async () => {
    searchInput = ''
    setSearchInput(searchInput);
    await getProductItem();
    let html = `<html>
    <style>
    .img-container {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 50;
      right: 50;
      top: 0;
    }

    span{
      font-size: 30px;
    }
    .date-container {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    table {
       border-collapse: collapse;
      border: 1px solid black;
      width: 100%;
      margin-top: 50px;
    }

    .th1 {
      border-top: 0px;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: center;
      padding: 8px;
    }

    .th2 {
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: center;
      padding: 8px;
    }

    .th3 {
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: center;
      padding: 8px;
    }

    .th4 {
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: center;
      padding: 8px;
    }

    .th5 {
      border-bottom: 1px solid black;
      text-align: center;
      padding: 8px;
    }

    .td1 {
      border-right: 1px solid black;
      text-align: center;
      padding: 8px;
    }

    .td2 {
      border-right: 1px solid black;
      text-align: left;
      padding: 8px;
    }

    .td3 {
      border-right: 1px solid black;
      text-align: left;
      padding: 8px;
    }

    .td4 {
      border-right: 1px solid black;
      text-align: center;
      padding: 8px;
    }

    .td5 {
      text-align: right;
      padding: 8px;
    }

    @media print {
      @page {
        size: A4;
      }
    }


    tr:nth-child(even) {background-color: #f2f2f2;}
    </style>
    <body  onload="window.print(); window.close();">

    <div class="img-container">
    <img src="/vyanjanLogo.png" alt="logo" height="50px"/>
    <span>Top Selling Products</span>
</div>
<div class="date-container">
<div>`
    html +=
      (userValue.fromDate ? `<p>` + (format(new Date(userValue.fromDate), formatedDate)) + `</p>
  </div>
  <div>
  <p>    -    </p>
  </div>
  <div>`
        :
        '')
    html +=
      `<p>` + (userValue.toDate ? (format(new Date(userValue.toDate), formatedDate)) : '') + `</p>
  </div>
</div>

    <table>
    <thead>
    <th class="th1">#</th>
      <th class="th2">Products</th>
      <th class="th3">Food Makers</th>
      <th class="th4">Quantity</th>
      <th class="th5">Amount</th>
    </thead>
    <tbody>`
    let res = productItem.length
    for (let i = 0; i < res; i++) {
      html +=
        `<tr>
        <td class="td1">`+ (i + 1) + `</td>
          <td class="td2">`+ productItem[i].name + `</td>
          <td class="td3">`+ productItem[i].displayName + `</td>
          <td class="td4">`+ productItem[i].quantity + `</td>
          <td class="td5">₹`+ (productItem[i].totalAmount).toFixed(2) + `</td>
         </tr>`
    }
    html += `</tbody>
      </table>
     </body>
    </html >`

    let frame1: any = document.createElement('iframe');
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);
    frame1.contentWindow.document.open();
    frame1.contentWindow.document.write(html);
    frame1.contentWindow.document.close();
  }

  // when cloud function is called
  async function searchData(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData; // return the response data
    } catch (error) {
      console.error(error);
    }
  }

  // invoked everytime when their is searchvalue in searchBox
  const searchItems = async (searchValue: any) => {
    if (searchValue) {
      setFlag(true);
      setPage(0);
      setSearchInput(searchValue);
      searchInput = searchValue
      try {
        const items: any[] = [];
        const itemsc: any[] = [];
        const items0: any[] = [];
        const items1: any[] = [];
        const items2: any[] = [];
        let data: any[] = [];
        let data0: any[] = [];
        let data2: any[] = [];
        let sort: any[] = [];
        let amount: any[] = [];
        let finalData: any[] = [];

        let qc = query(collection(db, 'makerProduct'));
        const querySnapshotc = await getDocs(qc)
        querySnapshotc.forEach((docc: any) => {
          itemsc.push({ id: docc.id, ...docc.data() });
        });

        const requestBody = {
          query: searchValue,
          size: itemsc.length
        };
        const result = await searchData(process.env.REACT_APP_MAKERPRODUCT_SEARCH, requestBody);
        for (let i = 0; i < result.data.length; i++) {
          let element = result.data[i]._source;
          let q = query(collection(db, 'makerProduct'));
          q = query(q, where('displayName', '==', element.displayName))
          const querySnapshot = await getDocs(q)
          querySnapshot.forEach((doc: any) => {
            items.push({ id: doc.id, ...doc.data() });
          });
        }
        const userOrderCollectionRef = collection(db, 'userOrders');
        let q0 = query(userOrderCollectionRef, where('orderStatus', '!=', 'Cancelled'));
        const querySnapshot0 = await getDocs(q0)
        querySnapshot0.forEach((doc0: any) => {
          if (doc0.data() && doc0.data().orderDetail && doc0.data().orderDetail.length > 0) {
            let res = doc0.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items0.push({ id: doc0.id, ...doc0.data().orderDetail[i] });
            }
          }
        });

        let q1 = query(userOrderCollectionRef);
        if (userValue.fromDate && userValue.toDate) {
          let firstDate = Timestamp.fromDate(userValue.fromDate);
          let secondDate = Timestamp.fromDate(new Date(new Date(userValue.toDate).setDate(new Date(userValue.toDate).getDate() + 1)));
          q1 = query(q1, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        }
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          if (doc1.data() && doc1.data().orderDetail && doc1.data().orderDetail.length > 0) {
            let res = doc1.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items1.push({ id: doc1.id, ...doc1.data().orderDetail[i] });
            }
          }
        });

        data0 = items0.map((e: any) => {
          return ({
            ...e,
            match: items1.filter((d: any, i: number) => e.id === d.id),
          })
        })

        console.log(data0);

        data2 = items.map((e: any) => {
          return ({
            ...e,
            quantity: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.quantity, 0),
          })
        })

        data = data2.map((e: any) => {
          return ({
            ...e,
            totalAmount: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.totalAmount, 0),
          })
        })
        sort = data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)
        const usersCollectionRef = collection(db, 'users');
        const q2 = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'asc'))
        const querySnapshot2 = await getDocs(q2)
        querySnapshot2.forEach((doc2: any) => {
          items2.push({ id: doc2.id, ...doc2.data() });
        });
        finalData = sort.map((e: any) => {
          return ({
            ...e,
            makerDisplayName: items2.filter((d: any) => e.makerId === d.uid).map((e: any) => e.displayName),
          })
        })
        amount = finalData.filter((e: any) => e.totalAmount > 0)

        if (amount.length > 0) {
          setFlag(true);
          setProductItem(amount);
        } else {
          setFlag(false);
        }
        productItem = amount;
        setRow(amount.length);


      }
      catch (error) {
        console.log(error)
        setIsLoading(false);
      }
    } else {
      searchInput = ""
      setSearchInput("")
      await getProductItem();
    }
    setIsLoading(false);
  }

  return (
    <>
      <Helmet>
        <title>Top Selling Product Report | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>

      <div>
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    to="/admin/dashboard"
                    style={{ display: 'flex', color: "black" }}
                  >
                    <HomeIcon />
                  </Link>
                  <Typography color="inherit" >Report-Top Selling Products</Typography>
                </Breadcrumbs>
              </Stack>

              <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                <div>
                  {isLoading ? <Loader title="Loading..." /> :
                    <div>
                      <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                        <StyledRoot
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'white',

                          }}
                        >
                          <StyledSearch
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value)}
                            placeholder="Search by Products..."
                            startAdornment={
                              <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                              </InputAdornment>
                            }
                          />
                          <div className='largeScreenContent' >
                            {chipStartDate === true && chipEndDate === true ?
                              <> {
                                userValue.fromDate && userValue.toDate ?
                                  <Chip color="primary" size="small" label={format(new Date(userValue.fromDate), formatedDate) + "-" + format(new Date(userValue.toDate), formatedDate)} sx={{ mx: 1 }}
                                    onDelete={(e: any) => handleDelete(userValue.fromDate)}
                                  /> : ""
                              }
                              </>
                              :
                              ''}
                          </div>
                          <div style={{ display: "flex" }}>
                            {flag === true ?
                              <>
                                <Tooltip title="Print" arrow>
                                  <IconButton
                                    sx={{ color: '#1565c0' }}
                                    color="inherit"
                                    size="small"
                                    onClick={handlePrint}
                                  >
                                    <LocalPrintshopRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Export File" arrow>
                                  <IconButton
                                    sx={{ color: '#1565c0', marginTop: '4px' }}
                                    color="inherit"
                                    size="small"
                                  >
                                    <CSVLink {...csvlink} style={{ color: '#1565c0' }}><UploadFileRoundedIcon /></CSVLink>
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Select Date" arrow>
                                  <IconButton
                                    sx={{ color: '#1565c0' }}
                                    color="inherit"
                                    size="small"
                                    onClick={handleOpenRangePicker}
                                  >
                                    <DateRangeIcon />
                                  </IconButton>
                                </Tooltip>
                              </> :
                              <>
                                <Tooltip title="Select Date" arrow>
                                  <IconButton
                                    sx={{ color: '#1565c0' }}
                                    color="inherit"
                                    size="small"
                                    onClick={handleOpenRangePicker}
                                  >
                                    <DateRangeIcon />
                                  </IconButton>
                                </Tooltip>
                              </>}

                          </div>
                        </StyledRoot>
                        <div className='smallScreenContent' >
                          {chipStartDate === true && chipEndDate === true ?
                            <>{
                              userValue.fromDate && userValue.toDate ?
                                <Chip color="primary" size="small" label={format(new Date(userValue.fromDate), formatedDate) + "-" + format(new Date(userValue.toDate), formatedDate)} sx={{ mx: 1 }}
                                  onDelete={(e: any) => handleDelete(userValue.fromDate)}
                                /> : ""
                            }
                            </>
                            :
                            ''}
                        </div>
                        {
                          (flag === true) ?
                            <>
                              <Scrollbar>
                                <TableContainer className="report-list" sx={{ backgroundColor: 'white' }}>
                                  <Table stickyHeader>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell >#</TableCell>
                                        <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Products</Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Makers</Typography></TableCell>
                                        <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Quantity</Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Amount</Typography></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {productItem
                                        .slice(page * limit, page * limit + limit)
                                        .map((arr: any, index: any) => {
                                          return (
                                            <TableRow hover key={arr.id}>
                                              <TableCell sx={{ pt: 1, pb: 1 }}>
                                                {(page * limit) + index + 1}
                                              </TableCell>
                                              <TableCell align="center" sx={{ color: '#212B36' }}><Typography variant="subtitle2" noWrap >
                                                {arr.displayName}</Typography>
                                              </TableCell>
                                              <TableCell align="center"><Typography noWrap fontSize="0.875rem">
                                                {arr.makerDisplayName}</Typography>
                                              </TableCell>
                                              <TableCell align="center">
                                                {arr.quantity}
                                              </TableCell>
                                              <TableCell align="center"><Typography noWrap fontSize="0.875rem">
                                                ₹ {(arr.totalAmount).toFixed(2)}</Typography>
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Scrollbar>
                              <TablePagination
                                component="div"
                                count={row}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleLimitChange}
                                page={page}
                                rowsPerPage={limit}
                                rowsPerPageOptions={[10, 20, 30, 40]}
                                sx={{ pt: 1, pb: 1 }}
                              />
                            </>
                            :
                            <TableContainer className="report-list-notFound">
                              <Table>
                                <Divider />
                                <TableBody>
                                  <TableRow>
                                    <Paper
                                      sx={{
                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                      }}
                                    >
                                      <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                      <Typography variant="h6" paragraph>
                                        Data not Available
                                      </Typography>
                                    </Paper>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                        }
                      </Card>
                    </div>
                  }
                </div>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <div className='dialogSmall'>
          <Dialog open={isOpen} onClose={handleCloseDailog}
            PaperProps={{
              sx: {
                maxWidth: 'none',
                width: "306.33px",
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                borderRadius: '6px',
                boxShadow: 24,
                margin: '0px',
              }
            }}
            className='dialogSmall'
          >
            <DialogTitle sx={{ marginBottom: '20px' }}>
              <IconButton
                sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 3, top: 12 }}
                onClick={handleCloseDailog}
              >
                <CancelIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DateRangePicker
                className='rangepicker'
                // ranges={[selectionRange]}
                ranges={state}
                onChange={handleSelect}
              />
            </DialogContent>
            <DialogActions sx={{ pl: '24px', pr: '24px' }} >
              <Button
                onClick={save}
              >Save</Button>
              <Button
                onClick={handleClear}
              >Clear</Button>
            </DialogActions>
          </Dialog>
        </div>

        <div className='dialogLarge'>
          <Dialog open={isOpen} onClose={handleCloseDailog}
            PaperProps={{
              sx: {
                maxWidth: 'none',
                width: "606.33px",
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                borderRadius: '6px',
                boxShadow: 24,
                margin: '0px',
              }
            }}
            className='dialogLarge'
          >
            <DialogTitle sx={{ marginBottom: '20px' }}>
              <IconButton
                sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 3, top: 12 }}
                onClick={handleCloseDailog}
              >
                <CancelIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DateRangePicker
                className='rangepicker'
                // ranges={[selectionRange]}
                ranges={state}
                onChange={handleSelect}
              />
            </DialogContent>
            <DialogActions sx={{ pl: '24px', pr: '24px' }} >
              <Button
                onClick={save}
              >Save</Button>
              <Button
                onClick={handleClear}
              >Clear</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
}