import { Helmet } from 'react-helmet-async';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  Tooltip,
  Grid,
  alpha,
  OutlinedInput,
  styled,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Paper,
  Divider,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HomeIcon from '@mui/icons-material/Home';
import { Link, useNavigate } from 'react-router-dom';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { db } from './../firebase';
import { collection, endBefore, getDocs, limit, limitToLast, orderBy, query, startAfter, where } from '@firebase/firestore';
import Loader from './spinner';
import Iconify from '../components/iconify';
import { getCountFromServer } from 'firebase/firestore';
import './FoodMakerPage.css';
import { format } from 'date-fns';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function PaymentTransactionPage() {
  const [page, setPage] = useState(0);
  let [limits, setLimits] = useState(10);
  const [row, setRow] = useState<number>(10);
  let [asc, setAsc] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState<any>([]); //to show data inside page
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisible, setFirstVisible] = useState<any>();
  let [searchInput, setSearchInput] = useState('');
  const [orderField, setOrderField] = useState<string>('createdDate');
  let [orderByField, setOrderByField] = useState<any>('desc');
  const [flag, setFlag] = useState<boolean>(true);
  const navigate = useNavigate();
  const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

  //if click on view set this item in sessionStorage which used in detail page
  const handleClickVisible = (element: any) => {
    let id = element?.id
    sessionStorage.setItem("orderNo", element?.orderNo + "");
    navigate(`/admin/ordersTransaction/view/${id}`);
  }

  // it is the first method invoked when this page is open
  useEffect(() => {
    getdata();
  }, [])

  //call intially
  const getdata = async () => {
    let items: any[] = [];
    let items1: any[] = [];
    let items2: any[] = [];
    let data: any[] = [];
    let finalData: any[] = [];
    if (searchInput) {
      setIsLoading(false);
      setFlag(true);
    } else {
      setIsLoading(true);
    }
    try {
      const paymentCollectionRef = collection(db, 'userOrderPayments')
      const userCollectionRef = collection(db, 'users');
      const orderCollectionRef = collection(db, 'userOrders')
      const qLenn = query(paymentCollectionRef, where('paymentStatus', '==', 'success'), orderBy(orderField, orderByField))
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count);
      const q = query(paymentCollectionRef, where('paymentStatus', '==', 'success'), orderBy(orderField, orderByField), limit(limits));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        let itm = doc.data();
        let obj = {
          userOrderId: doc.data().userOrderId
        }
        if (obj.userOrderId) {
          const q = query(orderCollectionRef, where('id', '==', obj.userOrderId))
          const _querySnapshot = await getDocs(q)
          _querySnapshot.forEach((_doc) => {
            itm.orderNo = _doc.data().orderNo
            itm.seekerId = _doc.data().seekerId
            itm.makerId = _doc.data().makerId
            itm.currentSeekerName = _doc.data().seekerName
            itm.currentMakerName = _doc.data().makerName
          })
        }
        items.push(itm);
      });

      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisible(querySnapshot.docs[0]);
      const q1 = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'))
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        items1.push({ id: doc1.id, ...doc1.data() });
      });

      const q2 = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'))
      const querySnapshot2 = await getDocs(q2)
      querySnapshot2.forEach((doc2: any) => {
        items2.push({ id: doc2.id, ...doc2.data() });
      });

      data = items.map((e: any) => {
        return ({
          ...e,
          makerName: items1.filter((d: any) => d.uid === e.makerId).map((f: any) => f.displayName)
        })
      })

      finalData = data.map((e: any) => {
        return ({
          ...e,
          seekerName: items2.filter((d: any) => d.uid === e.seekerId).map((f: any) => f.displayName)
        })
      })

      if (finalData.length > 0) {
        setDetail(finalData);
        setFlag(true);
      } else {
        setFlag(false);
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  // when cloud function is called
  async function searchData(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData; // return the response data
    } catch (error) {
      console.error(error);
    }
  }

  // invoked everytime when their is searchvalue in searchBox
  const searchItems = async (searchValue: any, startIndex: number, fetchRecord: number) => {
    const items: any[] = [];
    setSearchInput(searchValue);
    searchInput = searchValue
    if (searchValue === '') {
      getdata();
    }
    else {
      try {
        if (searchInput) {
          setIsLoading(false);
          setFlag(true);
        } else {
          setIsLoading(true);
        }
        const requestBody = {
          query: searchValue,
          size: fetchRecord,
          from: startIndex
        };
        const result = await searchData(process.env.REACT_APP_USERORDER_SEARCH, requestBody);
        const paymentCollectionRef = collection(db, 'userOrderPayments')
        const userCollectionRef = collection(db, 'users');
        const orderCollectionRef = collection(db, 'userOrders')
        for (let i = 0; i < result.data.length; i++) {
          const element = result.data[i]._source;
          setFlag(true);
          const q = query(orderCollectionRef, where('orderNo', '==', element.orderNo + ""), orderBy(orderField, orderByField), limit(limits))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            items.push(doc.data())
          })
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisible(querySnapshot.docs[0]);
          let j = 1;
          items.forEach(async (obj) => {
            if (obj.makerId) {
              const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.makerName = _doc.data().displayName;
              })
            }
            if (obj.seekerId) {
              const q = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
              const _querySnapshot1 = await getDocs(q)
              _querySnapshot1.forEach((_doc1) => {
                obj.seekerName = _doc1.data().displayName;
              })
            }
            if (obj.id) {
              const q = query(paymentCollectionRef, where('paymentStatus', '==', 'success'), where('userOrderId', '==', obj.id))
              const querySnapshot1 = await getDocs(q)
              querySnapshot1.forEach((doc1) => {
                obj.transactionId = doc1.data().transactionId
                obj.transactionDate = doc1.data().transactionDate
                obj.amount = doc1.data().amount
                obj.payment = doc1.data().payment
                obj.currentMakerName = doc1.data().makerName
                obj.currentSeekerName = doc1.data().seekerName
              })
              if (j === items.length) {
                setDetail(items);
                setRow(result.totalRecords)
                if (items.length > 0) {
                  setFlag(true);
                } else {
                  setFlag(false);
                }
              } else {
                j++
              }
            }
          })
        }
        if (items.length > 0) {
          setFlag(true);
        } else {
          setFlag(false);
        }
      } catch (error) {
        console.log(error)
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }

  // when we want to do sorting in descending order
  const sortDescending = async () => {
    setPage(0);
    setOrderField('createdDate');
    setOrderByField('desc');
    orderByField = 'desc'
    setTimeout(async () => {
      await getdata();
      setAsc(true)
      asc = true;
    }, 1000);
  }

  // when we want to do sorting in ascending order
  const sortAscending = async () => {
    setPage(0);
    setOrderField('createdDate');
    setOrderByField('asc');
    orderByField = 'asc'
    setTimeout(async () => {
      await getdata();
      setAsc(false)
      asc = false;
    }, 1000);
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handleChangePage = async (event: any, newPage: number): Promise<void> => {
    if (searchInput) {
      setPage(newPage);
      searchItems(searchInput, (newPage * limits), limits)
    } else {
      setPage(newPage);
      const items: any[] = [];
      const items1: any[] = [];
      let items2: any[] = [];
      let finalData: any[] = [];
      let data: any[] = [];
      if (page < newPage) {
        try {
          const userCollectionRef = collection(db, 'users');
          const orderCollectionRef = collection(db, 'userOrders')
          const q = query(collection(db, 'userOrderPayments')
            , where('paymentStatus', '==', 'success')
            , orderBy(orderField, orderByField)
            , startAfter(lastVisible)
            , limit(limits));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            let itm = doc.data();
            let obj = {
              userOrderId: doc.data().userOrderId
            }
            if (obj.userOrderId) {
              const q = query(orderCollectionRef, where('id', '==', obj.userOrderId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                itm.orderNo = _doc.data().orderNo
                itm.seekerId = _doc.data().seekerId
                itm.makerId = _doc.data().makerId
                itm.currentSeekerName = _doc.data().seekerName
                itm.currentMakerName = _doc.data().makerName
              })
            }
            items.push(itm);
          });

          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisible(querySnapshot.docs[0]);
          const q1 = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'))
          const querySnapshot1 = await getDocs(q1)
          querySnapshot1.forEach((doc1: any) => {
            items1.push({ id: doc1.id, ...doc1.data() });
          });

          const q2 = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'))
          const querySnapshot2 = await getDocs(q2)
          querySnapshot2.forEach((doc2: any) => {
            items2.push({ id: doc2.id, ...doc2.data() });
          });

          data = items.map((e: any) => {
            return ({
              ...e,
              makerName: items1.filter((d: any) => d.uid === e.makerId).map((f: any) => f.displayName)
            })
          })

          finalData = data.map((e: any) => {
            return ({
              ...e,
              seekerName: items2.filter((d: any) => d.uid === e.seekerId).map((f: any) => f.displayName)
            })
          })

          if (finalData.length > 0) {
            setDetail(finalData);
            setFlag(true);
          } else {
            setFlag(false);
          }
          updateState(querySnapshot)
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const userCollectionRef = collection(db, 'users');
          const orderCollectionRef = collection(db, 'userOrders')
          const q = query(collection(db, 'userOrderPayments')
            , where('paymentStatus', '==', 'success')
            , orderBy(orderField, orderByField)
            , endBefore(firstVisible)
            , limitToLast(limits));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            let itm = doc.data();
            let obj = {
              userOrderId: doc.data().userOrderId
            }
            if (obj.userOrderId) {
              const q = query(orderCollectionRef, where('id', '==', obj.userOrderId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                itm.orderNo = _doc.data().orderNo
                itm.seekerId = _doc.data().seekerId
                itm.makerId = _doc.data().makerId
                itm.currentSeekerName = _doc.data().seekerName
                itm.currentMakerName = _doc.data().makerName
              })
            }
            items.push(itm);
          });

          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisible(querySnapshot.docs[0]);
          const q1 = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'))
          const querySnapshot1 = await getDocs(q1)
          querySnapshot1.forEach((doc1: any) => {
            items1.push({ id: doc1.id, ...doc1.data() });
          });

          const q2 = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'))
          const querySnapshot2 = await getDocs(q2)
          querySnapshot2.forEach((doc2: any) => {
            items2.push({ id: doc2.id, ...doc2.data() });
          });

          data = items.map((e: any) => {
            return ({
              ...e,
              makerName: items1.filter((d: any) => d.uid === e.makerId).map((f: any) => f.displayName)
            })
          })

          finalData = data.map((e: any) => {
            return ({
              ...e,
              seekerName: items2.filter((d: any) => d.uid === e.seekerId).map((f: any) => f.displayName)
            })
          })

          if (finalData.length > 0) {
            setDetail(finalData);
            setFlag(true);
          } else {
            setFlag(false);
          }
          updateState(querySnapshot)
        } catch (error) {
          console.log(error)
        }
      }
    };
  }

  // update first visible and last visible value according to pagination (next and previous button)
  const updateState = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimits(parseInt(event.target.value));
    limits = parseInt(event.target.value);
    setPage(0);
    getdata();
  };

  return (
    <>
      <Helmet>
        <title> Orders Transaction |Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack alignItems="left" justifyContent="space-between" mb={5}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit" >Orders Transaction</Typography>
              </Breadcrumbs>
            </Stack>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
              <div>
                {isLoading ? <Loader title="Loading..." /> :
                  <>
                    <StyledRoot
                      sx={{
                        color: 'primary.main',
                        bgcolor: 'white',

                      }}
                    >
                      <StyledSearch
                        value={searchInput}
                        onChange={(e) => searchItems(e.target.value, page * limits, limits)}
                        placeholder="Search by Order Number..."
                        startAdornment={
                          <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                          </InputAdornment>
                        }
                      />
                    </StyledRoot>
                    {(flag === true) ?
                      <>
                        <Scrollbar>
                          <TableContainer className="orderTransaction-list" sx={{ backgroundColor: "white" }}>
                            <Table stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell>#</TableCell>
                                  <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                  <TableCell align="center" sx={{ width: '20%' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">FoodMaker Name
                                  </Typography></TableCell>
                                  <TableCell align="center" sx={{ width: '20%' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">FoodSeeker Name
                                  </Typography></TableCell>
                                  <TableCell align="center" sx={{ width: '15%' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Date
                                    {(asc === false) ?
                                      <IconButton
                                        sx={{ marginLeft: "6px", color: "black" }}
                                        color="inherit"
                                        size="small"
                                        onClick={sortDescending}
                                      >
                                        <ArrowDropDownIcon />
                                      </IconButton>
                                      :
                                      <IconButton
                                        sx={{ marginLeft: "6px", color: "black" }}
                                        color="inherit"
                                        size="small"
                                        onClick={sortAscending}
                                      >
                                        < ArrowDropUpIcon />
                                      </IconButton>
                                    }</Typography></TableCell>
                                  <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Orders Amount</Typography></TableCell>
                                  <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Payment Mode</Typography></TableCell>
                                  <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Transaction Id</Typography></TableCell>
                                  <TableCell align="right"><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {detail.map((data: any, index: number) => (
                                  <TableRow hover key={index} sx={{ bgcolor: 'white' }}>
                                    <TableCell>{(page * limits) + index + 1}</TableCell>
                                    <TableCell align='center' sx={{ color: '#212B36' }}>
                                      <Typography variant="subtitle2" noWrap>  {(data.orderNo) ? data.orderNo : "--"}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center"><Typography noWrap fontSize="0.875rem">{data.makerName ? data.makerName : data.currentMakerName}</Typography></TableCell>
                                    <TableCell align="center"><Typography noWrap fontSize="0.875rem">{data.seekerName ? data.seekerName : data.currentSeekerName}</Typography></TableCell>
                                    <TableCell align="center"><Typography noWrap fontSize="0.875rem">{format(new Date(data.createdDate.seconds * 1000), formatedDate) ? format(new Date(data.createdDate.seconds * 1000), formatedDate) : "--"}</Typography></TableCell>
                                    <TableCell align="center"><Typography noWrap fontSize="0.875rem">₹ {(data.amount).toFixed(2)}</Typography></TableCell>
                                    {data.payment && data.payment.length > 1 ?
                                      <>
                                        <TableCell align="center">
                                          <Typography noWrap fontSize="0.875rem">
                                            {((data.payment && data.payment.length > 0 && data.payment[0]?.paymentMode).charAt(0).toUpperCase() + (data.payment[0]?.paymentMode).substr(1).toLowerCase())} + {((data.payment[1]?.paymentMode).charAt(0).toUpperCase() + (data.payment[1]?.paymentMode).substr(1).toLowerCase())}
                                          </Typography></TableCell>
                                        <TableCell align="center"><Typography noWrap fontSize="0.875rem">{(data.payment[1]?.razorPayOrderId) ? data.payment[1]?.razorPayOrderId : '--'}</Typography></TableCell>
                                      </>
                                      :
                                      <>
                                        <TableCell align="center"> <Typography noWrap fontSize="0.875rem">{(data.payment && data.payment.length > 0 && data.payment[0]?.paymentMode.length > 0) ? (data.payment[0]?.paymentMode.charAt(0).toUpperCase() + data.payment[0]?.paymentMode.substr(1).toLowerCase()) : ''}</Typography></TableCell>
                                        <TableCell align="center"><Typography noWrap fontSize="0.875rem">{(data.payment && data.payment.length > 0 && data.payment[0]?.razorPayOrderId) ? data.payment[0]?.razorPayOrderId : '--'}</Typography></TableCell>
                                      </>
                                    }
                                    <TableCell align='right' sx={{ pt: 0.5, pb: 0.5 }}>
                                      <Tooltip title="View" arrow>
                                        <IconButton
                                          sx={{ color: '#1565c0' }}
                                          color="inherit"
                                          size="small"
                                          onClick={(e) => { handleClickVisible(data) }}
                                        >
                                          <VisibilityIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                        <TablePagination
                          component="div"
                          count={row}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleLimitChange}
                          page={page}
                          rowsPerPage={limits}
                          rowsPerPageOptions={[10, 20, 30, 40]}
                        />
                      </>
                      :
                      <TableContainer className="orderTransaction-list-notFound" sx={{ backgroundColor: "white" }}>
                        <Table>
                          <Divider />
                          <TableBody>
                            <TableRow>
                              <Paper
                                sx={{
                                  width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                }}
                              >
                                <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                <Typography variant="h6" paragraph>
                                  Data not Available
                                </Typography>
                              </Paper>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    }
                  </>
                }
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}