import { Helmet } from 'react-helmet-async';
import { SetStateAction, useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Drawer,
  Divider,
  FormGroup,
  FormControl,
  Paper,
  Grid,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import CancelIcon from '@mui/icons-material/Cancel';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
import Loader from './spinner';
import { collection, doc, endAt, getDocs, orderBy, query, startAt, updateDoc, where } from 'firebase/firestore';
import { db } from './../firebase'

const initialState = {
  id: "",
  foodMakerName: "",
  commission: "",
}

export default function CommissionPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [commissions, setCommissions] = useState<any>(initialState)
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState<number>(10);
  const [row, setRow] = useState<number>(10);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState<any>([])
  const [sort, setSort] = useState(false)

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/admin/dashboard');
  }

  const onOpenFilter = () => {
    setOpen(!open)
  }

  const handleChange = (e: any) => {
    e.preventDefault();
    const { name, value } = e.target;
    setCommissions({
      ...commissions,
      [name]: value,
    });
  }

  const [isCommissionError, setCommissionError] = useState(false);
  const [commissionErrorMsg, setCommissionErrorMsg] = useState("");
  const reg = new RegExp(/\d\d{0,9}(\.\d{1,3})?%?$/);
  const validateCommision = (arr: any) => {
    if (arr.target.value) {
      if ((reg.test(arr.target.value))) {
        setCommissionError(false);
        setCommissionErrorMsg("");
      } else {
        setCommissionError(true);
        setCommissionErrorMsg("Number and Dot is Allowed");
      }
    } else {
      setCommissionError(true);
      setCommissionErrorMsg("Enter the Number");
    }
  }

  const [isNameError, setNameError] = useState(false);
  const [NameErrorMsg, setNameErrorMsg] = useState("");

  const regName = new RegExp(/^[A-Za-z_]+$/);
  const validateName = (arr: any) => {
    if (arr.target.value) {
      if ((regName.test(arr.target.value))) {
        setNameError(false);
        setNameErrorMsg("");
      } else {
        setNameError(true);
        setNameErrorMsg("Only Character is Allowed");
      }
    } else {
      setNameError(true);
      setNameErrorMsg("Please enter Name");
    }
  }

  const validateForm = () => {
    let flag = true;
    if (!commissions.commission) {
      setCommissionError(true);
      setCommissionErrorMsg("Enter the Number");
      flag = false;
    } else {
      if ((reg.test(commissions.commission))) {
        setCommissionError(false);
        setCommissionErrorMsg("");
      } else {
        setCommissionError(true);
        setCommissionErrorMsg("Number and Dot is Allowed");
        flag = false;
      }
    }
    return flag;
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  const editCommisionDialog = (arr: any) => {
    arr.preventDefault();
    const { name, value } = arr.target;
    setCommissions({
      ...commissions,
      [name]: value,
    });
    setIsOpen(true);
  }

  const handleClickisEdit = (no: number, no1: number) => {
    let obj = {
      id: no,
      commission: no1
    }
    setCommissions(obj)
    setIsOpen(true);
    setCommissionError(false);
    setCommissionErrorMsg("");
  };

  const handleEditCommision = () => {
    let flag = validateForm();
    setIsLoading(true)
    setIsOpen(false)
    if (flag) {
      try {
        const obj = {
          commission: {
            commission: commissions.commission
          }
        }
        const dataRef = doc(db, 'users', commissions.id)
        updateDoc(dataRef, obj)
        getCommission();
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    }
    setIsOpen(false)
  }

  useEffect(() => {
    getCommission();
  }, [])

  const getCommission = async () => {
    setIsLoading(true);
    try {
      let items: any[] = [];
      const userCollectionRef = collection(db, 'users')
      const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'asc'))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data() && doc.data().commission.commission && doc.data().commission.commission.length > 0) {
          items.push(doc.data());
        }
      });
      setDetail(items);
      setRow(items.length)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  }

  const handleSubmit = async () => {
    let items: any[] = [];
    try {
      const userCollectionRef = collection(db, 'users')
      const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), orderBy('firstName'), startAt(commissions.foodMakerName), endAt(commissions.foodMakerName + '\uf8ff'))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setDetail(items);
    } catch (error) {
      console.log(error)
    }
    setOpen(false)
  }

  const handleClear = () => {
    setCommissions(initialState)
    getCommission();
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const sortDecending = async () => {
    let Desc: any[] = []
    const nameCollectionRef = collection(db, 'users')
    const q = query(nameCollectionRef, where("role.roleName", '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'desc'))
    const QuerySnapshot = await getDocs(q);
    QuerySnapshot.forEach((doc: any) => {
      Desc.push(doc.data());
    })
    setDetail(Desc);
    setSort(true)
  }

  const sortAscending = async () => {
    let Asc: any[] = []
    const nameCollectionRef = collection(db, 'users')
    const q = query(nameCollectionRef, where("role.roleName", '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'asc'))
    const QuerySnapshot = await getDocs(q);
    QuerySnapshot.forEach((doc: any) => {
      Asc.push(doc.data());
    })
    setDetail(Asc);
    setSort(false)
  }

  return (
    <>
      <Helmet>
        <title> Commission | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <div>
        {isLoading ? <Loader title="Loading..." /> :
          <>
            <Container maxWidth="xl">
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item xs={12}>
                  <Stack alignItems="left" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom mb={0}>
                      Commission
                    </Typography>
                    <Typography variant="h5" component="h5" gutterBottom mb={0} color='lightblue'>
                      <IconButton
                        color="inherit"
                        size="small"
                        sx={{ fontSize: "10px" }}
                        onClick={handleClick}
                      >
                        <HomeIcon sx={{ fontSize: "15px" }} />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        size="small"
                        sx={{ fontSize: "12px" }}
                      >
                        / Commission
                      </IconButton>
                    </Typography>
                  </Stack>
                  <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                    <Drawer
                      anchor="right"
                      open={open}
                      onClose={onOpenFilter}
                      PaperProps={{
                        sx: { width: 280, border: 'none', overflow: 'hidden' },
                      }}
                    >
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                        <IconButton onClick={onOpenFilter} >
                          <Iconify icon="eva:close-fill" sx={{ color: "red" }} />
                        </IconButton>
                        <Typography variant="subtitle1" sx={{ marginRight: "67%", color: "black" }}>
                          <b>Filters</b>
                        </Typography>
                      </Stack>
                      <Divider />
                      <Scrollbar>
                        <Stack spacing={3} sx={{ p: 3, backgroundColor: 'white' }}>
                          <div>
                            <FormGroup>
                              <FormControl fullWidth className="mb-3" sx={{ pb: 3 }}>
                                <TextField id="filter"
                                  label="Name"
                                  type="text"
                                  name="foodMakerName"
                                  value={commissions.foodMakerName}
                                  onChange={(e) => { handleChange(e); validateName(e) }}
                                  helperText={isNameError && NameErrorMsg}
                                />
                              </FormControl>
                            </FormGroup>
                          </div>
                          <Grid>
                            <Button
                              size="small"
                              type="submit"
                              color="primary"
                              variant="contained"
                              className="mb-3"
                              onClick={handleSubmit}
                              sx={{ marginLeft: 2 }}
                            >
                              Search
                            </Button>
                            <Button
                              size="small"
                              type="submit"
                              color="primary"
                              variant="contained"
                              className="mb-3"
                              onClick={handleClear}
                              sx={{ marginLeft: 6 }}
                            >
                              Clear
                            </Button>
                          </Grid>
                        </Stack>
                      </Scrollbar>
                    </Drawer>
                    {
                      (detail && detail.length > 0) ?
                        <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                          {/* <Divider /> */}
                          <Scrollbar>
                            <TableContainer style={{ borderRadius: '10px', backgroundColor: 'white' }}>
                              <Table>
                                <TableHead>
                                  <TableCell colSpan={4} sx={{ pt: 0.5, pb: 0.5 }} align="right">
                                    <IconButton
                                      color="inherit"
                                      size="small"
                                      onClick={onOpenFilter}
                                    >
                                      <FilterListIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableHead>
                                <TableRow>
                                  <TableCell sx={{ pt: 1, pb: 1 }} align="left"><b>#</b></TableCell>
                                  <TableCell sx={{ pt: 1, pb: 1 }} align="left"><b>Food Makers</b>
                                    {
                                      (sort === false) ?
                                        <IconButton
                                          sx={{ marginLeft: "2px", color: "black" }}
                                          color="inherit"
                                          size="small"
                                          onClick={sortDecending}
                                        >
                                          <ArrowDropDownIcon />
                                        </IconButton>
                                        :
                                        <IconButton
                                          sx={{ marginLeft: "2px", color: "black" }}
                                          color="inherit"
                                          size="small"
                                          onClick={sortAscending}
                                        >
                                          <ArrowDropUpIcon />
                                        </IconButton>
                                    }
                                  </TableCell>
                                  <TableCell sx={{ pt: 1, pb: 1 }} align="left"><b>Commission</b></TableCell>
                                  <TableCell align="right" sx={{ pt: 1, pb: 1 }}><b>Action</b></TableCell>
                                </TableRow>
                                <TableBody>
                                  {
                                    detail
                                      .slice(page * limit, page * limit + limit)
                                      .map((arr: any, index: number) => {
                                        return (
                                          <TableRow
                                            hover
                                            key={arr.id}
                                          >
                                            <TableCell align="left" sx={{ pt: 1, pb: 1 }}>{(page * limit) + index + 1}</TableCell>
                                            <TableCell align="left" sx={{ pt: 1, pb: 1 }}>{arr.firstName} {arr.lastName}</TableCell>
                                            <TableCell align="left" sx={{ pt: 1, pb: 1 }}> {arr.commission.commission}</TableCell>
                                            <TableCell align="right" sx={{ pt: 1, pb: 1 }}>
                                              <Tooltip title="Edit" arrow>
                                                <IconButton
                                                  sx={{ color: '#1565c0' }}
                                                  color="inherit"
                                                  size="small"
                                                  onClick={(e) => handleClickisEdit(arr.id, arr.commission.commission)}
                                                >
                                                  <EditTwoToneIcon fontSize="small" />
                                                </IconButton>
                                              </Tooltip>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          <Divider />
                          <TablePagination
                            rowsPerPageOptions={[10, 20, 30, 40]}
                            component="div"
                            count={row}
                            rowsPerPage={limit}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Card>
                        :
                        <Table>
                          <TableHead>
                            <TableCell align='right' colSpan={4} sx={{ pt: 0.5, pb: 0.5 }}>
                              <IconButton
                                color="inherit"
                                size="small"
                                onClick={onOpenFilter}
                              >
                                <FilterListIcon />
                              </IconButton>
                            </TableCell>
                          </TableHead>
                          <Divider />
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={6} sx={{ pt: 1, pb: 1, py: 3 }}>
                                <Paper
                                  sx={{
                                    textAlign: 'center',
                                  }}
                                >
                                  <img src="\search.png" height="50" width="50" style={{ marginLeft: "35%" }} />
                                  <Typography variant="h6" paragraph>
                                    Data not Available
                                  </Typography>
                                </Paper>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                    }
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </>
        }
      </div >
      <div>
        <Dialog open={isOpen} onClose={handleClose}>
          <DialogTitle>
            Edit Commission
            <Button
              sx={{ fontSize: "2px", color: "red" }}
              onClick={handleClose}
              style={{ bottom: "34px", marginLeft: "81%" }}
            >
              <CancelIcon />
            </Button>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Value"
              type="text"
              fullWidth
              variant="outlined"
              name="commission"
              value={commissions.commission}
              onChange={(arr) => { editCommisionDialog(arr); validateCommision(arr) }}
              required={true}
            />
            <DialogContentText style={{ color: 'red', height: '22px' }}>{isCommissionError && commissionErrorMsg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditCommision}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}