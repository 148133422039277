import { createContext, useContext, useEffect, useState } from 'react';
import {
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from '../firebase';

export const UserContext = createContext({});

export const AuthContextProvider = ({ children }: any) => {
  const [user, setUser] = useState({});

  // const createUser = (email: string, password: string) => {
  //   return createUserWithEmailAndPassword(auth, email, password);
  // };

  // const signIn = (email: string, password: string) => {
  //   return signInWithEmailAndPassword(auth, email, password)
  // }

  // const logout = () => {
  //   return signOut(auth)
  // }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser: any) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};