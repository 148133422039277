import { Helmet } from 'react-helmet-async';
import { Button, FormGroup, Stack, Typography, Box, Grid, Checkbox, FormControlLabel, FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material';
import './LoginPage.css'
import './../App.css'
import { Form } from 'react-bootstrap';
import Iconify from '../components/iconify';
import { collection, query, where, getDocs, doc, setDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signInWithEmailAndPassword, auth, db } from '../firebase';
import Loader from './spinner';
import LoginLoader from './loginLoader';
import NotificationForeground from '../notification';

const initialState = {
  email: "",
  password: "",
}

export default function LoginPage() {
  const navigate = useNavigate();
  const [login, setLogin] = useState<any>(initialState)
  const [isEmailValidateError, setIsEmailValidateError] = useState(false);
  const [emailValidateErrorMsg, setEmailValidateErrorMsg] = useState("");
  const [isPasswordValidateError, setIsPasswordValidateError] = useState(false);
  const [passwordValidateErrorMsg, setPasswordValidateErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const token = JSON.parse(localStorage.getItem('tokenGenerated') || '{}');

  window.onpopstate = () => {
    navigate(1);
  }

  useEffect(() => {
    remember();
  }, [])

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setLogin({ ...login, [name]: value });
  };

  const validateEmail = (e: any) => {
    if (e.target.value) {
      setIsEmailValidateError(false);
      setEmailValidateErrorMsg("");
    } else {
      setIsEmailValidateError(true);
      setEmailValidateErrorMsg("Email is required");
    }
  }

  const ValidatePassword = (e: any) => {
    if (e.target.value) {
      setIsPasswordValidateError(false);
      setPasswordValidateErrorMsg("");
    } else {
      setIsPasswordValidateError(true);
      setPasswordValidateErrorMsg("Password is required");
    }
  }

  const validateForm = (event: any) => {
    event.preventDefault();
    let flag = true;
    if (!login.email) {
      setIsEmailValidateError(true);
      setEmailValidateErrorMsg("Email is required");
    }
    else {
      setIsEmailValidateError(false);
      setEmailValidateErrorMsg("");
    }
    if (!login.password) {
      setIsPasswordValidateError(true);
      setPasswordValidateErrorMsg("Password is required");
      flag = false;
    }
    else {
      setIsPasswordValidateError(false);
      setPasswordValidateErrorMsg("");
    }
    return flag;
  }

  const handleClick = async (event: any) => {
    setIsLoading(true);
    if (!login.email || !login.password) {
    }
    else {
      event.preventDefault();
      let flag = validateForm(event);
      if (flag) {
        try {
          const res = await signInWithEmailAndPassword(auth, login.email, login.password);
          if (res) {
            const usersCollectionRef = collection(db, 'users');
            const q = query(usersCollectionRef, where('email', '==', login.email), where('role.roleName', '==', 'admin'))
            const querySnapshot = await getDocs(q);
            const items: any[] = [];
            querySnapshot.forEach((doc: any) => {
              // items.push(doc.data());
              items.push({ id: doc.id, ...doc.data() });
            });
            localStorage.setItem('user', JSON.stringify(items))
            {
              if (items.length <= 0) {
                setIsLoading(false);
              } else {
                setIsLoading(false);
                navigate('/admin/dashboard');
                const settingRef = collection(db, 'systemFlags')
                const q = query(settingRef, where('flagGroupName', '==', 'Date'))
                const querySnapshot = await getDocs(q);
                const items1: any[] = [];
                querySnapshot.forEach((doc: any) => {
                  items1.push(doc.data());
                });
                localStorage.setItem('dateFormat', JSON.stringify(items1[0]?.value))
                const q1 = query(settingRef, where('flagGroupName', '==', 'Commission'))
                const querySnapshot1 = await getDocs(q1);
                const items2: any[] = [];
                querySnapshot1.forEach((doc1: any) => {
                  items2.push(doc1.data());
                });
                localStorage.setItem('commission', JSON.stringify(items2[0]?.value))
                if (!(items && items[0]?.tokenGenerated)) {
                  let obj = {
                    'tokenGenerated': token,
                  }
                  const tokenRef = doc(db, 'users', items[0]?.id);
                  await setDoc(tokenRef, obj, { merge: true });
                } else if ((items[0]?.tokenGenerated != token)) {
                  let obj = {
                    'tokenGenerated': token,
                  }
                  const tokenRef = doc(db, 'users', items[0]?.id);
                  await setDoc(tokenRef, obj, { merge: true });
                }
              }
            }
            if (checked === true) {
              localStorage.setItem('Data', JSON.stringify(login))
            }
          }
        } catch (err: any) {
          toast.error("Invalid Credential", {
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        }
      };
    }
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault();
  }

  const remember = () => {
    if (checked === true) {
      setChecked(false);
    } else if (checked === false) {
      setChecked(true);
      getDetail();
    }
  }

  const getDetail = () => {
    if ((localStorage.getItem('Data'))) {
      const storedData = JSON.parse(localStorage.getItem('Data') || '{}');
      setLogin(storedData);
      setChecked(true);
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <Helmet>
        <title> Login | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <NotificationForeground />
      <Grid container spacing={0} style={{ flexDirection: 'row', gap: '2rem' }}>
        <Box className="container-info ">
          <Typography className="centered">
            <Box display="flex" alignItems="center">
              <img src="/vyanjanLogo.png" alt="logo" width="24%" className="centered-img" />
              <Box >
                <img src="/vyanjanTagline.png" alt="logo" width="100%" className="centered-img" />
              </Box>
            </Box>
          </Typography>
        </Box >
        <div className="container-main">
          <Box sx={{ width: "80%", margin: "auto" }} className="centered">
            <Typography style={{ fontWeight: '700', fontSize: "152%" }}>
              LOGIN
            </Typography>
            <Form onSubmit={onFormSubmit}>
              <TextField
                sx={{ width: "100%", mt: 3 }}
                required
                name="email"
                label="Email address"
                autoFocus
                value={login.email}
                onChange={(e: any) => { validateEmail(e); handleInputChange(e); }}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{isEmailValidateError && emailValidateErrorMsg}</FormHelperText>
              <TextField
                required
                sx={{ width: "100%" }}
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={login.password}
                onChange={(e: any) => { ValidatePassword(e); handleInputChange(e) }}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{isPasswordValidateError && passwordValidateErrorMsg}</FormHelperText>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <FormGroup >
                  <FormControlLabel
                    control={
                      <Checkbox name="remember me"
                        checked={checked}
                        onChange={remember}
                        sx={{ size: '0.865rem' }} />
                    }
                    label={<p style={{ fontSize: '0.865rem' }}>Remember me</p>}
                  /></FormGroup>
                <RouterLink to="/forgotPassword" style={{ textDecoration: "none", color: "#1565c0", fontSize: '0.865rem' }}>
                  Forgot Password?
                </RouterLink>
              </Stack>

              <Button fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
                <div >
                  {isLoading ? <LoginLoader title="Loading..." /> : 'Login'}
                </div>
              </Button>

            </Form>
            {/* <Stack direction="row" display="flex" alignItems="center" justifyContent="center" sx={{ my: 2 }}>
        <RouterLink to="/terms&condition" style={{ textDecoration: "none", color: "#1565c0" }}>
          Terms & Condition
        </RouterLink>
        <RouterLink to="/privacypolicy" style={{ marginLeft: "50px", textDecoration: "none", color: "#1565c0" }}>
          Privacy Policy
        </RouterLink>
      </Stack> */}
          </Box>
        </div>
      </Grid>

    </>
  );
}