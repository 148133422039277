import { Breadcrumbs, Card, Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, } from '@mui/material';
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import Loader from '../spinner';
import HomeIcon from '@mui/icons-material/Home';
import { collection, endBefore, getCountFromServer, getDocs, limit, limitToLast, query, startAfter, where, orderBy, Timestamp } from 'firebase/firestore';
import { db } from './../../firebase'
import Scrollbar from '../../components/scrollbar';
import { format } from 'date-fns';

export const PaymentHistoryNewDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    let [limits, setLimits] = useState<number>(10);
    const [row, setRow] = useState<number>(10);
    const [detail, setDetail] = useState<any>([]) //show data inside page
    const [userFirstName, setUserFirstName] = useState<any>([])
    const [flag, setFlag] = useState<boolean>(true);
    const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

    let MakerId = sessionStorage.getItem('makerId');
    let startDate: any = sessionStorage.getItem('fromDate');
    let endDate: any = sessionStorage.getItem('toDate');

    // it is the first method invoked when this page is open
    useEffect(() => {
        getData();
        let MakerName = sessionStorage.getItem('makerName');
        if (MakerName) {
            setUserFirstName(MakerName)
        }
    }, [])

    //call initially
    const getData = async () => {
        const items: any[] = [];
        let filteredData: any[] = [];
        setIsLoading(true);
        try {
            const orderCollectionRef = collection(db, 'userOrders')
            const q = query(orderCollectionRef, where('makerId', '==', MakerId), where('orderStatus', '==', 'Delivered'))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })

            filteredData = items.filter((item: any) => 
            new Date(item.deliveryDate).getTime() >= new Date(startDate).getTime() && new Date(item.deliveryDate).getTime() <= new Date(endDate).getTime()
            );

            filteredData.forEach(element => {
                element.deliveryDate = new Date(element.deliveryDate);
            });

            filteredData = filteredData.sort((a: any, b: any) => (b.deliveryDate > a.deliveryDate) ? -1 : 1);

            setDetail(filteredData)
            if (filteredData.length > 0) {
                setFlag(true);
            } else {
                setFlag(false);
            }
            setRow(filteredData.length)

        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }
    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleChangePage = async (event: any, newPage: any) => {
        setPage(newPage);
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleChangeRowsPerPage = (event: any) => {
        setPage(0);
        setLimits(parseInt(event.target.value, 10));
    };

    return (
        <div>
            <Helmet>
                <title> Makers Payment Transaction Detail Page | Vyanjan-Pursuit for Hunger! </title>
            </Helmet>
            <div>
                <Container maxWidth="xl">
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <Stack alignItems="left" justifyContent="space-between" mb={5}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        to="/admin/dashboard"
                                        style={{ display: 'flex', color: "black" }}
                                    >
                                        <HomeIcon />
                                    </Link>
                                    <Link
                                        to="/admin/makerspayment"
                                        style={{ display: 'flex', color: "black", textDecoration: "none" }}
                                    >
                                        Makers Payment
                                    </Link>
                                    <Typography color="inherit">{userFirstName}</Typography>
                                </Breadcrumbs>
                            </Stack>
                            <Card className="card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                <div>
                                    {isLoading ? <Loader title="Loading..." /> :
                                        <>
                                            {(flag === true) ?
                                                <>
                                                    <Scrollbar>
                                                        <TableContainer className="tableContainer-list" sx={{ backgroundColor: 'white' }}>
                                                            <Table stickyHeader>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell >#</TableCell>
                                                                        <TableCell sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Seekers</Typography></TableCell>
                                                                        <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                                                        <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Date</Typography></TableCell>
                                                                        <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Orders Amount</Typography></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {detail.slice(page * limits, page * limits + limits).map((arr: any, index: number) =>
                                                                    (
                                                                        <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                                                            <TableCell >{(page * limits) + index + 1}</TableCell>
                                                                            <TableCell sx={{ color: '#212B36' }}>
                                                                                <Typography variant='subtitle2' noWrap>{arr.seekerName}</Typography></TableCell>
                                                                            <TableCell ><Typography noWrap fontSize="0.875rem">{arr.orderNo}</Typography></TableCell>
                                                                            <TableCell ><Typography noWrap fontSize="0.875rem">{format(new Date(arr.deliveryDate), formatedDate)}</Typography></TableCell>
                                                                            <TableCell ><Typography noWrap fontSize="0.875rem">₹ {(arr.totalNetAmount).toFixed(2)}</Typography></TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Scrollbar>
                                                    <TablePagination
                                                        rowsPerPageOptions={[10, 20, 30, 40]}
                                                        component="div"
                                                        count={row}
                                                        rowsPerPage={limits}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                </>
                                                :
                                                <TableContainer className="tableContainer-list" sx={{ backgroundColor: "white" }}>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            }
                                        </>
                                    }
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>

        </div>
    )
}
