import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Container,
  Typography,
  Card,
  Table,
  Paper,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  MenuItem,
  Select,
  Box,
  styled,
  Stack,
  Toolbar,
  Button,
} from '@mui/material';
import { AppWebsiteVisits } from '../sections/@dashboard/app';
import { useState, useEffect } from 'react';
import Scrollbar from '../components/scrollbar';
import { collection, where, getDocs, query, getCountFromServer, orderBy, Timestamp } from 'firebase/firestore';
import { db } from './../firebase';
import { Link, useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
import { fShortenNumber } from '../utils/formatNumber';
import SmallLoader from './dashboardSmallLoader';
import GraphLoader from './dashboardGraphLoader';
import TableLoader from './dashboardTableLoader';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

export default function DashboardAppPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading5, setIsLoading5] = useState(false);
  const [isLoading6, setIsLoading6] = useState(false);
  const [isLoading7, setIsLoading7] = useState(false);
  const [isLoading8, setIsLoading8] = useState(false);
  const [isLoading9, setIsLoading9] = useState(false);
  const [isLoading10, setIsLoading10] = useState(false);
  const [foodMakerCount, setFoodMakerCount] = useState<any>([])
  const [foodSeekerCount, setFoodSeekerCount] = useState<any>([])
  const [pendingfoodMakerCount, setPendingFoodMakerCount] = useState<any>([])
  const [activeOrdersCount, setActiveOrdersCount] = useState<any>([])
  const [activeOrdersDeliveredCount, setActiveOrdersDeliveredCount] = useState<any>([])
  const [makerPayment, setMakerPayment] = useState<any>([])
  const [finalfoodMaker, setFinalFoodMaker] = useState<any>([]);
  const [foodSeeker, setFoodSeeker] = useState<any>([]);
  const [productCategory, setProductCategory] = useState<any>([]);
  const [productItem, setProductItem] = useState<any>([]);
  const [selectedValueFoodMaker, setSelectedValueFoodMaker] = useState<any>('1');
  const [selectedValueFoodSeeker, setSelectedValueFoodSeeker] = useState<any>('1');
  const [selectedValueProductItem, setSelectedValueProductItem] = useState<any>('1');
  const [selectedValueProductCategory, setSelectedValueProductCategory] = useState<any>('1');
  const [year, setYear] = useState<any>([]);
  const [payment, setPayment] = useState<any>([]);
  const [orders, setOrders] = useState<any>([]);
  const [selectOrders, setSelectOrders] = useState(2023);
  const [selectCommission, setSelectCommission] = useState(2023);
  const [adminCommissionCount, setAdminCommissionCount] = useState<any>([]);
  const page: number = 0;
  const limit: number = 10;
  const navigate = useNavigate();

  // it is the first method invoked when this page is open
  useEffect(() => {
    handleTotalCount();
    getMakerPaymentCount();
    getAdminCommissionCount();
    getGraph1();
    getAdminCommissionData(selectCommission);
    getOrdersData(selectOrders);
    getFoodMaker();
    getFoodSeeker();
    getFoodItem();
    getFoodCategory();
  }, [])

  const handleTopMakers = () => {
    navigate('/admin/topfoodmakers');
  }

  const handleTopSeekers = () => {
    navigate('/admin/topfoodseekers');
  }

  //set value in localstorage when clicked pending makers
  const handlePendingMakers = () => {
    localStorage.setItem('status', JSON.stringify('Pending'))
    localStorage.setItem('checked', JSON.stringify(true))
  }

  //set value in localstorage when clicked makers
  const handleAllMakers = () => {
    localStorage.setItem('status', JSON.stringify('All'))
  }

  //set value in localstorage when clicked delivered orders
  const handleDeliveredOrders = () => {
    localStorage.setItem('orderStatus', JSON.stringify('Delivered'))
  }

  //call initially
  const handleTotalCount = async () => {
    setIsLoading(true);
    try {
      const foodMakerCountRef = collection(db, "users");
      const q = query(foodMakerCountRef, where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0))
      const snapshot = await getCountFromServer(q);
      setFoodMakerCount(snapshot.data().count)
      const foodSeekerCountRef = collection(db, 'users')
      const q1 = query(foodSeekerCountRef, where("role.roleName", "==", "foodSeeker"), where('isDelete', '==', 0))
      const querySnapshot = await getCountFromServer(q1)
      setFoodSeekerCount(querySnapshot.data().count)
      const pendingfoodMakerCountRef = collection(db, "users");
      const q2 = query(pendingfoodMakerCountRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', false), where('isDelete', '==', 0))
      const snapshot2 = await getCountFromServer(q2);
      setPendingFoodMakerCount(snapshot2.data().count)
      const activeOrdersCountRef = collection(db, "userOrders");
      const q3 = query(activeOrdersCountRef, where('isActive', '==', 1))
      const snapshot3 = await getCountFromServer(q3);
      setActiveOrdersCount(snapshot3.data().count)
      const activeOrdersDeliveredCountRef = collection(db, "userOrders");
      const q4 = query(activeOrdersDeliveredCountRef, where('isActive', '==', 1), where('orderStatus', '==', 'Delivered'))
      const snapshot4 = await getCountFromServer(q4);
      setActiveOrdersDeliveredCount(snapshot4.data().count)
      setIsLoading(false);
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  //call initially
  const getGraph1 = async () => {
    setYear(year.length = 0);
    for (let i = 10; i >= 0; i--) {
      let previousYear = new Date().getFullYear() - i
      let obj = {
        data: previousYear,
      }
      year.push(obj);
    }
    setYear(year);
  }

  //call when dropdown value change in orders
  const handleChangeOrders = (event: any) => {
    setSelectOrders(event.target.value);
    getOrdersData(event.target.value);
  };

  //call when dropdown value change in commission
  const handleChangeCommission = (event: any) => {
    setSelectCommission(event.target.value);
    getAdminCommissionData(event.target.value);
  };

  //call initially
  const getMakerPaymentCount = async () => {
    try {
      let date = new Date().getFullYear();
      const items: any[] = [];
      const items1: any[] = [];
      let data: any[] = [];
      let data2: any[] = [];
      let data1: any[] = [];
      let firstDay = new Date(date, 0, 1);
      let lastDay = new Date(date, 11, 31);
      let firstDate = Timestamp.fromDate(firstDay);
      let secondDate = Timestamp.fromDate(lastDay);
      const usersCollectionRef = collection(db, 'users');
      const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'asc'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const userOrderCollectionRef = collection(db, 'userOrders');
      const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        items1.push({ id: doc1.id, ...doc1.data() });
      });
      const filteredItems = items1.filter(item => item.orderStatus === 'Delivered');
      data1 = items.map((e: any) => {
        return ({
          ...e,
          finalAmount1: filteredItems.filter((d: any) => e.uid === d.makerId),
        })
      })
      data2 = data1.map((e: any) => {
        return ({
          ...e,
          totalOrderNo: e.finalAmount1.length,
        })
      })
      data = data2.map((e: any) => {
        return ({
          ...e,
          finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
        })
      })
      let finalData = data.map((e: any) => {
        return ({
          ...e,
          adminCommission: (e.finalAmount) * (e.commission.commission / 100),
        })
      })
      const finalizedAmountData = finalData.reduce((a: any, v: any) => a + v.finalAmount, 0);
      setMakerPayment(finalizedAmountData);
    } catch (error) {
      console.log(error)
    }
  }

  //call initially
  const getAdminCommissionCount = async () => {
    const items: any[] = [];
    const items1: any[] = [];
    let data: any[] = [];
    let data1: any[] = [];
    setIsLoading(true);
    try {
      let date = new Date().getFullYear();
      let firstDay = new Date(date, 0, 1);
      let lastDay = new Date(date, 11, 31);
      let firstDate = Timestamp.fromDate(firstDay);
      let secondDate = Timestamp.fromDate(lastDay);
      const userOrderCollectionRef = collection(db, 'userOrders');
      let q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        items1.push({ id: doc1.id, ...doc1.data() });
      });

      const usersCollectionRef = collection(db, 'users');
      const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true),
        orderBy('firstName', 'asc'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const filteredItems = items1.filter(item => item.orderStatus === 'Delivered');
      data1 = items.map((e: any) => {
        return ({
          ...e,
          finalAmount1: filteredItems.filter((d: any) => e.uid === d.makerId),
        })
      })

      data = items.map((e: any) => {
        return ({
          ...e,
          finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
        })
      })

      let finalData = data.map((e: any) => {
        return ({
          ...e,
          adminCommission: (e.finalAmount) * (e.commission.commission / 100),
        })
      })
      const finalizedAmount = finalData.reduce((a: any, v: any) => a + v.adminCommission, 0);
      setAdminCommissionCount(finalizedAmount);
    }
    catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  }

  //call initially
  const getAdminCommissionData = async (selectCommission: any) => {
    debugger
    setPayment(payment.length = 0);
    setIsLoading5(true);
    try {
      let date = new Date(new Date().getFullYear(), 0, 1);
      for (let i = date.getMonth(); i < 12; i++) {
        const items: any[] = [];
        const items1: any[] = [];
        let data: any[] = [];
        let firstDay = new Date(selectCommission, i, 1);
        let lastDay = new Date(selectCommission, i + 1, 0);
        let firstDate = Timestamp.fromDate(firstDay);
        let secondDate = Timestamp.fromDate(lastDay);
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'asc'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });

        const userOrderCollectionRef = collection(db, 'userOrders');
        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });

        const filteredItems = items1.filter(item => item.orderStatus === 'Delivered');

        data = items.map((e: any) => {
          return ({
            ...e,
            finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
          })
        })

        let finalData = data.map((e: any) => {
          return ({
            ...e,
            adminCommission: (e.finalAmount) * (e.commission.commission / 100),
          })
        })

        const finalizedCommissionData = finalData.reduce((a: any, v: any) => a + v.adminCommission, 0);

        let obj = {
          adminProfit: (finalizedCommissionData).toFixed(2),
        }
        payment.push(obj);
      }
      setPayment(payment);
    } catch (error) {
      console.log(error)
      setIsLoading5(false);
    }
    setIsLoading5(false);
  }

  //call initially
  const getOrdersData = async (selectOrders: any) => {
    setOrders(orders.length = 0);
    setIsLoading6(true);
    try {
      let date = new Date(new Date().getFullYear(), 0, 1);
      for (let i = date.getMonth(); i < 12; i++) {
        const items: any[] = [];
        const items1: any[] = [];
        let data: any[] = [];
        let data2: any[] = [];
        let data1: any[] = [];
        let firstDay = new Date(selectOrders, i, 1);
        let lastDay = new Date(selectOrders, i + 1, 0);
        let firstDate = Timestamp.fromDate(firstDay);
        let secondDate = Timestamp.fromDate(lastDay);
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), orderBy('firstName', 'asc'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });

        const userOrderCollectionRef = collection(db, 'userOrders');
        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });

        const filteredItems = items1.filter(item => item.orderStatus === 'Delivered');

        data1 = items.map((e: any) => {
          return ({
            ...e,
            finalAmount1: filteredItems.filter((d: any) => e.uid === d.makerId),
          })
        })

        data2 = data1.map((e: any) => {
          return ({
            ...e,
            totalOrderNo: e.finalAmount1.length,
          })
        })

        data = data2.map((e: any) => {
          return ({
            ...e,
            finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
          })
        })

        let finalData = data.map((e: any) => {
          return ({
            ...e,
            adminCommission: (e.finalAmount) * (e.commission.commission / 100),
          })
        })

        const finalizedAmountData = finalData.reduce((a: any, v: any) => a + v.finalAmount, 0);
        const finalizedOrderData = finalData.reduce((a: any, v: any) => a + v.totalOrderNo, 0);
        let obj = {
          totalSales: fShortenNumber(finalizedAmountData),
          totalOrders: fShortenNumber(finalizedOrderData),
        }
        orders.push(obj);
      }
      setOrders(orders);
    } catch (error) {
      console.log(error)
      setIsLoading6(false);
    }
    setIsLoading6(false);
  }

  //call initially
  const getFoodMaker = async () => {
    const items: any[] = [];
    const items1: any[] = [];
    let data: any[] = [];
    let data1: any[] = [];
    let sort: any[] = [];
    let amount: any[] = [];
    setIsLoading7(true);
    try {
      let curr = new Date();
      let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
      let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));


      let firstDate = Timestamp.fromDate(firstday);
      let secondDate = Timestamp.fromDate(lastday);
      const usersCollectionRef = collection(db, 'users');
      const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), where('isDelete', '==', 0), orderBy('firstName', 'asc'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const userOrderCollectionRef = collection(db, 'userOrders');
      const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        items1.push({ id: doc1.id, ...doc1.data() });
      });

      const filteredItems = items1.filter(item => item.orderStatus !== 'Cancelled');

      data1 = items.map((e: any) => {
        return ({
          ...e,
          finalAmount1: filteredItems.filter((d: any) => e.uid === d.makerId),
        })
      })

      data = items.map((e: any) => {
        return ({
          ...e,
          finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
        })
      })

      sort = data.sort((a, b) => (a.finalAmount > b.finalAmount) ? -1 : 1)

      amount = sort.filter((e: any) => e.finalAmount > 0)
      setFinalFoodMaker(amount);
    } catch (error) {
      console.log(error)
    }
    setIsLoading7(false);
  }

  //call initially
  const getFoodSeeker = async () => {
    const items: any[] = [];
    const items1: any[] = [];
    let data: any[] = [];
    let data1: any[] = [];
    let sort: any[] = [];
    let amount: any[] = [];
    setIsLoading8(true);
    try {
      let curr = new Date();
      let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
      let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));


      let firstDate = Timestamp.fromDate(firstday);
      let secondDate = Timestamp.fromDate(lastday);
      const usersCollectionRef = collection(db, 'users');
      const q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy('firstName', 'asc'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const userOrderCollectionRef = collection(db, 'userOrders');
      const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        items1.push({ id: doc1.id, ...doc1.data() });
      });
      const filteredItems = items1.filter(item => item.orderStatus !== 'Cancelled');
      data1 = items.map((e: any) => {
        return ({
          ...e,
          finalAmount1: filteredItems.filter((d: any) => e.uid === d.seekerId),
        })
      })

      data = items.map((e: any) => {
        return ({
          ...e,
          finalAmount: filteredItems.filter((d: any) => e.uid === d.seekerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
        })
      })

      sort = data.sort((a, b) => (a.finalAmount > b.finalAmount) ? -1 : 1)

      amount = sort.filter((e: any) => e.finalAmount > 0)
      setFoodSeeker(amount);
    } catch (error) {
      console.log(error)
    }
    setIsLoading8(false);
  }

  //call initially
  const getFoodItem = async () => {
    const items: any[] = [];
    const items0: any[] = [];
    const items1: any[] = [];
    const items2: any[] = [];
    const items3: any[] = [];
    let data: any[] = [];
    let data0: any[] = [];
    let data1: any[] = [];
    let sort: any[] = [];
    let amount: any[] = [];
    let finalData: any[] = [];
    setIsLoading9(true);
    try {
      let curr = new Date();
      let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
      let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));


      let firstDate = Timestamp.fromDate(firstday);
      let secondDate = Timestamp.fromDate(lastday);
      const q = collection(db, 'makerProduct');
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });

      const userOrderCollectionRef = collection(db, 'userOrders');
      let q0 = query(userOrderCollectionRef, where('orderStatus', '!=', 'Cancelled'));
      const querySnapshot0 = await getDocs(q0)
      querySnapshot0.forEach((doc0: any) => {
        if (doc0.data() && doc0.data().orderDetail && doc0.data().orderDetail.length > 0) {
          let res = doc0.data().orderDetail.length
          for (let i = 0; i < res; i++) {
            items0.push({ id: doc0.id, ...doc0.data().orderDetail[i] });
          }
        }
      });

      const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        if (doc1.data() && doc1.data().orderDetail && doc1.data().orderDetail.length > 0) {
          let res = doc1.data().orderDetail.length
          for (let i = 0; i < res; i++) {
            items1.push({ id: doc1.id, ...doc1.data().orderDetail[i] });
          }
        }
      });

      data0 = items0.map((e: any) => {
        return ({
          ...e,
          match: items1.filter((d: any, i: number) => e.id === d.id),
        })
      })

      data1 = items.map((e: any) => {
        return ({
          ...e,
          totalAmount: data0.filter((d: any) => e.pId === d.productId),
        })
      })

      data = items.map((e: any) => {
        return ({
          ...e,
          totalAmount: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.totalAmount, 0),
        })
      })

      sort = data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)

      const usersCollectionRef = collection(db, 'users');
      const q3 = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true),where('isDelete', '==', 0), orderBy('firstName', 'asc'))
      const querySnapshot3 = await getDocs(q3)
      querySnapshot3.forEach((doc3: any) => {
        items3.push({ id: doc3.id, ...doc3.data() });
      });
      finalData = sort.map((e: any) => {
        return ({
          ...e,
          makerName: items3.filter((d: any) => e.makerId === d.uid).map((e: any) => e.firstName),
        })
      })

      amount = finalData.filter((e: any) => e.totalAmount > 0)
      setProductItem(amount);
    } catch (error) {
      console.log(error)
    }
    setIsLoading9(false);
  }

  //call initially
  const getFoodCategory = async () => {
    const items: any[] = [];
    const items0: any[] = [];
    const items1: any[] = [];
    const items2: any[] = [];
    let data: any[] = [];
    let data0: any[] = [];
    let data1: any[] = [];
    let sort: any[] = [];
    let amount: any[] = [];
    setIsLoading10(true);
    try {
      let curr = new Date();
      let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
      let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));


      let firstDate = Timestamp.fromDate(firstday);
      let secondDate = Timestamp.fromDate(lastday);
      const q = collection(db, 'makerProduct');
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const userOrderCollectionRef = collection(db, 'userOrders');
      let q0 = query(userOrderCollectionRef, where('orderStatus', '!=', 'Cancelled'));
      const querySnapshot0 = await getDocs(q0)
      querySnapshot0.forEach((doc0: any) => {
        if (doc0.data() && doc0.data().orderDetail && doc0.data().orderDetail.length > 0) {
          let res = doc0.data().orderDetail.length
          for (let i = 0; i < res; i++) {
            items0.push({ id: doc0.id, ...doc0.data().orderDetail[i] });
          }
        }
      });

      const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        if (doc1.data() && doc1.data().orderDetail && doc1.data().orderDetail.length > 0) {
          let res = doc1.data().orderDetail.length
          for (let i = 0; i < res; i++) {
            items1.push({ id: doc1.id, ...doc1.data().orderDetail[i] });
          }
        }
      });

      data0 = items0.map((e: any) => {
        return ({
          ...e,
          match: items1.filter((d: any, i: number) => e.id === d.id),
        })
      })

      data = items.map((e: any) => {
        return ({
          ...e,
          totalAmount: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.totalAmount, 0),
        })
      })

      sort = data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)

      const q2 = collection(db, 'productCategory');
      const querySnapshot2 = await getDocs(q2)
      querySnapshot2.forEach((doc2: any) => {
        items2.push({ id: doc2.id, ...doc2.data() });
      });
      data1 = sort.map((e: any) => {
        return ({
          ...e,
          productCategoryImage: items2.filter((d: any) => e.productCategoryId === d.id).map((e: any) => e.productUrl),
        })
      })

      amount = data1.filter((e: any) => e.totalAmount > 0)
      setProductCategory(amount);
    } catch (error) {
      console.log(error)
    }
    setIsLoading10(false);
  }

  //call when dropdown value change in food maker
  const handleChangeFoodMaker = async (e: any) => {
    setSelectedValueFoodMaker(e.target.value);
    if (e.target.value === 1) {
      getFoodMaker();
    }
    else if (e.target.value === 2) {
      const items: any[] = [];
      const items1: any[] = [];
      let data: any[] = [];
      let data1: any[] = [];
      let sort: any[] = [];
      let amount: any[] = [];
      setIsLoading7(true);
      try {
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true), where('isDelete', '==', 0), orderBy('firstName', 'asc'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let firstDate = Timestamp.fromDate(firstDay);
        let secondDate = Timestamp.fromDate(lastDay);
        const userOrderCollectionRef = collection(db, 'userOrders');
        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });
        const filteredItems = items1.filter(item => item.orderStatus !== 'Cancelled');
        data1 = items.map((e: any) => {
          return ({
            ...e,
            finalAmount1: filteredItems.filter((d: any) => e.uid === d.makerId),
          })
        })

        data = items.map((e: any) => {
          return ({
            ...e,
            finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
          })
        })

        sort = data.sort((a, b) => (a.finalAmount > b.finalAmount) ? -1 : 1)

        amount = sort.filter((e: any) => e.finalAmount > 0)
        setFinalFoodMaker(amount);
      } catch (error) {
        console.log(error)
      }
      setIsLoading7(false);
    }
    else if (e.target.value === 3) {
      const items: any[] = [];
      const items1: any[] = [];
      let data: any[] = [];
      let data1: any[] = [];
      let sort: any[] = [];
      let amount: any[] = [];
      setIsLoading7(true);
      try {
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true),where('isDelete', '==', 0), orderBy('firstName', 'asc'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        let currentDate = new Date();
        let theFirst = new Date(currentDate.getFullYear(), 0, 1);
        let theLast = new Date(currentDate.getFullYear(), 11, 31);
        let firstDate = Timestamp.fromDate(theFirst);
        let secondDate = Timestamp.fromDate(theLast);
        const userOrderCollectionRef = collection(db, 'userOrders');
        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });
        const filteredItems = items1.filter(item => item.orderStatus !== 'Cancelled');
        data1 = items.map((e: any) => {
          return ({
            ...e,
            finalAmount1: filteredItems.filter((d: any) => e.uid === d.makerId),
          })
        })

        data = items.map((e: any) => {
          return ({
            ...e,
            finalAmount: filteredItems.filter((d: any) => e.uid === d.makerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
          })
        })

        sort = data.sort((a, b) => (a.finalAmount > b.finalAmount) ? -1 : 1)

        amount = sort.filter((e: any) => e.finalAmount > 0)
        setFinalFoodMaker(amount);
      } catch (error) {
        console.log(error)
      }
      setIsLoading7(false);
    }

  };

  //call when dropdown value change in food seeker
  const handleChangeFoodSeeker = async (e: any) => {
    setSelectedValueFoodSeeker(e.target.value);
    if (e.target.value === 1) {
      getFoodSeeker();
    }
    else if (e.target.value === 2) {
      const items: any[] = [];
      const items1: any[] = [];
      let data: any[] = [];
      let data1: any[] = [];
      let sort: any[] = [];
      let amount: any[] = [];
      setIsLoading8(true);
      try {
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy('firstName', 'asc'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let firstDate = Timestamp.fromDate(firstDay);
        let secondDate = Timestamp.fromDate(lastDay);
        const userOrderCollectionRef = collection(db, 'userOrders');
        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });
        const filteredItems = items1.filter(item => item.orderStatus !== 'Cancelled');
        data1 = items.map((e: any) => {
          return ({
            ...e,
            finalAmount1: filteredItems.filter((d: any) => e.uid === d.seekerId),
          })
        })

        data = items.map((e: any) => {
          return ({
            ...e,
            finalAmount: filteredItems.filter((d: any) => e.uid === d.seekerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
          })
        })

        sort = data.sort((a, b) => (a.finalAmount > b.finalAmount) ? -1 : 1)

        amount = sort.filter((e: any) => e.finalAmount > 0)
        setFoodSeeker(amount);
      } catch (error) {
        console.log(error)
      }
      setIsLoading8(false);
    }
    else if (e.target.value === 3) {
      const items: any[] = [];
      const items1: any[] = [];
      let data: any[] = [];
      let data1: any[] = [];
      let sort: any[] = [];
      let amount: any[] = [];
      setIsLoading8(true);
      try {
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy('firstName', 'asc'))
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        let currentDate = new Date();
        let theFirst = new Date(currentDate.getFullYear(), 0, 1);
        let theLast = new Date(currentDate.getFullYear(), 11, 31);
        let firstDate = Timestamp.fromDate(theFirst);
        let secondDate = Timestamp.fromDate(theLast);
        const userOrderCollectionRef = collection(db, 'userOrders');
        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });
        const filteredItems = items1.filter(item => item.orderStatus !== 'Cancelled');
        data1 = items.map((e: any) => {
          return ({
            ...e,
            finalAmount1: filteredItems.filter((d: any) => e.uid === d.seekerId),
          })
        })

        data = items.map((e: any) => {
          return ({
            ...e,
            finalAmount: filteredItems.filter((d: any) => e.uid === d.seekerId).reduce((a: any, v: any) => a + v.totalNetAmount, 0),
          })
        })

        sort = data.sort((a, b) => (a.finalAmount > b.finalAmount) ? -1 : 1)

        amount = sort.filter((e: any) => e.finalAmount > 0)
        setFoodSeeker(amount);
      } catch (error) {
        console.log(error)
      }
      setIsLoading8(false);
    }
  };

  //call when dropdown value change in product item
  const handleChangeProductItem = async (e: any) => {
    setSelectedValueProductItem(e.target.value);
    if (e.target.value === 1) {
      getFoodItem();
    }
    else if (e.target.value === 2) {
      const items: any[] = [];
      const items0: any[] = [];
      const items1: any[] = [];
      const items2: any[] = [];
      let data: any[] = [];
      let data0: any[] = [];
      let data1: any[] = [];
      let sort: any[] = [];
      let amount: any[] = [];
      let finalData: any[] = [];
      setIsLoading9(true);
      try {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let firstDate = Timestamp.fromDate(firstDay);
        let secondDate = Timestamp.fromDate(lastDay);
        const q = collection(db, 'makerProduct');
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const userOrderCollectionRef = collection(db, 'userOrders');
        let q0 = query(userOrderCollectionRef, where('orderStatus', '!=', 'Cancelled'));
        const querySnapshot0 = await getDocs(q0)
        querySnapshot0.forEach((doc0: any) => {
          if (doc0.data() && doc0.data().orderDetail && doc0.data().orderDetail.length > 0) {
            let res = doc0.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items0.push({ id: doc0.id, ...doc0.data().orderDetail[i] });
            }
          }
        });

        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          if (doc1.data() && doc1.data().orderDetail && doc1.data().orderDetail.length > 0) {
            let res = doc1.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items1.push({ id: doc1.id, ...doc1.data().orderDetail[i] });
            }
          }
        });

        data0 = items0.map((e: any) => {
          return ({
            ...e,
            match: items1.filter((d: any, i: number) => e.id === d.id),
          })
        })

        data1 = items.map((e: any) => {
          return ({
            ...e,
            totalAmount: data0.filter((d: any) => e.pId === d.productId),
          })
        })

        data = items.map((e: any) => {
          return ({
            ...e,
            totalAmount: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.totalAmount, 0),
          })
        })

        sort = data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)

        const usersCollectionRef = collection(db, 'users');
        const q2 = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true),where('isDelete', '==', 0), orderBy('firstName', 'asc'))
        const querySnapshot2 = await getDocs(q2)
        querySnapshot2.forEach((doc2: any) => {
          items2.push({ id: doc2.id, ...doc2.data() });
        });
        finalData = sort.map((e: any) => {
          return ({
            ...e,
            makerName: items2.filter((d: any) => e.makerId === d.uid).map((e: any) => e.firstName),
          })
        })

        amount = finalData.filter((e: any) => e.totalAmount > 0)
        setProductItem(amount);
      } catch (error) {
        console.log(error)
      }
      setIsLoading9(false);
    }
    else if (e.target.value === 3) {
      const items: any[] = [];
      const items0: any[] = [];
      const items1: any[] = [];
      const items2: any[] = [];
      let data: any[] = [];
      let data0: any[] = [];
      let data1: any[] = [];
      let sort: any[] = [];
      let amount: any[] = [];
      let finalData: any[] = [];
      setIsLoading9(true);
      try {
        let currentDate = new Date();
        let theFirst = new Date(currentDate.getFullYear(), 0, 1);
        let theLast = new Date(currentDate.getFullYear(), 11, 31);
        let firstDate = Timestamp.fromDate(theFirst);
        let secondDate = Timestamp.fromDate(theLast);
        const q = collection(db, 'makerProduct');
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const userOrderCollectionRef = collection(db, 'userOrders');
        let q0 = query(userOrderCollectionRef, where('orderStatus', '!=', 'Cancelled'));
        const querySnapshot0 = await getDocs(q0)
        querySnapshot0.forEach((doc0: any) => {
          if (doc0.data() && doc0.data().orderDetail && doc0.data().orderDetail.length > 0) {
            let res = doc0.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items0.push({ id: doc0.id, ...doc0.data().orderDetail[i] });
            }
          }
        });

        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          if (doc1.data() && doc1.data().orderDetail && doc1.data().orderDetail.length > 0) {
            let res = doc1.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items1.push({ id: doc1.id, ...doc1.data().orderDetail[i] });
            }
          }
        });

        data0 = items0.map((e: any) => {
          return ({
            ...e,
            match: items1.filter((d: any, i: number) => e.id === d.id),
          })
        })
        data1 = items.map((e: any) => {
          return ({
            ...e,
            totalAmount: data0.filter((d: any) => e.pId === d.productId),
          })
        })

        data = items.map((e: any) => {
          return ({
            ...e,
            totalAmount: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.totalAmount, 0),
          })
        })

        sort = data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)

        const usersCollectionRef = collection(db, 'users');
        const q2 = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isVerified', '==', true),where('isDelete', '==', 0), orderBy('firstName', 'asc'))
        const querySnapshot2 = await getDocs(q2)
        querySnapshot2.forEach((doc2: any) => {
          items2.push({ id: doc2.id, ...doc2.data() });
        });
        finalData = sort.map((e: any) => {
          return ({
            ...e,
            makerName: items2.filter((d: any) => e.makerId === d.uid).map((e: any) => e.firstName),
          })
        })

        amount = finalData.filter((e: any) => e.totalAmount > 0)
        setProductItem(amount);
      } catch (error) {
        console.log(error)
      }
      setIsLoading9(false);
    }
  };

  //call when dropdown value change in product category
  const handleChangeProductCategory = async (e: any) => {
    setSelectedValueProductCategory(e.target.value);
    if (e.target.value === 1) {
      getFoodCategory();
    }
    else if (e.target.value === 2) {
      const items: any[] = [];
      const items0: any[] = [];
      const items1: any[] = [];
      const items2: any[] = [];
      let data: any[] = [];
      let data0: any[] = [];
      let data1: any[] = [];
      let sort: any[] = [];
      let amount: any[] = [];
      setIsLoading10(true);
      try {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let firstDate = Timestamp.fromDate(firstDay);
        let secondDate = Timestamp.fromDate(lastDay);
        const q = collection(db, 'makerProduct');
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const userOrderCollectionRef = collection(db, 'userOrders');
        let q0 = query(userOrderCollectionRef, where('orderStatus', '!=', 'Cancelled'));
        const querySnapshot0 = await getDocs(q0)
        querySnapshot0.forEach((doc0: any) => {
          if (doc0.data() && doc0.data().orderDetail && doc0.data().orderDetail.length > 0) {
            let res = doc0.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items0.push({ id: doc0.id, ...doc0.data().orderDetail[i] });
            }
          }
        });

        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          if (doc1.data() && doc1.data().orderDetail && doc1.data().orderDetail.length > 0) {
            let res = doc1.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items1.push({ id: doc1.id, ...doc1.data().orderDetail[i] });
            }
          }
        });

        data0 = items0.map((e: any) => {
          return ({
            ...e,
            match: items1.filter((d: any, i: number) => e.id === d.id),
          })
        })
        data = items.map((e: any) => {
          return ({
            ...e,
            totalAmount: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.totalAmount, 0),
          })
        })

        sort = data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)

        const q2 = collection(db, 'productCategory');
        const querySnapshot2 = await getDocs(q2)
        querySnapshot2.forEach((doc2: any) => {
          items2.push({ id: doc2.id, ...doc2.data() });
        });
        data1 = sort.map((e: any) => {
          return ({
            ...e,
            productCategoryImage: items2.filter((d: any) => e.productCategoryId === d.id).map((e: any) => e.productUrl),
          })
        })

        amount = data1.filter((e: any) => e.totalAmount > 0)
        setProductCategory(amount);
      } catch (error) {
        console.log(error)
      }
      setIsLoading10(false);
    }
    else if (e.target.value === 3) {
      const items: any[] = [];
      const items0: any[] = [];
      const items1: any[] = [];
      const items2: any[] = [];
      let data: any[] = [];
      let data0: any[] = [];
      let data1: any[] = [];
      let sort: any[] = [];
      let amount: any[] = [];
      setIsLoading10(true);
      try {
        let currentDate = new Date();
        let theFirst = new Date(currentDate.getFullYear(), 0, 1);
        let theLast = new Date(currentDate.getFullYear(), 11, 31);
        let firstDate = Timestamp.fromDate(theFirst);
        let secondDate = Timestamp.fromDate(theLast);
        const q = collection(db, 'makerProduct');
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const userOrderCollectionRef = collection(db, 'userOrders');
        let q0 = query(userOrderCollectionRef, where('orderStatus', '!=', 'Cancelled'));
        const querySnapshot0 = await getDocs(q0)
        querySnapshot0.forEach((doc0: any) => {
          if (doc0.data() && doc0.data().orderDetail && doc0.data().orderDetail.length > 0) {
            let res = doc0.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items0.push({ id: doc0.id, ...doc0.data().orderDetail[i] });
            }
          }
        });

        const q1 = query(userOrderCollectionRef, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          if (doc1.data() && doc1.data().orderDetail && doc1.data().orderDetail.length > 0) {
            let res = doc1.data().orderDetail.length
            for (let i = 0; i < res; i++) {
              items1.push({ id: doc1.id, ...doc1.data().orderDetail[i] });
            }
          }
        });

        data0 = items0.map((e: any) => {
          return ({
            ...e,
            match: items1.filter((d: any, i: number) => e.id === d.id),
          })
        })
        data = items.map((e: any) => {
          return ({
            ...e,
            totalAmount: data0.filter((d: any) => e.pId === d.productId).reduce((a: any, v: any) => a + v.totalAmount, 0),
          })
        })

        sort = data.sort((a, b) => (a.totalAmount > b.totalAmount) ? -1 : 1)

        const q2 = collection(db, 'productCategory');
        const querySnapshot2 = await getDocs(q2)
        querySnapshot2.forEach((doc2: any) => {
          items2.push({ id: doc2.id, ...doc2.data() });
        });
        data1 = sort.map((e: any) => {
          return ({
            ...e,
            productCategoryImage: items2.filter((d: any) => e.productCategoryId === d.id).map((e: any) => e.productUrl),
          })
        })

        amount = data1.filter((e: any) => e.totalAmount > 0)
        setProductCategory(amount);
      } catch (error) {
        console.log(error)
      }
      setIsLoading10(false);
    }
  };

  let totalSales = []
  for (let i = 0; i < orders.length; i++) {
    const element = orders[i];
    totalSales.push(element.totalSales)
  }

  let totalOrders = []
  for (let i = 0; i < orders.length; i++) {
    const element = orders[i];
    totalOrders.push(element.totalOrders)
  }

  const series = [
    {
      name: 'Sells',
      type: 'column',
      fill: 'solid',
      data: totalSales,
    },
    {
      name: 'Orders',
      type: 'column',
      fill: 'solid',
      data: totalOrders,
    },
  ]

  let adminProfit = []
  for (let i = 0; i < payment.length; i++) {
    const element = payment[i];
    adminProfit.push(element.adminProfit)
  }

  const series1 = [
    {
      name: 'Admin-Commission',
      type: 'area',
      fill: 'gradient',
      data: adminProfit
    },
  ]

  return (
    <>
      <Helmet>
        <title> Dashboard | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl" style={{ marginBottom: '50px' }}>
        <Grid container spacing={3} style={{ overflow: 'auto' }}>
          <Grid item xs={12} sm={6} md={3} >
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                bgcolor: 'rgb(209, 233, 252)',
                borderRadius: '12px',
                height: '238px',
              }}
            >
              <div style={{ height: '158px' }}>
                {isLoading ? <SmallLoader title="Loading..." /> :
                  <>

                    <StyledIcon
                      sx={{
                        backgroundImage: `linear-gradient(135deg, rgba(16, 57, 150, 0) 0%, rgba(16, 57, 150, 0.24) 100%)`,
                      }}
                    >
                      <Iconify icon={"material-symbols:food-bank-outline"} width={24} height={24} sx={{ color: 'rgb(6, 27, 100)' }} />
                    </StyledIcon>
                    <Grid container spacing={0} direction="row" style={{ display: "flex", justifyContent: 'space-around' }}>
                      <Box pr={1} ml={1} >
                        <Typography variant="h3" style={{ color: 'rgb(6, 27, 100)', fontFamily: '"Public Sans", sans-serif', fontSize: '2rem', fontWeight: '700' }}>{pendingfoodMakerCount === 0 ? '0' : fShortenNumber(pendingfoodMakerCount)}/{foodMakerCount === 0 ? '0' : fShortenNumber(foodMakerCount)}</Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72, fontFamily: '"Public Sans", sans-serif', fontSize: '0.875rem', fontWeight: '600', color: 'rgb(6, 27, 100)' }}>
                          <Link to='/admin/foodMakers' style={{ textDecoration: "none", color: 'inherit' }} onClick={handlePendingMakers}>
                            Pending/</Link>
                          <Link to='/admin/foodMakers' style={{ textDecoration: "none", color: 'inherit' }} onClick={handleAllMakers}>
                            Makers</Link>
                        </Typography>
                      </Box>
                      <Box pr={1} ml={1}
                      >
                        <Typography variant="h3" style={{ color: 'rgb(6, 27, 100)', fontFamily: '"Public Sans", sans-serif', fontSize: '2rem', fontWeight: '700' }}>{foodSeekerCount === 0 ? '0' : fShortenNumber(foodSeekerCount)}</Typography>
                        <Typography variant="subtitle2" sx={{ opacity: 0.72, fontFamily: '"Public Sans", sans-serif', fontSize: '0.875rem', fontWeight: '600', color: 'rgb(6, 27, 100)' }}>
                          <Link to='/admin/foodSeekers' style={{ textDecoration: "none", color: 'inherit' }}>
                            Seekers</Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </>}
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                bgcolor: 'rgb(208, 242, 255)',
                borderRadius: '12px',
                height: '238px',
              }}
            >
              <div style={{ height: '158px' }}>
                {isLoading ? <SmallLoader title="Loading..." /> :
                  <>
                    <StyledIcon
                      sx={{
                        backgroundImage: `linear-gradient(135deg, rgba(12, 83, 183, 0) 0%, rgba(12, 83, 183, 0.24) 100%)`,
                      }}
                    >
                      <Iconify icon={"fluent-mdl2:activate-orders"} width={24} height={24} sx={{ color: 'rgb(4, 41, 122)' }} />
                    </StyledIcon>
                    <Typography variant="h3" style={{ color: 'rgb(4, 41, 122)', fontFamily: '"Public Sans", sans-serif', fontSize: '2rem', fontWeight: '700' }}>{activeOrdersDeliveredCount === 0 ? '0' : fShortenNumber(activeOrdersDeliveredCount)}/{activeOrdersCount === 0 ? '0' : fShortenNumber(activeOrdersCount)}</Typography>
                    {/* <Typography variant="subtitle2" sx={{ opacity: 0.72, fontFamily: '"Public Sans", sans-serif', fontSize: '0.875rem', fontWeight: '600', color: 'rgb(4, 41, 122)' }}>
                      <Link to='/admin/orders' style={{ textDecoration: "none", color: 'inherit' }}>
                        Active Orders</Link>
                    </Typography> */}
                    <Typography variant="subtitle2" sx={{ opacity: 0.72, fontFamily: '"Public Sans", sans-serif', fontSize: '0.875rem', fontWeight: '600', color: 'rgb(6, 27, 100)' }}>
                      <Link to='/admin/orders' style={{ textDecoration: "none", color: 'inherit' }} onClick={handleDeliveredOrders}>
                        Delivered/</Link>
                      <Link to='/admin/orders' style={{ textDecoration: "none", color: 'inherit' }} >
                        Active Orders</Link>
                    </Typography>
                  </>}
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                bgcolor: 'rgb(255, 247, 205)',
                borderRadius: '12px',
                height: '238px',
              }}
            >
              <div style={{ height: '158px' }}>
                {isLoading ? <SmallLoader title="Loading..." /> :
                  <>
                    <StyledIcon
                      sx={{
                        backgroundImage: `linear-gradient(135deg, rgba(183, 129, 3, 0) 0%, rgba(183, 129, 3, 0.24) 100%)`,
                      }}
                    >
                      <Iconify icon={"mdi:account-payment-outline"} width={24} height={24} sx={{ color: 'rgb(122, 79, 1)' }} />
                    </StyledIcon>
                    <Typography variant="h3" style={{ color: 'rgb(122, 79, 1)', fontFamily: '"Public Sans", sans-serif', fontSize: '2rem', fontWeight: '700' }}>₹ {makerPayment === 0 ? '0' : fShortenNumber(makerPayment - adminCommissionCount)}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72, fontFamily: '"Public Sans", sans-serif', fontSize: '0.875rem', fontWeight: '600', color: 'rgb(122, 79, 1)' }}>
                      <Link to="/admin/makerspayment" style={{ textDecoration: "none", color: 'inherit' }}>
                        Makers Payable
                      </Link>
                    </Typography>
                  </>}
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                bgcolor: 'rgb(255, 231, 217)',
                borderRadius: '12px',
                height: '238px'
              }}
            >
              <div style={{ height: '158px' }}>
                {isLoading ? <SmallLoader title="Loading..." /> :
                  <>
                    <StyledIcon
                      sx={{
                        backgroundImage: `linear-gradient(135deg, rgba(183, 33, 54, 0) 0%, rgba(183, 33, 54, 0.24) 100%)`,
                      }}
                    >
                      <Iconify icon={"fluent-mdl2:nonprofit-logo-32"} width={24} height={24} sx={{ color: 'rgb(122, 12, 46)' }} />
                    </StyledIcon>
                    <Typography variant="h3" style={{ color: 'rgb(122, 12, 46)', fontFamily: '"Public Sans", sans-serif', fontSize: '2rem', fontWeight: '700' }}>₹ {adminCommissionCount === 0 ? '0' : fShortenNumber(adminCommissionCount)}</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: 0.72, fontFamily: '"Public Sans", sans-serif', fontSize: '0.875rem', fontWeight: '600', color: 'rgb(122, 12, 46)' }}>
                      <Link to="/admin/admincommission" style={{ textDecoration: "none", color: 'inherit' }}>
                        Admin Commission</Link>
                    </Typography>
                  </>}
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
              <>
                <div >
                  {isLoading5 ? <GraphLoader title="Loading..." /> :
                    <AppWebsiteVisits
                      title={<Typography sx={{ fontFamily: '"Public Sans", sans-serif', fontSize: '1.125rem', fontWeight: '700' }}>
                        <Link to="/admin/admincommission" style={{ textDecoration: "none", color: 'inherit' }}>
                          Admin Commission </Link>
                      </Typography>}
                      subheader={
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selectCommission}
                          onChange={handleChangeCommission}
                        >
                          {year.map((e: any) => (
                            <MenuItem value={e.data} key={e.data}>{e.data}</MenuItem>
                          ))}
                        </Select>}
                      chartData={series1}
                    />
                  }
                </div>
              </>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
              <>
                <div>
                  {isLoading6 ? <GraphLoader title="Loading..." /> :
                    <AppWebsiteVisits
                      title={<Typography sx={{ fontFamily: '"Public Sans", sans-serif', fontSize: '1.125rem', fontWeight: '700' }}>
                        <Link to="/admin/orders" style={{ textDecoration: "none", color: 'inherit' }}>
                          Sells/Orders</Link>
                      </Typography>}
                      subheader={
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selectOrders}
                          onChange={handleChangeOrders}
                        >
                          {year.map((e: any) => (
                            <MenuItem value={e.data} key={e.data}>{e.data}</MenuItem>
                          ))}
                        </Select>}
                      chartData={series}
                    />
                  }
                </div>
              </>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', height: '491px' }}>
              <StyledRoot
                sx={{
                  color: 'primary.main',
                  bgcolor: 'white',

                }}
              >
                <Typography variant="h6" sx={{ color: '#212B36' }}>
                  <Link to="/admin/topfoodmakers" style={{ textDecoration: "none", color: '#212B36' }}>
                    <b>Top 10 Makers List</b></Link>
                </Typography>
                <div style={{ display: "flex" }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedValueFoodMaker}
                    onChange={handleChangeFoodMaker}
                    style={{ paddingLeft: '1px', paddingRight: '1px' }}
                  >
                    <MenuItem value={1}>Weekly</MenuItem>
                    <MenuItem value={2}>Monthly</MenuItem>
                    <MenuItem value={3}>Yearly</MenuItem>
                  </Select>
                  <Button
                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px', height: '56px', ml: '10px', mt: '-1px' }}
                    variant="contained"
                    onClick={handleTopMakers}>
                    View All
                  </Button>
                </div>
              </StyledRoot>
              < Scrollbar >
                <TableContainer sx={{ height: '395px', backgroundColor: 'white' }}>
                  <>
                    {isLoading7 ? <TableLoader title="Loading..." /> :
                      <>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow >
                              <TableCell>#</TableCell>
                              <TableCell align="left" sx={{ color: '#212B36' }} ><Typography variant='subtitle2' noWrap>Name</Typography></TableCell>
                              <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Email</Typography></TableCell>
                              <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Contact No.</Typography></TableCell>
                              <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Gender</Typography></TableCell>
                              <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Orders Amount</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          {finalfoodMaker && finalfoodMaker.length > 0 ?
                            <TableBody>
                              {
                                finalfoodMaker
                                  .slice(page * limit, page * limit + limit)
                                  .map((arr: any, index: any) => {
                                    return (
                                      <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                        <TableCell>
                                          {index + 1}
                                        </TableCell>
                                        <TableCell align="left" sx={{ color: '#212B36' }}>
                                          <Stack direction="row" justifyContent="left" spacing={2}>
                                            {arr.profilePicUrl ? <Avatar src={arr.profilePicUrl}></Avatar> : <Avatar >{arr.firstName ? arr.firstName[0] : null}</Avatar>}
                                            <Typography variant="subtitle2" noWrap sx={{ display: "flex", alignItems: "center" }}>
                                              {arr.firstName}
                                            </Typography>
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center">{arr.email}</TableCell>
                                        <TableCell align="center">{arr.contactNo}</TableCell>
                                        <TableCell align="center">{arr.gender}</TableCell>
                                        <TableCell align="center">₹ {(arr.finalAmount).toFixed(2)}</TableCell>
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                            :
                            <TableBody>
                              <TableRow sx={{ bgcolor: 'white' }}>
                                {/* <TableCell colSpan={6}> */}
                                <Paper
                                  sx={{
                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                  }}
                                >
                                  <img src="\search.png" alt="no data" height="50" width="50" style={{ marginLeft: "35%" }} />
                                  <Typography variant="h6" paragraph>
                                    Data not Available
                                  </Typography>
                                </Paper>
                                {/* </TableCell> */}
                              </TableRow>
                            </TableBody>
                          }
                        </Table>
                      </>
                    }
                  </>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', height: '491px' }}>
              <StyledRoot
                sx={{
                  color: 'primary.main',
                  bgcolor: 'white',

                }}
              >
                <Typography variant="h6" sx={{ color: '#212B36' }}>
                  <Link to="/admin/topfoodseekers" style={{ textDecoration: "none", color: '#212B36' }}>
                    <b>Top 10 Seekers List</b></Link>
                </Typography>
                <div style={{ display: "flex" }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedValueFoodSeeker}
                    onChange={handleChangeFoodSeeker}
                  >
                    <MenuItem value={1}>Weekly</MenuItem>
                    <MenuItem value={2}>Monthly</MenuItem>
                    <MenuItem value={3}>Yearly</MenuItem>
                  </Select>
                  <Button
                    sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px', height: '56px', ml: '10px', mt: '-1px' }}
                    variant="contained"
                    onClick={handleTopSeekers}>
                    View All
                  </Button>
                </div>
              </StyledRoot>
              <Scrollbar>
                <TableContainer sx={{ height: '395px', backgroundColor: 'white' }}>
                  <>
                    {isLoading8 ? <TableLoader title="Loading..." /> :
                      <>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow >
                              <TableCell align="center" >#</TableCell>
                              <TableCell align="left" sx={{ color: '#212B36' }} ><Typography variant='subtitle2' noWrap>Name</Typography></TableCell>
                              <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Email</Typography></TableCell>
                              <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Contact No.</Typography></TableCell>
                              <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Gender</Typography></TableCell>
                              <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Orders Amount</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          {foodSeeker && foodSeeker.length > 0 ?
                            <TableBody>
                              {
                                foodSeeker
                                  .slice(page * limit, page * limit + limit)
                                  .map((arr: any, index: any) => {
                                    return (
                                      <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                        <TableCell >
                                          {index + 1}
                                        </TableCell >
                                        <TableCell align="left" sx={{ color: '#212B36' }}>
                                          <Stack direction="row" justifyContent="left" spacing={2}>
                                            {arr.profilePicUrl ? <Avatar src={arr.profilePicUrl}></Avatar> : <Avatar >{arr.firstName ? arr.firstName[0] : null}</Avatar>}
                                            <Typography variant="subtitle2" noWrap sx={{ display: "flex", alignItems: "center" }}>
                                              {arr.firstName}
                                            </Typography>
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center">{arr.email}</TableCell>
                                        <TableCell align="center">{arr.contactNo}</TableCell>
                                        <TableCell align="center">{arr.gender}</TableCell>
                                        <TableCell align="center">₹ {(arr.finalAmount).toFixed(2)}</TableCell>
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                            :
                            <TableBody>
                              <TableRow sx={{ bgcolor: 'white' }}>
                                {/* <TableCell colSpan={4}> */}
                                <Paper
                                  sx={{
                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                  }}
                                >
                                  <img src="\search.png" alt="no data" height="50" width="50" style={{ marginLeft: "35%" }} />
                                  <Typography variant="h6" paragraph>
                                    Data not Available
                                  </Typography>
                                </Paper>
                                {/* </TableCell> */}
                              </TableRow>
                            </TableBody>
                          }
                        </Table>
                      </>
                    }
                  </>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', height: '491px' }}>
              <StyledRoot
                sx={{
                  color: 'primary.main',
                  bgcolor: 'white',

                }}
              >
                <Typography variant="h6" sx={{ color: '#212B36' }}>
                  <Link to="/admin/topsellingproducts" style={{ textDecoration: "none", color: '#212B36' }}>
                    <b>Top 10 Selling Product List</b></Link>
                </Typography>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedValueProductItem}
                  onChange={handleChangeProductItem}
                  style={{ paddingLeft: '1px', paddingRight: '1px' }}
                >
                  <MenuItem value={1}>Weekly</MenuItem>
                  <MenuItem value={2}>Monthly</MenuItem>
                  <MenuItem value={3}>Yearly</MenuItem>
                </Select>
              </StyledRoot>
              <Scrollbar>
                <TableContainer sx={{ height: '395px', backgroundColor: 'white' }}>
                  <>
                    {isLoading9 ? <TableLoader title="Loading..." /> :
                      <>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow >
                              <TableCell >#</TableCell>
                              <TableCell align="left" sx={{ color: '#212B36' }} ><Typography noWrap fontWeight="600" fontSize="0.875rem">Product</Typography></TableCell>
                              <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Makers</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          {productItem && productItem.length > 0 ?
                            <TableBody>
                              {
                                productItem
                                  .slice(page * limit, page * limit + limit)
                                  .map((arr: any, index: any) => {
                                    return (
                                      <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                        <TableCell >
                                          {index + 1}
                                        </TableCell>
                                        <TableCell align="left" sx={{ color: '#212B36' }}>
                                          <Stack direction="row" justifyContent="left" spacing={2}>
                                            {arr.productImage ? <Avatar src={arr.productImage} variant="rounded"></Avatar> : <Avatar variant="rounded">{arr.name ? arr.name[0] : null}</Avatar>}
                                            <Typography variant='subtitle2' noWrap sx={{ display: "flex", alignItems: "center" }}>
                                              {arr.name}
                                            </Typography>
                                          </Stack>
                                        </TableCell>
                                        <TableCell align="center">
                                          {arr.makerName}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                            :
                            <TableBody>
                              <TableRow sx={{ bgcolor: 'white' }}>
                                {/* <TableCell colSpan={4}> */}
                                <Paper
                                  sx={{
                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                  }}
                                >
                                  <img src="\search.png" alt="no data" height="50" width="50" style={{ marginLeft: "35%" }} />
                                  <Typography variant="h6" paragraph>
                                    Data not Available
                                  </Typography>
                                </Paper>
                                {/* </TableCell> */}
                              </TableRow>
                            </TableBody>
                          }
                        </Table>
                      </>
                    }
                  </>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', height: '491px' }}>
              <StyledRoot
                sx={{
                  color: 'primary.main',
                  bgcolor: 'white',

                }}
              >
                <Typography variant="h6" sx={{ color: '#212B36' }}>
                  <Link to="/admin/topsellbyproductcategory" style={{ textDecoration: "none", color: '#212B36' }}>
                    <b>Top 10 Product Category List</b></Link>
                </Typography>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={selectedValueProductCategory}
                  onChange={handleChangeProductCategory}
                  style={{ paddingLeft: '1px', paddingRight: '1px' }}
                >
                  <MenuItem value={1}>Weekly</MenuItem>
                  <MenuItem value={2}>Monthly</MenuItem>
                  <MenuItem value={3}>Yearly</MenuItem>
                </Select>
              </StyledRoot>
              <Scrollbar>
                <TableContainer sx={{ height: '395px', backgroundColor: 'white' }}>
                  <>
                    {isLoading10 ? <TableLoader title="Loading..." /> :
                      <>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow >
                              <TableCell>#</TableCell>
                              <TableCell align="left" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Product Category</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          {productCategory && productCategory.length > 0 ?
                            <TableBody>
                              {
                                productCategory
                                  .slice(page * limit, page * limit + limit)
                                  .map((arr: any, index: any) => {
                                    return (
                                      <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                        <TableCell  >
                                          {index + 1}
                                        </TableCell >
                                        <TableCell align="left" sx={{ color: '#212B36' }}>
                                          <Stack direction="row" justifyContent="left" spacing={2}>
                                            {arr.productCategoryImage ? <Avatar src={arr.productCategoryImage} variant="rounded"></Avatar> : <Avatar src="/defaultFood.png" variant="rounded"></Avatar>}
                                            <Typography variant='subtitle2' noWrap sx={{ display: "flex", alignItems: "center" }}>
                                              {arr.productCategory}
                                            </Typography>
                                          </Stack>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                            :
                            <TableBody>
                              <TableRow sx={{ bgcolor: 'white' }}>
                                {/* <TableCell colSpan={3}> */}
                                <Paper
                                  sx={{
                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                  }}
                                >
                                  <img src="\search.png" alt="no data" height="50" width="50" style={{ marginLeft: "35%" }} />
                                  <Typography variant="h6" paragraph>
                                    Data not Available
                                  </Typography>
                                </Paper>
                                {/* </TableCell> */}
                              </TableRow>
                            </TableBody>
                          }
                        </Table>
                      </>
                    }
                  </>
                </TableContainer>
              </Scrollbar>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}