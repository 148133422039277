import { Helmet } from 'react-helmet-async';
import { ChangeEvent, useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  Tooltip,
  Switch,
  Drawer,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  InputAdornment,
  alpha,
  OutlinedInput,
  styled,
  Toolbar,
  Breadcrumbs,
  Chip,
  Box,
  FormHelperText,
} from '@mui/material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate, Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { addDoc, collection, doc, endBefore, getCountFromServer, getDocs, limit, limitToLast, orderBy, query, serverTimestamp, startAfter, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import Loader from './spinner';
import './FoodMakerPage.css';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const initialState = {
  id: '',
  firstName: '',
  gender: "",
  contactNo: null,
  isActive: "",
  fcmToken: "",
}

export default function FoodSeekerPage() {
  const [foodSeeker, setFoodSeeker] = useState<any>(initialState);
  const [open, setOpen] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState(false)
  const [page, setPage] = useState(0);
  let [limits, setLimits] = useState<number>(10);
  const [row, setRow] = useState<number>(10);
  const [contactNo, setContactNo] = useState<any>([])
  let [detail, setDetail] = useState<any>([]); // to show data inside page
  const [selected, setSelected] = useState("");
  let [searchInput, setSearchInput] = useState('');
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisible, setFirstVisible] = useState<any>();
  const [orderField, setOrderField] = useState<string>('firstName');
  let [orderByField, setOrderByField] = useState<any>('asc');
  const [chipGender, setChipGender] = useState<boolean>(false);
  const [chipContactNo, setChipContactNo] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(true);
  const [isNumberValidateError, setIsNumberValidateError] = useState(false);
  const [numberValidateErrorMsg, setNumberValidateErrorMsg] = useState("");
  const [validateFlag, setValidateFlag] = useState(false);
  const navigate = useNavigate();
  const senderId = (JSON.parse(localStorage.getItem('user') || '{}'));

  //if click on view set this item in sessionStorage which used in detail page
  const handleClickVisible = (element: any) => {
    let uid = element?.uid
    sessionStorage.setItem("FirstName", element?.firstName + "");
    sessionStorage.setItem("LastName", element?.lastName + "");
    navigate(`/admin/foodSeekers/view/${uid}`)
  }

  // open on clicking filter icon
  const onOpenFilter = () => {
    setOpen(!open)
  }

  //close switch dailog
  const handleCloseSwitch = () => {
    setIsCheck(false);
    setFoodSeeker('');
  }

  // call when click on switch
  const handleSwitch = async (id: number, isActive: boolean, fcmToken: string) => {
    let obj = {
      "id": id,
      "isActive": isActive,
      "fcmToken": fcmToken
    }
    setFoodSeeker(obj);
    setIsCheck(true);
  };

  //call when switch status changed and saved
  const handleCheckSwitch = async () => {
    try {
      //update the isActive status when activate and deactivate
      if (foodSeeker.isActive === 1) {
        const obj = {
          isActive: 0
        }
        const activeStatus = doc(db, 'users', foodSeeker.id)
        updateDoc(activeStatus, obj)
        let data = JSON.parse(JSON.stringify(detail));
        let ind = data.findIndex((c: any) => c.id === foodSeeker.id);
        if (ind > -1) {
          data[ind].isActive = 0;
        }
        setDetail(data);
        sendFCMTokenInActive(); // send notification
        const notificationRef = collection(db, 'notifications')
        await addDoc(notificationRef, { //add data in notification collection
          receiverId: foodSeeker.id,
          senderId: senderId[0]?.uid,
          title: "InActive",
          subTitle: "Your account is Deactivated",
          notificationNumber: 18,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp()
        });
      } else if (foodSeeker.isActive === 0) {
        const obj = {
          isActive: 1
        }
        const activeStatus = doc(db, 'users', foodSeeker.id)
        updateDoc(activeStatus, obj)
        let data = JSON.parse(JSON.stringify(detail));
        let ind = data.findIndex((c: any) => c.id === foodSeeker.id);
        if (ind > -1) {
          data[ind].isActive = 1;
        }
        setDetail(data);
        sendFCMTokenActive(); //send notification
        const notificationRef = collection(db, 'notifications')
        await addDoc(notificationRef, { //add data in notification collection
          receiverId: foodSeeker.id,
          senderId: senderId[0]?.uid,
          title: "Active",
          subTitle: "Your account is Activated",
          notificationNumber: 17,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp()
        });
      }
    } catch (error) {
      console.log(error)
    }
    setIsCheck(false);
  };

  // it is the first method invoked when this page is open
  useEffect(() => {
    getFoodSeeker();
    getDropdownData();
  }, [])

  //call intitally
  const getFoodSeeker = async () => {
    debugger
    const items: any[] = [];
    const items1: any[] = [];
    let data: any[] = [];
    if (searchInput) {
      setIsLoading(false);
      setFlag(true);
    } else {
      setIsLoading(true);
    }
    try {
      const usersCollectionRef = collection(db, 'users');
      let qLenn = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy(orderField, orderByField));
      if (foodSeeker.firstName) {
        qLenn = query(qLenn, where('firstName', '>=', (foodSeeker.firstName.charAt(0).toUpperCase() + foodSeeker.firstName.substr(1).toLowerCase())),
          where('firstName', '<', (foodSeeker.firstName.charAt(0).toUpperCase() + foodSeeker.firstName.substr(1).toLowerCase()) + 'z'))
      }
      if (foodSeeker.gender) {
        qLenn = query(qLenn, where('gender', '==', foodSeeker.gender))
      }
      if (foodSeeker.contactNo) {
        qLenn = query(qLenn, where('contactNo', '==', foodSeeker.contactNo))
      }
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count);
      let q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy(orderField, orderByField), limit(limits));
      if (foodSeeker.firstName) {
        q = query(q, where('firstName', '>=', (foodSeeker.firstName.charAt(0).toUpperCase() + foodSeeker.firstName.substr(1).toLowerCase())),
          where('firstName', '<', (foodSeeker.firstName.charAt(0).toUpperCase() + foodSeeker.firstName.substr(1).toLowerCase()) + 'z'))
      }
      if (foodSeeker.gender) {
        q = query(q, where('gender', '==', foodSeeker.gender))
      }
      if (foodSeeker.contactNo) {
        q = query(q, where('contactNo', '==', foodSeeker.contactNo))
      }
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const q1 = query(usersCollectionRef, orderBy(orderField, orderByField))
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc1: any) => {
        items1.push({ id: doc1.id, ...doc1.data() });
      });
      data = items.map((e: any) => {
        return ({
          ...e,
          referenceUserName: items1.filter((d: any) => d.uid === e.referedUserId).map((f: any) => f.displayName)
        })
      })
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisible(querySnapshot.docs[0]);
      setDetail(data);
      if (data.length > 0) {
        setFlag(true);
      } else {
        setFlag(false);
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  //change value while filtering field
  const handleChange = (arr: any) => {
    setChipGender(false);
    arr.preventDefault();
    const { name, value } = arr.target;
    setFoodSeeker({ ...foodSeeker, [name]: value });
  }

  //change value while filtering contactNo field
  const handleChange1 = (v: any) => {
    setChipContactNo(false);
    setSelected(v)
    foodSeeker.contactNo = v.target.value;
  }

  const numberRegex = new RegExp(/^((\+){1}91){1}[98765]{1}[0-9]{9}$/); // contact no regex with country code
  const validateNumber = (e: any) => {
    if (e.target.value) {
      if ((numberRegex.test(e.target.value))) {
        setIsNumberValidateError(false);
        setNumberValidateErrorMsg("");
        setValidateFlag(false);
      } else {
        setIsNumberValidateError(true);
        setNumberValidateErrorMsg("10 digit phone number along with prefix country code");
        setValidateFlag(true);
      }
    }
  }

  // when click on search button in filter
  const handleSearch = async () => {
    if (validateFlag === false) {
      setFlag(false);
      setPage(0);
      if (foodSeeker.contactNo && foodSeeker.gender && (validateFlag === false)) {
        setChipContactNo(true);
        setChipGender(true);
      }
      else if (foodSeeker.contactNo) {
        setChipContactNo(true);
      }
      else if (foodSeeker.gender) {
        setChipGender(true);
      }
      if (searchInput) {
        searchItems(searchInput, page * limits, limits);
      } else {
        getFoodSeeker();
      }
      setOpen(false)
    }

  };

  //when click on clear button in filter
  const handleClear = () => {
    setFoodSeeker(initialState)
    foodSeeker.firstName = "";
    foodSeeker.gender = "";
    foodSeeker.contactNo = "";
    setSelected("")
    setChipContactNo(false);
    setChipGender(false);
    getFoodSeeker();
    setOpen(false);
  }

  // when any chip is deleted
  const handleDelete = (e: any) => {
    if (e === foodSeeker.firstName) {
      foodSeeker.firstName = "";
    }
    else if (e === foodSeeker.gender) {
      setChipGender(false);
      foodSeeker.gender = "";
    }
    else if (e === foodSeeker.contactNo) {
      setChipContactNo(false);
      foodSeeker.contactNo = "";
      setSelected("");
    }
    handleSearch();
    // handleClear();
  }

  // when we want to do sorting in descending order
  const sortDecending = async () => {
    setPage(0);
    setOrderField('firstName');
    setOrderByField('desc');
    orderByField = 'desc'
    setTimeout(async () => {
      await getFoodSeeker();
      setSort(true)
    }, 1000);
  }

  // when we want to do sorting in ascending order
  const sortAscending = async () => {
    setPage(0);
    setOrderField('firstName');
    setOrderByField('asc');
    orderByField = 'asc'
    setTimeout(async () => {
      await getFoodSeeker();
      setSort(false)
    }, 1000);
  }

  //send notification while switch active
  const sendFCMTokenActive = async () => {
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      body: JSON.stringify({
        "registration_ids": [foodSeeker.fcmToken],
        "notification": {
          "title": "Active",
          "body": "Your account is Activated",
          "sound": "default",
          "color": "#ff3296fa",
          "vibrate": "300",
          "priority": "high",
        },
      }),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'key=AAAAvT050EY:APA91bHOLzULyLPZo2AVnwLcgzMr5oah-gf6NH1eQLupY3RGR6nAkBjBdMX81LaRYeF3Dub26OCA-TCDN7-NMZktmcRt8DtcThJB9EjjEGbLIuHTwMmcbiGWyEa2fsOVRVkuVp6wQypZ'
      }
    }).then((response) => {
      return response.json
    }).catch((error) => {
      console.log(error)
    })
  }

  //send notification while switch inactive
  const sendFCMTokenInActive = async () => {
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      body: JSON.stringify({
        "registration_ids": [foodSeeker.fcmToken],
        "notification": {
          "title": "InActive",
          "body": "Your account is Deactivated",
          "sound": "default",
          "color": "#ff3296fa",
          "vibrate": "300",
          "priority": "high",
        },
      }),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'key=AAAAvT050EY:APA91bHOLzULyLPZo2AVnwLcgzMr5oah-gf6NH1eQLupY3RGR6nAkBjBdMX81LaRYeF3Dub26OCA-TCDN7-NMZktmcRt8DtcThJB9EjjEGbLIuHTwMmcbiGWyEa2fsOVRVkuVp6wQypZ'
      }
    }).then((response) => {
      return response.json
    }).catch((error) => {
      console.log(error)
    })
  }

  // when cloud function is called
  async function searchData(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData; // return the response data
    } catch (error) {
      console.error(error);
    }
  }

  // invoked everytime when their is searchvalue in searchBox
  const searchItems = async (searchValue: any, startIndex: number, fetchRecord: number) => {
    try {
      setFlag(true);
      setPage(0);
      setSearchInput(searchValue);
      searchInput = searchValue;
      if (searchValue) {
        const items: any[] = [];
        const items1: any[] = [];
        let data: any[] = [];
        const requestBody = {
          query: searchValue,
          role: "foodSeeker",
          size: fetchRecord,
          from: startIndex
        };
        const result = await searchData(process.env.REACT_APP_USER_SEARCH, requestBody);
        const usersCollectionRef = collection(db, 'users');
        for (let i = 0; i < result.data.length; i++) {
          let element = result.data[i]._source;
          setFlag(true)

          let q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'), where('isDelete', '==', 0),orderBy(orderField, orderByField), limit(limits));
          if (foodSeeker.firstName) {
            q = query(q, where('firstName', '>=', (foodSeeker.firstName.charAt(0).toUpperCase() + foodSeeker.firstName.substr(1).toLowerCase())),
              where('firstName', '<', (foodSeeker.firstName.charAt(0).toUpperCase() + foodSeeker.firstName.substr(1).toLowerCase()) + 'z'))
          }
          if (foodSeeker.gender) {
            q = query(q, where('gender', '==', foodSeeker.gender))
          }
          if (foodSeeker.contactNo) {
            q = query(q, where('contactNo', '==', foodSeeker.contactNo))
          }
          q = query(q, where('displayName', '==', element.displayName))

          const querySnapshot = await getDocs(q)
          querySnapshot.forEach((doc: any) => {
            items.push({ id: doc.id, ...doc.data() });
          });
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisible(querySnapshot.docs[0]);
        }
        const q1 = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy(orderField, orderByField))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });
        data = items.map((e: any) => {
          return ({
            ...e,
            referenceUserName: items1.filter((d: any) => d.uid === e.referedUserId).map((f: any) => f.displayName)
          })
        })

        if (data.length > 0) {
          setDetail(data);
          detail = data;
          setRow(data.length)
          setFlag(true);
        } else {
          setFlag(false);
        }
      } else {
        searchInput = ""
        setSearchInput("")
        await getFoodSeeker();
      }
    }
    catch (error) {
      console.log(error)
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handlePageChange = async (event: any, newPage: number): Promise<void> => {
    if (searchInput) {
      setPage(newPage);
      searchItems(searchInput, (newPage * limits), limits)
    } else {
      setPage(newPage);
      if (page < newPage) {
        let next = query(collection(db, 'users'),
          where('role.roleName', '==', 'foodSeeker'),
          where('isDelete', '==', 0),
          orderBy(orderField, orderByField),
          startAfter(lastVisible),
          limit(limits));
        if (searchInput) {
          next = query(next, where('firstName', '>=', searchInput)
            , where('firstName', '<', searchInput + 'z'))
        }
        if (foodSeeker.firstName) {
          next = query(next, where('firstName', '>=', foodSeeker.firstName),
            where('firstName', '<', foodSeeker.firstName + 'z'))
        }
        if (foodSeeker.gender) {
          next = query(next, where('gender', '==', foodSeeker.gender))
        }
        if (foodSeeker.contactNo) {
          next = query(next, where('contactNo', '==', foodSeeker.contactNo))
        }
        const items: any[] = [];
        const items1: any[] = [];
        let data: any[] = [];
        const querySnapshot = await getDocs(next);
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const q1 = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy(orderField, orderByField))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });
        data = items.map((e: any) => {
          return ({
            ...e,
            referenceUserName: items1.filter((d: any) => d.uid === e.referedUserId).map((f: any) => f.displayName)
          })
        })
        updateState(querySnapshot)
        setDetail(data);
      } else {
        let next = query(collection(db, 'users'),
          where('role.roleName', '==', 'foodSeeker'),
          where('isDelete', '==', 0),
          orderBy(orderField, orderByField),
          endBefore(firstVisible),
          limitToLast(limits));
        if (searchInput) {
          next = query(next, where('firstName', '>=', searchInput)
            , where('firstName', '<', searchInput + 'z'))
        }
        if (foodSeeker.firstName) {
          next = query(next, where('firstName', '>=', foodSeeker.firstName),
            where('firstName', '<', foodSeeker.firstName + 'z'))
        }
        if (foodSeeker.gender) {
          next = query(next, where('gender', '==', foodSeeker.gender))
        }
        if (foodSeeker.contactNo) {
          next = query(next, where('contactNo', '==', foodSeeker.contactNo))
        }
        const items: any[] = [];
        const items1: any[] = [];
        let data: any[] = [];
        const querySnapshot = await getDocs(next);
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const q1 = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy(orderField, orderByField))
        const querySnapshot1 = await getDocs(q1)
        querySnapshot1.forEach((doc1: any) => {
          items1.push({ id: doc1.id, ...doc1.data() });
        });

        data = items.map((e: any) => {
          return ({
            ...e,
            referenceUserName: items1.filter((d: any) => d.uid === e.referedUserId).map((f: any) => f.displayName)
          })
        })
        updateState(querySnapshot);
        setDetail(data);
      }
    }
  };

  //call initially
  const getDropdownData = async () => {
    const items: any[] = [];
    setIsLoading(true);
    try {
      const usersCollectionRef = collection(db, 'users');
      const q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), orderBy(orderField, orderByField));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setContactNo(items);
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  // update first visible and last visible value according to pagination (next and previous button)
  const updateState = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimits(parseInt(event.target.value));
    limits = parseInt(event.target.value);
    setPage(0);
    getFoodSeeker();
  };

  return (
    <>
      <Helmet>
        <title> Food Seekers | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack alignItems="left" justifyContent="space-between" mb={5}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit">Food Seekers</Typography>
              </Breadcrumbs>
            </Stack>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
              <div>
                {isLoading ? <Loader title="Loading..." /> :
                  <>
                    <Drawer
                      anchor="right"
                      open={open}
                      onClose={onOpenFilter}
                      PaperProps={{
                        sx: { width: 280, border: 'none', overflow: 'hidden' },
                      }}
                    >
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                        <IconButton onClick={onOpenFilter} >
                          <Iconify icon="eva:close-fill" sx={{ color: "red" }} />
                        </IconButton>
                        <Typography variant="subtitle1" sx={{ marginRight: "67%", color: "black" }}>
                          Filters
                        </Typography>
                      </Stack>
                      <Divider />
                      <Scrollbar>
                        <Stack spacing={3} sx={{ p: 3, backgroundColor: 'white', height: 'calc(100vh - 100px)' }}>
                          <div>
                            <FormGroup>
                              <FormControl >
                                <FormLabel id="demo-controlled-radio-buttons-group" >Gender</FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="gender"
                                  value={foodSeeker.gender}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                  <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                  <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                </RadioGroup>
                              </FormControl>
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <FormControl>
                                <TextField id="filter"
                                  label="Contact Number"
                                  type="text"
                                  name="contactNo"
                                  value={foodSeeker.contactNo}
                                  onChange={(e: any) => { handleChange1(e); validateNumber(e); }}
                                />
                                <FormHelperText style={{ color: 'red', height: '5px' }}>{isNumberValidateError && numberValidateErrorMsg}</FormHelperText>
                              </FormControl>
                            </FormGroup>
                          </div>
                        </Stack>
                      </Scrollbar>
                      <Box sx={{ pl: '16px', pr: '16px', pt: '8px', pb: '8px', bottom: "10px", width: '100%' }}>
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          variant="contained"
                          className="mb-3"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </Box>
                      <Box sx={{ pl: '16px', pr: '16px', pt: '8px', pb: '16px', bottom: "0px", width: '100%' }}>
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          variant="contained"
                          onClick={handleClear}
                        >
                          Clear
                        </Button>
                      </Box>
                    </Drawer>
                    <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                      <div>
                        <StyledRoot
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'white',
                          }}
                        >
                          <StyledSearch
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value, page * limits, limits)}
                            placeholder="Search by Name..."
                            startAdornment={
                              <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                              </InputAdornment>
                            }
                          />

                          <div className="largeScreenContent" style={{ width: '100%' }}>
                            {chipGender === true ?
                              <>
                                {
                                  foodSeeker.gender && foodSeeker.gender.length > 0 ?
                                    <Chip color="primary" size="small" label={foodSeeker.gender} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDelete(foodSeeker.gender)} /> : ""
                                }
                              </>
                              :
                              ''
                            }
                            {chipContactNo === true ?
                              <>
                                {
                                  foodSeeker.contactNo && foodSeeker.contactNo.length > 0 ?
                                    <Chip color="primary" size="small" label={foodSeeker.contactNo} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDelete(foodSeeker.contactNo)} /> : ""
                                }
                              </>
                              :
                              ''
                            }
                          </div>

                          <Tooltip title="Filter list">
                            <IconButton
                              color="inherit"
                              size="small"
                              onClick={onOpenFilter}
                            >
                              <FilterListIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledRoot>
                        <div className="smallScreenContent" style={{ width: '100%' }}>
                          {chipGender === true ?
                            <>
                              {
                                foodSeeker.gender && foodSeeker.gender.length > 0 ?
                                  <Chip color="primary" size="small" label={foodSeeker.gender} sx={{ mx: 1 }}
                                    onDelete={(e) => handleDelete(foodSeeker.gender)} /> : ""
                              }
                            </>
                            :
                            ''
                          }
                          {chipContactNo === true ?
                            <>
                              {
                                foodSeeker.contactNo && foodSeeker.contactNo.length > 0 ?
                                  <Chip color="primary" size="small" label={foodSeeker.contactNo} sx={{ mx: 1 }}
                                    onDelete={(e) => handleDelete(foodSeeker.contactNo)} /> : ""
                              }
                            </>
                            :
                            ''
                          }
                        </div>
                      </div>
                      {
                        (flag === true) ?
                          <>
                            <Scrollbar>
                              <TableContainer className="foodmaker-list" sx={{ backgroundColor: "white" }}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="left" >#</TableCell>
                                      <TableCell align="left" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Name
                                        {
                                          (sort === false) ?
                                            <IconButton
                                              sx={{ marginLeft: "2px", color: "black" }}
                                              color="inherit"
                                              size="small"
                                              onClick={sortDecending}
                                            >
                                              <ArrowDropDownIcon />
                                            </IconButton>
                                            :
                                            <IconButton
                                              sx={{ marginLeft: "2px", color: "black" }}
                                              color="inherit"
                                              size="small"
                                              onClick={sortAscending}
                                            >
                                              <ArrowDropUpIcon />
                                            </IconButton>
                                        }
                                      </Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Gender</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Contact No</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Reference User</Typography></TableCell>
                                      <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {
                                      detail.map((arr: any, index: any) =>
                                      (
                                        <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                          <TableCell align="left">{(page * limits) + index + 1}</TableCell>
                                          <TableCell component="th" scope="row" padding="none" >
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                              {arr.profilePicUrl ? <Avatar src={arr.profilePicUrl}></Avatar> : <Avatar>{arr.firstName ? arr.firstName[0] : null}</Avatar>}
                                              <Typography variant="subtitle2" noWrap>
                                                {arr.firstName}  {arr.lastName}
                                              </Typography>
                                            </Stack>
                                          </TableCell>
                                          <TableCell align="left" >{arr.gender}</TableCell>
                                          <TableCell align="left" >{arr.contactNo}</TableCell>
                                          <TableCell align="left" >
                                            {(arr.referenceUserName === '') ? "--" : arr.referenceUserName}
                                          </TableCell>
                                          <TableCell align="right" style={{ display: "flex" }} >
                                            <Tooltip title="View" arrow>
                                              <IconButton
                                                sx={{ color: '#1565c0' }}
                                                color="inherit"
                                                size="small"
                                                onClick={(e) => { handleClickVisible(arr) }}
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title={(arr.isActive === 1) ? "Active" : "Inactive"} arrow>
                                              <Switch
                                                checked={arr.isActive}
                                                onClick={(e) => handleSwitch(arr.id, arr.isActive, arr.fcmToken)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                              />
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                      )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Scrollbar>
                            <TablePagination
                              component="div"
                              count={row}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleLimitChange}
                              page={page}
                              rowsPerPage={limits}
                              rowsPerPageOptions={[10, 20, 30, 40]}
                            />
                          </>
                          :
                          <TableContainer className="foodmaker-list-notFound" sx={{ backgroundColor: "white" }}>
                            <Table>
                              <Divider />
                              <TableBody>
                                <TableRow>
                                  <Paper
                                    sx={{
                                      width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                    }}
                                  >
                                    <img src="\search.png" alt="no data" height="50" width="50" style={{ marginLeft: "35%" }} />
                                    <Typography variant="h6" paragraph>
                                      Data not Available
                                    </Typography>
                                  </Paper>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                      }
                    </Card>
                  </>
                }
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <div>
        <Dialog open={isCheck} onClose={handleCloseSwitch}>
          <DialogTitle>{(foodSeeker.isActive === 1) ? 'Active' : 'InActive'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {(foodSeeker.isActive === 0) ? 'Are you sure you want to Active?' : 'Are you sure you want to InActive?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSwitch}>Cancel</Button>
            <Button onClick={handleCheckSwitch}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}