import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getMessaging, onMessage } from "firebase/messaging";
import {getFunctions, httpsCallable} from "firebase/functions";
import {onAuthStateChanged,createUserWithEmailAndPassword,signInWithEmailAndPassword,getAuth,sendPasswordResetEmail,signOut,confirmPasswordReset } from "@firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDeYV2-GylGx9apj0sAM-rBbCARXqlsfps",
  authDomain: "vyanjan-b3af8.firebaseapp.com",
  databaseURL: "https://vyanjan-b3af8-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "vyanjan-b3af8",
  storageBucket: "vyanjan-b3af8.appspot.com",
  messagingSenderId: "812776017990",
  appId: "1:812776017990:web:1968a513e433fd65c1fa47",
  measurementId: "G-3RS5VC56CW"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const functions = getFunctions(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export {storage,db,auth,messaging,onAuthStateChanged,createUserWithEmailAndPassword,signInWithEmailAndPassword,sendPasswordResetEmail,confirmPasswordReset,signOut,functions, app}
