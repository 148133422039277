import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../src/context/AuthContext';

const Protected = ({ children }: any) => {
  const { user }: any = UserAuth();

  if (!user) {
    return <Navigate to='/' />;
  }
  return children;
};

export default Protected;