import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  Tooltip,
  Switch,
  Drawer,
  TextField,
  FormControl,
  Divider,
  FormGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
  Grid,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  Avatar,
  Rating,
  styled,
  Toolbar,
  InputAdornment,
  alpha,
  OutlinedInput,
  Breadcrumbs,
  Chip,
  Box,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppBadIcon from '@mui/icons-material/GppBad';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PercentIcon from '@mui/icons-material/Percent';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { db } from '../firebase';
import { collection, query, where, setDoc, getDocs, orderBy, limit, startAfter, getCountFromServer, endBefore, limitToLast, doc, updateDoc, addDoc, serverTimestamp } from "firebase/firestore";
import Loader from './spinner';
import './FoodMakerPage.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Label from '../components/label';
import { format } from 'date-fns';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 357,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 367,
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const initialState = {
  id: "",
  uid: "",
  firstName: "",
  location: "",
  contactNo: null,
  gender: "",
  email: "",
  displayName: "",
  commission: localStorage.getItem('commission'),
  isActive: "",
  referedUserId: "",
  isVerified: "",
  fcmToken: "",
  bankId: '',
  makerId: '',
  isBankVerified: '',
  status: '',
}

export default function FoodMakerPage() {
  const [foodMaker, setFoodMaker] = useState<any>(initialState);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState(false)
  const [page, setPage] = useState<number>(0);
  let [limits, setLimits] = useState<number>(10);
  const [row, setRow] = useState<number>(10);
  let [detail, setDetail] = React.useState<any>([]); // to show data inside page
  const [location, setLocation] = useState<any>([])
  const [selected, setSelected] = useState<any>("")
  const [selected1, setSelected1] = useState<any>("")
  let [searchInput, setSearchInput] = useState('');
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisible, setFirstVisible] = useState<any>();
  const [orderField, setOrderField] = useState<string>('firstName');
  let [orderByField, setOrderByField] = useState<any>('asc');
  const [chipLocation, setChipLocation] = useState<boolean>(false);
  const [chipGender, setChipGender] = useState<boolean>(false);
  const [chipContactNo, setChipContactNo] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(true);
  const [isNumberValidateError, setIsNumberValidateError] = useState(false);
  const [numberValidateErrorMsg, setNumberValidateErrorMsg] = useState("");
  const [validateFlag, setValidateFlag] = useState(false);
  const [isBankVerification, setIsBankVerification] = useState(false);
  const [bankDetail, setBankDetail] = useState<any>([])
  let [checked, setChecked] = React.useState(false);
  const [isCommissionError, setCommissionError] = useState(false);
  const [commissionErrorMsg, setCommissionErrorMsg] = useState("");
  const senderId = (JSON.parse(localStorage.getItem('user') || '{}'));
  const navigate = useNavigate();

  // it is the first method invoked when this page is open
  useEffect(() => {
    //Get value from localstorage which is set while clicking on first card in dashboard page
    if (localStorage.getItem('status')) {
      foodMaker.status = JSON.parse(localStorage.getItem('status') || '{}');
      if (foodMaker.status === 'Pending') {
        setChecked(true);
        checked = true;
      }
      else {
        setChecked(false);
        checked = false;
      }
    }
    else {
      setFoodMaker(initialState)
      foodMaker.status = 'All';
    }
    getFoodMaker();
    getDropdownData();
  }, [])

  // when searchemail cloud function is called
  async function searchEmail(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      return response; // return the response data
    } catch (error) {
      console.error(error);
    }
  }

  const reg = new RegExp(/^0*(?:[1-9]\d?|100)$/); // regex for checking number from 1-100
  const validateCommision = (arr: any) => {
    if (arr.target.value) {
      if ((reg.test(arr.target.value))) {
        setCommissionError(false);
        setCommissionErrorMsg("");
      } else {
        setCommissionError(true);
        setCommissionErrorMsg("1-100 Number only Allowed");
      }
    } else {
      setCommissionError(true);
      setCommissionErrorMsg("");
    }
  }

  const numberRegex = new RegExp(/^((\+){1}91){1}[98765]{1}[0-9]{9}$/); // contact no regex with country code
  const validateNumber = (e: any) => {
    if (e.target.value) {
      if ((numberRegex.test(e.target.value))) {
        setIsNumberValidateError(false);
        setNumberValidateErrorMsg("");
        setValidateFlag(false);
      } else {
        setIsNumberValidateError(true);
        setNumberValidateErrorMsg("10 digit phone number along with prefix country code");
        setValidateFlag(true);
      }
    }
  }

  // call while validating error in end
  const validateForm = (e: any) => {
    e.preventDefault();
    let flag = true;
    if (!foodMaker.commission) {
      setCommissionError(true);
      setCommissionErrorMsg("");
      flag = false;
    } else if (foodMaker.commission >= 1 && foodMaker.commission <= 100) {
      setCommissionError(true);
      setCommissionErrorMsg("");
    }
    else {
      if ((reg.test(foodMaker.commission))) {
        setCommissionError(false);
        setCommissionErrorMsg("");
      } else {
        setCommissionError(true);
        setCommissionErrorMsg("1-100 Number only Allowed");
        flag = false;
      }
    }
    return flag;
  }

  //if click on view set this item in sessionStorage which used in detail page
  const handleClickDetails = (element: any) => {
    let uid = element?.uid
    sessionStorage.setItem("FirstName", element?.firstName + "");
    sessionStorage.setItem("LastName", element?.lastName + "");
    navigate(`/admin/foodMakers/view/${uid}`)
  }

  // open on clicking filter icon
  const onOpenFilter = () => {
    setOpen(!open)
  }

  //close switch dailog
  const handleCloseSwitch = () => {
    setFoodMaker(initialState);
    setIsCheck(false);
    setIsOpen(false);
  }

  // call when click on switch
  const handleSwitch = async (id: number, isActive: boolean, fcmToken: string) => {
    let obj = {
      "id": id,
      "isActive": isActive,
      "fcmToken": fcmToken,
      "status": foodMaker.status
    }
    setFoodMaker(obj);
    setIsCheck(true);
  };

  //call when switch status changed and saved
  const handleCheckSwitch = async () => {
    try {
      //update the isActive status when activate and deactivate
      if (foodMaker.isActive === 1) {
        const obj = {
          isActive: 0
        }
        const activeStatus = doc(db, 'users', foodMaker.id)
        updateDoc(activeStatus, obj)
        let data = JSON.parse(JSON.stringify(detail));
        let ind = data.findIndex((c: any) => c.id === foodMaker.id);
        if (ind > -1) {
          data[ind].isActive = 0;
        }
        setDetail(data);
        sendFCMTokenInActive();  // send notification
        const notificationRef = collection(db, 'notifications')
        await addDoc(notificationRef, { //add data in notification collection
          receiverId: foodMaker.id,
          senderId: senderId[0]?.uid,
          title: "InActive",
          subTitle: "Your account is Deactivated",
          notificationNumber: 18,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp()
        });

      } else if (foodMaker.isActive === 0) {
        const obj = {
          isActive: 1
        }
        const activeStatus = doc(db, 'users', foodMaker.id)
        updateDoc(activeStatus, obj)
        let data = JSON.parse(JSON.stringify(detail));
        let ind = data.findIndex((c: any) => c.id === foodMaker.id);
        if (ind > -1) {
          data[ind].isActive = 1;
        }
        setDetail(data);
        sendFCMTokenActive(); //send notification
        const notificationRef = collection(db, 'notifications')
        await addDoc(notificationRef, { //add data in notification collection
          receiverId: foodMaker.id,
          senderId: senderId[0]?.uid,
          title: "Active",
          subTitle: "Your account is Activated",
          notificationNumber: 17,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp()
        });
      }
    } catch (error) {
      console.log(error)
    }
    setIsCheck(false);
    setFoodMaker(initialState);
  };

  //change value while filtering gender field
  const handleChange = (e: any) => {
    setChipGender(false);
    e.preventDefault();
    const { name, value } = e.target;
    setFoodMaker({
      ...foodMaker,
      [name]: value,
    });

  }

  //change value while filtering status field
  const handleChangeStatus = (e: any) => {
    localStorage.removeItem('status');
    e.preventDefault();
    foodMaker.status = e.target.value
    if (foodMaker.status === 'Pending') {
      setChecked(true);
      checked = true;
    } else {
      setChecked(false);
      checked = false;
    }
    getFoodMaker();
  }

  //change value while filtering location field
  const handleChange1 = (e: any, v: any) => {
    setChipLocation(false);
    setSelected(v)
    foodMaker.location = v
  }

  //change value while filtering contactNo field
  const handleChange2 = (v: any) => {
    setChipContactNo(false);
    setSelected1(v);
    foodMaker.contactNo = v.target.value;
  }

  //called when click on edit button
  const handleClickisEdit = (no: number, no1: number, str: any) => {
    let obj = {
      uid: no,
      commission: no1
    }
    if (str === false) {
      setIsOpen(false);
      setCommissionError(false);
      setCommissionErrorMsg("");
      if (foodMaker.status === 'Pending') {
        setIsOpen(false);
      }
    } else {
      setFoodMaker(obj)
      setIsOpen(true);
      setCommissionError(false);
      setCommissionErrorMsg("");
    }
  };

  // call when click on verifiy button
  const handleClickisAdd = (element: any) => {
    if (foodMaker.status === 'Pending') { // when status is pending for all
      toast.error('Commission will not be set for single field, if verify all is selected!', {
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
      })
    }
    else { // when single field verifying
      setIsOpen(true);
      let obj = {
        id: element?.uid,
        referedUserId: element?.referedUserId,
        fcmToken: element?.fcmToken,
        commission: foodMaker.commission,
        email: element?.email,
        displayName: element?.displayName
      }
      setFoodMaker(obj);
    }
  }

  //verifiy all is clicked
  const handleChangeCheckedAll = async () => {
    if (foodMaker.status === "Pending") {
      await getFoodMaker();
      if (detail && detail.length > 0) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    } else if (foodMaker.status === "All") {
      foodMaker.status = "All"
      await getFoodMaker();
    }

  };

  // to verify bank detail
  const handleClickBankVerification = async (arr: any) => {
    if (arr.bankId) { // if bank id is present
      let obj = {
        "bankId": arr.bankId,
        "isBankVerified": true,
        "fcmToken": arr.fcmToken,
        "makerId": arr.id,
      }
      setFoodMaker(obj)
      const items: any[] = [];
      try {
        const bankCollectionRef = collection(db, 'bankDetail');
        const q = query(bankCollectionRef, where('makerId', '==', arr.id))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          items.push(doc.data());
        })
        setBankDetail(items);
      } catch (error) {
        console.log(error)
      }
      setIsBankVerification(true);
    } else { // if not have bank id
      toast.error('Not have a Bank Account!', {
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
      })
    }
  }

  //close bank verification dialog
  const handleCloseBankVerification = () => {
    setFoodMaker(initialState);
    setIsBankVerification(false);
  }

  //when bank detail verified and clicked on save
  const handleCheckBankVerification = async () => {
    try {
      if (foodMaker.isBankVerified === true) {
        const obj = {
          isVerified: true
        }
        const activeStatus = doc(db, 'bankDetail', foodMaker.bankId)
        await setDoc(activeStatus, obj, { merge: true });
        let data = JSON.parse(JSON.stringify(detail));
        let ind = data.findIndex((c: any) => c.bankId === foodMaker.bankId);
        if (ind > -1) {
          data[ind].bankDetail.isVerified = !data[ind].bankDetail.isVerified;
        }
        setDetail(data);
        detail = data
        sendFCMTokenBankVerification();
        const notificationRef = collection(db, 'notifications')
        await addDoc(notificationRef, {
          receiverId: foodMaker.makerId,
          senderId: senderId[0]?.id,
          title: "Verified",
          subTitle: "Your Bank Account is Verified",
          notificationNumber: 16,
          createdDate: serverTimestamp(),
          modifiedDate: serverTimestamp()
        });
      } else if (foodMaker.isBankVerified === false) {
      }
    } catch (error) {
      console.log(error)
    }
    setIsBankVerification(false);
    setFoodMaker(initialState);
  };

  //call initially
  const getFoodMaker = async () => {
    if (searchInput) {
      setIsLoading(false);
      setFlag(true);
    } else {
      setIsLoading(true);
    }
    const items: any[] = [];
    try {
      const usersCollectionRef = collection(db, 'users');
      let qLenn = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0));
      if (foodMaker.status === 'Pending') {
        qLenn = query(qLenn, where('isVerified', '==', false))
      }
      if (foodMaker.status === 'Approved') {
        qLenn = query(qLenn, where('isVerified', '==', true))
      }
      if (foodMaker.firstName) {
        qLenn = query(qLenn, where('firstName', '>=', (foodMaker.firstName.charAt(0).toUpperCase() + foodMaker.firstName.substr(1).toLowerCase())),
          where('firstName', '<', (foodMaker.firstName.charAt(0).toUpperCase() + foodMaker.firstName.substr(1).toLowerCase()) + 'z'))
      }
      if (foodMaker.location) {
        qLenn = query(qLenn, where('city', '==', foodMaker.location))
      }
      if (foodMaker.gender) {
        qLenn = query(qLenn, where('gender', '==', foodMaker.gender))
      }
      if (foodMaker.contactNo) {
        qLenn = query(qLenn, where('contactNo', '==', foodMaker.contactNo))
      }
      const querySnapshotLen = await getCountFromServer(qLenn);
      if (querySnapshotLen.data().count > 0) {
        setChecked(true)
        setRow(querySnapshotLen.data().count);
        setFlag(true);
        let q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), orderBy(orderField, orderByField), limit(limits));
        if (foodMaker.status === 'Pending') {
          q = query(q, where('isVerified', '==', false))
        }
        if (foodMaker.status === 'Approved') {
          q = query(q, where('isVerified', '==', true))
        }
        if (foodMaker.location) {
          q = query(q, where('city', '==', foodMaker.location))
        }
        if (foodMaker.gender) {
          q = query(q, where('gender', '==', foodMaker.gender))
        }
        if (foodMaker.contactNo) {
          q = query(q, where('contactNo', '==', foodMaker.contactNo))
        }
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setFirstVisible(querySnapshot.docs[0]);
        let i = 1;
        items.forEach(async (obj: any) => {
          if (obj.uid) {
            obj.bankDetail = null;
            const bankCollectionRef = collection(db, 'bankDetail');
            const q1 = query(bankCollectionRef, where('makerId', '==', obj.uid))
            const _querySnapshot = await getDocs(q1);
            _querySnapshot.forEach((_doc) => {
              obj.bankDetail = _doc.data()
              obj.bankId = _doc.data().bankId;
            })
            if (i === items.length) {
              setFlag(true);
              setDetail(items);
              detail = items;
            } else {
              i++;
            }
          }
        })
      } else {
        setChecked(false)
        setFlag(false);
        setDetail(items);
        detail = items;
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
    localStorage.removeItem('status');
    setIsLoading(false);
  }

  //call initially
  const getDropdownData = async () => {
    const items: any[] = [];
    setIsLoading(true);
    try {
      const usersCollectionRef = collection(db, 'users');
      const q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),orderBy(orderField, orderByField));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      const temp = items.reduce((acc: any, item: any) => {
        if (acc[item.city]) {
          const idx = acc[item.city].findIndex((data: any) => data.city === item.city);
          if (idx === -1) {
            acc[item.city].push(item);
          }
        } else {
          acc[item.city] = [item];
        }
        return acc;
      }, {});
      let res: any[] = []; Object.values(temp).forEach(item => res = res.concat(item));
      setLocation(res);
    } catch (error) {
      console.log(error)
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  //send notification while adding commission
  const sendFCMTokenVerified = async (fcmToken: any) => {
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      body: JSON.stringify({
        "registration_ids": [fcmToken],
        "notification": {
          "title": "Commission Added",
          "body": "You are Successfully Verified and Commission is set",
          "sound": "default",
          "color": "#ff3296fa",
          "vibrate": "300",
          "priority": "high",
        },
      }),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'key=AAAAvT050EY:APA91bHOLzULyLPZo2AVnwLcgzMr5oah-gf6NH1eQLupY3RGR6nAkBjBdMX81LaRYeF3Dub26OCA-TCDN7-NMZktmcRt8DtcThJB9EjjEGbLIuHTwMmcbiGWyEa2fsOVRVkuVp6wQypZ'
      }
    }).then((response) => {
      return response.json
    }).catch((error) => {
      console.log(error)
    })
  }

  //send notification while switch active
  const sendFCMTokenActive = async () => {
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      body: JSON.stringify({
        "registration_ids": [foodMaker.fcmToken],
        "notification": {
          "title": "Active",
          "body": "Your account is Activated",
          "sound": "default",
          "color": "#ff3296fa",
          "vibrate": "300",
          "priority": "high",
        },
      }),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'key=AAAAvT050EY:APA91bHOLzULyLPZo2AVnwLcgzMr5oah-gf6NH1eQLupY3RGR6nAkBjBdMX81LaRYeF3Dub26OCA-TCDN7-NMZktmcRt8DtcThJB9EjjEGbLIuHTwMmcbiGWyEa2fsOVRVkuVp6wQypZ'
      }
    }).then((response) => {
      return response.json
    }).catch((error) => {
      console.log(error)
    })
  }

  //send notification while switch inactive
  const sendFCMTokenInActive = async () => {
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      body: JSON.stringify({
        "registration_ids": [foodMaker.fcmToken],
        "notification": {
          "title": "InActive",
          "body": "Your account is Deactivated",
          "sound": "default",
          "color": "#ff3296fa",
          "vibrate": "300",
          "priority": "high",
        },
      }),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'key=AAAAvT050EY:APA91bHOLzULyLPZo2AVnwLcgzMr5oah-gf6NH1eQLupY3RGR6nAkBjBdMX81LaRYeF3Dub26OCA-TCDN7-NMZktmcRt8DtcThJB9EjjEGbLIuHTwMmcbiGWyEa2fsOVRVkuVp6wQypZ'
      }
    }).then((response) => {
      return response.json
    }).catch((error) => {
      console.log(error)
    })
  }

  //send notification while bank account verified
  const sendFCMTokenBankVerification = async () => {
    fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      body: JSON.stringify({
        "registration_ids": [foodMaker.fcmToken],
        "notification": {
          "title": "Verified",
          "body": "Your Bank Account is Verified",
          "sound": "default",
          "color": "#ff3296fa",
          "vibrate": "300",
          "priority": "high",
        },
      }),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': 'key=AAAAvT050EY:APA91bHOLzULyLPZo2AVnwLcgzMr5oah-gf6NH1eQLupY3RGR6nAkBjBdMX81LaRYeF3Dub26OCA-TCDN7-NMZktmcRt8DtcThJB9EjjEGbLIuHTwMmcbiGWyEa2fsOVRVkuVp6wQypZ'
      }
    }).then((response) => {
      return response.json
    }).catch((error) => {
      console.log(error)
    })
  }

  //call when clicked on save in commission dialog
  const handleVerifiedSave = async (e: any) => {
    setIsLoading(true)
    let flag = validateForm(e);
    if (flag) {
      try {
        if (foodMaker.uid) { //edit
          const obj = {
            commission: {
              commission: foodMaker.commission
            }
          }
          const dataRef = doc(db, 'users', foodMaker.uid)
          await setDoc(dataRef, obj, { merge: true });
          let data = JSON.parse(JSON.stringify(detail));
          let ind = data.findIndex((c: any) => c.id === foodMaker.uid);
          if (ind > -1) {
            data[ind].commission = {
              commission: foodMaker.commission
            }
          }
          setDetail(data);
        } else { //add
          if (foodMaker.status === 'Pending') {
            for (let i = 0; i < detail.length; i++) {
              const obj = {
                commission: {
                  commission: foodMaker.commission,
                },
                isVerified: true,
              };
              const datasRef = doc(db, 'users', detail[i]?.uid);
              await setDoc(datasRef, obj, { merge: true });
              let data = JSON.parse(JSON.stringify(detail));
              let ind = data.findIndex((c: any) => c.id === detail[i].uid);
              if (ind > -1) {
                data[ind].commission = {
                  commission: foodMaker.commission
                }
                data[ind].isVerified = !data[ind].isVerified
              }
              setDetail(data);
              sendFCMTokenVerified(detail[i]?.fcmToken); // send notification
              const items: any[] = [];
              const systemFlagsCollectionRef = collection(db, 'systemFlags');
              const q = query(systemFlagsCollectionRef, where('flagGroupName', '==', 'Email Credentials'), orderBy('displayOrder', 'asc'))
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach(async (doc) => {
                items.push(doc.data());
              })
              const items1: any[] = [];
              const systemFlagsCollectionRef1 = collection(db, 'systemFlags');
              const q1 = query(systemFlagsCollectionRef1, where('flagGroupName', '==', 'Welcome Mail'), orderBy('displayOrder', 'asc'))
              const querySnapshot1 = await getDocs(q1);
              querySnapshot1.forEach(async (doc1) => {
                items1.push(doc1.data());
              })
              let _ind = items1.findIndex(c => c.name === "noReplyMakerWelcomeMail");
              let _html = "";
              if (_ind >= 0) {
                _html = items1[_ind].value.replace("[currentDate]", format(new Date(), 'dd MMM yyyy')).replace("[userName]", detail[i]?.displayName).replace("[currentYear]", new Date().getFullYear());
              }
              let host_Name = items[0].value;
              let sender_Name = items[1].value;
              let sender_Email = items[2].value;
              let sender_Password = items[3].value;
              let enable_ssl = items[4].value;
              let port = items[5].value;
              const request = {
                hostName: host_Name,
                senderEmail: sender_Email,
                senderPassword: sender_Password,
                enableSSL: enable_ssl === "1" ? true : false,
                port: port,
                to: detail[i]?.email,
                subject: 'Email Subject',
                html: _html
              };
              const result = await searchEmail('https://us-central1-vyanjan-b3af8.cloudfunctions.net/sendEmailForMaker', request); // email sent
              const notificationRef = collection(db, 'notifications')
              await addDoc(notificationRef, { //add data in firestore collection
                receiverId: detail[i]?.id,
                senderId: senderId[0]?.id,
                title: "Commission Added",
                subTitle: "You are Successfully Verified and Commission is set",
                notificationNumber: 15,
                createdDate: serverTimestamp(),
                modifiedDate: serverTimestamp()
              });
            }
            getFoodMaker();
          } else {
            const newdata = {
              commission: {
                commission: foodMaker.commission,
              },
              isVerified: true,
            };
            const datasRef = doc(db, 'users', foodMaker.id);
            await setDoc(datasRef, newdata, { merge: true });
            let data = JSON.parse(JSON.stringify(detail));
            let ind = data.findIndex((c: any) => c.id === foodMaker.id);
            if (ind > -1) {
              data[ind].commission = {
                commission: foodMaker.commission
              }
              data[ind].isVerified = !data[ind].isVerified
            }
            setDetail(data);
            sendFCMTokenVerified(foodMaker.fcmToken); //send notification
            const items: any[] = [];
            const systemFlagsCollectionRef = collection(db, 'systemFlags');
            const q = query(systemFlagsCollectionRef, where('flagGroupName', '==', 'Email Credentials'), orderBy('displayOrder', 'asc'))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
              items.push(doc.data());
            })
            const items1: any[] = [];
            const systemFlagsCollectionRef1 = collection(db, 'systemFlags');
            const q1 = query(systemFlagsCollectionRef1, where('flagGroupName', '==', 'Welcome Mail'), orderBy('displayOrder', 'asc'))
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach(async (doc1) => {
              items1.push(doc1.data());
            })
            let _ind1 = items1.findIndex(c => c.name === "noReplyMakerWelcomeMail");
            let _html1 = "";
            if (_ind1 >= 0) {
              _html1 = items1[_ind1].value.replace("[currentDate]", format(new Date(), 'dd MMM yyyy')).replace("[userName]", foodMaker.displayName).replace("[currentYear]", new Date().getFullYear());
            }
            let host_Name = items[0].value;
            let sender_Name = items[1].value;
            let sender_Email = items[2].value;
            let sender_Password = items[3].value;
            let enable_ssl = items[4].value;
            let port = items[5].value;
            const request = {
              hostName: host_Name,
              senderEmail: sender_Email,
              senderPassword: sender_Password,
              enableSSL: enable_ssl === "1" ? true : false,
              port: port,
              to: foodMaker.email,
              subject: 'Email Subject',
              html: _html1
            };
            const result = await searchEmail('https://us-central1-vyanjan-b3af8.cloudfunctions.net/sendEmailForMaker', request); //email sent
            const notificationRef = collection(db, 'notifications')
            await addDoc(notificationRef, { //add data in firestore collection
              receiverId: foodMaker.id,
              senderId: senderId[0]?.id,
              title: "Commission Added",
              subTitle: "You are Successfully Verified and Commission is set",
              notificationNumber: 15,
              createdDate: serverTimestamp(),
              modifiedDate: serverTimestamp()
            });
          }
        }
        setIsOpen(false);
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
      setIsLoading(false)
    }
  }

  //call when commission dialog value change
  const addCommissionDialog = (arr: any) => {
    const { name, value } = arr.target;
    setFoodMaker({ ...foodMaker, [name]: value });
    setIsOpen(true)
  }

  //call when click on search while filtering
  const handleSearch = async () => {
    if (validateFlag === false) {
      setFlag(false);
      setPage(0);
      if (foodMaker.contactNo && foodMaker.location && foodMaker.gender) {
        setChipContactNo(true);
        setChipLocation(true);
        setChipGender(true);
      }
      else if (foodMaker.contactNo && foodMaker.location) {
        setChipContactNo(true);
        setChipLocation(true);
      }
      else if (foodMaker.contactNo && foodMaker.gender) {
        setChipContactNo(true);
        setChipGender(true);
      }
      else if (foodMaker.location && foodMaker.gender) {
        setChipLocation(true);
        setChipGender(true);
      }
      else if (foodMaker.contactNo) {
        setChipContactNo(true);
      }
      else if (foodMaker.location) {
        setChipLocation(true);
      }
      else if (foodMaker.gender) {
        setChipGender(true);
      }
      if (searchInput) {
        searchItems(searchInput, (page * limits), limits);
      } else {
        getFoodMaker();
      }
      setOpen(false)
    }
  }

  //call when click on clear button while filtering
  const handleClear = (e: any) => {
    setFoodMaker(initialState);
    foodMaker.firstName = "";
    foodMaker.contactNo = "";
    foodMaker.location = "";
    foodMaker.gender = "";
    setChipContactNo(false);
    setChipLocation(false);
    setChipGender(false);
    setSelected("");
    setSelected1("");
    getFoodMaker();
    setOpen(false)
  }

  //call when we clear any chip
  const handleDelete = (e: any) => {
    if (e === foodMaker.firstName) {
      foodMaker.firstName = "";
    }
    else if (e === foodMaker.contactNo) {
      foodMaker.contactNo = "";
      setChipContactNo(false);
      setSelected1("");
    }
    else if (e === foodMaker.location) {
      setChipLocation(false);
      foodMaker.location = "";
      setSelected("");
    }
    else if (e === foodMaker.gender) {
      setChipGender(false);
      foodMaker.gender = "";
    }
    handleSearch();
  }

  // when we want to do sorting in descending order
  const sortDecending = async () => {
    setPage(0);
    setOrderField('firstName');
    setOrderByField('desc');
    orderByField = 'desc'
    setTimeout(async () => {
      await getFoodMaker();
      setSort(true)
    }, 1000);
  }

  // when we want to do sorting in ascending order
  const sortAscending = async () => {
    setPage(0);
    setOrderField('firstName');
    setOrderByField('asc');
    orderByField = 'asc'
    setTimeout(async () => {
      await getFoodMaker();
      setSort(false)
    }, 1000);
  }

  // when cloud function is called
  async function searchData(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData; // return the response data
    } catch (error) {
      console.error(error);
    }
  }

  // invoked everytime when their is searchvalue in searchBox
  const searchItems = async (searchValue: any, startIndex: number, fetchRecord: number) => {
    try {
      setSearchInput(searchValue);
      searchInput = searchValue;
      if (searchValue) {
        const items: any[] = [];
        const requestBody = {
          query: searchValue,
          role: "foodMaker",
          size: fetchRecord,
          from: startIndex
        };
        const result = await searchData(process.env.REACT_APP_USER_SEARCH, requestBody);
        for (let i = 0; i < result.data.length; i++) {
          let element = result.data[i]._source;
          setFlag(true);
          const usersCollectionRef = collection(db, 'users');
          setChecked(true)
          let q = query(usersCollectionRef, where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), orderBy(orderField, orderByField), limit(limits));
          if (foodMaker.status === 'Pending') {
            q = query(q, where('isVerified', '==', false))
          }
          if (foodMaker.status === 'Approved') {
            q = query(q, where('isVerified', '==', true))
          }
          if (foodMaker.location) {
            q = query(q, where('city', '==', foodMaker.location))
          }
          if (foodMaker.gender) {
            q = query(q, where('gender', '==', foodMaker.gender))
          }
          if (foodMaker.contactNo) {
            q = query(q, where('contactNo', '==', foodMaker.contactNo))
          }
          q = query(q, where('displayName', '==', element.displayName))
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc: any) => {
            items.push({ id: doc.id, ...doc.data() });
          });
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setFirstVisible(querySnapshot.docs[0]);
          let j = 1;
          items.forEach(async (obj: any) => {
            if (obj.uid) {
              obj.bankDetail = null;
              const bankCollectionRef = collection(db, 'bankDetail');
              const q1 = query(bankCollectionRef, where('makerId', '==', obj.uid))
              const _querySnapshot = await getDocs(q1);
              _querySnapshot.forEach((_doc) => {
                obj.bankDetail = _doc.data()
                obj.bankId = _doc.data().bankId;
              })
              if (j === items.length) {
                setDetail(items);
                setRow(result.totalRecords)
                detail = items;
                if (detail.length > 0) {
                  setFlag(true)
                } else {
                  setFlag(false)
                }
              } else {
                j++;
              }
            }
          })
        }
        if (result.data.length) {
          setDetail(items);
          setRow(result.totalRecords)
          detail = items;
          if (detail.length > 0) {
            setFlag(true)
          } else {
            setFlag(false)
          }
        } else {
          setFlag(false)
        }
      } else {
        searchInput = ""
        setSearchInput("")
        await getFoodMaker();
      }
    }
    catch (error) {
      console.log(error)
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  // when next(>) button in pagination change which is on bottom right of the page
  const nextData = async () => {
    let next = query(collection(db, "users"),
      where('role.roleName', '==', 'foodMaker'),
      where('isDelete', '==', 0),
      orderBy(orderField, orderByField),
      startAfter(lastVisible),
      limit(limits));
    if (searchInput) {
      next = query(next, where('firstName', '>=', searchInput)
        , where('firstName', '<', searchInput + 'z'))
    }
    if (foodMaker.status === 'Pending') {
      next = query(next, where('isVerified', '==', false))
    }
    if (foodMaker.status === 'Approved') {
      next = query(next, where('isVerified', '==', true))
    }
    if (foodMaker.firstName) {
      next = query(next, where('firstName', '>=', foodMaker.firstName),
        where('firstName', '<', foodMaker.firstName + 'z'))
    }
    if (foodMaker.location) {
      next = query(next, where('city', '==', foodMaker.location))
    }
    if (foodMaker.gender) {
      next = query(next, where('gender', '==', foodMaker.gender))
    }
    if (foodMaker.contactNo) {
      next = query(next, where('contactNo', '==', foodMaker.contactNo))
    }
    const items: any[] = [];
    const querySnapshot = await getDocs(next);
    querySnapshot.forEach((doc: any) => {
      items.push({ id: doc.id, ...doc.data() });
    });
    let i = 1;
    items.forEach(async (obj: any) => {
      if (obj.uid) {
        obj.bankDetail = null;
        const bankCollectionRef = collection(db, 'bankDetail');
        const q1 = query(bankCollectionRef, where('makerId', '==', obj.uid))
        const _querySnapshot = await getDocs(q1);
        _querySnapshot.forEach((_doc) => {
          obj.bankDetail = _doc.data()
          obj.bankId = _doc.data().bankId;
        })
        if (i === items.length) {
          setDetail(items);
        } else {
          i++;
        }
      }
    })
    updateState(querySnapshot)
  }

  // when previous(<) button in pagination change which is on bottom right of the page
  const previousData = async () => {
    let next = query(collection(db, "users"),
      where('role.roleName', '==', 'foodMaker'),
      where('isDelete', '==', 0),
      orderBy(orderField, orderByField),
      endBefore(firstVisible),
      limitToLast(limits));
    if (searchInput) {
      next = query(next, where('firstName', '>=', searchInput)
        , where('firstName', '<', searchInput + 'z'))
    }
    if (foodMaker.status === 'Pending') {
      next = query(next, where('isVerified', '==', false))
    }
    if (foodMaker.status === 'Approved') {
      next = query(next, where('isVerified', '==', true))
    }
    if (foodMaker.firstName) {
      next = query(next, where('firstName', '>=', foodMaker.firstName),
        where('firstName', '<', foodMaker.firstName + 'z'))
    }
    if (foodMaker.location) {
      next = query(next, where('city', '==', foodMaker.location))
    }
    if (foodMaker.gender) {
      next = query(next, where('gender', '==', foodMaker.gender))
    }
    if (foodMaker.contactNo) {
      next = query(next, where('contactNo', '==', foodMaker.contactNo))
    }
    const items: any[] = [];
    const querySnapshot = await getDocs(next);
    querySnapshot.forEach((doc) => {
      items.push({ id: doc.id, ...doc.data() });
    });
    let i = 1;
    items.forEach(async (obj: any) => {
      if (obj.uid) {
        obj.bankDetail = null;
        const bankCollectionRef = collection(db, 'bankDetail');
        const q1 = query(bankCollectionRef, where('makerId', '==', obj.uid))
        const _querySnapshot = await getDocs(q1);
        _querySnapshot.forEach((_doc) => {
          obj.bankDetail = _doc.data()
          obj.bankId = _doc.data().bankId;
        })
        if (i === items.length) {
          setDetail(items);
        } else {
          i++;
        }
      }
    })
    updateState(querySnapshot);
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handlePageChange = async (event: any, newPage: number) => {
    if (searchInput) {
      setPage(newPage);
      searchItems(searchInput, (newPage * limits), limits)
    } else {
      setPage(newPage);
      if (page < newPage) {
        nextData();
      } else {
        previousData();
      }
    }
  };

  // update first visible and last visible value according to pagination (next and previous button)
  const updateState = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimits(parseInt(event.target.value));
    limits = parseInt(event.target.value);
    setPage(0);
    getFoodMaker();
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <Helmet>
        <title> Food Makers | Vyanjan-Pursuit for Hunger!</title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack alignItems="left" justifyContent="space-between" mb={5} >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit" >Food Makers</Typography>
              </Breadcrumbs>
            </Stack>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
              <Drawer
                anchor="right"
                open={open}
                onClose={onOpenFilter}
                PaperProps={{
                  sx: { width: 280, border: 'none', overflow: 'hidden' },
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="left" sx={{ px: 1, py: 2 }}>
                  <IconButton onClick={onOpenFilter} >
                    <Iconify icon="eva:close-fill" sx={{ color: "red" }} />
                  </IconButton>
                  <Typography variant="subtitle1" sx={{ marginRight: "67%", color: "black" }}>
                    Filters</Typography>
                </Stack>
                <Divider />
                <Scrollbar>
                  <Stack spacing={3} sx={{ p: 3, backgroundColor: 'white', height: 'calc(100vh - 100px)' }}>
                    <div>
                      <FormGroup>
                        <FormControl >
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={location.map((e: any) => e.city)}
                            sx={{ width: 230 }}
                            value={selected}
                            onChange={(e, v) => { handleChange1(e, v) }}
                            renderInput={(params) => <TextField {...params} label="Location" name="location" />}
                          />
                        </FormControl>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <FormControl  >
                          <FormLabel id="demo-controlled-radio-buttons-group" >Gender</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="gender"
                            value={foodMaker.gender}
                            onChange={handleChange}
                          >
                            <FormControlLabel value="Female" control={<Radio />} label="Female" />
                            <FormControlLabel value="Male" control={<Radio />} label="Male" />
                            <FormControlLabel value="Others" control={<Radio />} label="Others" />
                          </RadioGroup>
                        </FormControl>
                      </FormGroup>
                    </div>
                    <div>
                      <FormGroup>
                        <FormControl>
                          <TextField id="filter"
                            label="Contact Number"
                            type="text"
                            name="contactNo"
                            value={foodMaker.contactNo}
                            onChange={(e: any) => { handleChange2(e); validateNumber(e); }}
                          />
                          <FormHelperText style={{ color: 'red', height: '5px' }}>{isNumberValidateError && numberValidateErrorMsg}</FormHelperText>
                        </FormControl>
                      </FormGroup>
                    </div>
                  </Stack>
                </Scrollbar>
                <Box sx={{ pl: '16px', pr: '16px', pt: '8px', pb: '8px', bottom: "10px", width: '100%' }}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="mb-3"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </Box>
                <Box sx={{ pl: '16px', pr: '16px', pt: '8px', pb: '16px', bottom: "0px", width: '100%' }}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                </Box>
              </Drawer>
              <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                <div>
                  {isLoading ? <Loader title="Loading..." /> :
                    <>
                      <div>
                        <StyledRoot
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'white',

                          }}
                        >
                          <StyledSearch
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value, page * limits, limits)}
                            placeholder="Search by Name..."
                            startAdornment={
                              <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                              </InputAdornment>
                            }
                          />
                          <div className="largeScreenContent" style={{ width: '100%' }}>
                            {chipGender === true ?
                              <>
                                {
                                  foodMaker.gender && foodMaker.gender.length > 0 ?
                                    <Chip color="primary" size="small" label={foodMaker.gender} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDelete(foodMaker.gender)} /> : ""
                                }
                              </>
                              :
                              ''
                            }
                            {chipContactNo === true ?
                              <>
                                {
                                  foodMaker.contactNo && foodMaker.contactNo.length > 0 ?
                                    <Chip color="primary" size="small" label={foodMaker.contactNo} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDelete(foodMaker.contactNo)} /> : ""
                                }
                              </>
                              :
                              ''
                            }
                            {chipLocation === true ?
                              <>
                                {
                                  foodMaker.location && foodMaker.location.length > 0 ?
                                    <Chip color="primary" size="small" label={foodMaker.location} sx={{ mx: 1 }}
                                      onDelete={(e) => handleDelete(foodMaker.location)} /> : ""
                                }
                              </>
                              :
                              ''
                            }
                          </div>
                          <div className="dataShowLarge">
                            <FormControl sx={{ width: "134px", ml: 0.5, mb: 1, mr: '10px', mt: '10px' }} size="small">
                              <InputLabel id="demo-simple-select-label">Status</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="status"
                                value={foodMaker.status || 'All'}
                                label="Status"
                                onChange={(e) => { handleChangeStatus(e) }}
                              >
                                <MenuItem value='Pending'>Pending</MenuItem>
                                <MenuItem value='Approved'>Approved</MenuItem>
                                <MenuItem value='All'>All</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className='dataShowLarge'>
                            {((foodMaker.status === "Pending") && detail.length > 0) &&
                              <FormControl sx={{ width: "134px", mb: 1, mt: '10px' }}>
                                <Button
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  onClick={handleChangeCheckedAll}>
                                  Verify All
                                </Button>
                              </FormControl>
                            }
                          </div>
                          <Tooltip title="Filter list">
                            <IconButton
                              color="inherit"
                              size="small"
                              onClick={onOpenFilter}
                            >
                              <FilterListIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledRoot>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: "0px 13px 16px 25px" }}>
                          <div className="dataShowSmall">
                            <FormControl sx={{ width: "134px" }} size="small">
                              <InputLabel id="demo-simple-select-label">Status</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="status"
                                value={foodMaker.status || 'All'}
                                label="Status"
                                onChange={(e) => { handleChangeStatus(e) }}
                              >
                                <MenuItem value='Pending'>Pending</MenuItem>
                                <MenuItem value='Approved'>Approved</MenuItem>
                                <MenuItem value='All'>All</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className='dataShowSmall'>
                            {!checked &&
                              <FormControl sx={{ width: "134px", mb: 1, mt: '10px' }}>
                                <Button
                                  type="submit"
                                  color="primary"
                                  variant="contained"
                                  onClick={handleChangeCheckedAll}>
                                  Verify All
                                </Button>
                              </FormControl>
                            }
                          </div>
                        </div>
                        <div className="smallScreenContent" style={{ margin: 5 }}>
                          {chipGender === true ?
                            <>
                              {
                                foodMaker.gender && foodMaker.gender.length > 0 ?
                                  <Chip color="primary" size="small" label={foodMaker.gender} sx={{ mx: 1 }}
                                    onDelete={(e) => handleDelete(foodMaker.gender)} /> : ""
                              }
                            </>
                            :
                            ''
                          }
                          {chipContactNo === true ?
                            <>
                              {
                                foodMaker.contactNo && foodMaker.contactNo.length > 0 ?
                                  <Chip color="primary" size="small" label={foodMaker.contactNo} sx={{ mx: 1 }}
                                    onDelete={(e) => handleDelete(foodMaker.contactNo)} /> : ""
                              }
                            </>
                            :
                            ''
                          }
                          {chipLocation === true ?
                            <>
                              {
                                foodMaker.location && foodMaker.location.length > 0 ?
                                  <Chip color="primary" size="small" label={foodMaker.location} sx={{ mx: 1 }}
                                    onDelete={(e) => handleDelete(foodMaker.location)} /> : ""
                              }
                            </>
                            :
                            ''
                          }
                        </div>
                      </div>
                      {
                        ((flag === true)) ?
                          <>
                            <Scrollbar>
                              <TableContainer className="foodmaker-list" sx={{ backgroundColor: "white" }}>
                                <Table stickyHeader>
                                  <TableHead >
                                    <TableRow >
                                      <TableCell align="left" >#</TableCell>
                                      <TableCell align="left" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Name{
                                        (sort === false) ?
                                          <IconButton
                                            sx={{ marginLeft: "2px", color: "black" }}
                                            color="inherit"
                                            size="small"
                                            onClick={sortDecending}
                                          >
                                            <ArrowDropDownIcon />
                                          </IconButton>
                                          :
                                          <IconButton
                                            sx={{ marginLeft: "2px", color: "black" }}
                                            color="inherit"
                                            size="small"
                                            onClick={sortAscending}
                                          >
                                            <ArrowDropUpIcon />
                                          </IconButton>
                                      }
                                      </Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Location</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Gender</Typography></TableCell>
                                      <TableCell align="left"><Typography noWrap fontWeight="600" fontSize="0.875rem">Contact No</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Commission</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Rating</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Bank Verification Status</Typography></TableCell>
                                      <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody >
                                    {
                                      detail.map((arr: any, index: any) => (
                                        <>
                                          <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                            <TableCell align="left" > {(page * limits) + index + 1}</TableCell>
                                            <TableCell component="th" scope="row" padding="none" sx={{ color: '#212B36' }}>
                                              <Stack direction="row" alignItems="center" spacing={2}>
                                                {arr.profilePicUrl ? <Avatar src={arr.profilePicUrl}></Avatar> : <Avatar>{arr.firstName ? arr.firstName[0] : null}</Avatar>}
                                                <Typography variant="subtitle2" noWrap>
                                                  {arr.firstName} {arr.lastName}
                                                </Typography>
                                              </Stack>
                                            </TableCell>
                                            <TableCell align="left" ><Typography noWrap fontSize="0.875rem">{arr.city}</Typography></TableCell>
                                            <TableCell align="left" ><Typography noWrap fontSize="0.875rem">{arr.gender}</Typography></TableCell>
                                            <TableCell align="left" ><Typography noWrap fontSize="0.875rem">{arr.contactNo}</Typography></TableCell>
                                            <TableCell align="left" ><Typography noWrap fontSize="0.875rem">{(arr.isVerified === true) ? arr.commission.commission : "0"}%</Typography></TableCell>
                                            <TableCell align="left" >
                                              <Rating name="rating" value={arr.averageReviewCount} defaultValue={0} readOnly />
                                            </TableCell>
                                            <TableCell align="left" >
                                              {arr.bankDetail === null ?
                                                <Label sx={{ color: "white", backgroundColor: "#e20700", width: '85px' }}>Not Available</Label> :
                                                arr.bankDetail.isVerified === true ?
                                                  <Label sx={{ color: "white", backgroundColor: "#53bc5b", width: '85px' }}>Verified</Label>
                                                  :
                                                  <Tooltip title="Bank Account Activation" arrow>
                                                    <IconButton
                                                      sx={{ color: '#1565c0' }}
                                                      color="inherit"
                                                      size="small"
                                                      onClick={(e) => { handleClickBankVerification(arr) }}
                                                    >
                                                      <AccountBalanceIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                              }
                                            </TableCell>
                                            <TableCell align="right" style={{ display: "flex" }} >
                                              <Tooltip title="View" arrow>
                                                <IconButton
                                                  sx={{ color: '#1565c0' }}
                                                  color="inherit"
                                                  size="small"
                                                  onClick={(e) => { handleClickDetails(arr) }}
                                                >
                                                  <VisibilityIcon />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title={(arr.isVerified === true) ? 'Verified' : 'Not Verified'} arrow>
                                                {(arr.isVerified === true) ?
                                                  <IconButton
                                                    sx={{ color: 'green' }}
                                                    color="inherit"
                                                    size="small"
                                                  >
                                                    <VerifiedUserIcon sx={{ fontSize: "23px" }} />
                                                  </IconButton>
                                                  :
                                                  <IconButton
                                                    sx={{ color: 'red' }}
                                                    color="inherit"
                                                    size="small"
                                                    onClick={(e) => handleClickisAdd(arr)}
                                                  >
                                                    <GppBadIcon sx={{ fontSize: "25px" }} />
                                                  </IconButton>}
                                              </Tooltip>
                                              <Tooltip title={(arr.isActive === 1) ? "Active" : "Inactive"} arrow>
                                                <Switch
                                                  checked={arr.isActive}
                                                  onClick={(e) => { handleSwitch(arr.id, arr.isActive, arr.fcmToken) }}
                                                  inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                              </Tooltip>
                                              <Tooltip title="Edit" arrow>
                                                <IconButton
                                                  sx={{ color: '#1565c0' }}
                                                  color="inherit"
                                                  size="small"
                                                  onClick={(e) => handleClickisEdit(arr.uid, arr.commission.commission, arr.isVerified)}
                                                >
                                                  <EditTwoToneIcon fontSize="small" />
                                                </IconButton>
                                              </Tooltip>

                                            </TableCell>
                                          </TableRow>
                                        </>
                                      )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Scrollbar>
                            <TablePagination
                              component="div"
                              count={row}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleLimitChange}
                              page={page}
                              rowsPerPage={limits}
                              rowsPerPageOptions={[10, 20, 30, 40]}
                              sx={{ pt: 1, pb: 1 }}
                            />
                          </>
                          :
                          <TableContainer className="foodmaker-list-notFound" sx={{ backgroundColor: "white" }}>
                            <Table>
                              <Divider />
                              <TableBody>
                                <TableRow>
                                  <Paper
                                    sx={{
                                      width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                    }}
                                  >
                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                    <Typography variant="h6" paragraph>
                                      Data not Available
                                    </Typography>
                                  </Paper>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                      }
                    </>
                  }
                </div>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Container >

      <>
        <Dialog open={isOpen} onClose={handleCloseSwitch}>
          <DialogTitle>
            {foodMaker.uid ? "Edit commission" : checked === true ? "Verify and All" : "Verify and Approved"}
            <IconButton
              sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 8, top: 8 }}
              onClick={handleCloseSwitch}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Add Commission"
              type="text"
              fullWidth
              variant="outlined"
              name="commission"
              value={foodMaker.commission}
              onChange={(arr) => { addCommissionDialog(arr); validateCommision(arr) }}
              required={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon />
                  </InputAdornment>
                ),
              }}
            />
            <DialogContentText style={{ color: 'red', height: '22px' }}>{isCommissionError && commissionErrorMsg}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleVerifiedSave}>Verify & Save</Button>
          </DialogActions>
        </Dialog>
      </>

      <div>
        <Dialog open={isCheck} onClose={handleCloseSwitch}>
          <DialogTitle>{(foodMaker.isActive === 1) ? 'Active' : 'InActive'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {(foodMaker.isActive === 0) ? 'Are you sure you want to Active?' : 'Are you sure you want to InActive?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSwitch}>Cancel</Button>
            <Button onClick={handleCheckSwitch}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog open={isBankVerification} onClose={handleCloseBankVerification}>
          <DialogTitle>Bank Account Verification</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {bankDetail[0]?.paymentMethod === "UPI" ?
                <>
                  <Box display="flex" alignItems="flex-start">Payment Method<Box sx={{ pl: { lg: 6, md: 6, sm: 3, xs: 3 }, pr: 1, color: '#212B36' }}>
                    {bankDetail[0]?.paymentMethod ? bankDetail[0]?.paymentMethod : "--"}
                  </Box></Box>
                  <Box display="flex" alignItems="flex-start">UPI ID<Box sx={{ pl: 14.8, pr: 1, color: '#212B36' }}>
                    {bankDetail[0]?.upiId ? bankDetail[0]?.upiId : "--"}
                  </Box></Box>
                </>
                :
                <>
                  <Box display="flex" alignItems="flex-start">Payment Method<Box sx={{ pl: { lg: 10.6, md: 10.6, sm: 10.6, xs: 5.3 }, pr: 1, color: '#212B36' }}>
                    {bankDetail[0]?.paymentMethod ? bankDetail[0]?.paymentMethod : "--"}
                  </Box></Box>
                  <Box display="flex" alignItems="flex-start">Bank Name<Box sx={{ pl: { lg: 15.5, md: 15.5, sm: 15.5, xs: 8.7 }, pr: 1, color: '#212B36' }}>
                    {bankDetail[0]?.bankName ? bankDetail[0]?.bankName : "--"}
                  </Box></Box>
                  <Box display="flex" alignItems="flex-start">Branch Name<Box sx={{ pl: { lg: 13.6, md: 13.6, sm: 13.6, xs: 3 }, pr: 1, color: '#212B36' }}>
                    {bankDetail[0]?.branchName ? bankDetail[0]?.branchName : "--"}
                  </Box></Box>
                  <Box display="flex" alignItems="flex-start">IFSC Code<Box sx={{ pl: { lg: 16.6, md: 16.6, sm: 16.6, xs: 10.5 }, pr: 1, color: '#212B36' }}>
                    {bankDetail[0]?.ifscCode ? bankDetail[0]?.ifscCode : "--"}
                  </Box></Box>
                  <Box display="flex" alignItems="flex-start">Account Holder Name<Box sx={{ pl: { lg: 6, md: 6, sm: 6, xs: 3.5 }, pr: 1, color: '#212B36' }}>
                    {(bankDetail[0]?.accHolderName) ? bankDetail[0]?.accHolderName : "--"}
                  </Box></Box>
                  <Box display="flex" alignItems="flex-start" sx={{ pb: 4 }}>Account Number<Box sx={{ pl: { lg: 10.6, md: 10.6, sm: 10.6, xs: 6.9 }, pr: 1, color: '#212B36' }}>
                    {(bankDetail[0]?.isVerified === true) ? bankDetail[0]?.accNumber.replace(/.(?=.{4,}$)/g, '*') : bankDetail[0]?.accNumber}
                  </Box></Box>
                </>
              }
              Are you sure you want to Activate Bank Account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseBankVerification}>Cancel</Button>
            <Button onClick={handleCheckBankVerification}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
      </div>
    </>
  );
}