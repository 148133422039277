import { Grid, Container, Stack, Breadcrumbs, Typography, Card, Divider, Paper, Table, TableBody, TableContainer, TableRow, TableHead, TableCell, TablePagination, Avatar, Rating, styled, Toolbar, Box } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import Loader from '../spinner';
import ScrollBar from 'react-perfect-scrollbar';
import { collection, endBefore, getCountFromServer, getDocs, limit, limitToLast, orderBy, query, startAfter, where } from 'firebase/firestore';
import { db } from '../../firebase';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

export const ProductCategoryDetail = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [detail, setDetail] = useState<any>([]) // show data inside page
    let [limits, setLimits] = useState<number>(10);
    const [row, setRow] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [productName, setProductName] = useState('')
    const [lastVisible, setLastVisible] = useState<any>();
    const [firstVisible, setFirstVisible] = useState<any>();
    const [flag, setFlag] = useState<boolean>(true);

    const id = useParams();

    // it is the first method invoked when this page is open
    useEffect(() => {
        getdata();
        let ProductName = sessionStorage.getItem('productName')
        if (ProductName) {
            setProductName(ProductName)
        }
    }, [])

    //call initially
    const getdata = async () => {
        const items: any[] = [];
        setIsLoading(true);
        try {
            const makerProductsRef = collection(db, 'makerProduct');
            const qlenn = query(makerProductsRef, where('productCategoryId', '==', id.id), orderBy('createdDate', 'desc'))
            const querySnapshotLen = await getCountFromServer(qlenn);
            setRow(querySnapshotLen.data().count)
            if (querySnapshotLen.data().count > 0) {
                const q = query(makerProductsRef, where('productCategoryId', '==', id.id), orderBy('createdDate', 'desc'), limit(limits))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc: any) => {
                    items.push(doc.data());
                })
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisible(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.makerId) {
                        const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodMaker'), where('uid', '==', obj.makerId))
                        const _querySnapshot = await getDocs(q);
                        _querySnapshot.forEach((_doc) => {
                            obj.name = _doc.data().displayName;
                        })
                        if (i === items.length) {
                            setDetail(items);
                            if (items.length > 0) {
                                setFlag(true);
                            } else {
                                setFlag(false);
                            }
                        } else {
                            i++;
                        }
                    }
                })
            } else {
                setFlag(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
        setIsLoading(false)
    }

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleChangePage = async (event: any, newPage: any) => {
        const items: any[] = [];
        setPage(newPage);
        if (page < newPage) {
            const makerProductsRef = collection(db, 'makerProduct');
            const qlenn = query(makerProductsRef, where('productCategoryId', '==', id.id), orderBy('createdDate', 'desc'))
            const querySnapshotLen = await getCountFromServer(qlenn);
            setRow(querySnapshotLen.data().count)

            const q = query(makerProductsRef, where('productCategoryId', '==', id.id), orderBy('createdDate', 'desc'), startAfter(lastVisible), limit(limits))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodMaker'), where('uid', '==', obj.makerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.name = _doc.data().displayName;
                    })
                    if (i === items.length) {
                        setDetail(items);
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot)
        } else {
            const makerProductsRef = collection(db, 'makerProduct');
            const qlenn = query(makerProductsRef, where('productCategoryId', '==', id.id), orderBy('createdDate', 'desc'))
            const querySnapshotLen = await getCountFromServer(qlenn);
            setRow(querySnapshotLen.data().count)

            const q = query(makerProductsRef, where('productCategoryId', '==', id.id), orderBy('createdDate', 'desc'), endBefore(firstVisible), limitToLast(limits))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodMaker'), where('uid', '==', obj.makerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.name = _doc.data().displayName;
                    })
                    if (i === items.length) {
                        setDetail(items);
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot)
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateState = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisible(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisible(documents.docs[documents.docs.length - 1]);
        }
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setPage(0);
        setLimits(parseInt(event.target.value));
        limits = parseInt(event.target.value)
    };

    return (
        <div>
            <Helmet>
                <title> Product Category Detail | Vyanjan-Pursuit for Hunger! </title>
            </Helmet>
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} >
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link
                                    to="/admin/dashboard"
                                    style={{ display: 'flex', color: "black" }}
                                >
                                    <HomeIcon />
                                </Link>
                                <Link
                                    to="/admin/productcategory"
                                    style={{ display: 'flex', color: "black", textDecoration: "none" }}
                                >
                                    Product Category
                                </Link>
                                <Typography color="inherit"> {productName} </Typography>
                            </Breadcrumbs>
                        </Stack>
                        <Card className="card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', height: 'calc(100vh - 255px)' }}>
                            <div>
                                {isLoading ? <Loader title="Loading..." /> :
                                    <>
                                        {flag ?
                                            <>
                                                <StyledRoot
                                                    sx={{
                                                        bgcolor: 'white',
                                                    }}
                                                >
                                                    {/* <Typography sx={{ color: '#637381', display: "flex" }} ><b>Product Category</b><Box>{productName}</Box></Typography> */}
                                                    <Typography>
                                                        <Box display="flex" alignItems="center"><b>Product Category</b><Box sx={{ ml: 1, color: 'grey' }}>{productName}</Box></Box>
                                                    </Typography>
                                                    <Typography>
                                                        <Box display="flex" alignItems="center" sx={{ ml: 1 }}><b>Total Products</b><Box sx={{ ml: 1, color: 'grey' }}>{detail.length}</Box></Box>
                                                    </Typography>
                                                    {/* <Typography sx={{ ml: 3.1, mb: 2 }}>
                                                        <Box display="flex" alignItems="flex-start"><Typography sx={{ fontFamily: '"Public Sans",sans serif' }}><b>Product Category</b></Typography><Box sx={{ pl: 5, color: 'grey' }}>
                                                            {productName}
                                                        </Box></Box>
                                                    </Typography> */}
                                                    {/* <StyledSearch
                                                        value={searchInput}
                                                        onChange={(e) => searchItems(e.target.value)}
                                                        placeholder="Search by Product Category..."
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                                            </InputAdornment>
                                                        }
                                                    /> */}

                                                </StyledRoot>
                                                <ScrollBar>
                                                    <TableContainer className="productCategorydetail-list" sx={{ backgroundColor: "white", overflow: "auto" }} >
                                                        <Table stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell >#</TableCell>
                                                                    <TableCell sx={{ color: '#212B36' }}> <Typography noWrap fontWeight="600" fontSize="0.875rem">Products </Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Makers</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Amount</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Bulk Orders Status</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Max Quantity per Day</Typography></TableCell>
                                                                    <TableCell ><Typography noWrap fontWeight="600" fontSize="0.875rem">Max Quantity per User</Typography></TableCell>
                                                                    <TableCell >Ratting</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {detail
                                                                    .map((arr: any, index: any) => {
                                                                        return (
                                                                            <TableRow hover key={arr.id}>
                                                                                <TableCell> {(page * limits) + index + 1}</TableCell>
                                                                                <TableCell sx={{ color: '#212B36' }}>
                                                                                    <Stack direction="row" justifyContent="left" spacing={3}>
                                                                                        {arr.productImage ? <Avatar src={arr.productImage} variant="rounded"></Avatar> : <Avatar src="/defaultFoodLogo.png" variant="rounded"></Avatar>}
                                                                                        <Typography variant='subtitle2' noWrap sx={{ display: "flex", alignItems: "center", textTransform: "capitalize" }}>
                                                                                            {arr.displayName}
                                                                                        </Typography>
                                                                                    </Stack>
                                                                                </TableCell>
                                                                                <TableCell ><Typography noWrap fontSize="0.875rem">{arr.name}</Typography></TableCell>
                                                                                <TableCell ><Typography noWrap fontSize="0.875rem">₹ {(arr.price).toFixed(2)}</Typography></TableCell>
                                                                                <TableCell >
                                                                                    {(arr.isBulkAvailable === false) ? <Typography sx={{ color: "red" }}>Not Accepted</Typography>
                                                                                        : <Typography sx={{ color: "green" }}>Accepted</Typography>}
                                                                                </TableCell>
                                                                                <TableCell >{arr.maxQtyPerDay}</TableCell>
                                                                                <TableCell >{arr.maxQtyPerUser}</TableCell>
                                                                                <TableCell ><Rating name="rating" value={arr.averageReviewCount} defaultValue={0} readOnly /></TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </ScrollBar>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 20, 30, 40]}
                                                    component="div"
                                                    count={row}
                                                    rowsPerPage={limits}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </>
                                            :
                                            <TableContainer sx={{ height: 'calc(100vh - 350px)', backgroundColor: "white" }}>
                                                <Table>
                                                    <Divider />
                                                    <TableBody>
                                                        <TableRow>
                                                            <Paper
                                                                sx={{
                                                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                }}
                                                            >
                                                                <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                <Typography variant="h6" paragraph>
                                                                    Data not Available
                                                                </Typography>
                                                            </Paper>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        }
                                    </>
                                }
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}