import { Helmet } from 'react-helmet-async';
import { ChangeEvent, useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Tooltip,
  Button,
  Divider,
  Drawer,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  TextField,
  Box,
  alpha,
  OutlinedInput,
  styled,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  Chip,
  TableHead,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from "@mui/icons-material/Clear";
import Iconify from '../components/iconify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { db } from '../firebase';
import { collection, getDocs, query, where, orderBy, Timestamp, limit, startAfter, endBefore, limitToLast, getCountFromServer } from 'firebase/firestore';
import Loader from './spinner';
import 'react-toastify/dist/ReactToastify.css';
import './FoodMakerPage.css';
import Label from '../components/label';
import { format } from 'date-fns';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 564,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 585,
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

let initialState = {
  id: "",
  orderNo: '',
  seekerId: "",
  makerId: "",
  startDate: null,
  endDate: null,
  totalNetAmount: "",
  orderStatus: "",
  order: "Both",
}

export default function OrdersPage() {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<number>(0);
  let [limits, setLimits] = useState<number>(10);
  const [row, setRow] = useState<number>(10);
  const [asc, setAsc] = useState(true);
  const [startValue, setStartValue] = useState<any>('');
  let [orders, setOrders] = useState<any>([]);
  const [detail, setDetail] = useState<any>([]); // bind data in dropdown inside filter of seekername
  const [detail1, setDetail1] = useState<any>([]); // bind data in dropdown inside filter of makername
  let [selectedSeekerName, setSelectedSeekerName] = useState<any>("");
  let [selectedMakerName, setSelectedMakerName] = useState<any>("");
  let [orderInitial, setOrderInitial] = useState<any>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisible, setFirstVisible] = useState<any>();
  let [searchInput, setSearchInput] = useState('');
  const [flag, setFlag] = useState<boolean>(true);
  const [orderField, setOrderField] = useState<string>('createdDate');
  let [orderByField, setOrderByField] = useState<any>('desc');
  const [chipSeekerName, setChipSeekerName] = useState<boolean>(false);
  const [chipMakerName, setChipMakerName] = useState<boolean>(false);
  const [chipStartDate, setChipStartDate] = useState<boolean>(false);
  const [chipEndDate, setChipEndDate] = useState<boolean>(false);
  const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');
  const navigate = useNavigate();

  //if click on view set this item in sessionStorage which used in detail page
  const handleClickVisible = (element: any) => {
    let id = element?.id
    sessionStorage.setItem("orderNo", element?.orderNo + "");
    navigate(`/admin/orders/view/${id}`);
  }

  // open on clicking filter icon
  const onOpenFilter = () => {
    setOpen(!open)
  }

  // it is the first method invoked when this page is open
  useEffect(() => {
    //Get value from localstorage which is set while clicking on second card in dashboard page
    if (localStorage.getItem('orderStatus')) {
      orderInitial.orderStatus = JSON.parse(localStorage.getItem('orderStatus') || '{}');
    }
    else {
      setOrderInitial(initialState)
      orderInitial.orderStatus = '';
    }
    getBoth();
    getDropdownData();
  }, [])

  //call when makername and seekername value change while filtering
  const handleChange = (e: any) => {
    setChipMakerName(false);
    setChipSeekerName(false);
  }

  // call when status change to do filtering
  const handleChange1 = (e: any) => {
    orderInitial.orderStatus = e.target.value;
    if (searchInput) {
      searchItems(searchInput, page * limits, limits);
    } else {
      getData()
    }
  }

  // call when order change to do filtering
  const handleChange2 = (e: any) => {
    orderInitial.order = e.target.value;
    if (searchInput) {
      searchItems(searchInput, page * limits, limits);
    } else {
      getData()
    }
  }

  // if startdate is selected
  const setStartDateValue = (specialStartDate: any) => {
    setChipStartDate(false);
    setStartValue(specialStartDate);
    setOrderInitial({ ...orderInitial, "startDate": specialStartDate });
  }

  //if enddate is selected
  const setEndDateValue = (specialEndDate: any) => {
    setChipEndDate(false);
    if (specialEndDate >= startValue) {
      setOrderInitial({ ...orderInitial, "endDate": specialEndDate });
    }
  }

  //call initially
  const getData = async () => {
    if (orderInitial.order === 'Single Order') {
      getOrders();
      getDropdownData();
    }
    else if (orderInitial.order === 'Bulk Order') {
      getBulkOrders();
      getDropdownData();
    } else if (orderInitial.order === 'Both') {
      getBoth();
      getDropdownData();
    }
  }

  //call when order is singleorder
  const getOrders = async () => {
    if (searchInput) {
      setIsLoading(false);
      setFlag(true);
    } else {
      setIsLoading(true);
    }
    let items: any[] = [];
    try {
      const orderCollectionRef = collection(db, 'userOrders');
      let qLenn = query(orderCollectionRef, where('isBulkOrder', '==', 0), orderBy(orderField, orderByField))
      if (orderInitial.orderNo) {
        setIsLoading(false)
        qLenn = query(qLenn, where('orderNo', '==', orderInitial.orderNo))
      }
      if (orderInitial.seekerId) {
        setIsLoading(false)
        qLenn = query(qLenn, where('seekerId', '==', orderInitial.seekerId))
      }
      if (orderInitial.makerId) {
        setIsLoading(false)
        qLenn = query(qLenn, where('makerId', '==', orderInitial.makerId))
      }
      if (orderInitial.orderStatus) {
        qLenn = query(qLenn, where('orderStatus', '==', orderInitial.orderStatus))
      }
      if (orderInitial.startDate && orderInitial.endDate) {
        setIsLoading(false)
        let firstDate = Timestamp.fromDate(orderInitial.startDate);
        let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
        qLenn = query(qLenn, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      }
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count)
      if (querySnapshotLen.data().count > 0) {
        setFlag(true);
        let q = query(orderCollectionRef, where('isBulkOrder', '==', 0), orderBy(orderField, orderByField), limit(limits))
        if (orderInitial.orderNo) {
          q = query(q, where('orderNo', '==', orderInitial.orderNo))
        }
        if (orderInitial.seekerId) {
          q = query(q, where('seekerId', '==', orderInitial.seekerId))
        }
        if (orderInitial.makerId) {
          q = query(q, where('makerId', '==', orderInitial.makerId))
        }
        if (orderInitial.orderStatus) {
          q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
        }
        if (orderInitial.startDate && orderInitial.endDate) {
          let firstDate = Timestamp.fromDate(orderInitial.startDate);
          let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
          q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        }
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          items.push(doc.data())
        })
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setFirstVisible(querySnapshot.docs[0]);
        let i = 1;
        items.forEach(async (obj) => {
          if (obj.seekerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.seekerDisplayName = _doc.data().displayName;
            })
          }
          if (obj.makerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.makerDisplayName = _doc.data().displayName;
            })
            if (i === items.length) {
              setOrders(items);
              if (items.length > 0) {
                setFlag(true);
              } else {
                setFlag(false);
              }
            } else {
              i++;
            }
          }
        })
      } else {
        setFlag(false);
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  //call when order is bulkorder
  const getBulkOrders = async () => {
    if (searchInput) {
      setIsLoading(false);
      setFlag(true);
    } else {
      setIsLoading(true);
    }
    let items: any[] = [];
    try {
      const orderCollectionRef = collection(db, 'userOrders');
      let qLenn = query(orderCollectionRef, where('isBulkOrder', '==', 1), orderBy(orderField, orderByField))
      if (orderInitial.orderNo) {
        setIsLoading(false)
        qLenn = query(qLenn, where('orderNo', '==', orderInitial.orderNo))
      }
      if (orderInitial.seekerId) {
        setIsLoading(false)
        qLenn = query(qLenn, where('seekerId', '==', orderInitial.seekerId))
      }
      if (orderInitial.makerId) {
        setIsLoading(false)
        qLenn = query(qLenn, where('makerId', '==', orderInitial.makerId))
      }
      if (orderInitial.orderStatus) {
        qLenn = query(qLenn, where('orderStatus', '==', orderInitial.orderStatus))
      }
      if (orderInitial.startDate && orderInitial.endDate) {
        setIsLoading(false)
        let firstDate = Timestamp.fromDate(orderInitial.startDate);
        let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
        qLenn = query(qLenn, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      }
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count)
      if (querySnapshotLen.data().count > 0) {
        setFlag(true);
        let q = query(orderCollectionRef, where('isBulkOrder', '==', 1), orderBy(orderField, orderByField), limit(limits))
        if (orderInitial.orderNo) {
          q = query(q, where('orderNo', '==', orderInitial.orderNo))
        }
        if (orderInitial.seekerId) {
          q = query(q, where('seekerId', '==', orderInitial.seekerId))
        }
        if (orderInitial.makerId) {
          q = query(q, where('makerId', '==', orderInitial.makerId))
        }
        if (orderInitial.orderStatus) {
          q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
        }
        if (orderInitial.startDate && orderInitial.endDate) {
          let firstDate = Timestamp.fromDate(orderInitial.startDate);
          let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
          q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        }
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          items.push(doc.data())
        })
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setFirstVisible(querySnapshot.docs[0]);
        let i = 1;
        items.forEach(async (obj) => {
          if (obj.seekerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.seekerDisplayName = _doc.data().displayName;
            })
          }
          if (obj.makerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.makerDisplayName = _doc.data().displayName;
            })
            if (i === items.length) {
              setOrders(items);
              if (items.length > 0) {
                setFlag(true);
              } else {
                setFlag(false);
              }
            } else {
              i++;
            }
          }
        })
      } else {
        setFlag(false);
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  // call when order is both
  const getBoth = async () => {
    if (searchInput) {
      setIsLoading(false);
      setFlag(true);
    } else {
      setIsLoading(true);
      setFlag(true);
    }
    let items: any[] = [];
    try {
      const orderCollectionRef = collection(db, 'userOrders');
      let qLenn = query(orderCollectionRef, orderBy(orderField, orderByField))
      if (orderInitial.orderNo) {
        setIsLoading(false)
        qLenn = query(qLenn, where('orderNo', '==', orderInitial.orderNo))
      }
      if (orderInitial.seekerId) {
        setIsLoading(false)
        qLenn = query(qLenn, where('seekerId', '==', orderInitial.seekerId))
      }
      if (orderInitial.makerId) {
        setIsLoading(false)
        qLenn = query(qLenn, where('makerId', '==', orderInitial.makerId))
      }
      if (orderInitial.orderStatus) {
        qLenn = query(qLenn, where('orderStatus', '==', orderInitial.orderStatus))
      }
      if (orderInitial.startDate && orderInitial.endDate) {
        setIsLoading(false)
        let firstDate = Timestamp.fromDate(orderInitial.startDate);
        let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
        qLenn = query(qLenn, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
      }
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count)
      if (querySnapshotLen.data().count > 0) {
        setFlag(true);
        let q = query(orderCollectionRef, orderBy(orderField, orderByField), limit(limits))
        if (orderInitial.orderNo) {
          q = query(q, where('orderNo', '==', orderInitial.orderNo))
        }
        if (orderInitial.seekerId) {
          q = query(q, where('seekerId', '==', orderInitial.seekerId))
        }
        if (orderInitial.makerId) {
          q = query(q, where('makerId', '==', orderInitial.makerId))
        }
        if (orderInitial.orderStatus) {
          q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
        }
        if (orderInitial.startDate && orderInitial.endDate) {
          let firstDate = Timestamp.fromDate(orderInitial.startDate);
          let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
          q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        }
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          items.push(doc.data())
        })
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setFirstVisible(querySnapshot.docs[0]);
        let i = 1;
        items.forEach(async (obj) => {
          if (obj.seekerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.seekerDisplayName = _doc.data().displayName;
            })
          }
          if (obj.makerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.makerDisplayName = _doc.data().displayName;
            })
            if (i === items.length) {
              setOrders(items);
              if (items.length > 0) {
                setFlag(true);
              } else {
                setFlag(false);
              }
            } else {
              i++;
            }
          }
        })
      } else {
        setFlag(false);
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
    localStorage.removeItem('orderStatus');
    setIsLoading(false)
  }

  //call initially to bind data in dropdown of filter
  const getDropdownData = async () => {
    if (orderInitial.order === 'Bulk Order') {
      let items: any[] = [];
      try {
        const orderCollectionRef = collection(db, 'userOrders');
        const userCollectionRef = collection(db, 'users');
        const q = query(orderCollectionRef, where('isBulkOrder', '==', 1), orderBy(orderField, orderByField))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          let itm = doc.data();
          let obj = {
            makerId: doc.data().makerId,
            seekerId: doc.data().seekerId
          }
          if (obj.makerId) {
            const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              itm.makerDisplayName = _doc.data().firstName;
            })
          }
          if (obj.seekerId) {
            const q1 = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
            const _querySnapshot1 = await getDocs(q1)
            _querySnapshot1.forEach((_doc1) => {
              itm.seekerDisplayName = _doc1.data().firstName;
            })
          }
          items.push(itm);
          const temp = items.reduce((acc: any, item: any) => {
            if (acc[item.seekerDisplayName]) {
              const idx = acc[item.seekerDisplayName].findIndex((data: any) => data.seekerId === item.seekerId);
              if (idx === -1) {
                acc[item.seekerDisplayName].push(item);
              }
            } else {
              acc[item.seekerDisplayName] = [item];
            }
            return acc;
          }, {});
          let res: any[] = []; Object.values(temp).forEach(item => res = res.concat(item));
          setDetail(res);
          const temp1 = items.reduce((acc: any, item: any) => {
            if (acc[item.makerDisplayName]) {
              const idx = acc[item.makerDisplayName].findIndex((data: any) => data.makerId === item.makerId);
              if (idx === -1) {
                acc[item.makerDisplayName].push(item);
              }
            } else {
              acc[item.makerDisplayName] = [item];
            }
            return acc;
          }, {});
          let res1: any[] = []; Object.values(temp1).forEach(item => res1 = res1.concat(item));
          setDetail1(res1);
        })
      } catch (error) {
        console.log(error)
      }
    } else if (orderInitial.order === 'Single Order') {
      let items: any[] = [];
      try {
        const orderCollectionRef = collection(db, 'userOrders');
        const userCollectionRef = collection(db, 'users');
        const q = query(orderCollectionRef, where('isBulkOrder', '==', 0), orderBy(orderField, orderByField))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          let itm = doc.data();
          let obj = {
            makerId: doc.data().makerId,
            seekerId: doc.data().seekerId
          }
          if (obj.makerId) {
            const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              itm.makerDisplayName = _doc.data().firstName;
            })
          }
          if (obj.seekerId) {
            const q1 = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
            const _querySnapshot1 = await getDocs(q1)
            _querySnapshot1.forEach((_doc1) => {
              itm.seekerDisplayName = _doc1.data().firstName;
            })
          }
          items.push(itm);
          const temp = items.reduce((acc: any, item: any) => {
            if (acc[item.seekerDisplayName]) {
              const idx = acc[item.seekerDisplayName].findIndex((data: any) => data.seekerId === item.seekerId);
              if (idx === -1) {
                acc[item.seekerDisplayName].push(item);
              }
            } else {
              acc[item.seekerDisplayName] = [item];
            }
            return acc;
          }, {});
          let res: any[] = []; Object.values(temp).forEach(item => res = res.concat(item));
          setDetail(res);
          const temp1 = items.reduce((acc: any, item: any) => {
            if (acc[item.makerDisplayName]) {
              const idx = acc[item.makerDisplayName].findIndex((data: any) => data.makerId === item.makerId);
              if (idx === -1) {
                acc[item.makerDisplayName].push(item);
              }
            } else {
              acc[item.makerDisplayName] = [item];
            }
            return acc;
          }, {});
          let res1: any[] = []; Object.values(temp1).forEach(item => res1 = res1.concat(item));
          setDetail1(res1);
        })
      } catch (error) {
        console.log(error)
      }
    } else if (orderInitial.order === 'Both' || orderInitial.order.length === 0) {
      let items: any[] = [];
      try {
        const orderCollectionRef = collection(db, 'userOrders');
        const userCollectionRef = collection(db, 'users');
        const q = query(orderCollectionRef, orderBy(orderField, orderByField))
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          let itm = doc.data();
          let obj = {
            makerId: doc.data().makerId,
            seekerId: doc.data().seekerId
          }
          if (obj.makerId) {
            const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              itm.makerDisplayName = _doc.data().firstName;
            })
          }
          if (obj.seekerId) {
            const q1 = query(userCollectionRef, where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
            const _querySnapshot1 = await getDocs(q1)
            _querySnapshot1.forEach((_doc1) => {
              itm.seekerDisplayName = _doc1.data().firstName;
            })
          }
          items.push(itm);
          const temp = items.reduce((acc: any, item: any) => {
            if (acc[item.seekerDisplayName]) {
              const idx = acc[item.seekerDisplayName].findIndex((data: any) => data.seekerId === item.seekerId);
              if (idx === -1) {
                acc[item.seekerDisplayName].push(item);
              }
            } else {
              acc[item.seekerDisplayName] = [item];
            }
            return acc;
          }, {});
          let res: any[] = []; Object.values(temp).forEach(item => res = res.concat(item));
          setDetail(res);
          const temp1 = items.reduce((acc: any, item: any) => {
            if (acc[item.makerDisplayName]) {
              const idx = acc[item.makerDisplayName].findIndex((data: any) => data.makerId === item.makerId);
              if (idx === -1) {
                acc[item.makerDisplayName].push(item);
              }
            } else {
              acc[item.makerDisplayName] = [item];
            }
            return acc;
          }, {});
          let res1: any[] = []; Object.values(temp1).forEach(item => res1 = res1.concat(item));
          setDetail1(res1);
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  // call when click on search button in filter
  const handleSearch = async (e: any) => {
    setFlag(false);
    setPage(0);
    if (orderInitial.seekerId && orderInitial.makerId && orderInitial.startDate && orderInitial.endDate) {
      setChipSeekerName(true);
      setChipMakerName(true);
      setChipStartDate(true);
      setChipEndDate(true);
    }
    else if (orderInitial.seekerId && orderInitial.makerId) {
      setChipSeekerName(true);
      setChipMakerName(true);
    }
    else if (orderInitial.seekerId && orderInitial.startDate && orderInitial.endDate) {
      setChipSeekerName(true);
      setChipStartDate(true);
      setChipEndDate(true);
    }
    else if (orderInitial.makerId && orderInitial.startDate && orderInitial.endDate) {
      setChipMakerName(true);
      setChipStartDate(true);
      setChipEndDate(true);
    }
    else if (orderInitial.makerId) {
      setChipMakerName(true);
    }
    else if (orderInitial.seekerId) {
      setChipSeekerName(true);
    }
    else if (orderInitial.startDate && orderInitial.endDate) {
      setChipStartDate(true);
      setChipEndDate(true);
    }
    if (searchInput) {
      searchItems(searchInput, page * limits, limits);
    } else {
      getData();
    }
    setOpen(false)
  }

  // call when clear button is clicked in filter
  const handleClear = (e: any) => {
    setOrderInitial(initialState);
    orderInitial.orderNo = "";
    orderInitial.seekerId = "";
    orderInitial.makerId = "";
    orderInitial.startDate = null
    orderInitial.endDate = null
    setSelectedSeekerName("");
    setSelectedMakerName("");
    setChipSeekerName(false);
    setChipMakerName(false);
    setChipStartDate(false);
    setChipEndDate(false);
    if (searchInput) {
      searchItems(searchInput, page * limits, limits)
    } else {
      getData();
    }
    setOpen(false)
  }

  // call when any chip is removed
  const handleDelete = async (e: any) => {
    if (e === orderInitial.seekerId) {
      setChipSeekerName(false);
      orderInitial.seekerId = "";
      setSelectedSeekerName("");
    }
    else if (e === orderInitial.makerId) {
      setChipMakerName(false);
      orderInitial.makerId = "";
      setSelectedMakerName("");
    }
    else if (e === orderInitial.startDate) {
      setChipStartDate(false);
      orderInitial.startDate = null;
      orderInitial.endDate = null;
      setChipEndDate(false);
    }
    else if (e === orderInitial.endDate) {
      setChipEndDate(false);
      orderInitial.endDate = null;
    }
    orderInitial.orderNo = "";
    if (!searchInput) {
      await handleSearch(e);
    } else {
      await searchItems(searchInput, page * limits, limits)
    }
  }

  // when cloud function is called
  async function searchData(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData; // return the response data
    } catch (error) {
      console.error(error);
    }
  }

  // invoked everytime when their is searchvalue in searchBox
  const searchItems = async (searchValue: any, startIndex: number, fetchRecord: number) => {
    setSearchInput(searchValue)
    searchInput = searchValue
    if (searchValue) {
      if (orderInitial.order === 'Both') {
        let items: any[] = [];
        try {
          const orderCollectionRef = collection(db, 'userOrders');
          const requestBody = {
            query: searchValue,
            size: fetchRecord,
            from: startIndex
          };
          const result = await searchData(process.env.REACT_APP_USERORDER_SEARCH, requestBody);
          for (let k = 0; k < result.data.length; k++) {
            const element = result.data[k]._source;
            setFlag(true);
            let q = query(orderCollectionRef, where('orderNo', '==', element.orderNo + ""), orderBy('createdDate', 'desc'), limit(limits))
            if (orderInitial.orderNo) {
              q = query(q, where('orderNo', '==', orderInitial.orderNo))
            }
            if (orderInitial.seekerId) {
              q = query(q, where('seekerId', '==', orderInitial.seekerId))
            }
            if (orderInitial.makerId) {
              q = query(q, where('makerId', '==', orderInitial.makerId))
            }
            if (orderInitial.orderStatus) {
              q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
            }
            if (orderInitial.startDate && orderInitial.endDate) {
              let firstDate = Timestamp.fromDate(orderInitial.startDate);
              let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
              q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
            }
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
              items.push(doc.data())
            })
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstVisible(querySnapshot.docs[0]);
            let i = 1;
            items.forEach(async (obj) => {
              if (obj.seekerId) {
                const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
                const _querySnapshot = await getDocs(q)
                _querySnapshot.forEach((_doc) => {
                  obj.seekerDisplayName = _doc.data().displayName;
                })
              }
              if (obj.makerId) {
                const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
                const _querySnapshot = await getDocs(q)
                _querySnapshot.forEach((_doc) => {
                  obj.makerDisplayName = _doc.data().displayName;
                })
                if (i === items.length) {
                  setOrders(items);
                  orders = items;
                  setRow(result.totalRecords)
                  if (items.length > 0) {
                    setFlag(true);
                  } else {
                    setFlag(false);
                  }

                } else {
                  i++;
                }
              }
            })
          }
          if (items.length > 0) {
            setFlag(true);
            setRow(result.totalRecords)
          } else {
            setFlag(false);
          }
        } catch (error) {
          console.log(error)
        }
      } else if (orderInitial.order === 'Single Order') {
        let items: any[] = [];
        try {
          const orderCollectionRef = collection(db, 'userOrders');
          const requestBody = {
            query: searchValue,
            size: fetchRecord,
            from: startIndex
          };
          const result = await searchData(process.env.REACT_APP_USERORDER_SEARCH, requestBody);
          for (let k = 0; k < result.data.length; k++) {
            const element = result.data[k]._source;;
            setFlag(true);
            let q = query(orderCollectionRef, where('isBulkOrder', '==', 0), where('orderNo', '==', element.orderNo + ""), orderBy('createdDate', 'desc'), limit(limits))
            if (orderInitial.orderNo) {
              q = query(q, where('orderNo', '==', orderInitial.orderNo))
            }
            if (orderInitial.seekerId) {
              q = query(q, where('seekerId', '==', orderInitial.seekerId))
            }
            if (orderInitial.makerId) {
              q = query(q, where('makerId', '==', orderInitial.makerId))
            }
            if (orderInitial.orderStatus) {
              q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
            }
            if (orderInitial.startDate && orderInitial.endDate) {
              let firstDate = Timestamp.fromDate(orderInitial.startDate);
              let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
              q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
            }
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
              items.push(doc.data())
            })
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstVisible(querySnapshot.docs[0]);
            let i = 1;
            items.forEach(async (obj) => {
              if (obj.seekerId) {
                const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
                const _querySnapshot = await getDocs(q)
                _querySnapshot.forEach((_doc) => {
                  obj.seekerDisplayName = _doc.data().displayName;
                })
              }
              if (obj.makerId) {
                const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
                const _querySnapshot = await getDocs(q)
                _querySnapshot.forEach((_doc) => {
                  obj.makerDisplayName = _doc.data().displayName;
                })
                if (i === items.length) {
                  setOrders(items);
                  setRow(result.totalRecords)
                  if (items.length > 0) {
                    setFlag(true);
                  } else {
                    setFlag(false);
                  }
                } else {
                  i++;
                }
              }
            })
          }
          if (items.length > 0) {
            setFlag(true);
            setRow(result.totalRecords)
          } else {
            setFlag(false);
          }
        } catch (error) {
          console.log(error)
        }
      } else if (orderInitial.order === 'Bulk Order') {
        let items: any[] = [];
        try {
          const orderCollectionRef = collection(db, 'userOrders');
          const requestBody = {
            query: searchValue,
            size: fetchRecord,
            from: startIndex
          };
          const result = await searchData(process.env.REACT_APP_USERORDER_SEARCH, requestBody);
          for (let k = 0; k < result.data.length; k++) {
            const element = result.data[k]._source;
            setFlag(true);
            let q = query(orderCollectionRef, where('isBulkOrder', '==', 1), where('orderNo', '==', element.orderNo + ""), orderBy('createdDate', 'desc'), limit(limits))
            if (orderInitial.orderNo) {
              q = query(q, where('orderNo', '==', orderInitial.orderNo))
            }
            if (orderInitial.seekerId) {
              q = query(q, where('seekerId', '==', orderInitial.seekerId))
            }
            if (orderInitial.makerId) {
              q = query(q, where('makerId', '==', orderInitial.makerId))
            }
            if (orderInitial.orderStatus) {
              q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
            }
            if (orderInitial.startDate && orderInitial.endDate) {
              let firstDate = Timestamp.fromDate(orderInitial.startDate);
              let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
              q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
            }
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
              items.push(doc.data())
            })
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstVisible(querySnapshot.docs[0]);
            let i = 1;
            items.forEach(async (obj) => {
              if (obj.seekerId) {
                const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
                const _querySnapshot = await getDocs(q)
                _querySnapshot.forEach((_doc) => {
                  obj.seekerDisplayName = _doc.data().displayName;
                })
              }
              if (obj.makerId) {
                const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
                const _querySnapshot = await getDocs(q)
                _querySnapshot.forEach((_doc) => {
                  obj.makerDisplayName = _doc.data().displayName;
                })
                if (i === items.length) {
                  setOrders(items);
                  setRow(result.totalRecords)
                  if (items.length > 0) {
                    setFlag(true);
                  } else {
                    setFlag(false);
                  }
                } else {
                  i++;
                }
              }
            })
          }
          if (items.length > 0) {
            setFlag(true);
            setRow(result.totalRecords)
          } else {
            setFlag(false);
          }
        } catch (error) {
          console.log(error)
        }
      }
    } else {
      searchInput = ""
      setSearchInput("")
      await getData()
    }
  }

  // when seekername is filtered
  const handleFilterSeekerName = async (e: any) => {
    e.preventDefault();
    let items: any[] = [];
    orderInitial.seekerId = e.target.value;
    if (orderInitial.seekerId.length > 0) {
      const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', orderInitial.seekerId))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      })
      setSelectedSeekerName(items[0].firstName)
    }
  }

  //when makername is fileterd
  const handleFilterMakerName = async (e: any) => {
    e.preventDefault();
    let items: any[] = [];
    orderInitial.makerId = e.target.value;
    if (orderInitial.makerId.length > 0) {
      const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', orderInitial.makerId))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      })
      setSelectedMakerName(items[0].firstName)
    }
  }

  // when we want to do sorting in descending order
  const sortDescending = async () => {
    setPage(0);
    setOrderField('createdDate');
    setOrderByField('desc');
    orderByField = 'desc'
    setTimeout(async () => {
      if (orderInitial.order === 'Both') {
        await getBoth();
      } else if (orderInitial.order === 'Single Order') {
        await getOrders();
      } else if (orderInitial.order === 'Bulk Order') {
        await getBulkOrders();
      }
      setAsc(true)
    }, 1000);
  }

  // when we want to do sorting in ascending order
  const sortAscending = async () => {
    setPage(0);
    setOrderField('createdDate');
    setOrderByField('asc');
    orderByField = 'asc'
    setTimeout(async () => {
      if (orderInitial.order === 'Both') {
        await getBoth();
      } else if (orderInitial.order === 'Single Order') {
        await getOrders();
      } else if (orderInitial.order === 'Bulk Order') {
        await getBulkOrders();
      }
      setAsc(false)
    }, 1000);
  }

  /// when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handlePageChange = async (event: any, newPage: number) => {
    if (searchInput) {
      setPage(newPage);
      await searchItems(searchInput, newPage * limits, limits)
    } else {
      setPage(newPage);
      if (page < newPage) {
        let q = query(collection(db, 'userOrders'), orderBy(orderField, orderByField),
          startAfter(lastVisible), limit(limits))
        if (orderInitial.orderNo) {
          q = query(q, where('orderNo', '==', orderInitial.orderNo))
        }
        if (orderInitial.orderNo) {
          q = query(q, where('orderNo', '==', orderInitial.orderNo))
        }
        if (orderInitial.seekerId) {
          q = query(q, where('seekerId', '==', orderInitial.seekerId))
        }
        if (orderInitial.makerId) {
          q = query(q, where('makerId', '==', orderInitial.makerId))
        }
        if (orderInitial.orderStatus) {
          q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
        }
        if (orderInitial.startDate && orderInitial.endDate) {
          let firstDate = Timestamp.fromDate(orderInitial.startDate);
          let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
          q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        }
        const items: any[] = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          items.push(doc.data())
        })
        let i = 1;
        items.forEach(async (obj) => {
          if (obj.seekerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.seekerDisplayName = _doc.data().displayName;
            })
          }
          if (obj.makerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.makerDisplayName = _doc.data().displayName;
            })
            if (i === items.length) {
              setOrders(items);
            } else {
              i++;
            }
          }
        })
        updateState(querySnapshot)
        if (orderInitial.order === 'Single Order') {
          let q = query(collection(db, 'userOrders'), where('isBulkOrder', '==', 0), orderBy(orderField, orderByField),
            startAfter(lastVisible), limit(limits))
          if (orderInitial.orderNo) {
            q = query(q, where('orderNo', '==', orderInitial.orderNo))
          }
          if (orderInitial.orderNo) {
            q = query(q, where('orderNo', '==', orderInitial.orderNo))
          }
          if (orderInitial.seekerId) {
            q = query(q, where('seekerId', '==', orderInitial.seekerId))
          }
          if (orderInitial.makerId) {
            q = query(q, where('makerId', '==', orderInitial.makerId))
          }
          if (orderInitial.orderStatus) {
            q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
          }
          if (orderInitial.startDate && orderInitial.endDate) {
            let firstDate = Timestamp.fromDate(orderInitial.startDate);
            let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
            q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
          }
          const items: any[] = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            items.push(doc.data())
          })
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.seekerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.seekerDisplayName = _doc.data().displayName;
              })
            }
            if (obj.makerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.makerDisplayName = _doc.data().displayName;
              })
              if (i === items.length) {
                setOrders(items);
              } else {
                i++;
              }
            }
          })
          updateState(querySnapshot)
        } else if (orderInitial.order === 'Bulk Order') {
          let q = query(collection(db, 'userOrders'), where('isBulkOrder', '==', 1), orderBy(orderField, orderByField),
            startAfter(lastVisible), limit(limits))
          if (orderInitial.orderNo) {
            q = query(q, where('orderNo', '==', orderInitial.orderNo))
          }
          if (orderInitial.seekerId) {
            q = query(q, where('seekerId', '==', orderInitial.seekerId))
          }
          if (orderInitial.makerId) {
            q = query(q, where('makerId', '==', orderInitial.makerId))
          }
          if (orderInitial.orderStatus) {
            q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
          }
          if (orderInitial.startDate && orderInitial.endDate) {
            let firstDate = Timestamp.fromDate(orderInitial.startDate);
            let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
            q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
          }
          const items: any[] = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            items.push(doc.data())
          })
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.seekerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.seekerDisplayName = _doc.data().displayName;
              })
            }
            if (obj.makerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.makerDisplayName = _doc.data().displayName;
              })
              if (i === items.length) {
                setOrders(items);
              } else {
                i++;
              }
            }
          })
          updateState(querySnapshot)
        } else if (orderInitial.order === 'Both') {
          let q = query(collection(db, 'userOrders'), orderBy(orderField, orderByField),
            startAfter(lastVisible), limit(limits))
          if (orderInitial.orderNo) {
            q = query(q, where('orderNo', '==', orderInitial.orderNo))
          }
          if (orderInitial.seekerId) {
            q = query(q, where('seekerId', '==', orderInitial.seekerId))
          }
          if (orderInitial.makerId) {
            q = query(q, where('makerId', '==', orderInitial.makerId))
          }
          if (orderInitial.orderStatus) {
            q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
          }
          if (orderInitial.startDate && orderInitial.endDate) {
            let firstDate = Timestamp.fromDate(orderInitial.startDate);
            let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
            q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
          }
          const items: any[] = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            items.push(doc.data())
          })
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.seekerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.seekerDisplayName = _doc.data().displayName;
              })
            }
            if (obj.makerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.makerDisplayName = _doc.data().displayName;
              })
              if (i === items.length) {
                setOrders(items);
              } else {
                i++;
              }
            }
          })
          updateState(querySnapshot)
        }
      } else {
        let q = query(collection(db, 'userOrders'), orderBy(orderField, orderByField),
          endBefore(firstVisible),
          limitToLast(limits))
        if (orderInitial.orderNo) {
          q = query(q, where('orderNo', '==', orderInitial.orderNo))
        }
        if (orderInitial.seekerId) {
          q = query(q, where('seekerId', '==', orderInitial.seekerId))
        }
        if (orderInitial.makerId) {
          q = query(q, where('makerId', '==', orderInitial.makerId))
        }
        if (orderInitial.orderStatus) {
          q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
        }
        if (orderInitial.startDate && orderInitial.endDate) {
          let firstDate = Timestamp.fromDate(orderInitial.startDate);
          let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
          q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
        }
        const items: any[] = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          items.push(doc.data())
        })
        let i = 1;
        items.forEach(async (obj) => {
          if (obj.seekerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.seekerDisplayName = _doc.data().displayName;
            })
          }
          if (obj.makerId) {
            const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
            const _querySnapshot = await getDocs(q)
            _querySnapshot.forEach((_doc) => {
              obj.makerDisplayName = _doc.data().displayName;
            })
            if (i === items.length) {
              setOrders(items);
            } else {
              i++;
            }
          }
        })
        updateState(querySnapshot)
        if (orderInitial.order === 'Single Order') {
          let q = query(collection(db, 'userOrders'), where('isBulkOrder', '==', 0), orderBy(orderField, orderByField),
            endBefore(firstVisible),
            limitToLast(limits))
          if (searchInput) {
            q = query(q, where('orderNo', '==', searchInput))
          }
          if (orderInitial.seekerId) {
            q = query(q, where('seekerId', '==', orderInitial.seekerId))
          }
          if (orderInitial.makerId) {
            q = query(q, where('makerId', '==', orderInitial.makerId))
          }
          if (orderInitial.orderStatus) {
            q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
          }
          if (orderInitial.startDate && orderInitial.endDate) {
            let firstDate = Timestamp.fromDate(orderInitial.startDate);
            let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
            q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
          }
          const items: any[] = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            items.push(doc.data())
          })
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.seekerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.seekerDisplayName = _doc.data().displayName;
              })
            }
            if (obj.makerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.makerDisplayName = _doc.data().displayName;
              })
              if (i === items.length) {
                setOrders(items);
              } else {
                i++;
              }
            }
          })
          updateState(querySnapshot)
        }
        else if (orderInitial.order === 'Bulk Order') {
          let q = query(collection(db, 'userOrders'), where('isBulkOrder', '==', 1), orderBy(orderField, orderByField), endBefore(firstVisible), limitToLast(limits))
          if (orderInitial.orderNo) {
            q = query(q, where('orderNo', '==', orderInitial.orderNo))
          }
          if (orderInitial.seekerId) {
            q = query(q, where('seekerId', '==', orderInitial.seekerId))
          }
          if (orderInitial.makerId) {
            q = query(q, where('makerId', '==', orderInitial.makerId))
          }
          if (orderInitial.orderStatus) {
            q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
          }
          if (orderInitial.startDate && orderInitial.endDate) {
            let firstDate = Timestamp.fromDate(orderInitial.startDate);
            let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
            q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
          }
          const items: any[] = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            items.push(doc.data())
          })
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.seekerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.seekerDisplayName = _doc.data().displayName;
              })
            }
            if (obj.makerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.makerDisplayName = _doc.data().displayName;
              })
              if (i === items.length) {
                setOrders(items);
              } else {
                i++;
              }
            }
          })
          updateState(querySnapshot)
        } else if (orderInitial.order === 'Both') {
          let q = query(collection(db, 'userOrders'), orderBy(orderField, orderByField),
            endBefore(firstVisible),
            limitToLast(limits))
          if (searchInput) {
            q = query(q, where('orderNo', '==', searchInput))
          }
          if (orderInitial.seekerId) {
            q = query(q, where('seekerId', '==', orderInitial.seekerId))
          }
          if (orderInitial.makerId) {
            q = query(q, where('makerId', '==', orderInitial.makerId))
          }
          if (orderInitial.orderStatus) {
            q = query(q, where('orderStatus', '==', orderInitial.orderStatus))
          }
          if (orderInitial.startDate && orderInitial.endDate) {
            let firstDate = Timestamp.fromDate(orderInitial.startDate);
            let secondDate = Timestamp.fromDate(new Date(new Date(orderInitial.endDate).setDate(new Date(orderInitial.endDate).getDate() + 1)));
            q = query(q, where('createdDate', '>', firstDate), where('createdDate', '<', secondDate))
          }
          const items: any[] = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc) => {
            items.push(doc.data())
          })
          let i = 1;
          items.forEach(async (obj) => {
            if (obj.seekerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('uid', '==', obj.seekerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.seekerDisplayName = _doc.data().displayName;
              })
            }
            if (obj.makerId) {
              const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodMaker'), where('uid', '==', obj.makerId))
              const _querySnapshot = await getDocs(q)
              _querySnapshot.forEach((_doc) => {
                obj.makerDisplayName = _doc.data().displayName;
              })
              if (i === items.length) {
                setOrders(items);
              } else {
                i++;
              }
            }
          })
          updateState(querySnapshot)
        }
      }
    }
  }

  // update first visible and last visible value according to pagination (next and previous button)
  const updateState = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimits(parseInt(event.target.value));
    limits = parseInt(event.target.value)
    setPage(0);
    getBoth()
  };

  //if we clear status which is selected then it is invoked
  const handleClearClick = () => {
    orderInitial.orderStatus = '';
    if (searchInput) {
      searchItems(searchInput, page * limits, limits);
    } else {
      getData();
    }
  };

  return (
    <>
      <Helmet>
        <title> Orders | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack alignItems="left" justifyContent="space-between" mb={5}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit">Orders</Typography>
              </Breadcrumbs>
            </Stack>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
              <div>
                {isLoading ? <Loader title="Loading..." /> :
                  <>
                    <Drawer
                      anchor="right"
                      open={open}
                      onClose={onOpenFilter}
                      PaperProps={{
                        sx: { width: 280, border: 'none', overflow: 'hidden' },
                      }}
                    >
                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
                        <IconButton onClick={onOpenFilter} >
                          <Iconify icon="eva:close-fill" sx={{ color: "red" }} />
                        </IconButton>
                        <Typography variant="subtitle1" sx={{ marginRight: "67%", color: "black" }}>
                          Filters
                        </Typography>
                      </Stack>
                      <Divider />
                      <Scrollbar>
                        <Stack spacing={3} sx={{ p: 3, backgroundColor: 'white', height: 'calc(100vh - 100px)' }}>
                          <div>
                            <FormGroup>
                              <FormControl >
                                <InputLabel id="demo-simple-select-label">Seeker Name</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="seekerId"
                                  value={orderInitial.seekerId}
                                  label="seekerName"
                                  onChange={(e) => { handleFilterSeekerName(e); handleChange(e) }}
                                >
                                  {detail.map((option: any) => (
                                    <MenuItem value={option.seekerId} key={option.seekerId}>{option.seekerDisplayName}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <FormControl>
                                <InputLabel id="demo-simple-select-label">Maker Name</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="makerId"
                                  value={orderInitial.makerId}
                                  label="makerName"
                                  onChange={(e) => { handleFilterMakerName(e); handleChange(e) }}
                                >
                                  {detail1.map((option: any) => (
                                    <MenuItem value={option.makerId} key={option.makerId}>{option.makerDisplayName}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <FormControl >
                                <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                  <DatePicker
                                    label="Start Date"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={orderInitial.startDate}
                                    onChange={(newValue: any) => { return setStartDateValue(newValue); }}
                                    renderInput={(params) => <TextField {...params} />} />
                                </LocalizationProvider>
                              </FormControl>
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <FormControl>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label="End Date"
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    value={orderInitial.endDate}
                                    onChange={(newValue: any) => { return setEndDateValue(newValue); }}
                                    renderInput={(params) => <TextField {...params} />} />
                                </LocalizationProvider>
                              </FormControl>
                            </FormGroup>
                          </div>
                        </Stack>
                      </Scrollbar>
                      <Box sx={{ pl: '16px', pr: '16px', pt: '8px', pb: '8px', bottom: "10px", width: '100%' }}>
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          variant="contained"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </Box>
                      <Box sx={{ pl: '16px', pr: '16px', pt: '8px', pb: '16px', bottom: "0px", width: '100%' }}>
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          variant="contained"
                          onClick={handleClear}
                        >
                          Clear
                        </Button>
                      </Box>
                    </Drawer>
                    <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                      <div>
                        <StyledRoot
                          sx={{
                            color: 'primary.main',
                            bgcolor: 'white',

                          }}
                        >
                          <StyledSearch
                            value={searchInput}
                            onChange={(e) => searchItems(e.target.value, page * limits, limits)}
                            placeholder="Search by Order Number..."
                            startAdornment={
                              <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                              </InputAdornment>
                            }
                          />
                          <div className="largeScreenContent" style={{ width: '100%' }}>
                            {chipSeekerName === true ? <>
                              {
                                selectedSeekerName && selectedSeekerName.length > 0 ?
                                  <Chip color="primary" size="small" label={selectedSeekerName} sx={{ mx: 1 }}
                                    onDelete={(e: any) => handleDelete(orderInitial.seekerId)} /> : ""
                              }
                            </> : ''}
                            {chipMakerName === true ? <>
                              {
                                selectedMakerName && selectedMakerName.length > 0 ?
                                  <Chip color="primary" size="small" label={selectedMakerName} sx={{ mx: 1 }}
                                    onDelete={(e: any) => handleDelete(orderInitial.makerId)} /> : ""
                              }
                            </> : ''}
                            {chipStartDate === true && chipEndDate === true ? <>
                              {
                                orderInitial.startDate && orderInitial.endDate ?
                                  <Chip color="primary" size="small" label={format(new Date(orderInitial.startDate), formatedDate) + "-" + format(new Date(orderInitial.endDate), formatedDate)} sx={{ mx: 1 }}
                                    onDelete={(e: any) => handleDelete(orderInitial.startDate)} /> : ""
                              }
                            </> : ''}
                          </div>
                          <div className="dataShowLarge">
                            <FormGroup >
                              <FormControl sx={{ width: "140px", mr: 0.5 }} size="small">
                                <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="orderStatus"
                                  value={orderInitial.orderStatus}
                                  label="Order Status"
                                  onChange={(e: any) => { handleChange1(e) }}
                                  endAdornment={(orderInitial.orderStatus) ? <IconButton sx={{ marginRight: '8px' }} onClick={handleClearClick}><ClearIcon sx={{ fontSize: '15px' }} /></IconButton> : ''}
                                >
                                  <MenuItem value='Confirmed'>Confirmed</MenuItem>
                                  <MenuItem value='Processed'>Processed</MenuItem>
                                  <MenuItem value='Shipped'>Shipped</MenuItem>
                                  <MenuItem value='Delivered'>Delivered</MenuItem>
                                  <MenuItem value='Cancelled'>Cancelled</MenuItem>
                                  <MenuItem value='Pending'>Pending</MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl fullWidth className="mb-3">
                              </FormControl>
                            </FormGroup>
                          </div>
                          <div className='dataShowLarge'>
                            <FormGroup>
                              <FormControl sx={{ width: "122px", ml: 0.5, mr: 1 }} size="small">
                                <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="order"
                                  value={orderInitial.order || 'Both'}
                                  label="Order Type"
                                  onChange={handleChange2}
                                >
                                  <MenuItem value='Single Order'>Single Order</MenuItem>
                                  <MenuItem value='Bulk Order'>Bulk Order</MenuItem>
                                  <MenuItem value='Both'>Both</MenuItem>
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </div>
                          <Tooltip title="Filter list">
                            <IconButton
                              color="inherit"
                              size="small"
                              onClick={onOpenFilter}
                            >
                              <FilterListIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledRoot>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 8px' }}>
                          <div className="dataShowSmall">
                            <FormGroup >
                              <FormControl sx={{ width: "134px", mr: 0.5, ml: 0.5, mb: 1, display: 'flex', justifyContent: 'flex-end' }} size="small">
                                <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="orderStatus"
                                  value={orderInitial.orderStatus}
                                  label="Order Status"
                                  onChange={(e: any) => { handleChange1(e) }}
                                  endAdornment={(orderInitial.orderStatus) ? <IconButton sx={{ marginRight: '8px' }} onClick={handleClearClick}><ClearIcon sx={{ fontSize: '15px' }} /></IconButton> : ''}

                                >
                                  <MenuItem value='Confirmed'>Confirmed</MenuItem>
                                  <MenuItem value='Processed'>Processed</MenuItem>
                                  <MenuItem value='Shipped'>Shipped</MenuItem>
                                  <MenuItem value='Delivered'>Delivered</MenuItem>
                                  <MenuItem value='Cancelled'>Cancelled</MenuItem>
                                  <MenuItem value='Pending'>Pending</MenuItem>
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </div>
                          <div className="dataShowSmall" >
                            <FormGroup>
                              <FormControl sx={{ width: "115px", ml: 0.5, mb: 1, mr: 0.5 }} size="small">
                                <InputLabel id="demo-simple-select-label">Order Type</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="order"
                                  value={orderInitial.order || 'Both'}
                                  label="Order Type"
                                  onChange={(e) => { handleChange2(e) }}
                                >
                                  <MenuItem value='Single Order'>Single Order</MenuItem>
                                  <MenuItem value='Bulk Order'>Bulk Order</MenuItem>
                                  <MenuItem value='Both'>Both</MenuItem>
                                </Select>
                              </FormControl>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="smallScreenContent" style={{ margin: 5 }}>
                          {chipSeekerName === true ? <>
                            {
                              selectedSeekerName && selectedSeekerName.length > 0 ?
                                <Chip color="primary" size="small" label={selectedSeekerName} sx={{ mx: 1 }}
                                  onDelete={(e: any) => handleDelete(orderInitial.seekerId)} /> : ""
                            }
                          </> : ''}
                          {chipMakerName === true ? <>
                            {
                              selectedMakerName && selectedMakerName.length > 0 ?
                                <Chip color="primary" size="small" label={selectedMakerName} sx={{ mx: 1 }}
                                  onDelete={(e: any) => handleDelete(orderInitial.makerId)} /> : ""
                            }
                          </> : ''}
                          {chipStartDate === true && chipEndDate === true ? <>
                            {
                              orderInitial.startDate && orderInitial.endDate ?
                                <Chip color="primary" size="small" label={format(new Date(orderInitial.startDate), formatedDate) + "-" + format(new Date(orderInitial.endDate), formatedDate)} sx={{ mx: 1 }}
                                  onDelete={(e: any) => handleDelete(orderInitial.startDate)} /> : ""
                            }
                          </> : ''}
                        </div>
                      </div>
                      {
                        (flag === true) ?
                          <>
                            <Scrollbar>
                              <TableContainer className="order-list" sx={{ backgroundColor: "white" }}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>#</TableCell>
                                      <TableCell align="left" sx={{ color: '#212B36' }} ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Seekers</Typography>
                                      </TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Makers</Typography>
                                      </TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Type </Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Date
                                        {(asc === false) ?
                                          <IconButton
                                            sx={{ marginLeft: "6px", color: "black" }}
                                            color="inherit"
                                            size="small"
                                            onClick={sortDescending}
                                          >
                                            <ArrowDropDownIcon /></IconButton>
                                          :
                                          <IconButton
                                            sx={{ marginLeft: "6px", color: "black" }}
                                            color="inherit"
                                            size="small"
                                            onClick={sortAscending}
                                          >
                                            < ArrowDropUpIcon />
                                          </IconButton>
                                        }</Typography>
                                      </TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Orders Amount</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Delivery Date</Typography></TableCell>
                                      <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Status</Typography></TableCell>
                                      <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {
                                      orders.map((arr: any, index: number) =>
                                      (
                                        <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                          <TableCell align="left" >{(page * limits) + index + 1} </TableCell>
                                          <TableCell align="left" sx={{ color: '#212B36' }}>
                                            <Typography variant="subtitle2" noWrap>
                                              {arr.orderNo}
                                            </Typography></TableCell>
                                          <TableCell align="left"><Typography noWrap fontSize="0.875rem">{(arr.seekerDisplayName) ? arr.seekerDisplayName : arr.seekerName}</Typography></TableCell>
                                          <TableCell align="left"><Typography noWrap fontSize="0.875rem">{(arr.makerDisplayName) ? arr.makerDisplayName : arr.makerName}</Typography></TableCell>
                                          <TableCell align="left"><Typography noWrap fontSize="0.875rem">{(arr.isBulkOrder === 1) ? 'Bulk Order' : 'Single Order'}</Typography></TableCell>
                                          <TableCell align="left"><Typography noWrap fontSize="0.875rem"> {format(new Date(arr.createdDate.seconds * 1000), formatedDate)}</Typography></TableCell>
                                          <TableCell align="left"><Typography noWrap fontSize="0.875rem">₹ {(arr.totalNetAmount).toFixed(2)}</Typography></TableCell>
                                          <TableCell align="left"><Typography noWrap fontSize="0.875rem">
                                            {format(new Date(arr.deliveryDate), formatedDate + " hh:mm a")}
                                          </Typography></TableCell>
                                          <TableCell align="left" >
                                            {arr.orderStatus === 'Confirmed' && <Label sx={{ color: "white", backgroundColor: "#919eab", width: '77px' }}>{arr.orderStatus}</Label>}
                                            {arr.orderStatus === 'Delivered' && <Label sx={{ color: "white", backgroundColor: "#53bc5b", width: '77px' }}>{arr.orderStatus}</Label>}
                                            {arr.orderStatus === 'Shipped' && <Label sx={{ color: "white", backgroundColor: "#1565c0", width: '77px' }}>{arr.orderStatus}</Label>}
                                            {arr.orderStatus === 'Processed' && <Label sx={{ color: "white", backgroundColor: "#fcde2a", width: '77px' }}>{arr.orderStatus}</Label>}
                                            {arr.orderStatus === 'Cancelled' && <Label sx={{ color: "white", backgroundColor: "#e20700", width: '77px' }}>{arr.orderStatus}</Label>}
                                            {arr.orderStatus === 'Pending' && <Label sx={{ color: "white", backgroundColor: "#fc7b2a", width: '77px' }}>{arr.orderStatus}</Label>}
                                          </TableCell>
                                          <TableCell align='right' style={{ display: "flex" }} >
                                            <Tooltip title="View" arrow>
                                              <IconButton
                                                sx={{ color: '#1565c0' }}
                                                color="inherit"
                                                size="small"
                                                onClick={(e) => { handleClickVisible(arr) }}
                                              >
                                                <VisibilityIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Scrollbar>
                            <TablePagination
                              component="div"
                              count={row}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleLimitChange}
                              page={page}
                              rowsPerPage={limits}
                              rowsPerPageOptions={[10, 20, 30, 40]}
                            />
                          </>
                          :
                          <TableContainer className="order-list-notFound" sx={{ backgroundColor: "white" }}>
                            <Table>
                              <Divider />
                              <TableBody>
                                <TableRow>
                                  <Paper
                                    sx={{
                                      width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                    }}
                                  >
                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                    <Typography variant="h6" paragraph>
                                      Data not Available
                                    </Typography>
                                  </Paper>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                      }
                    </Card>
                  </>
                }
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}