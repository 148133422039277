import FoodBankIcon from '@mui/icons-material/FoodBank';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import PaymentIcon from '@mui/icons-material/Payment';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import FeedbackIcon from '@mui/icons-material/Feedback';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PublicIcon from '@mui/icons-material/Public';
import PaymentsIcon from '@mui/icons-material/Payments';

const navConfig = [
  {
    title: 'Dashboard',
    path: '/admin/dashboard',
    icon: <DashboardIcon />,
  },
  {
    title: 'Food Makers',
    path: '/admin/foodMakers',
    icon: <FoodBankIcon />,
  },
  {
    title: 'Food Seekers',
    path: '/admin/foodSeekers',
    icon: <DinnerDiningIcon />,
  },
  {
    title: 'Orders',
    path: '/admin/orders',
    icon: <MenuIcon />,
  },
  // {
  //   title: 'Commission',
  //   path: '/admin/commission',
  //   icon: <HandshakeIcon />,
  // },
  {
    title: 'Makers Payment',
    path: '/admin/makerspayment',
    icon: <PaymentsIcon />,
  },
  {
    title: 'Orders Transaction',
    path: '/admin/ordersTransaction',
    icon: <PaymentIcon />,
  },
  {
    title: 'Region',
    path: '/admin/region',
    icon: <PublicIcon />,
  },
  // {
  //   title: 'Reward',
  //   path: '/admin/reward',
  //   icon: <GradeIcon />,
  // },
  // {
  //   title: 'Product Category',
  //   path: '/admin/productcategory',
  //   icon: < BrunchDiningIcon/>,
  // },
  {
    title: 'Banner',
    path: '/admin/banner',
    icon: <ViewCarouselIcon />,
  },
  // {
  //   title: 'Setting',
  //   path: '/admin/setting',
  //   icon: <SettingsIcon />,
  // },
  {
    title: 'Feedback',
    path: '/admin/feedback',
    icon: <FeedbackIcon />,
  },
  {
    title: 'Notifications',
    path: '/admin/notifications',
    icon: <NotificationsIcon />,
  }
];

export default navConfig;
