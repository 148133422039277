import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import validator from 'validator';
import { Typography, Box, Stack, Grid, Button, FormGroup, TextField, FormHelperText } from '@mui/material';
import './LoginPage.css'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { auth, sendPasswordResetEmail } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const initialstate = {
  email: "",
}

export default function ForgotPasswordPage() {
  const [forgetPassword, setForgetPassword] = useState<any>(initialstate)
  const [isEmailError, setIsEmailError] = useState(false);
  const [confirmEmailValidateErrorMsg, setConfirmEmailValidateErrorMsg] = useState("");
  const navigate = useNavigate();

  window.onpopstate = () => {
    navigate('/');
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setForgetPassword({ ...forgetPassword, [name]: value });
  };

  const confirmEmailValidation = (e: any) => {
    if (e.target.value) {
      if ((validator.isEmail(e.target.value))) {
        setIsEmailError(false);
        setConfirmEmailValidateErrorMsg("");
      } else {
        setIsEmailError(true);
        setConfirmEmailValidateErrorMsg("Invalid email");
      }
    } else {
      setIsEmailError(true);
      setConfirmEmailValidateErrorMsg("Enter your email");
    }
  }

  const validateForm = (e: any) => {
    e.preventDefault();
    let flag = true;
    if (!forgetPassword.email) {
      setIsEmailError(true);
      setConfirmEmailValidateErrorMsg("Enter your email");
    }
    else {
      if ((validator.isEmail(forgetPassword.email))) {
        setIsEmailError(false);
        setConfirmEmailValidateErrorMsg("");
      } else {
        setIsEmailError(true);
        setConfirmEmailValidateErrorMsg("Invalid email");
        flag = false;
      }
      return flag;
    }
  }

  const sendPasswordReset = async (e: any) => {
    e.preventDefault();
    let flag = validateForm(e)
    if (flag) {
      try {
        const res = await sendPasswordResetEmail(auth, forgetPassword.email);
        toast.success('Mail Send Successfully',
          {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
          });
        setTimeout(() => {
          navigate("/");
        }, 4000)
      } catch (err: any) {
        console.error(err);
        toast.error('Please check the Email', {
          autoClose: 6000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
        });
      }
    }
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <Helmet>
        <title> ForgotPassword | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <StyledRoot>
        <Grid container spacing={0} style={{ flexDirection: 'row', gap: '2rem' }}>
          <Box className="container-info ">
            <Typography className="centered">
              <Box display="flex" alignItems="center">
                <img src="/vyanjanLogo.png" alt="logo" width="24%" className="centered-img" />
                <Box >
                  <img src="/vyanjanTagline.png" alt="logo" width="100%" className="centered-img" />
                </Box>
              </Box>
            </Typography>
          </Box >
          <div className="container-main">
            <Box sx={{ width: '80%', margin: "auto" }} className="centered">
              <Typography style={{ fontWeight: '700', fontSize: "152%" }}>
                FORGOT PASSWORD
              </Typography>
              <Form onSubmit={onFormSubmit}>
                <FormGroup sx={{ mt: 3 }}>
                  <TextField label="Enter your Email" autoFocus name="email" value={forgetPassword.email} onChange={(e: any) => { confirmEmailValidation(e); handleInputChange(e) }} />
                  <FormHelperText style={{ color: 'red', height: '22px' }}>{isEmailError && confirmEmailValidateErrorMsg}</FormHelperText>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" >
                    <RouterLink to="/" style={{ textDecoration: "none", color: "#1565c0", fontSize: '0.865rem', marginBottom: '5%' }}>
                      Back To Login Page
                    </RouterLink>
                  </Stack>
                  <Button fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={(e) => sendPasswordReset(e)}>
                    Send mail
                  </Button>
                </FormGroup>
              </Form>
            </Box>
          </div>
        </Grid>
      </StyledRoot>
    </>
  );
}
