import {
  Card,
  Container,
  IconButton,
  Stack,
  Table,
  Button,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography,
  FormGroup,
  TextField,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Avatar,
  Breadcrumbs,
  Modal,
  Grid,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { addDoc, collection, deleteDoc, doc, getDocs, query, serverTimestamp, setDoc, Timestamp, orderBy, limit, limitToLast, startAfter, endBefore, getCountFromServer } from 'firebase/firestore';
import { db, storage } from '../firebase';
import CancelIcon from '@mui/icons-material/Cancel';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Loader from './spinner';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import AddIcon from '@mui/icons-material/Add';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import './Banner.css';
import Label from '../components/label';
import './FoodMakerPage.css';
import { format } from 'date-fns';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const stylelarge = {
  width: '386.33px',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  px: 3,
};

const stylesmall = {
  width: '300px',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  px: 3,
  zindex: 1,
};

const style1 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  px: 3,
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const initialState = {
  docId: '',
  bannerId: '',
  image: "",
  fromDate: null,
  toDate: null,
  photo: "",
  isActive: true,
}

const BannerPage = () => {
  const [page, setPage] = useState<number>(0);
  const [prevPage, setPrevPage] = useState<number>(1);
  let [limits, setLimits] = useState<number>(10);
  const [row, setRow] = useState<number>(10);
  const [detail, setDetail] = useState<any>([]); // to show data inside page
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [openChild, setOpenChild] = React.useState(false);
  const [ischeck, setIsCheck] = useState(false);
  const [startValue, setStartValue] = useState<any>('');
  const [endValue, setEndValue] = useState<any>('');
  const [userValue, setUserValue] = React.useState<any>(initialState);
  const [imageUrls, setImageUrls] = useState<any>('');
  const [isActive, setIsActive] = useState(true);
  const [isDel, setIsDel] = useState(false);
  let [result, setResult] = useState<any>('');
  const [imgSrc, setImgSrc] = useState<any>('')
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null)
  const imgRef = React.useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const scale: number = 1;
  const rotate: number = 0;
  const aspect: any = (16 / 9);
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisible, setFirstVisible] = useState<any>();
  const [prevFirstVisible, setPrevFirstVisible] = useState<any>();
  const [prevLastVisible, setPrevLastVisible] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isErrorMsg, setIsErrorMsg] = useState<any>('');
  const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

  const id = useParams();

  // it is the first method invoked when this page is open
  React.useEffect(() => {
    getData();
  }, []);

  //call intially
  const getData = async () => {
    const items: any[] = [];
    setIsLoading(true);
    try {
      const usersCollectionRef = collection(db, 'banner');
      const qLenn = query(usersCollectionRef, orderBy('displayOrder', 'asc'))
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count)
      const q = query(usersCollectionRef, orderBy('displayOrder', 'asc'), limit(limits))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisible(querySnapshot.docs[0]);
      setDetail(items);
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handlePageChange = async (event: any, newPage: any) => {
    setPrevPage(page);
    setPage(newPage);
    const items: any[] = [];
    if (page < newPage) {
      setPrevLastVisible(lastVisible);
      const q = query(collection(db, 'banner'), orderBy('displayOrder', 'asc'), startAfter(lastVisible), limit(limits))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      updateState(querySnapshot);
      // setFirstVisible(querySnapshot.docs[0]);
      setDetail(items);
    } else {
      setPrevFirstVisible(firstVisible);
      const q = query(collection(db, 'banner'), orderBy('displayOrder', 'asc'), endBefore(firstVisible), limitToLast(limits))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      updateState(querySnapshot);
      setDetail(items);
    }
  };

  // update first visible and last visible value according to pagination (next and previous button)
  const updateState = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleLimitChange = (event: { target: { value: string; }; }) => {
    setLimits(parseInt(event.target.value));
    limits = parseInt(event.target.value)
    setPage(0);
    getData();
  };

  // call when deleting data and be on the same page from which we are deleting
  const handlePagination = async () => {
    if ((page >= prevPage)) {
      let items: any[] = [];
      setDetail(items);
      const q = query(collection(db, 'banner'), orderBy('displayOrder', 'asc'), startAfter(prevLastVisible), limit(limits))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      updateState(querySnapshot);
      setDetail(items);
    } else {
      let items: any[] = [];
      setDetail(items);
      const q = query(collection(db, 'banner'), orderBy('displayOrder', 'asc'), endBefore(prevFirstVisible), limitToLast(limits))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      updateState(querySnapshot);
      setDetail(items);
    }
  }

  // add banner
  const handleClickisAdd = async (e: any) => {
    setUserValue(initialState);
    setResult('');
    setIsOpen(true);
  };

  // edit banner
  const handleClickisEdit = (st: string, img: string, from: any, to: any) => {
    setImageUrls('');
    let obj = {
      bannerId: st,
      image: img,
      fromDate: from,
      toDate: to,
    }
    if (img) {
      setResult(img);
    }
    else {
      setResult('');
    }
    setUserValue(obj);
    setIsOpen(true);
  };

  // close dialog
  const handleCloseUsersDialog = () => {
    setIsError(false);
    setIsOpen(false);
  };

  // image validation
  const validateImage = (e: any) => {
    if (e.target.value) {
      setIsError(false);
      setIsErrorMsg("");
    } else {
      setIsError(true);
      // setIsErrorMsg("Please fill the data");
      setIsErrorMsg("Image is Required");
      userValue.image = ''
    }
  }

  // form validation
  const validateForm = (e: any) => {
    e.preventDefault();
    let flag = true;
    // if (!(startValue && endValue)) {
    if (!((imgSrc && imageUrls) || userValue.image)) {
      setIsError(true);
      // setIsErrorMsg("Please fill the data");
      setIsErrorMsg("Image is Required");
      flag = false;
    } else {
      setIsError(false);
      setIsErrorMsg("");
    }
    return flag;
  }

  // call when click on save
  const save = async (e: any) => {
    let flag = validateForm(e);
    if (flag) {
      if (userValue.bannerId) {
        let obj: any
        if ((endValue)) {
          obj = {
            toDate: Timestamp.fromDate(userValue.toDate),
            image: imageUrls ? imageUrls : userValue.image,
            isActive: isActive,
          }
        } else if ((startValue)) {
          obj = {
            fromDate: Timestamp.fromDate(userValue.fromDate),
            image: imageUrls ? imageUrls : userValue.image,
            isActive: isActive,
          }
        }
        else if ((imgSrc && imageUrls)) {
          obj = {
            image: imageUrls ? imageUrls : userValue.image,
            isActive: isActive,
          }
        }
        else {
          obj = {
            fromDate: Timestamp.fromDate(userValue.fromDate),
            toDate: Timestamp.fromDate(userValue.toDate),
            image: imageUrls ? imageUrls : userValue.image,
            isActive: isActive,
          }
        }
        const datasRef = doc(db, 'banner', userValue.bannerId);
        await setDoc(datasRef, obj, { merge: true });
        let data = JSON.parse(JSON.stringify(detail));
        let ind = data.findIndex((c: any) => c.id === userValue.bannerId);
        if (ind > -1) {
          data[ind].fromDate = obj.fromDate ? obj.fromDate : data[ind].fromDate;
          data[ind].toDate = obj.toDate ? obj.toDate : data[ind].toDate;
          data[ind].image = obj.image
          data[ind].isActive = obj.isActive
        }
        setDetail(data);
        setIsOpen(false);
        setStartValue('');
        setEndValue('');
        getData();
      }
      else {
        let nextOrder = 1;
        let detailData = detail;
        detailData.sort((a: any, b: any) => (a.displayOrder > b.displayOrder) ? -1 : 1);
        nextOrder = detailData && detailData.length > 0 && (detailData[0].displayOrder + 1);
        setIsActive(true);
        await addDoc(collection(db, "banner"), {
          fromDate: startValue,
          toDate: endValue,
          image: imageUrls,
          isActive: isActive,
          createdDate: serverTimestamp(),
          displayOrder: nextOrder,
        });
        setImageUrls('');
        setStartValue('');
        setEndValue('');
        getData();
        setIsOpen(false);
      }
    }
  }

  // upload image in firestore
  const uploadImage = (e: any) => {
    const ids = v4();
    if (result === null) return;
    let data = result.includes(",") ? result.split(",")[1] : result;
    const blob = b64toBlob(data, 'image/png');
    const imageRef = ref(storage, `images/${'image_' + ids}`);
    uploadBytes(imageRef, blob).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls(url);
      });
    })
  }

  // select start date
  const setStartDateValue = (specialStartDate: any) => {
    setStartValue(specialStartDate);
    if (userValue.bannerId) {
      let val = format(new Date(specialStartDate), 'M/dd/yyyy')
      if (val <= format(new Date(userValue.toDate), 'M/dd/yyyy')) {
        setUserValue({ ...userValue, "fromDate": specialStartDate });
        userValue.toDate = val;
      }
    } else {
      setUserValue({ ...userValue, "fromDate": specialStartDate });
      userValue.fromDate = specialStartDate;
    }
  }

  // select end date
  const setEndDateValue = (specialEndDate: any) => {
    setEndValue(specialEndDate);
    let val = specialEndDate
    if (userValue.bannerId) {
      let val = format(new Date(specialEndDate), 'M/dd/yyyy')
      if (val >= format(new Date(userValue.fromDate), 'M/dd/yyyy')) {
        setUserValue({ ...userValue, "toDate": specialEndDate });
        userValue.toDate = val;
      }
    } else {
      if (val >= startValue) {
        setUserValue({ ...userValue, "toDate": val });
        userValue.toDate = val;
      }
    }
  }

  // switch change
  const handleSwitch = async (id: number, status: boolean) => {
    if (status === true) {
      let obj = {
        docId: id,
        isActive: false,
      }
      setUserValue(obj);
      setIsCheck(true);
    }
    else {
      let obj = {
        docId: id,
        isActive: true,
      }
      setUserValue(obj);
      setIsCheck(true);
    }
  };

  // save switch changes
  const handleCheckSwitch = async () => {
    let obj = {
      isActive: userValue.isActive,
    }
    const datasRef = doc(db, 'banner', userValue.docId);
    await setDoc(datasRef, obj, { merge: true });
    let data = JSON.parse(JSON.stringify(detail));
    let ind = data.findIndex((c: any) => c.id === userValue.docId);
    if (ind > -1) {
      data[ind].isActive = !data[ind].isActive;
    }
    setDetail(data);
    setIsCheck(false);
  };

  // close switch dialog
  const handleCloseSwitch = () => {
    setIsCheck(false);
  }

  // delete image
  const handleClickDel = async (id: any, image: any) => {
    let obj = {
      docId: id,
      image: image,
    }
    setUserValue(obj);
    setIsDel(true)
  }

  // delete image from firestore
  const handleDeleteProduct = async () => {
    const bannerRef = collection(db, 'banner');
    try {
      const deleteRef = doc(bannerRef, userValue.docId);
      const deleteStorageFile = ref(storage, userValue.image);
      deleteObject(deleteStorageFile).then(() => {
      }).catch((error: any) => {
      });
      deleteDoc(deleteRef);
      if (page === 0) {
        await getData();
      }
      else {
        await handlePagination()
      }
    }
    catch (error) {
      console.error(error);
    }
    setIsDel(false)
  }

  // delete dialog close
  const handleCancel = () => {
    setIsDel(false);
  }

  // select image
  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
    setOpenChild(true);

  }

  // when image crop
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  // convert image
  const b64toBlob = (b64Data: any, contentType: any, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // get image crop result
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
        const base64 = previewCanvasRef.current.toDataURL();
        setResult(base64);
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  // cropped image
  const croppedImage = (e: any) => {
    setCompletedCrop(e);
  }

  // open child dialog
  const handleOpenChild = () => {
    setImgSrc('');
  };

  // close child dialog
  const handleCloseChild = (e: any) => {
    setOpenChild(false);
  };

  // when image drag
  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) return;
    let items: any = [];
    items = Array.from(detail);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDetail(items);
    let order = 1;
    for (let i = 0; i < items.length; i++) {
      let obj = {
        'displayOrder': order + i,
      }
      const datasRef = doc(db, 'banner', items[i]?.id);
      await setDoc(datasRef, obj, { merge: true });
    }
  }

  return (
    <>
      <Helmet>
        <title> Banner | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt="-7px" >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit">Banner</Typography>
              </Breadcrumbs>
              <div className="largeScreenContent">
                <Button
                  sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                  variant="contained"
                  onClick={handleClickisAdd}
                  startIcon={<Iconify icon="eva:plus-fill" />}>
                  New Banner
                </Button>
              </div>
              <div className="smallScreenContent">
                <Button
                  sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                  variant="contained"
                  onClick={handleClickisAdd}
                >
                  <AddIcon />
                </Button>
              </div>
            </Stack>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', mt: "-7px" }}>
              <div>
                {isLoading ? <Loader title="Loading..." /> :
                  <>
                    <Scrollbar>
                      <TableContainer className="banner-list" sx={{ bgcolor: 'white' }}>
                        <Table>
                          <div>
                            <DragDropContext onDragEnd={handleOnDragEnd} >
                              <Droppable droppableId='character' direction='horizontal'>
                                {(provided: any) => (
                                  <Grid container className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                    {detail.map((arr: any, index: number) => (
                                      <Draggable key={arr.id} index={index} draggableId={arr.id}>
                                        {(provided: any) => (
                                          <Grid item xs={12} sm={6} md={4} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', m: 3, bgcolor: '#F9FAFB' }}>
                                              {
                                                (new Date(arr.toDate.seconds * 1000).getTime()) <= (new Date().getTime())
                                                  ?
                                                  <Label
                                                    variant="filled"
                                                    sx={{
                                                      zIndex: 9,
                                                      top: 16,
                                                      right: 16,
                                                      position: 'absolute',
                                                      textTransform: 'uppercase',
                                                      bgcolor: 'red',
                                                      color: 'white',
                                                    }}
                                                  >
                                                    Expired
                                                  </Label>
                                                  :
                                                  <Label
                                                    variant="filled"
                                                    sx={{
                                                      zIndex: 9,
                                                      top: 16,
                                                      right: 16,
                                                      position: 'absolute',
                                                      textTransform: 'uppercase',
                                                      bgcolor: 'blue',
                                                      color: 'white',
                                                    }}
                                                  >
                                                    Active
                                                  </Label>
                                              }
                                              {arr.image ?
                                                <Avatar
                                                  src={arr.image}
                                                  variant="square"
                                                  style={{ width: "100%", height: "100%" }}
                                                /> :
                                                <Avatar
                                                  src="/defaultFood.png"
                                                  variant="square"
                                                  style={{ width: "100%", height: "100%" }}
                                                />
                                              }
                                              <Box sx={{
                                                zIndex: 9,
                                                bottom: 65,
                                                right: 0,
                                                left: 0,
                                                position: 'absolute',
                                                bgcolor: 'white',
                                                opacity: '0.3',
                                                display: 'flex',
                                                justifyContent: 'right',
                                                fontSize: 20,
                                              }}>
                                                <p></p>
                                              </Box>
                                              <Tooltip title={(arr.isActive === false) ? "Inactive" : "Active"} arrow>
                                                <Switch
                                                  checked={arr.isActive}
                                                  onClick={(e) => handleSwitch(arr.id, arr.isActive)}
                                                  inputProps={{ 'aria-label': 'controlled' }}
                                                  sx={{
                                                    zIndex: 9,
                                                    bottom: 61,
                                                    right: 4,
                                                    position: 'absolute',
                                                  }}
                                                />
                                              </Tooltip>
                                              <Grid container spacing={0} direction="row" display='flex' justifyContent='space-between'>
                                                {arr.fromDate && arr.toDate ?
                                                  <Typography gutterBottom sx={{ ml: 1, mt: -1 }}>
                                                    <h5>{format(new Date(arr.fromDate.seconds * 1000), formatedDate)}<span style={{ margin: '0 5px' }}>To</span>{format(new Date(arr.toDate.seconds * 1000), formatedDate)}</h5>
                                                  </Typography>
                                                  :
                                                  <Typography gutterBottom sx={{ ml: 1, mt: -1 }}> <h5><Box>Default Banner</Box></h5></Typography>
                                                }
                                                <Box>
                                                  <Tooltip title="Edit " arrow>
                                                    <IconButton
                                                      sx={{ color: "#1565c0", marginTop: '5px' }}
                                                      onClick={(e) => handleClickisEdit(arr.id, arr.image, (new Date(arr.fromDate.seconds * 1000).toLocaleDateString("en-US")), (new Date(arr.toDate.seconds * 1000).toLocaleDateString("en-US")))}
                                                    >
                                                      <EditTwoToneIcon fontSize="small" />
                                                    </IconButton>
                                                  </Tooltip>
                                                  <Tooltip title="Delete " arrow>
                                                    <IconButton
                                                      sx={{ color: "#1565c0", marginTop: '5px' }}
                                                      onClick={(e) => handleClickDel(arr.id, arr.image)}
                                                    >
                                                      <DeleteForeverIcon fontSize="small" />
                                                    </IconButton>
                                                  </Tooltip>
                                                </Box>
                                              </Grid>
                                              <Grid container spacing={0} direction="row" display='flex' justifyContent='space-between'>
                                              </Grid>
                                            </Card>
                                          </Grid>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </Grid>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <TablePagination
                      component="div"
                      count={row}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleLimitChange}
                      page={page}
                      rowsPerPage={limits}
                      rowsPerPageOptions={[10, 20, 30, 40]}
                      sx={{ pt: 1, pb: 1 }}
                    />
                  </>
                }
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container >

      <div>
        <Dialog open={ischeck} onClose={handleCloseSwitch}>
          <DialogTitle>{(userValue.isActive === true) ? 'Active' : 'InActive'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {(userValue.isActive === true) ? 'Are you sure you want to Active?' : 'Are you sure you want to InActive?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSwitch}>Cancel</Button>
            <Button onClick={handleCheckSwitch}>Yes</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog open={isDel} onClose={handleCancel}>
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleDeleteProduct}>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className='dialogsmallScreenContent'>
        <React.Fragment>
          <Modal
            open={isOpen}
            onClose={handleCloseUsersDialog}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className='dialogsmallScreenContent'
          >
            <Box sx={{ ...stylesmall, }} className='dialogsmallScreenContent'>
              <h3 id="parent-modal-title"> {userValue.bannerId ? "Edit Banner" : "Add Banner"}
                <IconButton
                  sx={{
                    fontSize: "2px", color: "grey", position: 'absolute',
                    right: 3,
                    top: 12,
                  }}
                  onClick={handleCloseUsersDialog}
                >
                  <CancelIcon />
                </IconButton>
              </h3>
              <p id="parent-modal-description">
                <FormGroup>
                  <div className="App">
                    <div className="Crop-Controls">
                      <div>
                        {result ?
                          <>
                            <img src={result}
                              style={{
                                width: '338.33px',
                                height: '189.88px',
                                borderRadius: '1px solid black',
                              }}
                              alt="FoodImage"
                            />
                          </>
                          :
                          <img src="/defaultFood.png"
                            style={{
                              width: '338.33px',
                              height: '189.88px',
                              borderRadius: '1px solid black',
                            }}
                            alt="FoodImage"
                          />
                        }
                      </div>
                      <input
                        style={{
                          display: 'none',
                          position: 'fixed',
                          bottom: '5%',
                          paddingTop: '49px',
                          marginLeft: '-26px',
                        }}
                        id="icon-button-file"
                        accept="image/*"
                        type="file"
                        name="image"
                        onChange={(e) => { onSelectFile(e); validateImage(e) }}
                        className="upload-button" />
                      <label htmlFor="icon-button-file">
                        <IconButton component="span" color="primary" style={{ top: "-20px", right: "-230px " }}
                          onClick={handleOpenChild}
                        >
                          <UploadTwoToneIcon />
                        </IconButton>
                      </label>
                      {result &&
                        <IconButton component="span" color="primary" style={{ top: "-206px", right: "-187px " }} onClick={(e: any) => { setResult(''); validateImage(e) }}>
                          <CancelIcon />
                        </IconButton>
                      }
                    </div>
                  </div>
                  {(userValue.fromDate === 'Invalid Date' && userValue.toDate === 'Invalid Date') ?
                    <FormHelperText style={{ color: 'red', height: '1px' }}>{isError && isErrorMsg}</FormHelperText>
                    :
                    <>
                      <FormGroup >
                        <FormControl sx={{ mb: 2 }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DatePicker
                              label="Start Date"
                              openTo="day"
                              views={['year', 'month', 'day']}
                              value={userValue.fromDate}
                              onChange={(newValue: any) => {
                                setStartDateValue(newValue)
                              }}
                              maxDate={userValue.toDate}
                              renderInput={(params) => <TextField  {...params} />}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="End Date"
                              openTo="day"
                              views={['year', 'month', 'day']}
                              value={userValue.toDate}
                              onChange={(newValue: any) => {
                                setEndDateValue(newValue)
                              }}
                              minDate={userValue.fromDate}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormHelperText style={{ color: 'red', height: '1px' }}>{isError && isErrorMsg}</FormHelperText>
                      </FormGroup>
                    </>
                  }
                </FormGroup>
              </p>
              <React.Fragment>
                <Modal
                  hideBackdrop
                  open={openChild}
                  onClose={handleCloseChild}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box sx={{ ...style1 }} >
                    <h3 id="child-modal-title">Cropped Image
                      <IconButton
                        sx={{
                          fontSize: "2px", color: "grey", position: 'absolute',
                          right: 3,
                          top: 12,
                        }}
                        onClick={handleCloseChild}
                      >
                        <CancelIcon />
                      </IconButton></h3>
                    <p id="child-modal-description">
                      {!!imgSrc && (
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => croppedImage(c)}
                          aspect={aspect}
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, height: '300px' }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                      )}
                      <div style={{ display: 'none' }}>
                        {Boolean(completedCrop) && (
                          <div>
                            <canvas
                              ref={previewCanvasRef}
                              style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop && completedCrop.width,
                                height: completedCrop && completedCrop.height,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </p>
                    <DialogActions >
                      <Button onClick={(e) => { uploadImage(e); handleCloseChild(e) }}>Upload and Close</Button>
                    </DialogActions>
                  </Box>
                </Modal>
              </React.Fragment>
              <DialogActions >
                <Button onClick={(e) => { save(e) }} >Save</Button>
              </DialogActions>
            </Box >
          </Modal >
        </React.Fragment >
      </div>

      <div className='dialoglargeScreenContent'>
        <React.Fragment>
          <Modal
            open={isOpen}
            onClose={handleCloseUsersDialog}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className='dialoglargeScreenContent'
          >
            <Box sx={{ ...stylelarge, }} className='dialoglargeScreenContent'>
              <h3 id="parent-modal-title"> {userValue.bannerId ? "Edit Banner" : "Add Banner"}
                <IconButton
                  sx={{
                    fontSize: "2px", color: "grey", position: 'absolute',
                    right: 3,
                    top: 12,
                  }}
                  onClick={handleCloseUsersDialog}
                >
                  <CancelIcon />
                </IconButton>
              </h3>
              <p id="parent-modal-description">
                <FormGroup>
                  <div className="App">
                    <div className="Crop-Controls">
                      <div>
                        {result ?
                          <>
                            <img src={result}
                              style={{
                                width: '338.33px',
                                height: '189.88px',
                                borderRadius: '1px solid black',
                              }}
                              alt="FoodImage"
                            />
                          </>
                          :
                          <img src="/defaultFood.png"
                            style={{
                              width: '338.33px',
                              height: '189.88px',
                              borderRadius: '1px solid black',
                            }}
                            alt="FoodImage"
                          />
                        }
                      </div>
                      <input
                        style={{
                          display: 'none',
                          position: 'fixed',
                          bottom: '5%',
                          paddingTop: '49px',
                          marginLeft: '-26px',
                        }}
                        id="icon-button-file"
                        accept="image/*"
                        type="file"
                        name="image"
                        onChange={(e) => { onSelectFile(e); validateImage(e) }}
                        className="upload-button" />
                      <label htmlFor="icon-button-file">
                        <IconButton component="span" color="primary" style={{ top: "-20px", right: "-311px " }}
                          onClick={handleOpenChild}
                        >
                          <UploadTwoToneIcon />
                        </IconButton>
                      </label>
                      {result &&
                        <IconButton component="span" color="primary" style={{ top: "-206px", right: "-270px " }} onClick={(e: any) => { setResult(''); validateImage(e) }}>
                          <CancelIcon />
                        </IconButton>
                      }
                    </div>
                  </div>
                  {(userValue.fromDate === 'Invalid Date' && userValue.toDate === 'Invalid Date') ?
                    <FormHelperText style={{ color: 'red', height: '1px' }}>{isError && isErrorMsg}</FormHelperText>
                    :
                    <>
                      <FormGroup >
                        <FormControl sx={{ mb: 2 }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DatePicker
                              label="Start Date"
                              openTo="day"
                              views={['year', 'month', 'day']}
                              value={userValue.fromDate}
                              onChange={(newValue: any) => {
                                setStartDateValue(newValue)
                              }}
                              maxDate={userValue.toDate}
                              renderInput={(params) => <TextField  {...params} />}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormControl >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label="End Date"
                              openTo="day"
                              views={['year', 'month', 'day']}
                              value={userValue.toDate}
                              onChange={(newValue: any) => {
                                setEndDateValue(newValue)
                              }}
                              minDate={userValue.fromDate}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <FormHelperText style={{ color: 'red', height: '1px' }}>{isError && isErrorMsg}</FormHelperText>
                      </FormGroup>
                    </>
                  }
                </FormGroup>
              </p>
              <React.Fragment>
                <Modal
                  hideBackdrop
                  open={openChild}
                  onClose={handleCloseChild}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box sx={{ ...style1 }}>
                    <h3 id="child-modal-title">Cropped Image
                      <IconButton
                        sx={{
                          fontSize: "2px", color: "grey", position: 'absolute',
                          right: 3,
                          top: 12,
                        }}
                        onClick={handleCloseChild}
                      >
                        <CancelIcon />
                      </IconButton></h3>
                    <p id="child-modal-description">
                      {!!imgSrc && (
                        <ReactCrop
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => croppedImage(c)}
                          aspect={aspect}
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, height: '300px' }}
                            onLoad={onImageLoad}
                          />
                        </ReactCrop>
                      )}
                      <div style={{ display: 'none' }}>
                        {Boolean(completedCrop) && (
                          <div>
                            <canvas
                              ref={previewCanvasRef}
                              style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop && completedCrop.width,
                                height: completedCrop && completedCrop.height,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </p>
                    <DialogActions >
                      <Button onClick={(e) => { uploadImage(e); handleCloseChild(e) }}>Upload and Close</Button>
                    </DialogActions>
                  </Box>
                </Modal>
              </React.Fragment>
              <DialogActions >
                {!userValue.bannerId ?
                  <FormHelperText style={{ color: 'grey', height: '17px', paddingRight: '7px' }}>Note: To add default banner keep date empty</FormHelperText>
                  : ''}
                <Button onClick={(e) => { save(e) }} >Save</Button>
              </DialogActions>
            </Box >
          </Modal >
        </React.Fragment >
      </div>

    </>
  );
}

export default BannerPage;