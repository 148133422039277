import {
    Avatar,
    Card,
    CardContent,
    Container,
    IconButton,
    Rating,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography, Box, Grid, Tab, Breadcrumbs, Paper
} from '@mui/material';
import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { useNavigate, useParams, Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { collection, getCountFromServer, getDocs, orderBy, startAfter, query, where, limit, endBefore, limitToLast } from 'firebase/firestore';
import { db } from '../firebase';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Loader from './spinner'
import Label from '../components/label';
import { format } from 'date-fns';

const FoodSeekerDetails = () => {
    const [page, setPage] = useState<number>(0);
    let [limits, setLimits] = useState<number>(10);
    const [row, setRow] = useState<number>(10);
    const [pageReview, setPageReview] = useState<number>(0);
    let [limitReview, setLimitReview] = useState<number>(10);
    const [rowReview, setRowReview] = useState<number>(10);
    const [pageFav, setPageFav] = useState<number>(0);
    let [limitFav, setLimitFav] = useState<number>(10);
    const [rowFav, setRowFav] = useState<number>(10);
    const [pageFoodReview, setPageFoodReview] = useState<number>(0);
    let [limitFoodReview, setLimitFoodReview] = useState<number>(10);
    const [rowFoodReview, setRowFoodReview] = useState<number>(10);
    const [pageCart, setPageCart] = useState<number>(0);
    let [limitCart, setLimitCart] = useState<number>(10);
    const [rowCart, setRowCart] = useState<number>(10);
    const [pageReference, setPageReference] = useState<number>(0);
    let [limitReference, setLimitReference] = useState<number>(10);
    const [rowReference, setRowReference] = useState<number>(10);
    const [pageBulkOrder, setPageBulkOrder] = useState<number>(0);
    let [limitBulkOrder, setLimitBulkOrder] = useState<number>(10);
    const [rowBulkOrder, setRowBulkOrder] = useState<number>(10);
    const [pageWallet, setPageWallet] = useState<number>(0);
    let [limitWallet, setLimitWallet] = useState<number>(10);
    const [rowWallet, setRowWallet] = useState<number>(10);
    const [pageFavMaker, setPageFavMaker] = useState<number>(0);
    let [limitFavMaker, setLimitFavMaker] = useState<number>(10);
    const [rowFavMaker, setRowFavMaker] = useState<number>(10);
    const [value, setValue] = React.useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const [orderSend, setOrderSend] = useState<any>([]);
    const [profile, setProfile] = useState<any>([]);
    const [addresses, setAddresses] = useState<any>([]);
    const [cart, setCart] = useState<any>([]);
    const [favorite, setFavorite] = useState<any>([]);
    const [reviews, setReviews] = useState<any>([]);
    const [foodReviews, setFoodReviews] = useState<any>([])
    const [reference, setReference] = useState<any>([]);
    const [bulkOrder, setBulkOrder] = useState<any>([]);
    const [wallet, setWallet] = useState<any>([])
    const [userFirstName, setUserFirstName] = useState<any>([]);
    const [userLastName, setUserLastName] = useState<any>([]);
    const [totalAmount, setTotalAmount] = useState<any>([])
    const [favMaker, setFavMaker] = useState<any>([])
    const [lastVisible, setLastVisible] = useState<any>();
    const [firstVisible, setFirstVisible] = useState<any>();
    const [lastVisibleReview, setLastVisibleReview] = useState<any>();
    const [firstVisibleReview, setFirstVisibleReview] = useState<any>();
    const [lastVisibleReference, setLastVisibleReference] = useState<any>();
    const [firstVisibleReference, setFirstVisibleReference] = useState<any>();
    const [lastVisibleFav, setLastVisibleFav] = useState<any>();
    const [firstVisibleFav, setFirstVisibleFav] = useState<any>();
    const [lastVisibleFavMaker, setLastVisibleFavMaker] = useState<any>();
    const [firstVisibleFavMaker, setFirstVisibleFavMaker] = useState<any>();
    const [lastVisibleCart, setLastVisibleCart] = useState<any>();
    const [firstVisibleCart, setFirstVisibleCart] = useState<any>();
    const [lastVisibleBulkOrder, setLastVisibleBulkOrder] = useState<any>();
    const [firstVisibleBulkOrder, setFirstVisibleBulkOrder] = useState<any>();
    const [lastVisibleWallet, setLastVisibleWallet] = useState<any>();
    const [firstVisibleWallet, setFirstVisibleWallet] = useState<any>();
    const [lastVisibleFoodReview, setLastVisibleFoodReview] = useState<any>();
    const [firstVisibleFoodReview, setFirstVisibleFoodReview] = useState<any>();
    const [flagProduct, setflagProduct] = useState<boolean>(true);
    const [flagOrderSend, setflagOrderSend] = useState<boolean>(true);
    const [flagBulkOrder, setflagBulkOrder] = useState<boolean>(true);
    const [flagCart, setflagCart] = useState<boolean>(true);
    const [flagfavMaker, setflagfavMaker] = useState<boolean>(true);
    const [flagfavProduct, setflagfavProduct] = useState<boolean>(true);
    const [flagWallet, setflagWallet] = useState<boolean>(true);
    const [flagReference, setflagReference] = useState<boolean>(true);
    const [flagReview, setflagReview] = useState<boolean>(true);
    const [flagProductReview, setflagProductReview] = useState<boolean>(true);
    const navigate = useNavigate();
    const id = useParams();
    const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');

    //if click on view set this item in sessionStorage which used in detail page
    const handleClickVisible = (element: any) => {
        let id = element?.id;
        sessionStorage.setItem("orderNo", element?.orderNo + "");
        navigate(`/admin/orders/view/${id}`);
    }

    // it is the first method invoked when this page is open
    React.useEffect(() => {
        getData();
        let firstName = sessionStorage.getItem('FirstName');
        let lastName = sessionStorage.getItem('LastName');
        if (firstName && lastName) {
            setUserFirstName(firstName)
            setUserLastName(lastName)
        }
    }, []);

    //call intially
    const getData = async () => {
        let items: any[] = [];
        try {
            const usersCollectionRef = collection(db, 'users');
            const q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', id.uid))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items.push(doc.data());
            });
            let onlyAddress: any[] = [];
            onlyAddress = items.map((arr: any) => (arr.address))
            setAddresses(onlyAddress);
            setProfile(items);
            if (items.length > 0) {
                setflagProduct(true);
            } else {
                setflagProduct(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    //call when tab changes
    const handleChange = async (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === '2') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const orderCollectionRef = collection(db, 'userOrders');
                const qLenn = query(orderCollectionRef, where('seekerId', '==', id.uid), where('isBulkOrder', '==', 0), orderBy('createdDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRow(querySnapshotLen.data().count)
                const q = query(orderCollectionRef, where('seekerId', '==', id.uid), where('isBulkOrder', '==', 0), orderBy('createdDate', 'desc'), limit(limits))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc: any) => {
                    items.push(doc.data());
                })
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisible(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.makerId) {
                        const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', obj.makerId))
                        const _querySnapshot = await getDocs(q);
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().firstName;
                            obj.lastName = _doc.data().lastName;
                        })
                        if (i === items.length) {
                            setOrderSend(items);
                        } else {
                            i++;
                        }
                    }
                })
            } catch (error) {
                console.log(error)
            }
            if (items.length > 0) {
                setflagOrderSend(true);
            } else {
                setflagOrderSend(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '3') {
            setIsLoading(true)
            let items: any[] = []
            try {
                const orderCollectionRef = collection(db, 'userOrders');
                const qLenn = query(orderCollectionRef, where('seekerId', '==', id.uid), where('isBulkOrder', '==', 1)
                    , orderBy('createdDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRowBulkOrder(querySnapshotLen.data().count)
                const q = query(orderCollectionRef, where('seekerId', '==', id.uid), where('isBulkOrder', '==', 1)
                    , orderBy('createdDate', 'desc'), limit(limitBulkOrder))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc: any) => {
                    items.push(doc.data());
                })
                setLastVisibleBulkOrder(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleBulkOrder(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.makerId) {
                        const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', obj.makerId))
                        const _querySnapshot = await getDocs(q);
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().firstName;
                            obj.lastName = _doc.data().lastName;
                        })
                        if (i === items.length) {
                            setBulkOrder(items);
                        } else {
                            i++;
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            }
            if (items.length > 0) {
                setflagBulkOrder(true);
            } else {
                setflagBulkOrder(false);
            }
            setIsLoading(false)
        }
        else if (newValue === '4') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const cartCollectionRef = collection(db, 'userCarts');
                const userCollectionRef = collection(db, 'users');
                const qlenn = query(cartCollectionRef, where('uId', '==', id.uid), orderBy('createdDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qlenn);
                setRowCart(querySnapshotLen.data().count)
                const q = query(cartCollectionRef, where('uId', '==', id.uid), orderBy('createdDate', 'desc'), limit(limitCart))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc) => {
                    let itm = doc.data();
                    let obj = {
                        Id: doc.data().makerId
                    }
                    if (obj.Id) {
                        const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', obj.Id))
                        const _querySnapshot = await getDocs(q)
                        _querySnapshot.forEach((_doc) => {
                            itm.firstName = _doc.data().firstName;
                            itm.lastName = _doc.data().lastName;
                        })
                        items.push(itm);
                        if (items.length > 0) {
                            setflagCart(true);
                            setCart(items)
                        } else {
                            setflagCart(false);
                        }
                        setLastVisibleCart(querySnapshot.docs[querySnapshot.docs.length - 1]);
                        setFirstVisibleCart(querySnapshot.docs[0]);
                    }
                })
                if (querySnapshotLen.data().count > 0) {
                    setflagCart(true);
                } else {
                    setflagCart(false);
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            }

            setIsLoading(false);
        }
        else if (newValue === '5') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const usersCollectionRef = collection(db, 'users');
                const q = query(usersCollectionRef, where('uid', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'), limit(limitFavMaker))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items = doc.data().favoritesMakers;
                })
                setLastVisibleFavMaker(querySnapshot.docs[querySnapshot.docs.length - 1])
                setFirstVisibleFavMaker(querySnapshot.docs[0])
                if (items.length > 0) {
                    setflagfavMaker(true);
                    let i = 1;
                    items.forEach(async (obj: any) => {
                        if (obj.MakerId) {
                            const q = query(usersCollectionRef, where('isVerified', '==', true), where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', obj.MakerId))
                            const _querySnapshot = await getDocs(q);
                            _querySnapshot.forEach((_doc) => {
                                obj.name = _doc.data().displayName
                                obj.contactNo = _doc.data().contactNo
                                obj.gender = _doc.data().gender
                            })
                            if (obj.name) {
                                if (i === items.length) {
                                    setFavMaker(items);
                                    setRowFavMaker(items.length);
                                } else {
                                    i++;
                                }
                            } else {
                                setflagfavMaker(false)
                            }
                        }
                    })
                } else {
                    setflagfavMaker(false);
                }
            } catch (error) {
                console.log(error);
                setIsLoading(false)
            }
            setIsLoading(false)
        }
        else if (newValue === '6') {
            let items: any[] = [];
            setIsLoading(true)
            try {
                const usersCollectionRef = collection(db, 'users');
                const makerCollectionRef = (collection(db, 'makerProduct'))
                const q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker')
                    , where('uid', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'), limit(limitFav))
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc: any) => {
                    items = doc.data().favoriteProducts;
                });
                setLastVisibleFav(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleFav(querySnapshot.docs[0]);
                if (items.length > 0) {
                    setflagfavProduct(true);
                    let i = 1;
                    items.forEach(async (obj: any) => {
                        if (obj.pId) {
                            const q = query(makerCollectionRef, where('pId', '==', obj.pId))
                            const _querySnapshot = await getDocs(q)
                            _querySnapshot.forEach(async (_doc) => {
                                obj.name = _doc.data().name
                                obj.productImage = _doc.data().productImage
                                let obj1 = {
                                    makerId: _doc.data().makerId
                                }
                                if (obj1.makerId) {
                                    const q = query(usersCollectionRef, where('uid', '==', obj1.makerId),where('isDelete', '==', 0))
                                    const _querySnapshot1 = await getDocs(q);
                                    _querySnapshot1.forEach((_doc1) => {
                                        obj.makerName = _doc1.data().firstName
                                        obj.makerLastName = _doc1.data().lastName
                                    })
                                    if (i === items.length) {
                                        setFavorite(items)
                                        setRowFav(items.length)
                                    } else {
                                        i++;
                                    }
                                }
                            })
                        }
                    })
                } else {
                    setflagfavProduct(false);
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            }
            setIsLoading(false)
        }
        else if (newValue === '7') {
            setIsLoading(true);
            const items: any[] = [];
            let data: any[] = [];
            try {
                const Walletref = collection(db, 'userWalletHistory')
                const qlenn = query(Walletref, where('makerId', '==', id.uid), orderBy('modifiedDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qlenn);
                setRowWallet(querySnapshotLen.data().count)
                const q = query(Walletref, where('makerId', '==', id.uid), orderBy('modifiedDate', 'desc'), limit(limitWallet))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    items.push(doc.data());
                })
                setLastVisibleWallet(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleWallet(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.makerId) {
                        let walletAmount: number = 0;
                        const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'), where('isDelete', '==', 0),where('uid', '==', obj.makerId))
                        const _querySnapshot = await getDocs(q);
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().displayName;
                            walletAmount= _doc.data().walletAmount;
                        })
                        if (i === items.length) {
                            setWallet(items);
                            // data = items.reduce((a: any, v: any) => a = a + v.amount, 0)
                             setTotalAmount(walletAmount.toFixed(2))
                        } else {
                            i++;
                        }
                    }
                })
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            } if (items.length > 0) {
                setflagWallet(true);
            } else {
                setflagWallet(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '8') {
            const items: any[] = [];
            setIsLoading(true);
            try {
                const usersCollectionRef = collection(db, 'users');
                const qlenn = query(usersCollectionRef, where('referedUserId', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qlenn);
                setRowReference(querySnapshotLen.data().count)
                const q = query(usersCollectionRef, where('referedUserId', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'), limit(limitReference))
                const querySnapshot = await getDocs(q)
                querySnapshot.forEach((doc: any) => {
                    items.push({ id: doc.id, ...doc.data() });
                });
                setLastVisibleReference(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleReference(querySnapshot.docs[0]);
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setReference(items);
                setflagReference(true);
            } else {
                setflagReference(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '9') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const reviewCollectionRef = collection(db, 'userReviews');
                const userCollectionRef = collection(db, 'users');
                const qLenn = query(reviewCollectionRef, where('seekerId', '==', id.uid), where('productId', '==', null), orderBy('reviewDate', 'desc'))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRowReview(querySnapshotLen.data().count)
                const q = query(reviewCollectionRef, where('seekerId', '==', id.uid), where('productId', '==', null), orderBy('reviewDate', 'desc'), limit(limitReview))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc) => {
                    items.push(doc.data())
                })
                setLastVisibleReview(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleReview(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.makerId) {
                        const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', obj.makerId))
                        const _querySnapshot = await getDocs(q)
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().firstName;
                            obj.lastName = _doc.data().lastName;
                            obj.profile = _doc.data().profilePicUrl;
                        })
                        if (i === items.length) {
                            setReviews(items);
                        } else {
                            i++;
                        }
                    }
                })
                setLastVisibleReview(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleReview(querySnapshot.docs[0]);
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setflagReview(true);
            } else {
                setflagReview(false);
            }
            setIsLoading(false);
        }
        else if (newValue === '10') {
            let items: any[] = [];
            setIsLoading(true);
            try {
                const reviewCollectionRef = collection(db, 'userReviews');
                const userCollectionRef = collection(db, 'users');
                const productCollectionRef = collection(db, 'makerProduct');
                const qLenn = query(reviewCollectionRef, where('seekerId', 'in', [id.uid]), where('productId', '!=', null))
                const querySnapshotLen = await getCountFromServer(qLenn);
                setRowFoodReview(querySnapshotLen.data().count)
                const q = query(reviewCollectionRef, where('seekerId', 'in', [id.uid]), where('productId', '!=', null), limit(limitFoodReview))
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach(async (doc) => {
                    items.push(doc.data())
                })
                setLastVisibleFoodReview(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleFoodReview(querySnapshot.docs[0]);
                let i = 1;
                items.forEach(async (obj: any) => {
                    if (obj.makerId) {
                        const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', obj.makerId))
                        const _querySnapshot = await getDocs(q)
                        _querySnapshot.forEach((_doc) => {
                            obj.firstName = _doc.data().firstName;
                            obj.lastName = _doc.data().lastName;
                            obj.profile = _doc.data().profilePicUrl;
                        })
                    }
                    if (obj.productId) {
                        const q = query(productCollectionRef, where('pId', '==', obj.productId))
                        const _querySnapshot = await getDocs(q)
                        _querySnapshot.forEach((_doc) => {
                            obj.name = _doc.data().name;
                            obj.productImage = _doc.data().productImage;
                        })
                        if (i === items.length) {
                            setFoodReviews(items);
                        } else {
                            i++;
                        }
                    }
                })
                setLastVisibleFoodReview(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setFirstVisibleFoodReview(querySnapshot.docs[0]);
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            } if (items.length > 0) {
                setflagProductReview(true);
            } else {
                setflagProductReview(false);
            }
            setIsLoading(false);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handlePageChange = async (event: any, newPage: any) => {
        setPage(newPage);
        let items: any[] = [];
        if (page < newPage) {
            const orderCollectionRef = collection(db, 'userOrders');
            const q = query(orderCollectionRef, where('seekerId', '==', id.uid), where('isBulkOrder', '==', 0), orderBy('createdDate', 'desc'), startAfter(lastVisible), limit(limits))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'), where('isDelete', '==', 0),where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                    })
                    if (i === items.length) {
                        setOrderSend(items);
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot)
        } else {
            const orderCollectionRef = collection(db, 'userOrders');
            const q = query(orderCollectionRef, where('seekerId', '==', id.uid)
                , where('isBulkOrder', '==', 0)
                , orderBy('createdDate', 'desc')
                , endBefore(firstVisible),
                limitToLast(limits))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'), where('isDelete', '==', 0),where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                    })
                    if (i === items.length) {
                        setOrderSend(items);
                    } else {
                        i++;
                    }
                }
            })
            updateState(querySnapshot)
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleReviewPageChange = async (event: any, newPage: any) => {
        setPageReview(newPage);
        if (pageReview < newPage) {
            const items: any[] = [];
            const reviewCollectionRef = collection(db, 'userReviews');
            const userCollectionRef = collection(db, 'users');
            const q = query(reviewCollectionRef, where('seekerId', '==', id.uid), orderBy('reviewDate', 'desc'), startAfter(lastVisibleReview), limit(limitReview))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                items.push(doc.data())
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', obj.makerId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                        obj.profile = _doc.data().profilePicUrl;
                    })
                    if (i === items.length) {
                        setReviews(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateReview(querySnapshot);
        } else {
            const items: any[] = [];
            const reviewCollectionRef = collection(db, 'userReviews');
            const userCollectionRef = collection(db, 'users');
            const q = query(reviewCollectionRef, where('seekerId', '==', id.uid), orderBy('reviewDate', 'desc'), endBefore(firstVisibleReview), limitToLast(limitReview))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                items.push(doc.data())
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', obj.makerId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                        obj.profile = _doc.data().profilePicUrl;
                    })
                    if (i === items.length) {
                        setReviews(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateReview(querySnapshot);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleFoodReviewPageChange = async (event: any, newPage: any) => {
        setPageFoodReview(newPage);
        if (pageFoodReview < newPage) {
            const items: any[] = [];
            const reviewCollectionRef = collection(db, 'userReviews');
            const productCollectionRef = collection(db, 'makerProduct')
            const q = query(reviewCollectionRef, where('seekerId', '==', id.uid), orderBy('reviewDate', 'desc'), startAfter(lastVisibleFoodReview), limit(limitFoodReview))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                items.push(doc.data())
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.productId) {
                    const q = query(productCollectionRef, where('pId', '==', obj.productId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        obj.name = _doc.data().name;
                        obj.productImage = _doc.data().productImage;
                    })
                    if (i === items.length) {
                        setFoodReviews(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateFoodReview(querySnapshot);
        } else {
            const items: any[] = [];
            const reviewCollectionRef = collection(db, 'userReviews');
            const productCollectionRef = collection(db, 'makerProduct')
            const q = query(reviewCollectionRef, where('seekerId', '==', id.uid), orderBy('reviewDate', 'desc'), endBefore(firstVisibleFoodReview), limitToLast(limitFoodReview))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                items.push(doc.data())
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.productId) {
                    const q = query(productCollectionRef, where('pId', '==', obj.productId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        obj.name = _doc.data().name;
                        obj.productImage = _doc.data().productImage;
                    })
                    if (i === items.length) {
                        setFoodReviews(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateFoodReview(querySnapshot);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleReferencePageChange = async (event: any, newPage: any) => {
        setPageReference(newPage);
        if (pageReference < newPage) {
            const items: any[] = [];
            const q = query(collection(db, 'users'), where('referedUserId', '==', id.uid),where('isDelete', '==', 0), orderBy('createdDate', 'desc'), startAfter(lastVisibleReference), limit(limitReference))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            updateStateReference(querySnapshot);
            setReference(items);
        } else {
            const items: any[] = [];
            const q = query(collection(db, 'users'), where('referedUserId', '==', id.uid),where('isDelete', '==', 0), orderBy('createdDate', 'desc'), endBefore(firstVisibleReference), limitToLast(limitReference))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items.push({ id: doc.id, ...doc.data() });
            });
            updateStateReference(querySnapshot);
            setReference(items);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleFavPageChange = async (event: any, newPage: any) => {
        setPageFav(newPage);
        let items: any[] = [];
        if (pageFav < newPage) {
            const usersCollectionRef = collection(db, 'users');
            const makerCollectionRef = (collection(db, 'makerProduct'))
            const q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker')
                , where('uid', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'), startAfter(lastVisibleFav), limit(limitFav))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items = doc.data().favoriteProducts;
            });
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.pId) {
                    const q = query(makerCollectionRef, where('pId', '==', obj.pId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach(async (_doc) => {
                        obj.name = _doc.data().name
                        obj.productImage = _doc.data().productImage
                        let obj1 = {
                            makerId: _doc.data().makerId
                        }
                        if (obj1.makerId) {
                            const q = query(usersCollectionRef, where('uid', '==', obj1.makerId),where('isDelete', '==', 0))
                            const _querySnapshot1 = await getDocs(q);
                            _querySnapshot1.forEach((_doc1) => {
                                obj.makerName = _doc1.data().firstName
                                obj.makerLastName = _doc1.data().lastName
                            })
                            if (i === items.length) {
                                setFavorite(items)
                                setRowFav(items.length)
                            } else {
                                i++;
                            }
                        }
                    })
                }
            })
            updateStateFav(querySnapshot)
        } else {
            const makerCollectionRef = (collection(db, 'makerProduct'))
            const usersCollectionRef = collection(db, 'users');
            const q = query(usersCollectionRef, where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', id.uid)
                , orderBy('createdDate', 'desc')
                , endBefore(firstVisibleFav), limitToLast(limitFav))
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc: any) => {
                items = doc.data().favoriteProducts;
            });
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.pId) {
                    const q = query(makerCollectionRef, where('pId', '==', obj.pId))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach(async (_doc) => {
                        obj.name = _doc.data().name
                        obj.productImage = _doc.data().productImage
                        let obj1 = {
                            makerId: _doc.data().makerId
                        }
                        if (obj1.makerId) {
                            const q = query(usersCollectionRef, where('uid', '==', obj1.makerId),where('isDelete', '==', 0))
                            const _querySnapshot1 = await getDocs(q);
                            _querySnapshot1.forEach((_doc1) => {
                                obj.makerName = _doc1.data().firstName
                                obj.makerLastName = _doc1.data().lastName
                            })
                            if (i === items.length) {
                                setFavorite(items)
                                setRowFav(items.length)
                            } else {
                                i++;
                            }
                        }
                    })
                }
            })
            updateStateFav(querySnapshot)
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleFavMakerPageChange = async (event: any, newPage: any) => {
        setPageFavMaker(newPage);
        let items: any[] = [];
        if (pageFavMaker < newPage) {
            const usersCollectionRef = collection(db, 'users');
            const q = query(usersCollectionRef, where('uid', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'), startAfter(lastVisibleFavMaker), limit(limitFavMaker))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items = doc.data().favoritesMakers;
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.MakerId) {
                    const q = query(usersCollectionRef, where('isVerified', '==', true), where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', obj.MakerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.name = _doc.data().displayName
                        obj.contactNo = _doc.data().contactNo
                        obj.gender = _doc.data().gender
                    })
                    if (i === items.length) {
                        setFavMaker(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateFavMaker(querySnapshot);
        } else {
            const usersCollectionRef = collection(db, 'users');
            const q = query(usersCollectionRef, where('uid', '==', id.uid), where('isDelete', '==', 0),orderBy('createdDate', 'desc'), endBefore(firstVisibleFavMaker), limitToLast(limitFavMaker))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items = doc.data().favoritesMakers;
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.MakerId) {
                    const q = query(usersCollectionRef, where('isVerified', '==', true), where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', obj.MakerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.name = _doc.data().displayName
                        obj.contactNo = _doc.data().contactNo
                        obj.gender = _doc.data().gender
                    })
                    if (i === items.length) {
                        setFavMaker(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateFavMaker(querySnapshot);
        }
    }

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleCartPageChange = async (event: any, newPage: any) => {
        setPageCart(newPage);
        let items: any[] = [];
        if (pageCart < newPage) {
            const cartCollectionRef = collection(db, 'userCarts');
            const userCollectionRef = collection(db, 'users');
            const q = query(cartCollectionRef, where('uId', '==', id.uid), orderBy('createdDate', 'desc'), startAfter(lastVisibleCart), limit(limitCart))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                let itm = doc.data();
                let obj = {
                    Id: doc.data().makerId
                }
                if (obj.Id) {
                    const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'),where('isDelete', '==', 0), where('uid', '==', obj.Id))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        itm.firstName = _doc.data().firstName;
                        itm.lastName = _doc.data().lastName;
                    })
                    items.push(itm);
                    setCart(items)
                    setLastVisibleCart(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    setFirstVisibleCart(querySnapshot.docs[0]);
                }
            })
            updateStateCart(querySnapshot);
        } else {
            const cartCollectionRef = collection(db, 'userCarts');
            const userCollectionRef = collection(db, 'users');
            const q = query(cartCollectionRef, where('uId', '==', id.uid), orderBy('createdDate', 'desc'), endBefore(firstVisibleCart), limitToLast(limitCart))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                let itm = doc.data();
                let obj = {
                    Id: doc.data().makerId
                }
                if (obj.Id) {
                    const q = query(userCollectionRef, where('role.roleName', '==', 'foodMaker'), where('isDelete', '==', 0),where('uid', '==', obj.Id))
                    const _querySnapshot = await getDocs(q)
                    _querySnapshot.forEach((_doc) => {
                        itm.firstName = _doc.data().firstName;
                        itm.lastName = _doc.data().lastName;
                    })
                    items.push(itm);
                    setCart(items)
                    setLastVisibleCart(querySnapshot.docs[querySnapshot.docs.length - 1]);
                    setFirstVisibleCart(querySnapshot.docs[0]);
                }
            })
            updateStateCart(querySnapshot);
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handlePageChangeBulkOrder = async (event: any, newPage: any) => {
        setPageBulkOrder(newPage);
        let items: any[] = [];
        if (pageBulkOrder < newPage) {
            const orderCollectionRef = collection(db, 'userOrders');
            const q = query(orderCollectionRef, where('seekerId', '==', id.uid), where('isBulkOrder', '==', 1)
                , orderBy('createdDate', 'desc')
                , startAfter(lastVisibleBulkOrder), limit(limitBulkOrder))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'), where('isDelete', '==', 0),where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                    })
                    if (i === items.length) {
                        setBulkOrder(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateBulkOrder(querySnapshot)
        } else {
            const orderCollectionRef = collection(db, 'userOrders');
            const q = query(orderCollectionRef, where('seekerId', '==', id.uid)
                , where('isBulkOrder', '==', 1)
                , orderBy('createdDate', 'desc')
                , endBefore(firstVisibleBulkOrder),
                limitToLast(limitBulkOrder))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc: any) => {
                items.push(doc.data());
            })
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where("role.roleName", '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.seekerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().firstName;
                        obj.lastName = _doc.data().lastName;
                    })
                    if (i === items.length) {
                        setOrderSend(items);
                    } else {
                        i++;
                    }
                }
            })
            updateStateBulkOrder(querySnapshot)
        }
    };

    // when next(>) and previous(<) button in pagination change which is on bottom right of the page
    const handleWalletPageChange = async (event: any, newPage: number) => {
        setPageWallet(newPage)
        let items: any[] = [];
        if (pageWallet < newPage) {
            const q = query(collection(db, 'userWalletHistory'), where('makerId', '==', id.uid), orderBy('modifiedDate', 'desc')
                , startAfter(lastVisibleWallet), limit(limitWallet))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            updateStateWallet(querySnapshot);
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.makerId)
                        )
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().displayName;
                    })
                    if (i === items.length) {
                        setWallet(items);
                    } else {
                        i++;
                    }
                }
            })
        } else {
            const q = query(collection(db, 'userWalletHistory'), where('makerId', '==', id.uid), orderBy('modifiedDate', 'desc')
            , endBefore(firstVisibleWallet), limitToLast(limitWallet))
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            })
            updateStateWallet(querySnapshot)
            let i = 1;
            items.forEach(async (obj: any) => {
                if (obj.makerId) {
                    const q = query(collection(db, 'users'), where('role.roleName', '==', 'foodSeeker'),where('isDelete', '==', 0), where('uid', '==', obj.makerId))
                    const _querySnapshot = await getDocs(q);
                    _querySnapshot.forEach((_doc) => {
                        obj.firstName = _doc.data().displayName;
                    })
                    if (i === items.length) {
                        setWallet(items);
                    } else {
                        i++;
                    }
                }
            })
        }
    }

    // update first visible and last visible value according to pagination (next and previous button)
    const updateState = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisible(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisible(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateReview = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleReview(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleReview(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateFoodReview = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleFoodReview(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleFoodReview(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateReference = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleReference(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleReference(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateFav = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleFav(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleFav(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateFavMaker = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleFavMaker(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleFavMaker(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateCart = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleCart(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleCart(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateBulkOrder = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleBulkOrder(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleBulkOrder(documents.docs[documents.docs.length - 1]);
        }
    };

    // update first visible and last visible value according to pagination (next and previous button)
    const updateStateWallet = (documents: any) => {
        if (documents?.docs[0]) {
            setFirstVisibleWallet(documents.docs[0]);
        }
        if (documents?.docs[documents.docs.length - 1]) {
            setLastVisibleWallet(documents.docs[documents.docs.length - 1]);
        }
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setLimits(parseInt(event.target.value));
        limits = parseInt(event.target.value);
        handleChange(event, '2')
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleReviewLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageReview(0);
        setLimitReview(parseInt(event.target.value));
        limitReview = parseInt(event.target.value);
        handleChange(event, '9')
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleFoodReviewLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageFoodReview(0);
        setLimitFoodReview(parseInt(event.target.value));
        limitFoodReview = parseInt(event.target.value);
        handleChange(event, '10')
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleReferenceLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageReference(0);
        setLimitReference(parseInt(event.target.value));
        limitReference = parseInt(event.target.value);
        handleChange(event, '8')
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleCartLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageCart(0);
        setLimitCart(parseInt(event.target.value));
        limitCart = parseInt(event.target.value);
        handleChange(event, '4')
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleFavLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageFav(0);
        setLimitFav(parseInt(event.target.value));
        limitFav = parseInt(event.target.value);
        handleChange(event, '6')
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleFavMakerLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimitFavMaker(parseInt(event.target.value));
        setPageFavMaker(0);
        limitFavMaker = parseInt(event.target.value);
        handleChange(event, '5');
    };

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleBulkOrderLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageBulkOrder(0);
        setLimitBulkOrder(parseInt(event.target.value));
        limits = parseInt(event.target.value);
        handleChange(event, '3')
    }

    //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
    const handleWalletLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimitWallet(parseInt(event.target.value));
        setPageWallet(0);
        limitWallet = parseInt(event.target.value);
        handleChange(event, '7');
    };

    return (
        <>
            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Stack alignItems="left" justifyContent="space-between" mb={5}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    to="/admin/dashboard"
                                    style={{ display: 'flex', color: "black" }}
                                >
                                    <HomeIcon />
                                </Link>
                                <Link
                                    to="/admin/foodSeekers"
                                    style={{ display: 'flex', color: "black", textDecoration: "none" }}
                                >
                                    Food Seekers
                                </Link>
                                <Typography color="inherit">{userFirstName} {userLastName}</Typography>
                            </Breadcrumbs>
                        </Stack>
                        <Grid item xs={12}>
                            <Card className="makerdetail-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', margin: "auto", display: "flex", justifyContent: "space-evenly" }}>
                                <Box sx={{ width: "100%", typography: "body1" }}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange}
                                                aria-label="scrollable force tabs example"
                                                variant="scrollable" scrollButtons
                                                allowScrollButtonsMobile
                                            >
                                                <Tab label="Profile" value="1" />
                                                <Tab label="Orders (Send)" value="2" />
                                                <Tab label="Bulk Orders" value="3" />
                                                <Tab label="Cart" value="4" />
                                                <Tab label="Favourite Makers" value="5" />
                                                <Tab label="Favourite Products" value="6" />
                                                <Tab label="Wallet History" value="7" />
                                                <Tab label="Referred User" value="8" />
                                                <Tab label="Reviews" value="9" />
                                                <Tab label="Product Reviews" value="10" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1" style={{ height: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagProduct === true) ?
                                                                <>
                                                                    <ScrollBar>
                                                                        <TableContainer style={{ backgroundColor: 'white' }}>
                                                                            {profile.map((data: any) => (
                                                                                <Table key={data.id}>
                                                                                    <Grid container spacing={3} >
                                                                                        <Grid item xs={12} sm={6} md={6} >
                                                                                            <Box sx={{ margin: "auto", bgcolor: 'white' }} >
                                                                                                {data.profilePicUrl ? <Avatar sx={{ height: "100px", width: "100px", mt: 2, mb: 2, ml: 2 }} src={data.profilePicUrl}></Avatar> : <Avatar sx={{ height: "100px", width: "100px", mt: 2, mb: 2, ml: 2 }}>{data.firstName ? data.firstName[0] : null}</Avatar>}
                                                                                                <Typography gutterBottom ml={2}>
                                                                                                    <Box display="flex" alignItems="flex-start"><Typography><b>Name</b></Typography><Box sx={{ pl: 16, color: 'grey' }}>
                                                                                                        {data.firstName} {data.lastName}
                                                                                                    </Box></Box>
                                                                                                </Typography>
                                                                                                <Typography gutterBottom ml={2}>
                                                                                                    <Box display="flex" alignItems="flex-start"><Typography><b>Email</b></Typography><Box sx={{ pl: 16.5, color: 'grey' }}>
                                                                                                        {data.email}
                                                                                                    </Box></Box>
                                                                                                </Typography>
                                                                                                <Typography gutterBottom ml={2}>
                                                                                                    <Box display="flex" alignItems="flex-start"><Typography><b>Gender</b></Typography><Box sx={{ pl: 15, color: 'grey' }}>{data.gender}</Box></Box>
                                                                                                </Typography>
                                                                                                <Typography gutterBottom ml={2}>
                                                                                                    <Box display="flex" alignItems="flex-start"><Typography><b>Contact No</b></Typography><Box sx={{ pl: 11, color: 'grey' }}>{data.contactNo}</Box></Box>
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} sm={6} md={6} >
                                                                                            <Box >
                                                                                                <Typography sx={{ ml: 2 }}><b>Address</b></Typography>
                                                                                                <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', bgcolor: '#F9FAFB', height: 'calc(100vh - 420px)', overflowY: 'auto', m: 3 }}>
                                                                                                    {addresses[0]?.map((data: any, index: number) => (
                                                                                                        <CardContent key={data.id}>
                                                                                                            <Typography gutterBottom>
                                                                                                                <Box display="flex" alignItems="left"><Box sx={{ bgcolor: '#F9FAFB' }}>
                                                                                                                    <Typography sx={{ pl: 2, pt: 2 }}><b>{index + 1}.</b> {data.addressLine1 ? data.addressLine1 : "--"}, {data.pinCode ? data.pinCode : "--"}, {data.city ? data.city : "--"}, {data.state ? data.state : '--'}, {data.country ? data.country : "--"}
                                                                                                                    </Typography></Box></Box>
                                                                                                            </Typography>
                                                                                                        </CardContent>
                                                                                                    ))}
                                                                                                </Card>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Table>
                                                                            ))}
                                                                        </TableContainer>
                                                                    </ScrollBar>
                                                                </>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagOrderSend === true) ?
                                                                <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                    <ScrollBar>
                                                                        <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell align="center" >#</TableCell>
                                                                                        <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Makers</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Total Products</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Status</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Date</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Orders Amount</Typography></TableCell>
                                                                                        <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {orderSend.map((data: any, index: number) => (
                                                                                        <TableRow hover key={data.id}>
                                                                                            <TableCell align="center" >
                                                                                                {(page * limits) + index + 1}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                <Typography variant="subtitle2" noWrap>{data.orderNo}</Typography>
                                                                                            </TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {data.firstName} {data.lastName}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {data.orderDetail.length}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {data.orderStatus === 'Confirmed' && <Label sx={{ color: "white", backgroundColor: "#919eab", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Delivered' && <Label sx={{ color: "white", backgroundColor: "#53bc5b", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Shipped' && <Label sx={{ color: "white", backgroundColor: "#1565c0", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Processed' && <Label sx={{ color: "white", backgroundColor: "#fcde2a", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Cancelled' && <Label sx={{ color: "white", backgroundColor: "#e20700", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Pending' && <Label sx={{ color: "white", backgroundColor: "#fc7b2a", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {format(new Date(data.createdDate.seconds * 1000), formatedDate)}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                ₹ {(data.totalAmount).toFixed(2)}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align='right' >
                                                                                                <Tooltip title="View" arrow>
                                                                                                    <IconButton
                                                                                                        sx={{ color: '#1565c0' }}
                                                                                                        color="inherit"
                                                                                                        size="small"
                                                                                                        onClick={(e) => { handleClickVisible(data) }}
                                                                                                    >
                                                                                                        <VisibilityIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </ScrollBar>
                                                                    <TablePagination
                                                                        component="div"
                                                                        count={row}
                                                                        onPageChange={handlePageChange}
                                                                        onRowsPerPageChange={handleLimitChange}
                                                                        page={page}
                                                                        rowsPerPage={limits}
                                                                        rowsPerPageOptions={[10, 20, 30, 40]}
                                                                    />
                                                                </Card>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="3">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagBulkOrder === true) ?
                                                                <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                    <ScrollBar>
                                                                        <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell align="center" >#</TableCell>
                                                                                        <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Makers</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Total Products</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Status</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Date</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Orders Amount</Typography></TableCell>
                                                                                        <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {bulkOrder.map((data: any, index: number) => (
                                                                                        <TableRow hover key={data.id}>
                                                                                            <TableCell align="center" >
                                                                                                {(page * limits) + index + 1}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                <Typography variant="subtitle2" noWrap>{data.orderNo}</Typography>
                                                                                            </TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {data.firstName} {data.lastName}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {data.orderDetail.length}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" >
                                                                                                {data.orderStatus === 'Confirmed' && <Label sx={{ color: "white", backgroundColor: "#919eab", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Delivered' && <Label sx={{ color: "white", backgroundColor: "#53bc5b", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Shipped' && <Label sx={{ color: "white", backgroundColor: "#1565c0", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Processed' && <Label sx={{ color: "white", backgroundColor: "#fcde2a", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Cancelled' && <Label sx={{ color: "white", backgroundColor: "#e20700", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                                {data.orderStatus === 'Pending' && <Label sx={{ color: "white", backgroundColor: "#fc7b2a", width: '77px' }}>{data.orderStatus}</Label>}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {format(new Date(data.createdDate.seconds * 1000), formatedDate)}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                ₹ {(data.totalAmount).toFixed(2)}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align='right' sx={{ pt: 0.5, pb: 0.5 }}>
                                                                                                <Tooltip title="View" arrow>
                                                                                                    <IconButton

                                                                                                        sx={{ color: '#1565c0' }}
                                                                                                        color="inherit"
                                                                                                        size="small"
                                                                                                        onClick={(e) => { handleClickVisible(data) }}
                                                                                                    >
                                                                                                        <VisibilityIcon />
                                                                                                    </IconButton>
                                                                                                </Tooltip>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </ScrollBar>
                                                                    <TablePagination
                                                                        component="div"
                                                                        count={rowBulkOrder}
                                                                        onPageChange={handlePageChangeBulkOrder}
                                                                        onRowsPerPageChange={handleBulkOrderLimitChange}
                                                                        page={pageBulkOrder}
                                                                        rowsPerPage={limitBulkOrder}
                                                                        rowsPerPageOptions={[10, 20, 30, 40]}
                                                                    />
                                                                </Card>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="4">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagCart === true) ?
                                                                <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                    <ScrollBar>
                                                                        <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell align="center" >#</TableCell>
                                                                                        <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Makers</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Products</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Amount</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Quantity</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Total Amount</Typography></TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {cart.map((data: any, index: any) => (
                                                                                        <TableRow hover key={data.id}>
                                                                                            <TableCell align="center" >
                                                                                                {(pageCart * limitCart) + index + 1}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                <Typography variant="subtitle2" noWrap> {data.firstName} {data.lastName}</Typography>
                                                                                            </TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {data.productName}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                ₹ {(data.grossAmout).toFixed(2)}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {data.quantity}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                ₹ {(data.totalAmount).toFixed(2)}
                                                                                            </Typography></TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </ScrollBar>
                                                                    <TablePagination
                                                                        rowsPerPageOptions={[10, 20, 30, 40]}
                                                                        component="div"
                                                                        count={rowCart}
                                                                        rowsPerPage={limitCart}
                                                                        page={pageCart}
                                                                        onPageChange={handleCartPageChange}
                                                                        onRowsPerPageChange={handleCartLimitChange}
                                                                    />
                                                                </Card>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="5">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagfavMaker === true) ?
                                                                <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                    <ScrollBar>
                                                                        <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell align="center" >#</TableCell>
                                                                                        <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Makers</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Gender</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Contact</Typography></TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {favMaker.slice(pageFavMaker * limitFavMaker, pageFavMaker * limitFavMaker + limitFavMaker).map((arr: any, index: number) => (
                                                                                        <TableRow hover key={arr.id}>
                                                                                            <TableCell align="center" >
                                                                                                {(pageFavMaker * limitFavMaker) + index + 1}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }} >
                                                                                                <Typography variant="subtitle2" noWrap> {arr.name}</Typography>
                                                                                            </TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {arr.contactNo}
                                                                                            </Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {arr.gender}
                                                                                            </Typography> </TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </ScrollBar>
                                                                    <TablePagination
                                                                        rowsPerPageOptions={[10, 20, 30, 40]}
                                                                        component="div"
                                                                        count={rowFavMaker}
                                                                        rowsPerPage={limitFavMaker}
                                                                        page={pageFavMaker}
                                                                        onPageChange={handleFavMakerPageChange}
                                                                        onRowsPerPageChange={handleFavMakerLimitChange}
                                                                    />
                                                                </Card>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="6">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagfavProduct === true) ?
                                                                <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                    <ScrollBar>
                                                                        <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                            <Table stickyHeader>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell align="center" >#</TableCell>
                                                                                        <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Products</Typography></TableCell>
                                                                                        <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Food Makers</Typography></TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {favorite.slice(pageFav * limitFav, pageFav * limitFav + limitFav).map((arr: any, index: number) => (
                                                                                        <TableRow hover key={arr.id}>
                                                                                            <TableCell align="center" >
                                                                                                {(pageFav * limitFav) + index + 1}
                                                                                            </TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                <Stack direction="row" justifyContent="center" spacing={2}>
                                                                                                    {arr.productImage ? <Avatar src={arr.productImage} variant="rounded"></Avatar> : <Avatar variant="rounded">{arr.name ? arr.name[0] : null}</Avatar>}
                                                                                                    <Typography variant='subtitle2' noWrap>
                                                                                                        {arr.name}
                                                                                                    </Typography>
                                                                                                </Stack>
                                                                                            </TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                {arr.makerName} {arr.makerLastName}
                                                                                            </Typography></TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </ScrollBar>
                                                                    <TablePagination
                                                                        rowsPerPageOptions={[10, 20, 30, 40]}
                                                                        component="div"
                                                                        count={rowFav}
                                                                        rowsPerPage={limitFav}
                                                                        page={pageFav}
                                                                        onPageChange={handleFavPageChange}
                                                                        onRowsPerPageChange={handleFavLimitChange}
                                                                    />
                                                                </Card>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="7" >
                                            <div>
                                                {isLoading ? <Loader title="Loading..." /> :
                                                    <>
                                                        {(flagWallet === true) ?
                                                            <>

                                                                <>
                                                                    <Typography sx={{ ml: 3.1, mb: 2 }}>
                                                                        <Box display="flex" alignItems="flex-start"><Typography sx={{ fontFamily: '"Public Sans",sans serif' }}><b>Total Amount</b></Typography><Box sx={{ pl: 5, color: 'grey' }}>
                                                                            ₹ {(totalAmount)}
                                                                        </Box></Box>
                                                                    </Typography>
                                                                    <Card className="makerdetailtab7-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                        <ScrollBar>
                                                                            <TableContainer className="makerdetailtab7-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                                <Table stickyHeader>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell align="center">#</TableCell>
                                                                                            <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Title</Typography></TableCell>
                                                                                            <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Amount</Typography></TableCell>
                                                                                            <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Transaction Date</Typography></TableCell>
                                                                                            <TableCell align="center"><Typography noWrap fontWeight="600" fontSize="0.875rem">Type</Typography></TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {wallet.map((data: any, index: any) => (
                                                                                            <TableRow hover key={data.id}>
                                                                                                <TableCell align="center">{(pageWallet * limitWallet) + index + 1}</TableCell>
                                                                                                <TableCell align="center"><Typography noWrap fontSize="0.875rem">{data.title}</Typography></TableCell>
                                                                                                <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                    ₹ {(data.amount).toFixed(2)}
                                                                                                </Typography></TableCell>
                                                                                                <TableCell align="center"><Typography noWrap fontSize="0.875rem">{format(new Date(data.modifiedDate.seconds * 1000), formatedDate)}</Typography></TableCell>
                                                                                                <TableCell align="center"><Typography noWrap fontSize="0.875rem">{(data.walletHistoryType === 'credit') ?
                                                                                                    <Typography style={{ color: "green" }}>{data.walletHistoryType}</Typography> : <Typography style={{ color: "red" }}>{data.walletHistoryType}</Typography>}</Typography></TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </ScrollBar>
                                                                        <TablePagination
                                                                            component="div"
                                                                            count={rowWallet}
                                                                            onPageChange={handleWalletPageChange}
                                                                            onRowsPerPageChange={handleWalletLimitChange}
                                                                            page={pageWallet}
                                                                            rowsPerPage={limitWallet}
                                                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                                                        />
                                                                    </Card>
                                                                </>
                                                            </>
                                                            :
                                                            <>
                                                                <Typography sx={{ ml: 3.1, mb: 2 }}>
                                                                    <Box display="flex" alignItems="flex-start"><Typography sx={{ fontFamily: '"Public Sans",sans serif' }}><b>Total Amount</b></Typography><Box sx={{ pl: 5, color: 'grey' }}>
                                                                        ₹ 0.00
                                                                    </Box></Box>
                                                                </Typography>
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            </>}
                                                    </>
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel value="8">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagReference === true) ?
                                                                <>
                                                                    <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                        <ScrollBar>
                                                                            <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                                <Table stickyHeader>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell align="center" >#</TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Referred User</Typography></TableCell>
                                                                                            <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Role</Typography></TableCell>
                                                                                           <TableCell align="center" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Date</Typography></TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {reference.map((data: any, index: number) => (
                                                                                            <TableRow hover key={data.id}>
                                                                                                <TableCell align="center" >
                                                                                                    {(pageReference * limitReference) + index + 1}
                                                                                                </TableCell>
                                                                                                <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                    <Typography variant="subtitle2" noWrap>{data.displayName}</Typography>
                                                                                                </TableCell>
                                                                                                <TableCell align="center" sx={{textTransform: 'capitalize'}}>
                                                                                                    {data.role.roleName}
                                                                                                </TableCell>
                                                                                                <TableCell align="center" ><Typography noWrap fontSize="0.875rem">
                                                                                                    {format(new Date(data.createdDate.seconds * 1000), formatedDate)}
                                                                                                </Typography> </TableCell>
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </ScrollBar>
                                                                        <TablePagination
                                                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                                                            component="div"
                                                                            count={rowReference}
                                                                            rowsPerPage={limitReference}
                                                                            page={pageReference}
                                                                            onPageChange={handleReferencePageChange}
                                                                            onRowsPerPageChange={handleReferenceLimitChange}
                                                                        />
                                                                    </Card>
                                                                </>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="9">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagReview === true) ?
                                                                <>
                                                                    <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                        <ScrollBar>
                                                                            <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                                <Table stickyHeader>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell align='center'>#</TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                                                                            <TableCell align='left'><Typography noWrap fontWeight="600" fontSize="0.875rem"> Food Makers</Typography></TableCell>
                                                                                            <TableCell align='center'><Typography noWrap fontWeight="600" fontSize="0.875rem">Description</Typography></TableCell>
                                                                                            <TableCell align='center'><Typography noWrap fontWeight="600" fontSize="0.875rem">Reviews</Typography></TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {
                                                                                            reviews.map((arr: any, index: number) => (
                                                                                                <TableRow hover key={arr.id}>
                                                                                                    <TableCell align="center" >
                                                                                                        {(pageReview * limitReview) + index + 1}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                        <Typography variant='subtitle2' noWrap >{arr.orderNo}</Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell align="left" sx={{ color: '#212B36' }}>
                                                                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                                                                            {arr.profilePicUrl ? <Avatar src={arr.profilePicUrl}></Avatar> : <Avatar >{arr.firstName ? arr.firstName[0] : null}</Avatar>}
                                                                                                            <Typography>
                                                                                                                {arr.firstName} {arr.lastName}
                                                                                                            </Typography>
                                                                                                        </Stack>
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center"><Typography noWrap fontSize="0.875rem">{arr.description ? arr.description : "--"}</Typography></TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        <Rating name="rating" value={arr.ratting} readOnly />
                                                                                                        <Typography>
                                                                                                            {arr.rattingType}
                                                                                                        </Typography>
                                                                                                        <Typography fontSize="12px" color="grey">
                                                                                                            {format(new Date(arr.reviewDate.seconds * 1000), formatedDate)}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            ))
                                                                                        }
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </ScrollBar>
                                                                        <TablePagination
                                                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                                                            component="div"
                                                                            count={rowReview}
                                                                            rowsPerPage={limitReview}
                                                                            page={pageReview}
                                                                            onPageChange={handleReviewPageChange}
                                                                            onRowsPerPageChange={handleReviewLimitChange}
                                                                        />
                                                                    </Card>
                                                                </>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>}
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                        <TabPanel value="10">
                                            <>
                                                <div>
                                                    {isLoading ? <Loader title="Loading..." /> :
                                                        <>
                                                            {(flagProductReview === true) ?
                                                                <>
                                                                    <Card className="makerdetailtab2-card-list" sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px' }}>
                                                                        <ScrollBar>
                                                                            <TableContainer className="makerdetailtab2-tableContainer-list" sx={{ bgcolor: 'white' }}>
                                                                                <Table stickyHeader>
                                                                                    <TableHead>
                                                                                        <TableRow hover>
                                                                                            <TableCell align='center'>#</TableCell>
                                                                                            <TableCell align="center" sx={{ color: '#212B36' }}><Typography noWrap fontWeight="600" fontSize="0.875rem">Order Number</Typography></TableCell>
                                                                                            <TableCell align='left'> <Typography noWrap fontWeight="600" fontSize="0.875rem">Product</Typography></TableCell>
                                                                                            <TableCell align='left'><Typography noWrap fontWeight="600" fontSize="0.875rem"> Description</Typography></TableCell>
                                                                                            <TableCell align='center'><Typography noWrap fontWeight="600" fontSize="0.875rem">Reviews</Typography></TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {
                                                                                            foodReviews.map((arr: any, index: number) => (
                                                                                                <TableRow hover key={arr.id}>
                                                                                                    <TableCell align="center" >
                                                                                                        {(pageReview * limitReview) + index + 1}
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center" sx={{ color: '#212B36' }}>
                                                                                                        <Typography variant='subtitle2' noWrap >{arr.orderNo}</Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell align="left" sx={{ color: '#212B36' }}>
                                                                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                                                                            {arr.productImage ? <Avatar src={arr.productImage} variant="rounded"></Avatar> : <Avatar src="/defaultFood.png" variant="rounded"></Avatar>}
                                                                                                            <Typography>
                                                                                                                {arr.name}
                                                                                                            </Typography>
                                                                                                        </Stack>
                                                                                                    </TableCell>
                                                                                                    <TableCell align="left">
                                                                                                        <Typography noWrap fontSize="0.875rem">
                                                                                                            {arr.description ? arr.description : "--"}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        <Rating name="rating" value={arr.ratting} readOnly />
                                                                                                        <Typography>
                                                                                                            {arr.rattingType}
                                                                                                        </Typography>
                                                                                                        <Typography fontSize="12px" color="grey">
                                                                                                            {format(new Date(arr.reviewDate.seconds * 1000), formatedDate)}
                                                                                                        </Typography>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            ))
                                                                                        }
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>
                                                                        </ScrollBar>
                                                                        <TablePagination
                                                                            rowsPerPageOptions={[10, 20, 30, 40]}
                                                                            component="div"
                                                                            count={rowFoodReview}
                                                                            rowsPerPage={limitFoodReview}
                                                                            page={pageFoodReview}
                                                                            onPageChange={handleFoodReviewPageChange}
                                                                            onRowsPerPageChange={handleFoodReviewLimitChange}
                                                                        />
                                                                    </Card>
                                                                </>
                                                                :
                                                                <Paper
                                                                    sx={{
                                                                        width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                                                    }}
                                                                >
                                                                    <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                                                    <Typography variant="h6" paragraph>
                                                                        Data not Available
                                                                    </Typography>
                                                                </Paper>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Card>
                        </Grid >
                    </Grid>
                </Grid>
            </Container >
        </>
    );
}

export default FoodSeekerDetails