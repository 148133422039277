import { format } from 'date-fns';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemButton,
} from '@mui/material';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db, onMessageListener } from '../../../firebase';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';

// ----------------------------------------------------------------------

const initialState = {
  id: "",
}

export default function NotificationsPopover() {
  const [notificationsFoodMaker, setNotificationsFoodMaker] = useState<any>([]);
  const [notificationsBank, setNotificationsBank] = useState<any>([]);
  const [notificationsUnRead, setNotificationsUnRead] = useState<any>([]);
  const [data, setData] = useState<any>(initialState);
  const [flag, setFlag] = useState(false);
  const [flag1, setFlag1] = useState(false);
  const [open, setOpen] = useState(null);
  const [readFoodMaker, setReadFoodMaker] = useState(null);
  const [readBank, setReadBank] = useState(null);
  const [isDelALl, setIsDelAll] = useState<any>([]);
  const [isDelFoodMaker, setIsDelFoodMaker] = useState<any>([]);
  const [isDelBank, setIsDelBank] = useState<any>([]);
  const formatedDate = JSON.parse(localStorage.getItem('dateFormat') || '{}');
  const [notification, setNotification] = useState({ title: '', body: '' });

  const notify = async () =>
    setTimeout(() => {
      toast(<ToastDisplay />);
    }, 10);

  const ToastDisplay = () => {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };
  const navigate = useNavigate();

  const handleClick = (id: any) => {
    localStorage.setItem('status', JSON.stringify('Pending'))
    setOpen(null);
    setReadFoodMaker(null);
    navigate('/admin/foodMakers');
    try {
      const obj = {
        'isRead': true,
      }
      const NotificationsRef = doc(db, 'notifications', id);
      updateDoc(NotificationsRef, obj);
      // await setDoc(datasRef, obj, { merge: true });
    }
    catch (error) {
      console.error(error);
    }
    getNotificationunRead();
  }

  const handleClickBank = (id: any) => {
    localStorage.setItem('status', JSON.stringify('All'))
    setOpen(null);
    setReadBank(null);
    navigate('/admin/foodMakers')
    try {
      const obj = {
        'isRead': true,
      }
      const NotificationsRef = doc(db, 'notifications', id);
      updateDoc(NotificationsRef, obj);
      // await setDoc(datasRef, obj, { merge: true });
    }
    catch (error) {
      console.error(error);
    }
    getNotificationunRead();
  }

  const handleViewAll = () => {
    setOpen(null);
    navigate('/admin/notifications')
  }

  useEffect(() => {
    getNotificationFoodMaker();
    getNotificationBank();
    getNotificationunRead();
    if (notification?.title) {
      // notify()
      // addData();
    }
    onMessageListener().then((payload: any) => {
      setNotification({ title: payload.notification.title, body: payload.notification.body });
    })
      .catch((err) => console.log('failed: ', err));

  }, [])

  const getNotificationFoodMaker = async () => {
    const items: any[] = [];
    try {
      const notificationRef = collection(db, 'notifications');
      const q = query(notificationRef, where('role', '==', 'admin'), where('basedOn', '==', 'Register Food Maker'), where('isRead', '==', false))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setNotificationsFoodMaker(items);
      if (items.length > 0) {
        setFlag(true);
      } else {
        setFlag(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getNotificationBank = async () => {
    const items: any[] = [];
    try {
      const notificationRef = collection(db, 'notifications');
      const q = query(notificationRef, where('role', '==', 'admin'), where('basedOn', '==', 'Bank Details Added'), where('isRead', '==', false))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setNotificationsBank(items);
      if (items.length > 0) {
        setFlag1(true);
      } else {
        setFlag1(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getNotificationunRead = async () => {
    const items: any[] = [];
    try {
      const notificationRef = collection(db, 'notifications');
      const q = query(notificationRef, where('role', '==', 'admin'), where('isRead', '==', false))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });
      setNotificationsUnRead(items);
      if (items.length > 0) {
        setFlag1(true);
      } else {
        setFlag1(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handMarkAllRead = async () => {
    try {
      const items1: any = [];
      const notificationRef = collection(db, 'notifications');
      const q = query(notificationRef, where('role', '==', 'admin'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items1.push({ id: doc.id, ...doc.data() });
      });
      for (let i = 0; i < items1.length; i++) {
        // const deleteRef = doc(notificationRef, items1[i]?.id)
        // deleteDoc(deleteRef);
        const obj = {
          'isRead': true,
        }
        const NotificationsRef = doc(db, 'notifications', items1[i]?.id);
        updateDoc(NotificationsRef, obj);
      }
    }
    catch (error) {
      console.error(error);
    }
    getNotificationFoodMaker();
    getNotificationBank();
    setIsDelAll(false);
  }

  const handleFoodMakerMarkRead = async (id: any) => {
    try {
      const obj = {
        'isRead': true,
      }
      const NotificationsRef = doc(db, 'notifications', id);
      updateDoc(NotificationsRef, obj);
      // await setDoc(datasRef, obj, { merge: true });
    }
    catch (error) {
      console.error(error);
    }
    getNotificationFoodMaker();
    setIsDelFoodMaker(false);
  }

  const handleDeleteBank = async (id: any) => {
    try {
      const obj = {
        'isRead': true,
      }
      const NotificationsRef = doc(db, 'notifications', id);
      updateDoc(NotificationsRef, obj);
      // await setDoc(datasRef, obj, { merge: true });
    }
    catch (error) {
      console.error(error);
    }
    getNotificationBank();
    setIsDelBank(false);
  }

  // const handleCancelALl = () => {
  //   setIsDelAll(false)
  // }
  // const handleCancelFoodMaker = () => {
  //   setIsDelFoodMaker(false)
  // }
  // const handleCancelBank = () => {
  //   setIsDelBank(false)
  // }
  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  // const handleMarkAllAsRead = () => {
  //   setNotificationsUnRead(
  //     notificationsUnRead.map((notification: any) => ({
  //       ...notification,
  //       isUnRead: false,
  //     }))
  //   );
  // };

  return (
    <>
      <Toaster
        position="top-right"
      />
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge
          badgeContent={notificationsUnRead.length}
          color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2, px: 2.5 }}>
          {/* <Box sx={{ flexGrow: 1 }}> */}

          <Typography variant="subtitle1">Notifications</Typography>
          {flag === true && flag1 === true ?
            <>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                <Tooltip title="View All" arrow>
                  <IconButton
                    sx={{ pt: 0.5, pb: 0.5, color: '#1565c0', display: 'flex', justifyContent: 'flex-end' }}
                    color="inherit"
                    size="small"
                    onClick={(e) => handleViewAll()}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Mark All As Read" arrow>
                  <IconButton
                    sx={{ pt: 0.5, pb: 0.5, color: '#1565c0', display: 'flex', justifyContent: 'flex-end' }}
                    color="inherit"
                    size="small"
                    onClick={(e) => handMarkAllRead()}
                  >
                    <MarkEmailReadIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
            : ''}


          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          You have {totalUnRead} unread messages
          </Typography>
          </Box> */}

          {/* {notificationsUnRead.length > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
          // subheader={
          //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
          //     Register Food Maker
          //   </ListSubheader>
          // }
          >
            {flag === true ?
              <>
                {notificationsFoodMaker.map((arr: any) => (
                  // <NotificationItem key={arr.id} notification={arr.subtitle} />
                  // <p> {arr.subtitle}</p>
                  <ListItemButton
                    sx={{
                      py: 1.5,
                      px: 2.5,
                      mt: '1px',
                      ...(arr.isRead === 0 && {
                        bgcolor: 'action.selected',
                      }),
                    }}
                    onClick={(e) => { handleClick(arr.id) }}
                  >
                    {/* <ListItemAvatar>
                      <Avatar sx={{ bgcolor: 'background.neutral' }}></Avatar>
                    </ListItemAvatar> */}
                    <>
                      <ListItemText
                        primary={arr.subTitle}
                        secondary={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              color: 'text.disabled',
                            }}
                          >
                            {format(new Date(arr.createdDate.seconds * 1000), formatedDate)}
                          </Typography>
                        }
                      /> <Tooltip title="Mark As Read" arrow>
                        <IconButton
                          sx={{ pt: 0.5, pb: 0.5, color: '#1565c0', display: 'flex', justifyContent: 'flex-end' }}
                          color="inherit"
                          size="small"
                          onClick={(e) => handleFoodMakerMarkRead(arr.id)}
                        >
                          <MarkChatReadIcon fontSize="small" />
                        </IconButton>
                      </Tooltip></>
                  </ListItemButton >
                ))}
              </>
              :
              //  <ListItemButton
              //   sx={{
              //     py: 1.5,
              //     px: 2.5,
              //     mt: '1px',
              //     // ...(notification.isUnRead && {
              //     //   bgcolor: 'action.selected',
              //     // }),
              //   }}
              // >
              //   <ListItemText
              //     secondary={
              //       <Typography
              //         variant="caption"
              //         sx={{
              //           mt: 0.5,
              //           display: 'flex',
              //           alignItems: 'center',
              //           color: 'text.disabled',
              //         }}
              //       >
              //         No notification available
              //       </Typography>
              //     }
              //   />
              // </ListItemButton>
              ''
            }
          </List>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <List
            disablePadding
          // subheader={
          //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
          //     Register Bank Account
          //   </ListSubheader>
          // }
          >
            {flag1 === true ?
              <>
                {
                  notificationsBank.map((arr: any) => (
                    // <NotificationItem key={arr.id} notification={arr.subtitle} />
                    <ListItemButton
                      sx={{
                        py: 1.5,
                        px: 2.5,
                        mt: '1px',
                        ...(arr.isRead === 0 && {
                          bgcolor: 'action.selected',
                        }),
                      }}
                      onClick={(e) => { handleClickBank(arr.id) }}
                    >
                      {/* <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'background.neutral' }}></Avatar>
                      </ListItemAvatar> */}
                      <><ListItemText
                        primary={arr.subTitle}
                        secondary={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 0.5,
                              display: 'flex',
                              alignItems: 'center',
                              color: 'text.disabled',
                            }}
                          >
                            {format(new Date(arr.createdDate.seconds * 1000), formatedDate)}</Typography>
                        }
                      /> <Tooltip title="Mark As Read" arrow>
                          <IconButton
                            sx={{ pt: 0.5, pb: 0.5, color: '#1565c0', display: 'flex', justifyContent: 'flex-end' }}
                            color="inherit"
                            size="small"
                            onClick={(e) => handleDeleteBank(arr.id)}
                          >
                            <MarkChatReadIcon fontSize="small" />
                          </IconButton>
                        </Tooltip></>
                    </ListItemButton >
                    // arr.subtitle
                  ))
                }
              </>
              :
              ''
              // <ListItemButton
              //   sx={{
              //     py: 1.5,
              //     px: 2.5,
              //     mt: '1px',
              //   }}
              // >
              //   <ListItemText
              //     secondary={
              //       <Typography
              //         variant="caption"
              //         sx={{
              //           mt: 0.5,
              //           display: 'flex',
              //           alignItems: 'center',
              //           color: 'text.disabled',
              //         }}
              //       >
              //         No notification available
              //       </Typography>
              //     }
              //   />
              // </ListItemButton>
            }
          </List>

          <List
            disablePadding
          // subheader={
          //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
          //     Register Bank Account
          //   </ListSubheader>
          // }
          >
            {flag || flag1 === true ?
              ''
              :

              <ListItemButton
                sx={{
                  py: 1.5,
                  px: 2.5,
                  mt: '1px',
                }}
              >
                <ListItemText
                  secondary={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        color: 'text.disabled',
                      }}
                    >
                      No notification available
                    </Typography>
                  }
                />
              </ListItemButton>
            }
          </List>

        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Popover>
    </>
  );
}




