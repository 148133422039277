import { Helmet } from 'react-helmet-async';
import { ChangeEvent, useState, useEffect } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  Tooltip,
  TextField,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  Grid,
  Divider,
  alpha,
  OutlinedInput,
  styled,
  Toolbar,
  InputAdornment,
  Breadcrumbs,
  FormHelperText,
} from '@mui/material';
import Scrollbar from '../components/scrollbar';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { addDoc, collection, deleteDoc, doc, endBefore, getCountFromServer, getDocs, limit, limitToLast, orderBy, query, setDoc, startAfter, where } from 'firebase/firestore';
import { db } from '../firebase';
import Loader from './spinner';
import CancelIcon from '@mui/icons-material/Cancel';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconify from '../components/iconify';
import './Reward.css';
import './FoodMakerPage.css';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 280,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const initialState = {
  rewardId: '',
  rewardType: '',
  rupees: '',

}

export default function RewardPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  let [limits, setLimits] = useState<number>(10);
  let [row, setRow] = useState<number>(10);
  const [rewards, setRewards] = useState<any>([]);
  const [reward, setReward] = useState<any>(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isValueError, setValueError] = useState(false);
  const [ValueErrorMsg, setValueErrorMsg] = useState("");
  const [isNameError, setNameError] = useState(false);
  const [NameErrorMsg, setNameErrorMsg] = useState("");
  const [lastVisible, setLastVisible] = useState<any>();
  const [firstVisible, setFirstVisible] = useState<any>();
  let [searchInput, setSearchInput] = useState('');
  const [isDel, setIsDel] = useState(false);
  const [flag, setFlag] = useState<boolean>(true);

  const reg = new RegExp(/^\d*$/); //regex only number check
  const validateValue = (arr: any) => {
    if (arr.target.value) {
      if ((reg.test(arr.target.value))) {
        setValueError(false);
        setValueErrorMsg("");
      } else {
        setValueError(true);
        setValueErrorMsg("only Number is allowed");
      }
    }
    else {
      setValueError(true);
      setValueErrorMsg("Ruppee is required");
    }
  }

  const reg1 = new RegExp(/^[a-zA-Z_ ]+$/); // regex alphabet with space
  const validateName = (arr: any) => {
    const { value } = arr.target;
    if (value) {
      if ((reg1.test(arr.target.value))) {
        setNameError(false);
        setNameErrorMsg("");
      } else {
        setNameError(true);
        setNameErrorMsg("Alphabet with space allowed");
      }
    } else {
      setNameError(true);
      setNameErrorMsg("Reward type is required");
    }
  }

  // validate form
  const validateForm = (e: any) => {
    e.preventDefault();
    let flag = true;
    if (!reward.rewardType) {
      setNameError(true);
      setNameErrorMsg("Reward type is required");
      flag = false;
    } else {
      if ((reg1.test(reward.rewardType))) {
        setNameError(false);
        setNameErrorMsg("");
      } else {
        setNameError(true);
        setNameErrorMsg("Alphabet with space allowed");
        flag = false;
      }
    }
    if (!reward.rupees) {
      setValueError(true);
      setValueErrorMsg("Rupee is required");
      flag = false;
    } else {
      setValueError(false);
      setValueErrorMsg("");
    }
    return flag;
  }

  // it is the first method invoked when this page is open
  useEffect(() => {
    getrewards();
  }, [])

  //call intially
  const getrewards = async () => {
    const items: any[] = [];
    let items1: any[] = [];
    let data1: any[] = [];
    let data: any[] = [];
    if (searchInput) {
      setIsLoading(false);
      setFlag(true);
    } else {
      setIsLoading(true);
    }
    try {
      let qLenn = query(collection(db, 'reward'), orderBy('rewardType', 'desc'));
      if (searchInput) {
        const arr = searchInput.split(" ");
        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
        }
        const str = arr.join(" ");
        qLenn = query(qLenn, where('rewardType', '>=', str), where('rewardType', '<', str + 'z'))
      }
      const querySnapshotLen = await getCountFromServer(qLenn);
      setRow(querySnapshotLen.data().count);
      row = querySnapshotLen.data().count
      let q = query(collection(db, 'reward'), orderBy('rewardType', 'desc'), limit(limits));
      // if (searchInput) {
      //   const arr = searchInput.split(" ");
      //   for (let i = 0; i < arr.length; i++) {
      //     arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
      //   }
      //   const str = arr.join(" ");
      //   q = query(q, where('rewardType', '>=', str), where('rewardType', '<', str + 'z'))
      // }
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc: any) => {
        items.push({ id: doc.id, ...doc.data() });
      });

      const usersCollectionRef = collection(db, 'userReward');
      const q1 = query(usersCollectionRef);
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc: any) => {
        items1.push({ id: doc.id, ...doc.data() });
      });

      data1 = items.map((e: any) => {
        return ({
          ...e,
          total: items1.filter((d: any) => d.rewardType === e.rewardType)
        })
      })
      data = data1.map((e: any) => {
        return ({
          ...e,
          count: e.total.length,
        })
      })

      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setFirstVisible(querySnapshot.docs[0]);
      setRewards(data);
      setRow(data.length)
      if (items.length > 0) {
        setFlag(true);
      } else {
        setFlag(false);
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  }

  // close dialog
  const handleCloseDailog = () => {
    setIsOpen(false);
    setIsDel(false);
  };

  // edit
  const handleClickisEdit = (no: string, st1: string, st: number) => {
    setNameError(false);
    setNameErrorMsg("");
    setValueError(false);
    setValueErrorMsg("");
    let obj = {
      rewardId: no,
      rewardType: st1,
      rupees: st,
    }
    setReward(obj);
    setIsOpen(true);
  };

  // change value
  const handleChangeDialog = (arr: any) => {
    const { name, value } = arr.target;
    setReward({ ...reward, [name]: value });
    setIsOpen(true);
  }

  // call when save
  const saveReward = async (e: any) => {
    if (reward.rewardId) {
      let flag = validateForm(e);
      if (flag) {
        let obj = {
          rewardType: reward.rewardType,
          rupees: reward.rupees,
        }
        const datasRef = doc(db, 'reward', reward.rewardId);
        await setDoc(datasRef, obj, { merge: true });
        let data = JSON.parse(JSON.stringify(rewards));
        let ind = data.findIndex((c: any) => c.id === reward.rewardId);
        if (ind > -1) {
          data[ind].rewardType = reward.rewardType;
          data[ind].rupees = reward.rupees;
        }
        setRewards(data);
        setIsOpen(false);
      }
    }
    else {
      let flag = validateForm(e);
      if (flag) {
        const type: any[] = [];
        const rewardRef = collection(db, 'reward')
        const arr = reward.rewardType.split(" ");
        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
        }
        const str = arr.join(" ");
        const q = query(rewardRef, where('rewardType', '==', str))
        const querySnapshot1 = await getDocs(q);
        querySnapshot1.forEach((doc: any) => {
          type.push({ id: doc.id, ...doc.data() });
        });
        if (type.length > 0) {
          toast.error('Data Already Exists!', {
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored"
          });
        } else {
          const arr = reward.rewardType.split(" ");
          for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
          }
          const str = arr.join(" ");
          await addDoc(collection(db, "reward"), {
            rewardType: str,
            rupees: reward.rupees,
          });
          getrewards();
          setIsOpen(false);
        }
      }
    }
  }

  // delete
  const handleDelete = async () => {
    const rewardRef = collection(db, 'reward');
    try {
      const deleteRef = doc(rewardRef, reward.rewardId);
      deleteDoc(deleteRef);
      setIsDel(false);
      getrewards();
    }
    catch (error) {
      console.error(error);
    }
  }

  // when cloud function is called
  async function searchData(url = '', data = {}) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      return responseData; // return the response data
    } catch (error) {
      console.error(error);
    }
  }

  // invoked everytime when their is searchvalue in searchBox
  const searchItems = async (searchValue: any, startIndex: number, fetchRecord: number) => {
    const items: any[] = [];
    let items1: any[] = [];
    let data1: any[] = [];
    let data: any[] = [];
    setSearchInput(searchValue);
    searchInput = searchValue
    if (searchValue) {
      const requestBody = {
        query: searchValue,
        size: fetchRecord,
        from: startIndex
      };
      const result = await searchData(process.env.REACT_APP_REWARD_SEARCH, requestBody);
      for (let i = 0; i < result.data.length; i++) {
        const element = result.data[i]._source;
        const q = query(collection(db, 'reward'), where('rewardType', '==', element.rewardType), orderBy('rupees', 'desc'), limit(limits));
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });

        const usersCollectionRef = collection(db, 'userReward');
        const q1 = query(usersCollectionRef);
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach((doc: any) => {
          items1.push({ id: doc.id, ...doc.data() });
        });

        data1 = items.map((e: any) => {
          return ({
            ...e,
            total: items1.filter((d: any) => d.rewardType === e.rewardType)
          })
        })
        data = data1.map((e: any) => {
          return ({
            ...e,
            count: e.total.length,
          })
        })
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setFirstVisible(querySnapshot.docs[0]);
        setRewards(data);
        setRow(data.length)
        if (data.length > 0) {
          setFlag(true);
        } else {
          setFlag(false);
        }
      }
    } else {
      setSearchInput("");
      searchInput = ""
      await getrewards();
    }
  }

  // when next(>) and previous(<) button in pagination change which is on bottom right of the page
  const handlePageChange = async (event: any, newPage: number) => {
    if (searchInput) {
      setPage(newPage)
      searchItems(searchInput, newPage * limits, limits)
    } else {
      setPage(newPage);
      if (page < newPage) {
        let items: any[] = [];
        let items1: any[] = [];
        let data1: any[] = [];
        let data: any[] = [];
        let q = query(collection(db, 'reward'), orderBy('rewardType', 'desc'), startAfter(lastVisible), limit(limits));
        if (searchInput) {
          const arr = searchInput.split(" ");
          for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
          }
          const str = arr.join(" ");
          q = query(q, where('rewardType', '>=', str), where('rewardType', '<', str + 'z'))
        }
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const usersCollectionRef = collection(db, 'userReward');
        const q1 = query(usersCollectionRef);
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach((doc: any) => {
          items1.push({ id: doc.id, ...doc.data() });
        });

        data1 = items.map((e: any) => {
          return ({
            ...e,
            total: items1.filter((d: any) => d.rewardType === e.rewardType)
          })
        })
        data = data1.map((e: any) => {
          return ({
            ...e,
            count: e.total.length,
          })
        })

        updateState(querySnapshot)
        setRewards(data);
      } else {
        let items: any[] = [];
        let items1: any[] = [];
        let data1: any[] = [];
        let data: any[] = [];
        let q = query(collection(db, 'reward'), orderBy('rewardType', 'desc'), endBefore(firstVisible), limitToLast(limits));
        if (searchInput) {
          const arr = searchInput.split(" ");
          for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
          }
          const str = arr.join(" ");
          q = query(q, where('rewardType', '>=', str), where('rewardType', '<', str + 'z'))
        }
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach((doc: any) => {
          items.push({ id: doc.id, ...doc.data() });
        });
        const usersCollectionRef = collection(db, 'userReward');
        const q1 = query(usersCollectionRef);
        const querySnapshot1 = await getDocs(q1);
        querySnapshot1.forEach((doc: any) => {
          items1.push({ id: doc.id, ...doc.data() });
        });

        data1 = items.map((e: any) => {
          return ({
            ...e,
            total: items1.filter((d: any) => d.rewardType === e.rewardType)
          })
        })
        data = data1.map((e: any) => {
          return ({
            ...e,
            count: e.total.length,
          })
        })

        updateState(querySnapshot)
        setRewards(data);
      }
    };
  }

  // update first visible and last visible value according to pagination (next and previous button)
  const updateState = (documents: any) => {
    if (documents?.docs[0]) {
      setFirstVisible(documents.docs[0]);
    }
    if (documents?.docs[documents.docs.length - 1]) {
      setLastVisible(documents.docs[documents.docs.length - 1]);
    }
  };

  //no of rows in page limit change (ex: rows per page 10,20,30,40 in bottom right of the page)
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimits(parseInt(event.target.value));
    limits = parseInt(event.target.value)
    setPage(0);
    getrewards();
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <Helmet>
        <title> Reward | Vyanjan-Pursuit for Hunger! </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt="-7px">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  to="/admin/dashboard"
                  style={{ display: 'flex', color: "black" }}
                >
                  <HomeIcon />
                </Link>
                <Typography color="inherit">Reward</Typography>
              </Breadcrumbs>
              {/* <div className="largeScreenContent">
                <Button
                  sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                  variant="contained"
                  onClick={handleClickisAdd}
                  startIcon={<Iconify icon="eva:plus-fill" />}>
                  New Reward
                </Button>
              </div>
              <div className="smallScreenContent">
                <Button
                  sx={{ boxShadow: 'rgb(32 101 209 / 24%) 0px 8px 16px 0px' }}
                  variant="contained"
                  onClick={handleClickisAdd}
                >
                  <AddIcon />
                </Button>
              </div> */}
            </Stack>
            <Card sx={{ boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px', mt: "-7px" }}>
              <div>
                {isLoading ? <Loader title="Loading..." /> :
                  <>
                    <StyledRoot
                      sx={{
                        color: 'primary.main',
                        bgcolor: 'white',

                      }}
                    >
                      <StyledSearch
                        value={searchInput}
                        onChange={(e) => searchItems(e.target.value, (page * limits), limits)}
                        placeholder="Search by Reward Name..."
                        startAdornment={
                          <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                          </InputAdornment>
                        }
                      />
                    </StyledRoot>
                    {
                      (flag === true) ?
                        <>
                          <Scrollbar>
                            <TableContainer className="reward-list" sx={{ bgcolor: 'white' }}>
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell >#</TableCell>
                                    <TableCell align="left" sx={{ color: '#212B36' }}>
                                      <Typography noWrap fontWeight="600" fontSize="0.875rem">Reward Name</Typography>
                                    </TableCell>
                                    <TableCell align="left" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Amount</Typography></TableCell>
                                    <TableCell align="right" ><Typography noWrap fontWeight="600" fontSize="0.875rem">Action</Typography></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rewards.map((arr: any, index: any) => {
                                    return (
                                      <TableRow hover key={arr.id} sx={{ bgcolor: 'white' }}>
                                        <TableCell >
                                          {(page * limits) + index + 1}
                                        </TableCell>
                                        <TableCell align="left" sx={{ color: '#212B36' }}>
                                          <Typography variant="subtitle2" noWrap>
                                            {arr.rewardType}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="left" ><Typography noWrap fontSize="0.875rem">₹ {parseInt(arr.rupees).toFixed(2)}</Typography></TableCell>
                                        <TableCell align="right" >
                                          <Tooltip title="Edit" arrow>
                                            <IconButton
                                              sx={{ pt: 0.5, pb: 0.5, color: '#1565c0' }}
                                              color="inherit"
                                              size="small"
                                              onClick={(e) => handleClickisEdit(arr.id, arr.rewardType, arr.rupees)}
                                            >
                                              <EditTwoToneIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                          {/* {arr.count === 0 ?
                                            <Tooltip title="Delete" arrow>
                                              <IconButton
                                                sx={{ pt: 0.5, pb: 0.5, color: '#1565c0' }}
                                                color="inherit"
                                                size="small"
                                                onClick={(e) => handleClickisDelete(arr.id)}
                                              >
                                                <DeleteForeverIcon fontSize="small" />
                                              </IconButton>
                                            </Tooltip>
                                            : " "} */}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          <TablePagination
                            component="div"
                            count={row}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleLimitChange}
                            page={page}
                            rowsPerPage={limits}
                            rowsPerPageOptions={[10, 20, 30, 40]}
                          />
                        </>
                        :
                        <TableContainer className="reward-list-notFound" sx={{ backgroundColor: "white" }}>
                          <Table>
                            <Divider />
                            <TableBody>
                              <TableRow>
                                <Paper
                                  sx={{
                                    width: "160px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"
                                  }}
                                >
                                  <img src="\search.png" alt="search" height="50" width="50" style={{ marginLeft: "35%" }} />
                                  <Typography variant="h6" paragraph>
                                    Data not Available
                                  </Typography>
                                </Paper>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                    }
                  </>
                }
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <div className='dialogsmallScreenContent'>
        <Dialog open={isOpen} onClose={handleCloseDailog}
          PaperProps={{
            sx: {
              width: '300.33px',
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: '6px',
              boxShadow: 24,
              margin: '0px',
            }
          }}
          className='dialogsmallScreenContent'
        >
          <DialogTitle >
            {reward.rewardId ? "Edit Reward" : "Add Reward"}
            <IconButton
              sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 3, top: 12 }}
              onClick={handleCloseDailog}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          {reward.rewardId ?
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Reward Name"
                type="text"
                fullWidth
                variant="outlined"
                name="rewardType"
                disabled
                value={reward.rewardType}
                required={true}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{ }</FormHelperText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Rupeees"
                type="text"
                fullWidth
                variant="outlined"
                name="rupees"
                value={reward.rupees}
                onChange={(arr) => { handleChangeDialog(arr); validateValue(arr) }}
                required={true}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{isValueError && ValueErrorMsg}</FormHelperText>
            </DialogContent>
            :
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Reward Name"
                type="text"
                fullWidth
                variant="outlined"
                name="rewardType"
                value={reward.rewardType}
                onChange={(arr) => { handleChangeDialog(arr); validateName(arr) }}
                required={true}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{isNameError && NameErrorMsg}</FormHelperText>
              <TextField
                margin="dense"
                id="name"
                label="Rupees"
                type="text"
                fullWidth
                variant="outlined"
                name="rupees"
                value={reward.rupees}
                onChange={(arr) => { handleChangeDialog(arr); validateValue(arr) }}
                required={true}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{isValueError && ValueErrorMsg}</FormHelperText>
            </DialogContent>
          }
          <DialogActions sx={{ pl: '24px', pr: '24px' }} >
            <Button onClick={saveReward} >Save</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className='dialoglargeScreenContent'>
        <Dialog open={isOpen} onClose={handleCloseDailog}
          PaperProps={{
            sx: {
              width: "386.33px",
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: '6px',
              boxShadow: 24,
              margin: '0px',
            }
          }}
          className='dialoglargeScreenContent'
        >
          <DialogTitle >
            {reward.rewardId ? "Edit Reward" : "Add Reward"}
            <IconButton
              sx={{ fontSize: "2px", color: "grey", position: 'absolute', right: 3, top: 12 }}
              onClick={handleCloseDailog}
            >
              <CancelIcon />
            </IconButton>
          </DialogTitle>
          {reward.rewardId ?
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Reward Name"
                type="text"
                fullWidth
                variant="outlined"
                name="rewardType"
                disabled
                value={reward.rewardType}
                required={true}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{ }</FormHelperText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Rupeees"
                type="text"
                fullWidth
                variant="outlined"
                name="rupees"
                value={reward.rupees}
                onChange={(arr) => { handleChangeDialog(arr); validateValue(arr) }}
                required={true}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{isValueError && ValueErrorMsg}</FormHelperText>
            </DialogContent>
            :
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Reward Name"
                type="text"
                fullWidth
                variant="outlined"
                name="rewardType"
                value={reward.rewardType}
                onChange={(arr) => { handleChangeDialog(arr); validateName(arr) }}
                required={true}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{isNameError && NameErrorMsg}</FormHelperText>
              <TextField
                margin="dense"
                id="name"
                label="Rupees"
                type="text"
                fullWidth
                variant="outlined"
                name="rupees"
                value={reward.rupees}
                onChange={(arr) => { handleChangeDialog(arr); validateValue(arr) }}
                required={true}
              />
              <FormHelperText style={{ color: 'red', height: '22px' }}>{isValueError && ValueErrorMsg}</FormHelperText>
            </DialogContent>
          }
          <DialogActions sx={{ pl: '24px', pr: '24px' }} >
            <Button onClick={saveReward} >Save</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={isDel} onClose={handleCloseDailog}>
          <DialogTitle>Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDailog}>Cancel</Button>
            <Button onClick={handleDelete}>Delete</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}