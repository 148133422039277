import { useEffect, useState } from 'react';
import { Divider, Typography, Avatar, IconButton, Popover, Button, Box, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { auth, signOut } from '../../../firebase';
import LogoutIcon from '@mui/icons-material/Logout';
import './accountPopover.css';

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [user, setUser] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user') || '{}'));
  }, [])

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    setOpen(null);
    signOut(auth);
    navigate('/');
  }

  return (
    <>
      {
        user.map((arr: any) => (
          <>
            <IconButton
              className={open ? 'profile-open' : ''}
              onClick={handleOpen}
              sx={{
                p: 0
                // ...(open && {
                //   '&:before': {
                //   zIndex: 1,
                //   content: "''",
                //   width: '100%',
                //   height: '100%',
                //   borderRadius: '50%',
                //   position: 'absolute',
                //   bgcolor: 'grey',
                // },
                // }),
              }}
            >
              {arr.photoURL ? <Avatar src={arr.PhotoURL}></Avatar> : <Avatar src='./Image20230227180951.png'></Avatar>}
            </IconButton>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  p: 0,
                  mt: 1.5,
                  ml: 0.75,
                  width: 180,
                  '& .MuiMenuItem-root': {
                    typography: 'body2',
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle2" noWrap >
                  {(arr.firstName.charAt(0).toUpperCase() + arr.firstName.substr(1).toLowerCase())} {(arr.lastName.charAt(0).toUpperCase() + arr.lastName.substr(1).toLowerCase())}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap >
                  {arr.email}
                </Typography>
              </Box>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <MenuItem onClick={handleLogout} sx={{ m: 1 }} >
                Logout
              </MenuItem>
            </Popover>
          </>
        ))
      }
    </>
  );
}
